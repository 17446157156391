export enum TimesheetStatus {
  TIMESHEET_ENTERED_NOT_SAVED = 'E',
  TIMESHEET_SAVED_NOT_SUBMITTED = 'W',
  TIMESHEET_SUBMITTED_NOT_APPROVED = 'P',
  TIMESHEET_SUBMITTED_NOT_REVIEWED = 'G',
  TIMESHEET_APPROVED = 'V',
  TIMESHEET_RETURNED_NOT_APPROVED = 'R',
  TIMESHEET_RETURNED_NOT_SUBMITTED = 'N',
  TIMESHEET_CANCELLED = 'C',
  TIMESHEET_INVOICED = 'I',
  UVL_TIMESHEET_SAVED_NOT_SUBMITTED = 'U',
  UVL_TIMESHEET_SUBMITTED = 'S',
  UVL_TIMESHEET_CANCELLED = 'L',
}

export enum PurchaseOrderStatus {
  APPROVED = 'V',
  APPROVED_NO_RATES = 'N',
  APPROVED_PENDING_RATES_REVIEW = 'R',
  APPROVED_PENDING_RATES_REVIEW_SUBMITTED = 'P',
  PENDING_INITIAL_REVIEW = 'I',
  PENDING_INITIAL_REVIEW_SUBMITTED = 'S',
  PENDING_INITIAL_REVIEW_DECLINED = 'D',
  REQUEST_VENDOR_COMPLETION_FORM = 'F',
  SUBMIT_VENDOR_COMPLETION_FORM = 'C',
  APPROVE_VENDOR_COMPLETION_FORM = 'A',
  CANCELLED_PENDING_ORDER = 'E',
  CLOSED_PENDING_ORDER = 'L',
  PENDING_INITIAL_BU_REVIEW = 'BR',
  PENDING_INITIAL_BU_REVIEW_DECLINED = 'BD',
}

export enum InvoiceStatus {
  C_INVOICE_ENTERED_NOT_SAVED = 'E',
  C_INVOICE_SAVED_NOT_SUBMITTED = 'W',
  C_INVOICE_SAVED_NOT_SUBMITTED_RETURNED = 'RS',
  C_INVOICE_SUBMITTED_NOT_REVIEWED = 'P',
  C_PENDING = 'D',
  M_INVOICE_ENTERED = 'V',
  M_MISMATCH = 'M',
  M_APPROVED_NOT_PAID = 'A',
  M_PAID = 'MP',
  M_PAID_AP = 'AP',
  C_RETURNED_TO_TIMESHEET = 'T',
  C_INVOICE_SUBMITTED_NOT_REVIEWED_RETURNED = 'R',
  C_M_REVERSED_INVOICE_RETURNED_FROM_PEOPLESOFT = 'PS',
  M_FINAPPR = 'F',
  M_FINERROR = 'FE',
  M_WAPPR = 'X',
  C_INVOICE_REVIEWED_FAILED_TO_CREATE = 'C',
  C_INVOICE_REVIEWED_FAILED_TO_CREATE_PO_ISSUE = 'I',
  C_INVOICE_REVIEWED_FAILED_TO_CREATE_CAA_ISSUE = 'K',
  C_INVOICE_REVIEWED_FAILED_TO_CREATE_DUPLICATE = 'L',
  C_INVOICE_REVIEWED_FAILED_TO_CREATE_WOISSUE = 'N',
}

export enum BidRateSheetStatus {
  saved = 'SAVED',
  cancelled = 'CANCEL',
}
