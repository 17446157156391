import {
  LabelFieldMapping,
  POCategoryFieldMapping,
  PORateKeyMapping,
} from 'constants/poRates.ts';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
import _ from 'lodash';
import { isEmptyVal } from 'utils/utils';

const classificationListOptions = {
  [SERVICE_TYPES.LABOR]: [],
  [SERVICE_TYPES.EQUIPMENT]: [],
  [SERVICE_TYPES.REIMBURSABLE_MARKUP]: [],
  [SERVICE_TYPES.UNIT_PRICE_TASK]: [],
  [SERVICE_TYPES.LUMPSUM]: [],
  [SERVICE_TYPES.CU]: [],
  [SERVICE_TYPES.PRODUCTION_HOURS]: [],
};

export const getCostActivityOptions = (
  costActivities,
  polines,
  isZeroLineCostHidden,
) => {
  const costActivityOptions = [];

  costActivities?.forEach(obj => {
    if (isZeroLineCostHidden) {
      let findActivityByPoid = _.find(polines, {
        poid: obj.poid,
        polinenum: obj.polinenum,
      });
      if (findActivityByPoid?.linecost > 0) {
        costActivityOptions.push({
          value: obj?.costactivityid,
          label: obj?.activitylabel,
          description: obj?.description,
          remainingdollar: obj?.remainingdollar,
          timesheetapprover: obj?.timesheetapprover,
          costactivityid: obj?.costactivityid,
        });
      }
    } else {
      costActivityOptions.push({
        value: obj?.costactivityid,
        label: obj?.activitylabel,
        description: obj?.description,
        remainingdollar: obj?.remainingdollar,
        timesheetapprover: obj?.timesheetapprover,
        costactivityid: obj?.costactivityid,
      });
    }
  });

  return [
    { label: 'Select', value: '' },
    ..._.sortBy(_.uniqBy(costActivityOptions, 'value'), ['label']),
  ];
};

export const getCostActivityBySTOptions = (costActivities, polines, flag) => {
  const costActivityOptions = _.cloneDeep(classificationListOptions);
  for (const [classification, optionList] of Object.entries(costActivities)) {
    costActivityOptions[classification] = getCostActivityOptions(
      optionList,
      polines,
      flag,
    );
  }
  return costActivityOptions;
};

export const getRateScheduleOptions = purchaseOrder => {
  const rateScheduleOptions = _.cloneDeep(classificationListOptions);

  for (const [classification, optionList] of Object.entries(
    rateScheduleOptions,
  )) {
    const rateListKey = PORateKeyMapping[classification];
    purchaseOrder[rateListKey]?.forEach(obj =>
      optionList.push({
        value: obj?.[LabelFieldMapping['rateSchLabel']],
        label: obj?.[LabelFieldMapping['rateSchLabel']],
      }),
    );
    rateScheduleOptions[classification] = [
      { label: 'Select', value: '' },
      ..._.sortBy(_.uniqBy(optionList, 'label'), ['label']),
    ];
  }
  return rateScheduleOptions;
};

export const getCodeOptions = purchaseOrder => {
  const codeOptions = _.cloneDeep(classificationListOptions);

  for (const [classification, optionList] of Object.entries(codeOptions)) {
    const rateListKey = PORateKeyMapping[classification];
    purchaseOrder[rateListKey]?.forEach(obj =>
      optionList.push({
        value: obj?.[LabelFieldMapping['codeLabel']],
        label: obj?.[LabelFieldMapping['codeLabel']],
      }),
    );
    codeOptions[classification] = [
      { label: 'Select', value: '' },
      ..._.sortBy(_.uniqBy(optionList, 'label'), ['label']),
    ];
  }

  return codeOptions;
};

export const getCategoryOptions = purchaseOrder => {
  const categoryOptions = {
    [SERVICE_TYPES.LABOR]: [],
    [SERVICE_TYPES.EQUIPMENT]: [],
  };

  purchaseOrder['polaborrates']?.forEach(obj =>
    categoryOptions[SERVICE_TYPES.LABOR].push({
      value: obj?.[POCategoryFieldMapping[SERVICE_TYPES.LABOR]],
      label: obj?.[POCategoryFieldMapping[SERVICE_TYPES.LABOR]],
    }),
  );

  purchaseOrder['poeqptrates']?.forEach(obj =>
    categoryOptions[SERVICE_TYPES.EQUIPMENT].push({
      value: obj?.[POCategoryFieldMapping[SERVICE_TYPES.EQUIPMENT]],
      label: obj?.[POCategoryFieldMapping[SERVICE_TYPES.EQUIPMENT]],
    }),
  );

  for (let classificationName in categoryOptions) {
    categoryOptions[classificationName] = [
      { value: '', label: 'Select' },
      ..._.sortBy(_.uniqBy(categoryOptions[classificationName], 'label'), [
        'label',
      ]),
    ];
  }
  return categoryOptions;
};

export const getShiftOptions = purchaseOrder => {
  const shiftOptions = [{ label: 'Select', value: '' }];

  let showStandardShift = false;
  let showFirstShift = false;
  let showSecondShift = false;
  let showThirdShift = false;

  purchaseOrder?.['polaborrates']?.forEach(obj => {
    if (
      !isEmptyVal(obj.stlaborrate) ||
      !isEmptyVal(obj.otlaborrate) ||
      !isEmptyVal(obj.dtlaborrate)
    ) {
      showStandardShift = true;
    }

    if (
      !isEmptyVal(obj.stloadedrate) ||
      !isEmptyVal(obj.thloadedrate) ||
      !isEmptyVal(obj.dtloadedrate)
    ) {
      showFirstShift = true;
    }

    if (
      !isEmptyVal(obj.scndshftstloadedrate) ||
      !isEmptyVal(obj.scndshftthloadedrate) ||
      !isEmptyVal(obj.scndshftdtloadedrate)
    ) {
      showSecondShift = true;
    }

    if (
      !isEmptyVal(obj.thrdshftstloadedrate) ||
      !isEmptyVal(obj.thrdshftthloadedrate) ||
      !isEmptyVal(obj.thrdshftdtloadedrate)
    ) {
      showThirdShift = true;
    }
  });

  if (showStandardShift) {
    shiftOptions.push({ label: 'Standard', value: '0' });
  }
  if (showFirstShift) {
    shiftOptions.push({ label: '1', value: '1' });
  }
  if (showSecondShift) {
    shiftOptions.push({ label: '2', value: '2' });
  }
  if (showThirdShift) {
    shiftOptions.push({ label: '3', value: '3' });
  }

  return shiftOptions;
};

export const formatInvoiceData = data => {
  const timesheetAccount = data.timesheets.flatMap(
    timesheet => timesheet.timesheetAccounts,
  );

  const invoiceData = {
    activities: [],
    [SERVICE_TYPES.LABOR]: [],
    [SERVICE_TYPES.EQUIPMENT]: [],
    [SERVICE_TYPES.REIMBURSABLE_MARKUP]: [],
    [SERVICE_TYPES.UNIT_PRICE_TASK]: [],
    [SERVICE_TYPES.CU]: [],
    fuelAdjCost: data?.fuelAdjCost,
    travelAmount: data?.travelAmount,
    smallJobAmount: data?.smallJobAmount,
    deEnergizedAmount: data?.deEnergizedAmount,
    totalLaborAmount: data?.totalLaborAmount,
    totalEquipmentAmount: data?.totalEquipmentAmount,
    totalReimbursable: data?.totalReimbursable,
    totalUpTask: data?.totalUpTask,
    totalLumpsum: data?.totalLumpsum,
    totalCus: data?.totalCus,
    totalActivity: data?.totalActivity,
    invoiceAmount: data?.invoiceAmount,
    exceedsPOLineThreshold: data?.exceedsPolineThreshold,
    exceedsPOCommitted: data?.exceedsPoCommitted,
    timesheets: data?.timesheets,
    timesheetAccounts: timesheetAccount,
    purchaseOrder: data?.purchaseOrder,
    weekStartDate: data?.weekStartDate,
    weekEndDate: data?.weekEndDate,
    createMaxInv: data?.createMaxInv,
    totalActivityLabor: data?.totalActivityLabor,
    totalActivityEqp: data?.totalActivityEqp,
    totalActivityExpense: data?.totalActivityExpense,
    totalActivityReimb: data?.totalActivityReimb,
    totalActivityUptask: data?.totalActivityUptask,
    invoiceCosts: data?.invoiceCosts,
    invoiceLines: data?.invoiceLines,
    contractNmbr: data?.contractNmbr,
    invAdj: [],
  };

  if (data?.hasOwnProperty('activities')) {
    invoiceData['activities'] = data?.activities;
  }

  if (data?.hasOwnProperty('invoiceLabors')) {
    invoiceData[SERVICE_TYPES.LABOR] = data?.invoiceLabors;
  }

  if (data?.hasOwnProperty('invoiceEquipments')) {
    invoiceData[SERVICE_TYPES.EQUIPMENT] = data?.invoiceEquipments;
  }

  if (data?.hasOwnProperty('invoiceReimbursables')) {
    invoiceData[SERVICE_TYPES.REIMBURSABLE_MARKUP] = data?.invoiceReimbursables;
  }
  if (data?.hasOwnProperty('invoiceLumpsums')) {
    invoiceData[SERVICE_TYPES.LUMPSUM] = data?.invoiceLumpsums;
  }

  if (data?.hasOwnProperty('invoiceUptasks')) {
    invoiceData[SERVICE_TYPES.UNIT_PRICE_TASK] = data?.invoiceUptasks;
  }

  if (data?.hasOwnProperty('invoiceCus')) {
    invoiceData[SERVICE_TYPES.CU] = data?.invoiceCus;
  }
  let totalAdj = 0;
  if (data?.hasOwnProperty('fuelAdjCost') && data?.fuelAdjCost) {
    totalAdj += data?.fuelAdjCost;
    // @ts-ignore
    invoiceData.invAdj.push({
      name: 'Fuel Adjustment Cost',
      value: data?.fuelAdjCost,
    });
  }
  if (data?.hasOwnProperty('deEnergizedAmount') && data?.deEnergizedAmount) {
    totalAdj += data?.deEnergizedAmount;
    // @ts-ignore
    invoiceData.invAdj.push({
      name: 'De-Energized Amount',
      value: data?.deEnergizedAmount,
    });
  }
  if (data?.hasOwnProperty('smallJobAmount') && data?.smallJobAmount) {
    totalAdj += data?.smallJobAmount;
    // @ts-ignore
    invoiceData.invAdj.push({
      name: 'Small Job Amount',
      value: data?.smallJobAmount,
    });
  }
  if (data?.hasOwnProperty('travelAmount') && data?.travelAmount) {
    totalAdj += data?.travelAmount;
    // @ts-ignore
    invoiceData.invAdj.push({
      name: 'Travel Amount',
      value: data?.travelAmount,
    });
  }
  if (totalAdj) {
    invoiceData.invAdj.push({
      name: 'Total Adjustments',
      value: totalAdj,
    });
  }

  return invoiceData;
};

export const getServiceItemsOptions = lookup => {
  const serviceItemsOptions = _.cloneDeep(classificationListOptions);

  for (const [classification, optionList] of Object.entries(
    serviceItemsOptions,
  )) {
    if (classification === SERVICE_TYPES.PRODUCTION_HOURS) {
      lookup?.serviceItems?.forEach(obj => {
        const itemnum = obj.itemnum || '';
        const description = obj.description || '';
        optionList.push({
          label: `${itemnum} - ${description}`,
          value: itemnum,
        });
      });
    }
    serviceItemsOptions[classification] = [
      { label: 'Select', value: '' },
      ..._.sortBy(_.uniqBy(optionList, 'label'), ['label']),
    ];
  }
  return serviceItemsOptions;
};

export const getUnitOfMeasureOptions = lookup => {
  const unitOfMeasureOptions = _.cloneDeep(classificationListOptions);

  for (const [classification, optionList] of Object.entries(
    unitOfMeasureOptions,
  )) {
    if (classification === SERVICE_TYPES.PRODUCTION_HOURS) {
      lookup?.unitOfMeasure?.forEach(obj => {
        optionList.push({
          label: obj.label,
          value: obj.value,
          taskcodes: obj.taskcodes,
        });
      });
    }
    unitOfMeasureOptions[classification] = [
      { label: 'Select', value: '' },
      ..._.sortBy(_.uniqBy(optionList, 'label'), ['label']),
    ];
  }
  return unitOfMeasureOptions;
};
