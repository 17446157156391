import { createSlice } from '@reduxjs/toolkit';
import { getLocalDateTime } from 'utils/utils';
import { TimesheetStatusDisplayValue } from 'constants/statusValues';
const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  items: [],
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
    ponum: '',
    weekEndDate: '',
    workAreaId: '',
    caastatus: '',
    transWorkorder: '',
    crewType: '',
    bupath: '',
    circuitNmbr: '',
    authNmbr: '',
    contractNmbr: '',
    workStartDate: '',
    workEndDate: '',
    weekEndDate: '',
    vendor: '',
    worktype: '',
    estimate: '',
    forceFetch: false,
    enteredBy: '',
    lastUpdated: '',
    showAssigned: '',
  },
  inputList: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  recordItems: [],
  isTimesheetUploaded: false,
};

const timesheetSlice = createSlice({
  name: 'timesheetSlice',
  initialState,
  reducers: {
    fetchTimesheets(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = true;
      stateValues.pagination = { ...stateValues.pagination, ...action.payload };
      return { ...stateValues };
    },

    fetchSelectedTimesheetList(state) {
      state.isLoading = false;
    },

    setInputFilterData(state, action) {
      if (action.payload.length != 0) {
        state.inputList = action.payload;
      } else {
        const stateValues = { ...state };
        stateValues.inputList = [...initialState.inputList];
        return { ...stateValues };
      }
    },

    setSelectedTimesheetRecords(state, action) {
      const selectedTimesheetRecords = [];
      action.payload?.forEach(obj => {
        let objKeys = Object.keys(obj);
        if (objKeys[0] == 'uvl') {
          let objValues = Object.values(obj);
          selectedTimesheetRecords.push({
            label: objValues[0] ? 'Y' : 'N',
            value: objValues[0] ? 'Y' : 'N',
          });
        } else if (objKeys[0] == 'caastatus') {
          let objValues = Object.values(obj);
          selectedTimesheetRecords.push({
            label: TimesheetStatusDisplayValue[objValues[0]],
            value: TimesheetStatusDisplayValue[objValues[0]],
          });
        } else {
          let objValues = Object.values(obj);
          selectedTimesheetRecords.push({
            label: objValues[0],
            value: objValues[0],
          });
        }
      });
      const stateValues = { ...state };
      stateValues.recordItems = [...selectedTimesheetRecords];
      return { ...stateValues };
    },

    setAddNewInputFilterData(state, action) {
      const stateValues = { ...state };
      stateValues.inputList = [...stateValues.inputList, action.payload];
      return { ...stateValues };
    },

    saveTimesheet(state) {
      state.isLoading = false;
    },

    saveUploadTimesheet(state, action) {
      state.isLoading = false;
    },

    setTimesheets(state, action) {
      const timesheetList = [];
      action.payload.rows.forEach(obj => {
        if (obj.ewoTimesheet != true) {
          timesheetList.push({
            recordNmbr: obj.recordNmbr,
            po: obj.po,
            contractNmbr: obj.contractNmbr,
            ponum: obj.ponum,
            poid: obj.poid,
            vendorTimesheet: obj.vendorTimesheet,
            workEndDate: obj.workEndDate,
            workStartDate: obj.workStartDate,
            weekEndDate: obj.weekEndDate,
            enteredBy: obj.enteredBy,
            lastUpdated: getLocalDateTime(obj.updatedDate),
            caastatus: obj.caastatus,
          });
        }
      });
      const stateValues = { ...state };
      stateValues.items = [...timesheetList];
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },

    fetchPurchaseOrderTimesheet(state) {
      state.isLoading = false;
    },

    setPurchaseOrderTimesheet(state, action) {
      const poTimesheetList = [];
      action.payload.forEach(obj => {
        poTimesheetList.push({
          timesheetID: obj.recordNmbr,
          vendor: obj.vendorTimesheet,
          status: obj.status,
        });
      });
      const stateValues = { ...state };
      stateValues.items = [...poTimesheetList];
      return { ...stateValues };
    },

    setTimesheetPagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = { ...stateValues.pagination, ...action.payload };
      return { ...stateValues };
    },

    resetTimesheets() {
      return { ...initialState };
    },

    returnTimesheets(state, action) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },

    setIsLoading(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = action.payload;
      return { ...stateValues };
    },

    uploadTimesheetSuccess(state) {
      const stateValues = { ...state };
      stateValues.isTimesheetUploaded = true;
      return { ...stateValues };
    },

    uploadTimesheetReset(state) {
      const stateValues = { ...state };
      stateValues.isTimesheetUploaded = false;
      return { ...stateValues };
    },
  },
});

export const {
  fetchTimesheets,
  fetchSelectedTimesheetList,
  saveTimesheet,
  saveUploadTimesheet,
  setTimesheets,
  setInputFilterData,
  setAddNewInputFilterData,
  setSelectedTimesheetRecords,
  fetchPurchaseOrderTimesheet,
  setPurchaseOrderTimesheet,
  setTimesheetPagination,
  resetTimesheets,
  setError,
  unsetError,
  returnTimesheets,
  setIsLoading,
  uploadTimesheetSuccess,
  uploadTimesheetReset,
} = timesheetSlice.actions;

export default timesheetSlice.reducer;
