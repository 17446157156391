import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { FilterChipCloseIcon } from 'assets/images/FilterChipCloseIcon/FilterChipCloseIcon';
import { Divider } from 'components/common/Divider/Divider';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
import { Col, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { currencyFormat } from 'utils/formatHelper';
import { getClassificationLabel } from '../utils';
import moment from 'moment';

const PreviewInvoiceModal = ({ showModal, setShowModal }) => {
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);
  const timesheet = useSelector(state => state.timesheetDetail);
  const invoiceData = useSelector(state => state.lookup.invoiceData);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="xl"
      dialogClassName="invoicePreviewDialog"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            height: '26px',
          }}
        >
          <div className="backArrow">
            <BackArrowIcon styles={{ marginTop: '-4px' }} />
          </div>
          PO: {timesheet.ponum}
          <div
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            onClick={() => setShowModal(false)}
          >
            <FilterChipCloseIcon />
          </div>
        </div>
      </Modal.Header>
      <div
        style={{
          margin: '0 0 24px 24px',
          fontSize: '16px',
        }}
      >
        Timesheet Record {timesheet.recordNmbr}
      </div>
      <Divider />
      <Modal.Body className="invoicePreviewModalBody">
        <div className="aepContainer">
          <div className="innerContainer">
            <Row>
              {invoiceData.weekStartDate ? (
                <Col sm={5}>
                  <Form.Label>Invoice Start Date</Form.Label>
                  <br />
                  <Form.Control
                    value={moment(
                      invoiceData?.weekStartDate,
                      'YYYY-MM-DD',
                    ).format('MM/DD/YYYY')}
                    type="text"
                    disabled
                  />
                </Col>
              ) : null}
              {invoiceData.weekEndDate ? (
                <Col sm={7}>
                  <Form.Label>Invoice End Date</Form.Label>
                  <br />
                  <Form.Control
                    value={moment(
                      invoiceData?.weekEndDate,
                      'YYYY-MM-DD',
                    ).format('MM/DD/YYYY')}
                    type="text"
                    disabled
                  />
                </Col>
              ) : null}
            </Row>

            <Row className="lastChild">
              <Col sm={4}>
                <Form.Label>Vendor Contact Name</Form.Label>
                <br />
                <Form.Control
                  value={purchaseOrder?.originalData?.remitcompanyname}
                  type="text"
                  disabled
                />
              </Col>
              <Col sm={4}>
                <Form.Label>Telephone Number</Form.Label>
                <br />
                <Form.Control
                  value={purchaseOrder?.originalData?.remitcompanyphone}
                  type="text"
                  disabled
                />
              </Col>
              <Col sm={4}>
                <Form.Label>Remit Address</Form.Label>
                <br />
                <Form.Control
                  value={[
                    purchaseOrder?.originalData?.remitcompanyaddress1,
                    purchaseOrder?.originalData?.remitcompanyaddress2,
                    purchaseOrder?.originalData?.remitcompanyaddress3,
                    purchaseOrder?.originalData?.remitcompanyaddress4,
                    purchaseOrder?.originalData?.remitcompanyaddress5,
                  ]
                    .filter(Boolean)
                    .join(', ')}
                  type="text"
                  style={{ fontSize: '11.5px' }}
                  disabled
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className="aepContainer lastChild">
          <div className="innerContainer">
            {/* ********** */}
            {/* ACTIVITIES */}
            {/* ********** */}
            {invoiceData?.activities?.length > 0 && (
              <>
                <div className="tableTitle">Activities</div>
                <Table hover responsive striped bordered={false}>
                  <thead>
                    <tr>
                      <th>Activity Number</th>
                      <th>Activity Description</th>
                      <th className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData?.activities?.map((obj, index) => (
                      <tr key={index} className="tRow table-hover-row">
                        <td>{obj.activitynum}</td>
                        <td>{obj.activityDescription}</td>
                        <td className="text-end">
                          {currencyFormat(obj.amount)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <thead>
                    <tr className="tableFooter">
                      <th>Grand Total</th>
                      <th></th>
                      <th className="text-end">
                        {currencyFormat(invoiceData?.totalActivity)}
                      </th>
                    </tr>
                  </thead>
                </Table>
              </>
            )}

            {/* ***** */}
            {/* LABOR */}
            {/* ***** */}
            {invoiceData?.[SERVICE_TYPES.LABOR]?.length > 0 && (
              <>
                <div className="tableTitle">Labor</div>
                <Table hover responsive striped bordered={false}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Rate</th>
                      <th>Hours</th>
                      <th>Units</th>
                      <th className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData[SERVICE_TYPES.LABOR]?.map((row, indx) => (
                      <tr key={indx} className="tRow table-hover-row">
                        <td>{row.laborCode}</td>
                        <td>{row.employeeName}</td>
                        <td>{getClassificationLabel(row.laborType)}</td>
                        <td>{row.laborRate}</td>
                        <td>{row.laborHours}</td>
                        <td>{row.laborUnits}</td>

                        <td className="text-end">
                          {currencyFormat(row.lineTotal)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <thead>
                    <tr className="tableFooter">
                      <th>Total Labor Cost</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="text-end">
                        {currencyFormat(invoiceData?.totalLaborAmount)}
                      </th>
                    </tr>
                  </thead>
                </Table>
              </>
            )}

            {/* ********* */}
            {/* EQUIPMENT */}
            {/* ********* */}
            {invoiceData?.[SERVICE_TYPES.EQUIPMENT]?.length > 0 && (
              <>
                <div className="tableTitle">Equipment</div>
                <Table hover responsive striped bordered={false}>
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Equipment Id</th>
                      <th>Type</th>
                      <th>Rate</th>
                      <th>Hours</th>
                      <th>Units</th>
                      <th className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData[SERVICE_TYPES.EQUIPMENT]?.map((row, indx) => (
                      <tr key={indx} className="tRow table-hover-row">
                        <td>{row.equipDescription}</td>
                        <td>{row.equipId}</td>
                        <td>{getClassificationLabel(row.equipType)}</td>
                        <td>{row.equipRate}</td>
                        <td>
                          {row.equipType === 'opHours' ? row.equipHours : ''}
                        </td>
                        <td>
                          {row.equipType === 'equipmentRentalUnits'
                            ? row.equipHours
                            : row.equipUnits}
                        </td>
                        <td className="text-end">
                          {currencyFormat(row.lineTotal)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <thead>
                    <tr className="tableFooter">
                      <th>Total Equipment Cost</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="text-end">
                        {currencyFormat(invoiceData?.totalEquipmentAmount)}
                      </th>
                    </tr>
                  </thead>
                </Table>
              </>
            )}

            {/* ******************* */}
            {/* REIMBURSABLE MARKUP */}
            {/* ******************* */}
            {invoiceData?.[SERVICE_TYPES.REIMBURSABLE_MARKUP]?.length > 0 && (
              <>
                <div className="tableTitle">Reimbursable Markup</div>
                <Table hover responsive striped bordered={false}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Rate</th>
                      <th>Quantity</th>
                      <th>Base Price Markup</th>
                      <th>Delivery Markup</th>
                      <th>Misc Markup</th>
                      <th className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData[SERVICE_TYPES.REIMBURSABLE_MARKUP]?.map(
                      (row, indx) => (
                        <tr key={indx} className="tRow table-hover-row">
                          <td>{row.reimbCode}</td>
                          <td>{row.reimbRate}</td>
                          <td>{row?.reimbUnits}</td>
                          <td>{row.basepricemarkup}</td>
                          <td>{row.deliverymarkup}</td>
                          <td>{row.miscellaneousmarkup}</td>
                          <td className="text-end">
                            {currencyFormat(row.lineTotal)}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                  <thead>
                    <tr className="tableFooter">
                      <th>Total Reimbursable Cost</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="text-end">
                        {currencyFormat(invoiceData?.totalReimbursable)}
                      </th>
                    </tr>
                  </thead>
                </Table>
              </>
            )}

            {/* *************** */}
            {/* UNIT PRICE TASK */}
            {/* *************** */}
            {invoiceData?.[SERVICE_TYPES.UNIT_PRICE_TASK]?.length > 0 && (
              <>
                <div className="tableTitle">Unit Price Task</div>
                <Table hover responsive striped bordered={false}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Rate</th>
                      <th>Quantity</th>
                      <th className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData[SERVICE_TYPES.UNIT_PRICE_TASK]?.map(
                      (row, indx) => (
                        <tr key={indx} className="tRow table-hover-row">
                          <td>{row.uptaskCode}</td>
                          <td>{row?.uptaskRate}</td>
                          <td>{row?.uptaskUnits}</td>
                          <td className="text-end">
                            {currencyFormat(row.lineTotal)}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                  <thead>
                    <tr className="tableFooter">
                      <th>Total Unit Price Cost</th>
                      <th></th>
                      <th></th>
                      <th className="text-end">
                        {currencyFormat(invoiceData?.totalUpTask)}
                      </th>
                    </tr>
                  </thead>
                </Table>
              </>
            )}
            {/* ***** */}
            {/* LUMPSUM */}
            {/* ***** */}
            {invoiceData?.[SERVICE_TYPES.LUMPSUM]?.length > 0 && (
              <>
                <div className="tableTitle">Expense</div>
                <Table hover responsive striped bordered={false}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Rate</th>
                      <th>Units</th>
                      <th className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData[SERVICE_TYPES.LUMPSUM]?.map((row, indx) => (
                      <tr key={indx} className="tRow table-hover-row">
                        <td>{row.activitynum}</td>
                        <td>{row.activityDescription}</td>
                        <td>{row.lumpsumRate}</td>
                        <td>{row.lumpsumUnits}</td>

                        <td className="text-end">
                          {currencyFormat(row.lineTotal)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <thead>
                    <tr className="tableFooter">
                      <th>Total Labor Cost</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="text-end">
                        {currencyFormat(invoiceData?.totalLumpsum)}
                      </th>
                    </tr>
                  </thead>
                </Table>
              </>
            )}
            {/* ***** */}
            {/* LUMPSUM */}
            {/* ***** */}
            {invoiceData?.[SERVICE_TYPES.CU]?.length > 0 && (
              <>
                <div className="tableTitle">CU</div>
                <Table hover responsive striped bordered={false}>
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Rate</th>
                      <th>Units</th>
                      <th>Action</th>
                      <th className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData[SERVICE_TYPES.CU]?.map((row, indx) => (
                      <tr key={indx} className="tRow table-hover-row">
                        <td>{row.cuGroupCode}</td>
                        <td>{row.cuGroupRate}</td>
                        <td>{row.quantity}</td>
                        <th>{row.action}</th>
                        <td className="text-end">
                          {currencyFormat(row.lineTotal)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <thead>
                    <tr className="tableFooter">
                      <th>Total Cost</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="text-end">
                        {currencyFormat(invoiceData?.totalCus)}
                      </th>
                    </tr>
                  </thead>
                </Table>
              </>
            )}

            {/* ******************** */}
            {/* INVOICE ADJUSTMENT COST */}
            {/* ******************** */}
            {/* {Math.ceil(invoiceData?.fuelAdjCost ?? 0) > 0 && ( */}
            {(Math.ceil(invoiceData?.fuelAdjCost) !== 0 ||
              Math.ceil(invoiceData?.deEnergizedAmount) !== 0 ||
              Math.ceil(invoiceData?.smallJobAmount) !== 0 ||
              Math.ceil(invoiceData?.travelAmount) !== 0) && (
              <>
                <div className="tableTitle">Invoice Adjustments</div>
                <Table hover responsive striped bordered={false}>
                  {Math.ceil(invoiceData?.fuelAdjCost) !== 0 && (
                    <tr>
                      <th>Fuel Adjustment Cost</th>
                      <th className="text-end">
                        {currencyFormat(invoiceData?.fuelAdjCost)}
                      </th>
                    </tr>
                  )}
                  {Math.ceil(invoiceData?.deEnergizedAmount) !== 0 && (
                    <tr>
                      <th>De-Energized Amount</th>
                      <th className="text-end">
                        {currencyFormat(invoiceData?.deEnergizedAmount)}
                      </th>
                    </tr>
                  )}
                  {Math.ceil(invoiceData?.smallJobAmount) !== 0 && (
                    <tr>
                      <th>Small Job Amount</th>
                      <th className="text-end">
                        {currencyFormat(invoiceData?.smallJobAmount)}
                      </th>
                    </tr>
                  )}
                  {Math.ceil(invoiceData?.travelAmount) !== 0 && (
                    <tr>
                      <th>Travel Amount</th>
                      <th className="text-end">
                        {currencyFormat(invoiceData?.travelAmount)}
                      </th>
                    </tr>
                  )}
                </Table>
              </>
            )}
            <Table hover responsive striped bordered={false}>
              <thead>
                <tr className="tableFooter ">
                  <th className="text-end">
                    <span className="float-right">
                      Invoice Total:&nbsp;
                      {currencyFormat(invoiceData?.invoiceAmount)}
                    </span>
                  </th>
                </tr>
              </thead>
            </Table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { PreviewInvoiceModal };
