export enum SERVICE_TYPES {
  LABOR = 'timesheetLabors',
  EQUIPMENT = 'timesheetEquipments',
  REIMBURSABLE_MARKUP = 'timesheetReimbursables',
  UNIT_PRICE_TASK = 'timesheetUptasks',
  CU = 'timesheetCus',
  LUMPSUM = 'timesheetLumpsums',
  RATES = 'poRates',
  PRODUCTION_HOURS = 'timesheetProductionhours',
}

export const bidRaterSheetFinalSaveData = [
  {
    bidrateEquipmentRates: [],
    bidrateLaborRates: [],
    bidrateCus: [],
    bidrateReimbursibleMarkups: [],
    bidrateTaskCodes: [],
    bidrateUndergrndMltplrRates: [],
    bidrateUnionRates: [],
    bidrateUnitPrices: [],
  },
];

export enum SERVICE_TYPES_TABkEY {
  bidrateEquipmentRates = 'equipment',
  bidrateLaborRates = 'laborRates',
  bidrateCus = 'compatibleUnitGroups',
  bidrateReimbursibleMarkups = 'reimbursableMarkup',
  bidrateTaskCodes = 'taskCodeRates',
  bidrateUndergrndMltplrRates = 'undergroundMultiplier',
  bidrateUnitPrices = 'unitPriceRates',
  bidrateUnionRates = 'markups',
}

export enum SERVICE_TYPES_TABVALUES {
  bidrateEquipmentRates = 'Equipment Rates',
  bidrateLaborRates = 'Labor Rates',
  bidrateCus = 'Compatible Unit Groups (Overhead)',
  bidrateReimbursibleMarkups = 'Reimbursable Mark-ups',
  bidrateTaskCodes = 'Task Code Rates',
  bidrateUndergrndMltplrRates = 'Underground Multiplier Rates',
  bidrateUnitPrices = 'Unit Price Rates',
  bidrateUnionRates = 'Markups',
}

export enum SERVICE_TYPES_SELECTEDKEYS {
  reimbursableMarkup = 'EXPENSES',
  unitPriceRates = 'TASK/UNITPRICE',
  taskCodeRates = 'TASK/UNITPRICE',
  undergroundMultiplier = 'UGM',
  markups = 'BILLINGCODE',
  compatibleUnitGroups = 'COMPATIBLEUNIT',
}

export enum TAB_KEYS {
  laborRates = 'bidrateLaborRates',
  equipment = 'bidrateEquipmentRates',
  markups = 'bidrateUnionRates',
  reimbursableMarkup = 'bidrateReimbursibleMarkups',
  compatibleUnitGroups = 'bidrateCus',
  unitPriceRates = 'bidrateUnitPrices',
  undergroundMultiplier = 'bidrateUndergrndMltplrRates',
  taskCodeRates = 'bidrateTaskCodes',
}
