import React from 'react';

export const DownloadAllIcon = () => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <rect
          width="32"
          height="32"
          rx="4"
          ry="4"
          style={{ fill: '#005b96' }}
        />
        <g>
          <g>
            <path
              style={{ fill: '#fff' }}
              d="M20,4h-8c-1.1,0-1.99.9-1.99,2v16c-.01,1.1.88,1.99,1.98,2h12.01c1.1,0,2-.9,2-2v-12l-6-6ZM24,22h-12V6h7v5h5v11Z"
            />
            <path
              style={{ fill: '#fff' }}
              d="M18.63,14.19l.02,4.62,1.87-1.87c.26-.25.67-.23.92.03.24.25.24.64,0,.89l-2.98,2.98c-.25.25-.66.25-.91,0,0,0,0,0,0,0l-2.98-2.98c-.26-.25-.27-.66-.03-.92.25-.26.66-.27.92-.03,0,0,.02.02.03.03l1.88,1.87-.02-4.62c0-.36.29-.65.65-.65s.65.29.65.65Z"
            />
          </g>
          <path
            style={{ fill: '#fff' }}
            d="M19,26h-11v-15h-1.99v15c0,1.1.88,1.99,1.98,2h11.01v-2Z"
          />
        </g>
      </g>
    </svg>
  );
};
