import { http } from 'services/helpers/BaseApi.ts';
import queryString from 'query-string';
import _ from 'lodash';
import { tracking } from './helpers/Tracking.ts';
import { OperationEnum } from 'enums/operation.ts';

export const retrieveWorkTypeDetails = async payload => {
  try {
    return await http.get(`/worktype/${payload.workTypeId}`);
  } catch (error) {
    return error;
  }
};

export const retrieveUpdateFieldsData = async () => {
  try {
    return await http.get(`/worktype/worktypeupdatelist`);
  } catch (error) {
    return error;
  }
};

export const retrieveAdminWorkTypeData = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.worktype = queryParams.worktype
    ? queryParams.worktype.toString()
    : '';
  queryParams.accountNmbr = queryParams.accountNmbr
    ? queryParams.accountNmbr.toString()
    : '';
  queryParams.abmsActivity = queryParams.abmsActivity
    ? queryParams.abmsActivity.toString()
    : '';
  queryParams.worktypeCategory = queryParams.worktypeCategory
    ? queryParams.worktypeCategory.toString()
    : '';
  queryParams.orgDeptGroup = queryParams.orgDeptGroup
    ? queryParams.orgDeptGroup.toString()
    : '';
  queryParams.fetchAll = true;

  try {
    return await http.get(`/worktype?${queryString.stringify(queryParams)}`);
  } catch (error) {
    return error;
  }
};

export const retriveAllWorkAreas = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = 1;
  queryParams.limit = -1;
  queryParams.fetchAll = true;

  try {
    return await http.get(`/workarea?${queryString.stringify(queryParams)}`);
  } catch (error) {
    return error;
  }
};

export const retrieveWorkAreaList = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.orgDeptDesc = qParams.orgDeptDesc
    ? qParams.orgDeptDesc.toString()
    : '';
  queryParams.orgDeptGroup = qParams.orgDeptGroup
    ? qParams.orgDeptGroup.toString()
    : '';
  queryParams.fetchAll = true;

  try {
    return await http.get(`/org-dept?${queryString.stringify(queryParams)}`);
  } catch (error) {
    return error;
  }
};

export const addWorkArea = async payload => {
  try {
    tracking.setTrackingData(payload.data, 'recordNmbr');
    return await http.post(`/org-dept`, payload.data);
  } catch (error) {
    return error;
  }
};

export const getWorkArea = async payload => {
  try {
    return await http.get(`/org-dept/${payload}`);
  } catch (error) {
    return error;
  }
};

export const updateWorkArea = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'recordNmbr', OperationEnum.UPDATE);
    return await http.put(`/org-dept/${payload.recordNmbr}`, payload.data);
  } catch (error) {
    return error;
  }
};

export const updateWorkTypeDataDetails = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'workTypeId', OperationEnum.UPDATE);
    return await http.put(`/worktype/${payload.workTypeId}`, payload.data);
  } catch (error) {
    return error;
  }
};

export const addWorkTypeDataDetails = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'workTypeId', OperationEnum.INSERT);
    return await http.post(`/worktype`, payload.data);
  } catch (error) {
    return error;
  }
};

export const deleteWorkTypeDataDetails = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'workTypeId', OperationEnum.DELETE);
    return await http.post('/worktype/deleteMulti', payload.data);
  } catch (error) {
    return error;
  }
};

export const retrieveCrewTypeDetails = async crewtypeID => {
  try {
    return await http.get(`/crewtype/${crewtypeID}`);
  } catch (error) {
    return error;
  }
};

export const retrieveAdminCrewTypeData = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.crewType = qParams.crewType ? qParams.crewType.toString() : '';
  queryParams.crewDesc = qParams.crewDesc ? qParams.crewDesc.toString() : '';
  queryParams.dutyCode = qParams.dutyCode ? qParams.dutyCode.toString() : '';
  queryParams.fetchAll = true;

  try {
    return await http.get(`/crewtype?${queryString.stringify(queryParams)}`);
  } catch (error) {
    return error;
  }
};

export const addCrewTypeDataDetails = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'crewTypeId', OperationEnum.INSERT);
    return await http.post('/crewtype', payload.data);
  } catch (error) {
    return error;
  }
};

export const updateCrewTypeDataDetails = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'crewTypeId', OperationEnum.UPDATE);
    return await http.put(`/crewtype/${payload.crewTypeId}`, payload.data);
  } catch (error) {
    return error;
  }
};

export const deleteCrewTypeDataDetails = async payload => {
  try {
    tracking.setTrackingData(payload.data, 'crewTypeId', OperationEnum.DELETE);
    return await http.post('/crewtype/deleteMulti', payload.data);
  } catch (error) {
    return error;
  }
};

export const retrieveVendorList = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.fetchAll = true;
  if (queryParams.Company) {
    queryParams.Company = qParams.Company.toString();
  }
  if (queryParams.User) {
    queryParams.User = qParams.User.toString();
  }
  try {
    return await http.get(
      `/users/vendor/company?${queryString.stringify(queryParams)}`,
    );
  } catch (error) {
    return error;
  }
};

export const retrieveVendorCompanyList = async qParams => {
  try {
    return await http.get(
      `/vendors/list?limit=500&${queryString.stringify(qParams)}`,
    );
  } catch (error) {
    return error;
  }
};

export const retrieveVendorCompanyFilterList = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  try {
    return await http.get(
      `/vendors/filter?limit=500&${queryString.stringify(queryParams)}`,
    );
  } catch (error) {
    return error;
  }
};

export const retrieveAdminSiteFilterList = async qParams => {
  const queryParams = {
    limit: 500,
  };
  try {
    return await http.get(
      `/site/distinct/records/${qParams.columnname}?${queryString.stringify(
        queryParams,
      )}`,
    );
  } catch (error) {
    return error;
  }
};

export const retrievedistrictWorkAreaFilterList = async qParams => {
  const queryParams = {
    limit: 500,
  };
  try {
    return await http.get(
      `/dist-workarea/distinct/records/${
        qParams.columnname
      }?${queryString.stringify(queryParams)}`,
    );
  } catch (error) {
    return error;
  }
};

export const retrieveAdminWorkAreaFilterList = async qParams => {
  const queryParams = {
    limit: 500,
  };
  try {
    return await http.get(
      `/org-dept/distinct/records/${qParams.columnname}?${queryString.stringify(
        queryParams,
      )}`,
    );
  } catch (error) {
    return error;
  }
};

export const retrieveAdminFuelFilterList = async qParams => {
  const queryParams = {
    limit: 500,
  };
  try {
    return await http.get(
      `/fuel-rates/distinct/records/${
        qParams.columnname
      }?${queryString.stringify(queryParams)}`,
    );
  } catch (error) {
    return error;
  }
};
export const retrieveAdminCrewFilterList = async qParams => {
  const queryParams = {
    limit: 500,
  };
  try {
    return await http.get(
      `/crewtype/distinct/records/${qParams.columnname}?${queryString.stringify(
        queryParams,
      )}`,
    );
  } catch (error) {
    return error;
  }
};

export const retrieveWorkTypeFilterList = async qParams => {
  const queryParams = {
    limit: 500,
  };
  try {
    return await http.get(
      `/worktype/distinct/records/${qParams.columnname}?${queryString.stringify(
        queryParams,
      )}`,
    );
  } catch (error) {
    return error;
  }
};

export const updateVendorCompanyDetails = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'userId', OperationEnum.UPDATE);
    return await http.put(`/users/${payload.userId}/vendors`, payload.data);
  } catch (error) {
    return error;
  }
};

export const retrieveAdminSiteList = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.siteId = qParams.siteId ? qParams.siteId.toString() : '';
  queryParams.orgId = qParams.orgId ? qParams.orgId.toString() : '';
  queryParams.entitlement = qParams.entitlement
    ? qParams.entitlement.toString()
    : '';
  queryParams.fetchAll = true;

  try {
    return await http.get(`/site?${queryString.stringify(queryParams)}`);
  } catch (error) {
    return error;
  }
};

export const addAdminSiteList = async payload => {
  try {
    tracking.setTrackingData(payload.data, 'siteId');
    return await http.post(`/site/`, payload.data);
  } catch (error) {
    return error;
  }
};

export const deleteAdminSiteList = async payload => {
  try {
    return await http.delete(`/site/${payload.siteId}`);
  } catch (error) {
    return error;
  }
};

export const retrievefuelRatesList = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.fuelType = qParams.fuelType ? qParams.fuelType.toString() : '';
  queryParams.fuelRate = qParams.fuelRate ? qParams.fuelRate.toString() : '';
  queryParams.fetchAll = true;

  try {
    return await http.get(`/fuel-rates?${queryString.stringify(queryParams)}`);
  } catch (error) {
    return error;
  }
};

export const updatefuelRates = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'fuelRateNum', OperationEnum.UPDATE);
    return await http.put(
      `/fuel-rates/${payload.data.fuelRateNum}`,
      payload.data,
    );
  } catch (error) {
    return error;
  }
};

// @ts-ignore
export const getfuelTypes = async payload => {
  try {
    return await http.get(`/fuel-rates/fueltypes`);
  } catch (error) {
    return error;
  }
};

export const retrievedistrictWorkAreaList = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.cdDist = qParams.cdDist ? qParams.cdDist.toString() : '';
  queryParams.dsDist = qParams.dsDist ? qParams.dsDist.toString() : '';
  queryParams.fetchAll = true;

  try {
    return await http.get(
      `/dist-workarea?${queryString.stringify(queryParams)}`,
    );
  } catch (error) {
    return error;
  }
};

export const adddistrictWorkArea = async payload => {
  try {
    tracking.setTrackingData(payload.data, 'distWorkId');
    return await http.post(`/dist-workarea/`, payload.data);
  } catch (error) {
    return error;
  }
};

export const retriveWorkArea = async distWorkId => {
  try {
    return await http.get(`/dist-workarea/${distWorkId}`);
  } catch (error) {
    return error;
  }
};

export const deleteDistrictWorkArea = async distWorkId => {
  try {
    let queryString = '';
    for (let i = 0; i < distWorkId.length; i++) {
      if (i == 0) {
        queryString = queryString + `distWorkId=${distWorkId[i]}`;
      } else {
        queryString = queryString + `&distWorkId=${distWorkId[i]}`;
      }
    }
    return await http.delete(`/dist-workarea?${queryString}`);
  } catch (error) {
    return error;
  }
};

export const updatedistrictWorkArea = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'recordNmbr', OperationEnum.UPDATE);
    return await http.put(`/dist-workarea/${payload.recordNmbr}`, payload.data);
  } catch (error) {
    return error;
  }
};
