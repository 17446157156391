import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { DeleteIcon } from 'assets/images/DeleteIcon/DeleteIcon';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import {
  keyBidRateSheetTab,
  optionsBidRateSheetListTab,
} from 'constants/BidRateSheetTabs.ts';
import { ROUTES_STRING } from 'constants/routes';
import { ModuleACL } from 'enums/entitlements.ts';
import { BidRateSheet } from 'enums/fields.ts';
import { Page } from 'enums/page.ts';
import { BidRateSheetStatus } from 'enums/statuses.ts';
import _ from 'lodash';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Button, Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  fetchBidRateEquipmentTypes,
  fetchBidRateLocations,
  fetchBidRateSheetDetails,
  fetchBidRateSheetList,
  fetchBidRateWorkCategory,
  fetchSelectedBidRateSheetList,
  deleteBidRateSheetRecord,
  resetBidRateSheet,
  setBidRateRowPage,
  setBidRateSheetPagination,
  setNewBidRateSheet,
} from 'store/slices/BidRateSheets/bidRateSheetListSlice';
import { resetFilters, setBidratesheetFilters } from 'store/slices/filterSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { IsAllowedACL, filterAllowedOptionsACL } from 'utils/aclHelper';
import { isEmptyVal } from 'utils/utils';
import './BidRateSheetList.scss';
import { ConfirmationModal } from 'components/common/modals/ConfirmatinModal';
import ClipLoader from 'react-spinners/ClipLoader';

const BidRateSheetList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [tabs, setTabs] = useState([]);
  const [deletedBidSheetNmbr, setDeletedBidSheetNmbr] = useState(null);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [chipFilterValues, setChipFilterValues] = useState({});
  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);
  const [key, setKey] = useState('all');

  const TabKey = keyBidRateSheetTab;
  const optionsTab = optionsBidRateSheetListTab;
  const headers = [
    { key: BidRateSheet.bidRateSheetNo, value: 'Bid Rate Sheet No' },
    { key: BidRateSheet.bidRateSheet, value: 'Bid Rate Sheet' },
    { key: BidRateSheet.lastModifiedDate, value: 'Last Modified Date' },
    { key: BidRateSheet.status, value: 'Status' },
  ];
  const bidRateFiltersValues = [
    { label: 'Bid Rate Sheet Name', value: 'name' },
    { label: 'Bid Rate Sheet No', value: 'bidrateNmbr' },
  ];

  const user = useSelector(state => state.auth);
  const {
    items: bidRateSheetList,
    recordItems: selectedFilterRecordList,
    pagination,
    sheetDeleted,
  } = useSelector(state => state.bidRateSheetList);
  const inputListData = useSelector(state => state.filter.bidratesheet);
  const { currentPage, sortBy, sortOrder } = pagination;

  const handlenewBidRatesheetClick = () => {
    dispatch(setBidRateRowPage(false));
    dispatch(resetBidRateSheet());
    dispatch(setNewBidRateSheet({ newBidRateSheet: true }));
    navigate(`/${ROUTES_STRING.bidRateSheets}/new`);
  };

  const handleBidRateSheetClick = row => {
    let bidRateno = row.bidRateNumber;
    dispatch(fetchBidRateSheetDetails(row.bidRateNumber));
    dispatch(fetchBidRateLocations());
    dispatch(fetchBidRateEquipmentTypes());
    dispatch(fetchBidRateWorkCategory());
    dispatch(setNewBidRateSheet({ newBidRateSheet: false }));
    navigate(`/${ROUTES_STRING.bidRateSheets}/${bidRateno}`, {
      state: 'bidRateSheetRowPage',
    });
  };

  const objDecorate = obj => {
    let newObj = _.mapKeys(obj, (value, key) => {
      return key;
    });
    return newObj;
  };

  const handleContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });

    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });

    const selectedFilterDataDecorated = objDecorate(selectedFilterData);

    dispatch(fetchBidRateSheetList(selectedFilterDataDecorated));
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setFilterchip(true);
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendor' || val.FilterValue === 'vendorid') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setBidratesheetFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    // Check is using "vendorid"
    const filterchipDataDecorated = objDecorate(filterchipData);
    dispatch(fetchBidRateSheetList(filterchipDataDecorated));
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setBidRateSheetPagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };
  useEffect(() => {
    if (location?.state?.from !== 'bidrateDetail') {
      dispatch(resetFilters());
    }

    dispatch(setActivePage(Page.BID_RATE_SHEET));
    dispatch(resetBidRateSheet());
    const optionsAllowed = filterAllowedOptionsACL(
      user,
      ModuleACL.BIDRATESHEET,
      optionsTab,
    );
    setTabs(optionsAllowed);
    handleContinueClick(inputListData);
  }, [dispatch]);

  useEffect(() => {
    if (sheetDeleted) {
      handleContinueClick();
    }
  }, [sheetDeleted]);

  const handleTabSelect = key => {
    switch (key) {
      case TabKey.SAVED: {
        dispatch(
          setBidRateSheetPagination({
            forceFetch: true,
            currentPage: 1,
            caastatus: `${BidRateSheetStatus.saved}`,
          }),
        );
        break;
      }
      case TabKey.CANCELED: {
        dispatch(
          setBidRateSheetPagination({
            forceFetch: true,
            currentPage: 1,
            caastatus: `${BidRateSheetStatus.cancelled}`,
          }),
        );
        break;
      }
      default: {
        dispatch(
          setBidRateSheetPagination({
            forceFetch: true,
            currentPage: 1,
            caastatus: '',
          }),
        );
      }
    }
    setKey(key);
    // const paginationDiv = document.getElementById(`pagination_${key}`);

    // if (paginationDiv !== null || paginationDiv != undefined) {
    //   const divDisplay = paginationDiv.style.display;
    //   if (divDisplay === 'none') {
    //     paginationDiv.style.display = '';
    //   }
    // }
  };

  const deleteBidSheetRow = bidRateNumber => {
    setDeletedBidSheetNmbr(bidRateNumber);
    setDeleteModalShow(true);
  };

  const handleDeleteBidRateSheet = () => {
    dispatch(deleteBidRateSheetRecord({ bidRateNumber: deletedBidSheetNmbr }));
    setDeleteModalShow(false);
  };

  return (
    <>
      <div className="aepContainer aepHeader">Bid Rate Sheets</div>
      <div className="aepContainer">
        <div className="submitButtonDivWithoutMrgn">
          {IsAllowedACL(
            user,
            ModuleACL.BIDRATESHEET,
            'can_create_bidratesheet',
          ) && (
            <Fragment>
              <Button
                variant="primary"
                onClick={e => handlenewBidRatesheetClick()}
              >
                New Bid Rate Sheet
              </Button>
            </Fragment>
          )}
        </div>
        <Tabs id="poPage" activeKey={key} onSelect={k => handleTabSelect(k)}>
          {tabs.map((tab, index) => (
            <Tab
              eventKey={tab.key}
              title={tab.value}
              key={index}
              tabClassName="tab-item"
            >
              <div className="tableContainer">
                <Row>
                  <Col sm={11}>
                    <>
                      <div className="filterComponent">
                        <AEPFilterComponent
                          inputListData={inputListData}
                          filterValues={bidRateFiltersValues}
                          setInputFilterData={setBidratesheetFilters}
                          selectedFilterRecordList={selectedFilterRecordList}
                          fetchSelectedList={fetchSelectedBidRateSheetList}
                          handleContinueClick={handleContinueClick}
                          continueBtnDisable={continueBtnDisable}
                          handleFilterclose={handleFilterclose}
                          handleFilterChip={handleFilterChip}
                          filterChip={filterChip}
                          chipFilterValues={chipFilterValues}
                        />
                      </div>
                    </>
                  </Col>
                </Row>
                <Table hover responsive striped bordered={false}>
                  <thead>
                    <tr>
                      {headers.map((header, idx) => (
                        <th key={idx}>
                          {sortOrder === 'asc' && (
                            <div
                              id={header.key}
                              onClick={e => handleSorting(header.key, 'desc')}
                            >
                              {header.value}
                              <CaretUp style={{ marginLeft: '12px' }} />
                            </div>
                          )}
                          {sortOrder === 'desc' && (
                            <div
                              id={header.key}
                              onClick={e => handleSorting(header.key, 'asc')}
                            >
                              {header.value}
                              <CaretDown style={{ marginLeft: '12px' }} />
                            </div>
                          )}
                        </th>
                      ))}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bidRateSheetList.map((row, indx) => (
                      <tr key={indx} className="tRow table-hover-row">
                        <td onClick={e => handleBidRateSheetClick(row)}>
                          {row.bidRateNumber}
                        </td>
                        <td onClick={e => handleBidRateSheetClick(row)}>
                          {row.bidratesheetname}
                        </td>
                        <td onClick={e => handleBidRateSheetClick(row)}>
                          {moment(row.lastmodifieddate).format('MMM D,YYYY')}
                        </td>
                        <td onClick={e => handleBidRateSheetClick(row)}>
                          {row.caastatus}
                        </td>
                        <td>
                          <span
                            title="Delete"
                            onClick={e => deleteBidSheetRow(row.bidRateNumber)}
                            className="svgStyleThree"
                          >
                            <DeleteIcon />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <AEPPagination
                  fetchItems={fetchBidRateSheetList}
                  setPagination={setBidRateSheetPagination}
                  pagination={pagination}
                  currentPage={currentPage}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                />
              </div>
            </Tab>
          ))}
        </Tabs>
        <ConfirmationModal
          showModal={deleteModalShow}
          setShowModal={setDeleteModalShow}
          handleContinueClick={handleDeleteBidRateSheet}
          title="Delete Bid Rate Sheet"
          body="Are you sure to delete bid rate sheet?"
        />
      </div>
    </>
  );
};

export { BidRateSheetList };
