import React, { useEffect } from 'react'; // Add useEffect here
import { Layout } from 'components/Layout/Layout';
import { ROUTES, ROUTES_STRING } from 'constants/routes';
import Dashboard from 'pages/Dashboard/Dashboard';
import { InvoiceDetails } from 'pages/Invoice/InvoiceDetails/InvoiceDetails';
import { InvoiceList } from 'pages/Invoice/InvoiceList/InvoiceList';
import { InvoiceAnalyticsList } from 'pages/Invoice/InvoiceAnalytics/InvoiceAnalyticsList';
import { Login } from 'pages/Login/Login';
import { OAuthCallback } from 'pages/OAuth/OAuthCallback';
import { PurchaseOrderLayout } from 'pages/PurchaseOrder/PurchaseOrderDetails/PurchaseOrderLayout';
import { PurchaseOrderList } from 'pages/PurchaseOrder/PurchaseOrderList/PurchaseOrderList';
import { TimesheetEntry } from 'pages/Timesheet/TimesheetEntry/TimesheetEntry';
import { TimesheetList } from 'pages/Timesheet/TimesheetList/TimesheetList';
import { BidRateSheetList } from 'pages/BidRateSheets/BidRateSheetList/BidRateSheetList';
import { BidRateSheetEntry } from 'pages/BidRateSheets/BidRateSheetEntry/BidRateSheetEntry';
import { CompatibleUnitManual } from 'pages/AdditionalLinks/CompatibleUnitManual/CompatibleUnitManual';
import { UnitRevisions } from 'pages/AdditionalLinks/UnitRevisions/UnitRevisions';
import { StandardRevisions } from 'pages/AdditionalLinks/StandardRevisions/StandardRevisions';
import { AEPDistributionStandard } from 'pages/AdditionalLinks/AEPDistributionStandard/AEPDistributionStandard';
import { AdminModulesLayout } from 'pages/AdminModules/AdminModulesLayout/AdminModulesLayout';
import { AuditList } from 'pages/AuditModules/AuditList/AuditList';
import { AuditDetails } from 'pages/AuditModules/AuditDetails/AuditDetails';
import { CuDownload } from 'pages/AdditionalLinks/CuDownload/CuDownload';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import TimeSheetImport from 'pages/Timesheet/TimesheetImport/TimesheetImport';
//import WalkmeScript from 'components/walkMe';
import { Helmet } from 'react-helmet';

export default function App() {
  const isAuthenticated = useSelector(state => state.auth.isLoggedIn);
  const userName = useSelector(
    state => state.auth.userDisplayname || state.auth.uid,
  );

  // Routes declaration starts
  const unprotectedIndependentRoutes = {};

  const unprotectedRoutes = {
    [ROUTES_STRING.login]: <Login />,
    [ROUTES_STRING.oauthCallback]: <OAuthCallback />,
  };

  const protectedRoutes = {
    '/': <Dashboard />,
    [ROUTES_STRING.purchaseOrders]: <PurchaseOrderList />,
    [`${ROUTES_STRING.purchaseOrders}/:id`]: <PurchaseOrderLayout />,
    [ROUTES_STRING.timesheets]: <TimesheetList />,
    [ROUTES_STRING.importTimeSheet]: <TimeSheetImport />,
    [`${ROUTES_STRING.timesheets}/new`]: <TimesheetEntry />,
    [`${ROUTES_STRING.timesheets}/:id`]: <TimesheetEntry />,
    [`${ROUTES_STRING.invoices}`]: <InvoiceList />,
    [`${ROUTES_STRING.invoices}/:id`]: <InvoiceDetails />,
    [`${ROUTES_STRING.invoices}/new`]: <InvoiceDetails />,
    [`${ROUTES_STRING.invoices}/analytics`]: <InvoiceAnalyticsList />,
    [ROUTES_STRING.bidRateSheets]: <BidRateSheetList />,
    [`${ROUTES_STRING.bidRateSheets}/new`]: <BidRateSheetEntry />,
    [`${ROUTES_STRING.bidRateSheets}/:id`]: <BidRateSheetEntry />,
    [ROUTES_STRING.compatibleUnitManual]: <CompatibleUnitManual />,
    [ROUTES_STRING.unitRevisions]: <UnitRevisions />,
    [ROUTES_STRING.standardRevisions]: <StandardRevisions />,
    [ROUTES_STRING.aepDistributionsStandard]: <AEPDistributionStandard />,
    [ROUTES_STRING.admin]: <AdminModulesLayout />,
    [ROUTES_STRING.audit]: <AuditList />,
    [ROUTES_STRING.cuDownload]: <CuDownload />,
    [`${ROUTES_STRING.audit}/:id`]: <AuditDetails />,
    [`${ROUTES_STRING.audit}/new`]: <AuditDetails />,
  };
  // Routes declaration ends
  /************************* */

  const walkmeScriptUrl = process.env.REACT_APP_WALKME_URL;

  //add user name to dynatrace - This will show up on the User session
  // (to identify the user) on Dynatrace (UI Monitoring)
  useEffect(() => {
    if (window.dtrum && isAuthenticated && userName) {
      window.dtrum.identifyUser(userName);
    }
  }, [isAuthenticated, userName]);

  return (
    <div className="App">
      {
        //Embedd walkMe into the head tag of each page
        walkmeScriptUrl && (
          <Helmet>
            <script type="text/javascript" async src={walkmeScriptUrl} />
          </Helmet>
        )
      }
      <BrowserRouter>
        <Routes>
          {Object.entries(unprotectedIndependentRoutes).map(
            ([upRoute, upComponent]) => (
              <Route key={upRoute} path={`/${upRoute}`} element={upComponent} />
            ),
          )}
          {Object.entries(unprotectedRoutes).map(([upRoute, upComponent]) => (
            <Route key={upRoute} path={`/${upRoute}`} element={upComponent} />
          ))}

          {Object.entries(protectedRoutes).map(([pRoute, pComponent]) => (
            <Route
              key={pRoute}
              path={`/${pRoute}`}
              element={
                isAuthenticated ? (
                  <Layout>{pComponent}</Layout>
                ) : (
                  <Layout>
                    <Navigate replace to={ROUTES.login} />
                  </Layout>
                )
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}
