import { Form, Modal, Tab, Tabs, Row, Col } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { useEffect, useState } from 'react';
import _ from 'lodash';

const errorMsg = {
  ctRequired: 'Crew Type is a required field',
  ctDigitsOnly: 'Crew Type should be upto 2 digits',
  cdRequired: 'Description is a required field',
  cdMaxLimit: 'Description should be upto 50 charactor',
};

const AdminCrewTypeModel = ({
  show,
  crewTypeTitle,
  selectedCrewType,
  handleAdminCrewTypeSave,
  handleAdminCrewTypeCancel,
  dutyCode,
  orgGroup,
}) => {
  const tabs = [{ key: 'details', value: 'Details' }];
  const [key, setKey] = useState('details');
  const [crewTypeData, setCrewTypeData] = useState(selectedCrewType);
  const [crewTypeErrorMsg, setCrewTypeErrorMsg] = useState('');
  const [crewDescErrorMsg, setCrewDescErrorMsg] = useState('');

  useEffect(() => {
    setCrewTypeData(selectedCrewType);
    setCrewTypeErrorMsg('');
    setCrewDescErrorMsg('');
  }, [show]);

  const isValidation = {
    crewType: str => {
      const regExp = /^\d+$/;
      return regExp.test(str) && str.length === 2;
    },
    crewDesc: str => {
      const regExp = /^.{0,50}$/;
      return regExp.test(str);
    },
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'crewType') {
      crewTypeValidation(value);
    } else if (name === 'crewDesc') {
      crewDescValidation(value);
    }
    setCrewTypeData({ ...crewTypeData, [name]: value });
  };

  const crewTypeValidation = ct => {
    let isError = false;
    setCrewTypeErrorMsg('');
    if (ct === '') {
      setCrewTypeErrorMsg(errorMsg.ctRequired);
      isError = true;
    } else if (ct != '' && !isValidation.crewType(ct)) {
      setCrewTypeErrorMsg(errorMsg.ctDigitsOnly);
      isError = true;
    }
    return isError;
  };

  const crewDescValidation = cd => {
    let isError = false;
    setCrewDescErrorMsg('');
    if (cd === '') {
      setCrewDescErrorMsg(errorMsg.cdRequired);
      isError = true;
    } else if (cd != '' && !isValidation.crewDesc(cd)) {
      setCrewDescErrorMsg(errorMsg.cdMaxLimit);
      isError = true;
    }
    return isError;
  };

  const handleCrewTypeSubmit = () => {
    const isCtError = crewTypeValidation(crewTypeData.crewType);
    const isCdError = crewDescValidation(crewTypeData.crewDesc);
    if (!isCtError && !isCdError) {
      handleAdminCrewTypeSave(crewTypeData);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => handleAdminCrewTypeCancel()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>{crewTypeTitle}</Modal.Title>
      </Modal.Header>
      <Divider />
      <div className="aepContainer adminModuleCrewTypeDetailContainer">
        <Tabs id="poPage" activeKey={key} onSelect={k => setKey(k)}>
          {tabs.map((tab, index) => (
            <Tab eventKey={tab.key} title={tab.value} key={index}>
              <div className="poDetailsContainer">
                <Form>
                  <Row>
                    <Col sm={3}>
                      <Form.Group controlId="form.crewType">
                        <Form.Label>
                          Crew Type:
                          <span
                            style={{
                              color: 'red',
                              fontSize: '15px',
                              marginLeft: '5px',
                            }}
                          >
                            *
                          </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="crewType"
                          value={crewTypeData.crewType}
                          onChange={e => handleChange(e)}
                          style={{ display: 'block' }}
                        />
                        <div className="errorMessage">{crewTypeErrorMsg}</div>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="form.crewDesc">
                        <Form.Label>
                          Crew Description:
                          <span
                            style={{
                              color: 'red',
                              fontSize: '15px',
                              marginLeft: '5px',
                            }}
                          >
                            *
                          </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="crewDesc"
                          value={crewTypeData.crewDesc}
                          onChange={e => handleChange(e)}
                          style={{
                            width: '100%',
                            fontSize: '16px',
                            padding: '15px',
                            color: '#000',
                          }}
                        />
                        <div className="errorMessage">{crewDescErrorMsg}</div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Divider style={{ margin: '20px 0' }} />
                  <Row>
                    <Col sm={3} style={{ paddingRight: '10px' }}>
                      <Form.Group controlId="form.dutyCode">
                        <Form.Label>Duty Code:</Form.Label>
                        <Form.Select
                          name="dutyCode"
                          value={crewTypeData.dutyCode}
                          onChange={e => handleChange(e)}
                          style={{ width: '100%' }}
                        >
                          {dutyCode.map((type, index) => (
                            <option key={index} value={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={3} style={{ paddingRight: '10px' }}>
                      <Form.Group controlId="form.orgDeptGroup">
                        <Form.Label>Org Group:</Form.Label>
                        <Form.Select
                          name="orgDeptGroup"
                          value={crewTypeData.orgDeptGroup}
                          onChange={e => handleChange(e)}
                          style={{ width: '100%' }}
                        >
                          {orgGroup.map((type, index) => (
                            <option key={index} value={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Save"
          secondaryLabel="Cancel"
          primaryOnClick={() => handleCrewTypeSubmit()}
          secondaryOnClick={() => handleAdminCrewTypeCancel()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AdminCrewTypeModel;
