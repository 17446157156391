import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/invoiceService';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { trackPromise } from 'react-promise-tracker';
import {
  fetchInvoiceDetails,
  fetchRDADetails,
  setInvoiceDetails,
  updateInvoice,
  submitToMaximoPSValidation,
  sendInvoiceToMaximo,
  setCreateInvoiceError,
  submitToMaximoAttachDocument,
} from 'store/slices/Invoice/invoiceDetailSlice';
import {
  fetchInvoices,
  fetchInvoicesAnalytics,
  fetchSelectedInvoiceList,
  setInvoices,
  setSelectedInvoiceRecords,
  setError,
  createInvoice,
  setIsLoading,
  setAnalytics,
  exportInvoicesRequest,
  exportInvoicesSuccess,
  exportInvoicesFailure,
} from 'store/slices/Invoice/invoiceSlice';
import { formatInvoiceData } from '../utils';
import { getStatusByACL } from 'utils/aclHelper';
import { ModuleACL } from 'enums/entitlements.ts';
import {
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';

const invoicePaginationSelector = state => state.invoice.pagination;
const userSelector = state => state.auth;

const trackInvoiceDetails = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.sendToMaximo);

const trackAnalyticsDetails = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.getAnalyticsData);

function* handleInvoicesExportCompletion(action) {
  displaySuccess('Invoice has been downloaded to your workstation');
}
function* handleInvoicesExportCompletionError(action) {
  displayError(
    'Invoice Export error. Too many invoices to export. Please apply filter criteria and try again',
  );
}

function* getInvoices(action) {
  const queryParams = _.cloneDeep(yield select(invoicePaginationSelector));

  try {
    // Add permissions by status of entity
    let user = _.cloneDeep(yield select(userSelector));
    if (_.isEmpty(queryParams['caastatus'])) {
      const status = getStatusByACL(user, ModuleACL.INVOICE);
      if (!_.isEmpty(status)) {
        queryParams['caastatus'] = status;
      }
    }

    if (action.meta && action.meta.export) {
      // Export logic
      displaySuccess('Invoice Export Started');
      const response = yield call(service.exportInvoices, queryParams);
      // const blob = yield response.blob();
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.style.display = 'none';
      // a.href = url;
      // a.download = `INVOICES_${Date.now()}.xlsx`;
      // document.body.appendChild(a);
      // a.click();
      // window.URL.revokeObjectURL(url);
      yield put({ type: 'EXPORT_INVOICES_SUCCESS' });
    } else {
      // Fetch logic
      const response = yield call(service.retrieveInvoices, queryParams);
      const { data } = response;
      data.pagination = { totalItems: data.count };
      yield put(setInvoices(data));
    }
  } catch (e) {
    yield put(setError(true));

    if (action.meta && action.meta.export) {
      yield put({ type: 'EXPORT_INVOICES_FAILURE', payload: e });
    } else {
      displayError(
        'We are experiencing technical difficulties. Please come back shortly to try again!',
      );
    }
  } finally {
    yield put(setIsLoading(false));
  }
}
function* getRDADetails(action) {
  try {
    const response = yield call(
      f => service.retrieveRDAeDetails(action.payload.workorder),
      action.payload,
    );
    const { data } = response;
    if (action.payload.callback) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* getInvoiceDetails(action) {
  try {
    const response = yield call(
      f => service.retrieveInvoiceDetails(action.payload),
      action.payload,
    );
    const { data } = response;
    const formattedData = formatInvoiceData(data);
    let verifier = '';
    let approver = '';
    if (data.invoiceVerifiers && data.invoiceVerifiers.length) {
      let verifierData = data.invoiceVerifiers.find(itm => {
        return itm.approverType === 'V';
      });
      verifier = verifierData?.approver;

      let approverData = data.invoiceVerifiers.find(itm => {
        return itm.approverType === 'A';
      });
      approver = approverData?.approver;
    }
    yield put(
      setInvoiceDetails({
        ...formattedData,
        vendorInvNmbr: data?.vendorInvNmbr,
        ponum: data?.ponum,
        contractNmbr: data?.contractNmbr,
        invoiceNmbr: data?.invoiceNmbr,
        caastatus: data?.caastatus,
        maxStatus: data?.maxStatus,
        maxInvNmbr: data.maxInvNmbr,
        weekStartDate: data?.weekStartDate,
        weekEndDate: data?.weekEndDate,
        invoiceDate: data?.invoiceDate,
        retentionWithheld: data?.retentionWithheld,
        bupath: data?.bupath,
        fuelAdjCost: data?.fuelAdjCost,
        travelAmount: data?.travelAmount,
        smallJobAmount: data?.smallJobAmount,
        deEnergizedAmount: data?.deEnergizedAmount,
        carryCommentsTimesheet: data?.carryCommentsTimesheet,
        invoiceAttachments: data?.invoiceAttachments,
        invoicePmthistories: data?.invoicePmthistories,
        invoiceVerifiers: data?.invoiceVerifiers,
        invoiceStatushistories: data?.invoiceStatushistories,
        invoiceApprover: approver,
        invoiceVerifier: verifier,
      }),
    );
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* addInvoice(action) {
  try {
    const response = yield call(
      f => service.postInvoice(action.payload.data),
      action.payload.data,
    );
    const { data } = response;
    if (response.status === 200) {
      if (data[0]) {
        if (action.payload.callback) {
          action.payload.callback(data);
        }
      }
    } else {
      if (action.payload.callback) {
        action.payload.callback();
      }
      put(setError(true));
    }
  } catch (e) {
    if (action.payload.callback) {
      action.payload.callback();
    }
    put(setError(true));
  }
}

function* modifyInvoice(action) {
  try {
    const response = yield call(
      f => service.patchInvoice(action.payload),
      action.payload,
    );
    const { data } = response;
    if (response.status === 200) {
      if (data.isUpdated) {
        yield getInvoiceDetails({ payload: action.payload.invoiceNumber });
        if (action.payload.callback) {
          action.payload.callback(data);
        }
      }
    } else {
      if (action.payload.callback) {
        action.payload.callback();
      }
      put(setError(true));
    }
  } catch (e) {
    if (action.payload.callback) {
      action.payload.callback();
    }
    put(setError(true));
  }
}

function* sendToMaximo(action) {
  try {
    const response = yield call(
      trackInvoiceDetails,
      f => service.submitToMaximo(action.payload),
      action.payload,
    );
    const { data } = response;
    if (data?.error) {
      data.showError = true;
      yield put(setCreateInvoiceError(data));
      if (action.payload.callback) {
        data.error = true;
        action.payload.callback(data);
      }
    } else {
      if (action.payload.callback) {
        action.payload.callback(data);
      }
    }
  } catch (e) {
    if (action.payload.callback) {
      action.payload.callback();
    }

    put(setError(true));
  }
}

function* maximoPSValidation(action) {
  try {
    const response = yield call(
      trackInvoiceDetails,
      f => service.validateInvoice(action.payload),
      action.payload,
    );
    const { data } = response;
    let hasError = data.some(
      item => item?.ValidationResponse['fint:numberOfErrors'] != 0,
    );
    if (hasError) {
      data.showError = true;
      yield put(setCreateInvoiceError(data));
      if (action.payload.callback) {
        data.error = true;
        action.payload.callback(data);
      }
    } else {
      if (action.payload.callback) {
        action.payload.callback(data);
      }
    }
  } catch (e) {
    if (action.payload.callback) {
      action.payload.callback();
    }

    put(setError(true));
  }
}

function* maximoAttachDocument(action) {
  try {
    const response = yield call(
      trackInvoiceDetails,
      f => service.attachInvoiceDocuments(action.payload),
      action.payload,
    );
    const { data } = response;
    if (data?.error) {
      data.showError = true;
      yield put(setCreateInvoiceError(data));
      if (action.payload.callback) {
        action.payload.callback(data);
      }
    } else {
      if (action.payload.callback) {
        action.payload.callback(data);
      }
    }
  } catch (e) {
    if (action.payload.callback) {
      action.payload.callback();
    }

    put(setError(true));
  }
}

function* getSelectedInvoiceList(action) {
  try {
    const response = yield call(
      f => service.retreiveSelectedInvoiceList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(
      setSelectedInvoiceRecords({
        data,
        columnname: action.payload.columnname,
      }),
    );
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* getInvoicesAnalytics(action) {
  try {
    const queryParams = _.cloneDeep(yield select(invoicePaginationSelector));

    try {
      // Add permissions by status of entity
      let user = _.cloneDeep(yield select(userSelector));
      if (_.isEmpty(queryParams['caastatus'])) {
        const status = getStatusByACL(user, ModuleACL.INVOICE);
        if (!_.isEmpty(status)) {
          queryParams['caastatus'] = status;
        }
      }
      switch (queryParams['analyticsTab']) {
        case 'Analytics':
          queryParams['type'] = 'a';
          break;
        case 'Labor':
          queryParams['type'] = 'l';
          break;
        case 'Equipment':
          queryParams['type'] = 'e';
          break;
        default:
          queryParams['type'] = 'a';
          break;
      }
      delete queryParams['analyticsTab'];
      const response = yield call(
        trackAnalyticsDetails,
        f => service.retrieveInvoicesAnalytics(queryParams),
        action.payload,
      );
      const { data } = response;
      data.pagination = { totalItems: data.count };
      yield put(setAnalytics(data));
    } catch (e) {
      yield put(setError(true));
    } finally {
      yield put(setIsLoading(false));
    }
  } catch (e) {
    put(setError(true));
  }
}

function* watchFetchInvoices() {
  yield takeLatest(fetchInvoices.type, getInvoices);
}

function* watchFetchAnalytics() {
  yield takeLatest(fetchInvoicesAnalytics.type, getInvoicesAnalytics);
}

function* watchFetchInvoiceDetails() {
  yield takeLatest(fetchInvoiceDetails.type, getInvoiceDetails);
}

function* watchFetchRDA() {
  yield takeLatest(fetchRDADetails.type, getRDADetails);
}

function* watchFetchSelectedInvoiceList() {
  yield takeLatest(fetchSelectedInvoiceList.type, getSelectedInvoiceList);
}
function* watchCreateInvoice() {
  yield takeLatest(createInvoice.type, addInvoice);
}
function* watchUpdateInvoice() {
  yield takeLatest(updateInvoice.type, modifyInvoice);
}

function* watchsendInvoiceToMaximo() {
  yield takeLatest(sendInvoiceToMaximo.type, sendToMaximo);
}

function* watchsubmitToMaximoPSValidation() {
  yield takeLatest(submitToMaximoPSValidation.type, maximoPSValidation);
}

function* watchsubmitToMaximoAttachDocument() {
  yield takeLatest(submitToMaximoAttachDocument.type, maximoAttachDocument);
}
// Worker Saga: will be fired on exportInvoicesRequest actions
// function* exportInvoices(action) {
//   try {
//     const response = yield call(api.exportInvoices, action.payload);
//     const blob = yield response.blob();
//     const url = window.URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.style.display = 'none';
//     a.href = url;
//     a.download = `INVOICE_All_${Date.now()}.xlsx`;
//     document.body.appendChild(a);
//     a.click();
//     window.URL.revokeObjectURL(url);
//     yield put(exportInvoicesSuccess());
//   } catch (error) {
//     yield put(exportInvoicesFailure(error.toString()));
//   }
// }

// Watcher Saga: spawns a new exportInvoices task on each EXPORT_INVOICES_REQUEST action
function* watchExportInvoices() {
  yield takeLatest('EXPORT_INVOICES_REQUEST', getInvoices);
}
function* watchExportInvoicesSuccess() {
  yield takeLatest('EXPORT_INVOICES_SUCCESS', handleInvoicesExportCompletion);
}
function* watchExportInvoicesFailure() {
  yield takeLatest(
    'EXPORT_INVOICES_FAILURE',
    handleInvoicesExportCompletionError,
  );
}
export function* invoiceSaga() {
  yield all([
    watchFetchRDA(),
    watchFetchInvoices(),
    watchFetchSelectedInvoiceList(),
    watchFetchInvoiceDetails(),
    watchCreateInvoice(),
    watchUpdateInvoice(),
    watchsendInvoiceToMaximo(),
    watchsubmitToMaximoAttachDocument(),
    watchsubmitToMaximoPSValidation(),
    watchFetchAnalytics(),
    watchExportInvoices(),
    watchExportInvoicesSuccess(),
    watchExportInvoicesFailure(),
  ]);
}
