import React, { useState, useEffect } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { useDispatch } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import './PDFViewer.scss';
import { CaretLeft } from 'assets/images/CaretIcon/CaretLeft/CaretLeft';
import { CaretRight } from 'assets/images/CaretIcon/CaretRight/CaretRight';
import { PDFdownloader } from '../PDFdownloader/PDFdownloader';
import { http } from 'services/helpers/BaseApi.ts';
import {
  setIsPDFLoading,
  setLoading,
} from 'store/slices/purchaseOrder/purchaseOrderDetailsSlice';

const PDFViewer = pdfOptions => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [windowUrl, setWindowUrl] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    getFileUrl();
  }, [pdfOptions.refresh, pdfOptions.pdfUrl]);

  async function getFileUrl() {
    if (pdfOptions.pdfUrl) {
      loadFromServer();
    }
    if (pdfOptions.file) {
      loadFromFile();
    }
  }

  const loadFromFile = async () => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        // convert image file to base64 string
        // @ts-ignore
        setWindowUrl(reader.result);
      },
      false,
    );

    if (pdfOptions.file) {
      reader.readAsDataURL(pdfOptions.file);
    }
  };

  const loadFromServer = async () => {
    try {
      const url = await http.getFile({
        url: pdfOptions.pdfUrl,
        type: 'application/pdf',
        name: pdfOptions.name,
        extension: 'pdf',
      });
      dispatch(setIsPDFLoading(false));
      setWindowUrl(url);
    } catch (e) {
      dispatch(setIsPDFLoading(true));
    }
  };
  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <div className="aepContainer">
        <Tabs id="timesheetPage" activeKey={1}>
          <Tab
            eventKey="1"
            title={pdfOptions.isStyleSet ? null : 'Details'}
            key={1}
          >
            <div className={pdfOptions.isStyleSet ? '' : 'tableContainer'}>
              {pdfOptions.isStyleSet ? (
                <div className="customizedSvg">
                  <PDFdownloader
                    url={pdfOptions.pdfUrl || windowUrl}
                    name={pdfOptions.name}
                  />
                </div>
              ) : (
                <PDFdownloader
                  url={pdfOptions.pdfUrl || windowUrl}
                  name={pdfOptions.name}
                />
              )}

              <Document
                file={{
                  url: windowUrl,
                }}
                renderMode="svg"
                className="pdf-wid-hei"
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </Tab>
        </Tabs>
        <div className="pdfViewer">
          <div className="cursorArrow">
            <div className="leftCursorArrow" onClick={previousPage}>
              <CaretLeft
                style={{ fill: pageNumber !== 1 ? '#4a4a4a' : '#9e9fa1' }}
              />
            </div>
          </div>
          <div className="cursorGap" />
          <div className="cursorArrow">
            <div className="rightCursorArrow" onClick={nextPage}>
              <CaretRight
                style={{
                  fill: !numPages ? '#4a4a4a' : '#9e9fa1',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { PDFViewer };
