import { createSlice } from '@reduxjs/toolkit';
import _, { isEmpty } from 'lodash';
import { isEmptyVal } from 'utils/utils';

const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  items: [],
  recordItems: [],
  data: [],
  isStatus: false,
  bidRateNumber: '',
  caaStatus: '',
  workCategoryList: [],
  locationList: [],
  equipmentType: [],
  ownershipType: [],
  bidRateSheetDetailsList: {},
  newBidRateSheet: false,
  bidRateSheetLaborDetails: [],
  laborServiceTypeOptions: [],
  equipmentServiceTypeOptions: [],
  otherServiceTypeOptions: [],
  bidRateSheetDetailsData: [],
  bidRateRowPage: false,
  addTabContinueBtn: true,
  selectedBidRateTab: '',
  isSuccess: false,
  sheetSaved: false,
  sheetDeleted: false,
  preferenceColumns: {
    bidrateEquipmentRates: [],
    bidrateLaborRates: [],
    bidrateCus: [],
    bidrateReimbursibleMarkups: [],
    bidrateTaskCodes: [],
    bidrateUndergrndMltplrRates: [],
    bidrateUnionRates: [],
    bidrateUnitPrices: [],
  },
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
    heirarchypath: '',
    caastatus: '',
    forceFetch: false,
    search: '',
  },
  inputList: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  masterDataErrors: {
    bidrateEquipmentRates: {},
    bidrateLaborRates: {},
    bidrateCus: {},
    bidrateReimbursibleMarkups: {},
    bidrateUndergrndMltplrRates: {},
    bidrateUnionRates: {},
    bidrateUnitPrices: {},
  },
  masterDataErrorExists: false,
  showValidate: {
    bidrateEquipmentRates: false,
    bidrateLaborRates: false,
    bidrateCus: false,
    bidrateReimbursibleMarkups: false,
    bidrateUndergrndMltplrRates: false,
    bidrateUnionRates: false,
    bidrateUnitPrices: false,
  },
};

const bidRateSheetSlice = createSlice({
  name: 'bidRateSheetSlice',
  initialState,
  reducers: {
    fetchBidRateSheetList(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    fetchBidRateSheetDetails(state) {
      state.isLoading = false;
    },

    fetchBidRateWorkCategory(state) {
      state.isLoading = false;
    },

    fetchBidRateLocations(state) {
      state.isLoading = false;
    },

    fetchBidRateEquipmentTypes(state) {
      state.isLoading = false;
    },

    fetchBidRateOwnershipTypes(state) {
      state.isLoading = false;
    },

    fetchLaborServiceTypeOptions(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    fetchEquipmentServiceTypeOptions(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    fetchOtherServiceTypeOptions(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    fetchBidRateServiceTypeOptions(state, action) {
      state.isLoading = false;
    },

    setMasterDataError(state, action) {
      const stateValue = { ...state };
      stateValue.masterDataErrors = action.payload;
      return { ...stateValue };
    },

    setMasterDataErrorExists(state, action) {
      const stateValue = { ...state };
      stateValue.masterDataErrorExists = action.payload;
      return { ...stateValue };
    },

    setShowValidate(state, action) {
      const stateValue = { ...state };
      stateValue.showValidate = action.payload;
      return { ...stateValue };
    },

    setBidRateSheetSelectedTab(state, action) {
      const stateValue = { ...state };
      stateValue.selectedBidRateTab = action.payload;
      return { ...stateValue };
    },

    setLaborServiceTypeOptions(state, action) {
      const stateValues = { ...state };
      const bidRateSheetLabour = [];

      action.payload.rows.forEach(obj => {
        bidRateSheetLabour.push({
          laborCode: obj.craft,
          laborDescription: obj.description,
          laborCategory: obj.rescattype,
        });
      });
      stateValues.laborServiceTypeOptions = [...bidRateSheetLabour];
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },

    setEquipmentServiceTypeOptions(state, action) {
      const stateValues = { ...state };
      const bidRateSheetEquipment = [];

      action.payload.rows.forEach(obj => {
        bidRateSheetEquipment.push({
          eqpmntCode: obj.itemnum,
          eqpmntDescription: obj.description,
          eqpmntCategory: obj.eqpcategory,
          unitOfMeasure: obj.issueunit,
          horsepower: obj.horsepower,
          volumecfm: obj.volumecfm,
          size: obj.size,
          fuelGallonsPerHour: obj.gallonperhr,
          eqpmntDriveType: obj.eqptdriventype,
          volumeOfWaterGpm: obj.volwatergpm,
        });
      });
      stateValues.equipmentServiceTypeOptions = [...bidRateSheetEquipment];
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },

    setBidRateSheetSuccess(state, action) {
      const stateValues = { ...state };
      stateValues.isSuccess = action.payload;
      stateValues.isStatus = true;
      return { ...stateValues };
    },

    setOtherServiceTypeOptions(state, action) {
      const stateValues = { ...state };
      const bidRateSheetOther = [];

      action.payload.rows.forEach(obj => {
        bidRateSheetOther.push({
          code: obj.itemnum,
          description: obj.description,
          unitOfMeasure: obj.issueunit,
          heirarchypath: obj.heirarchypath,
        });
      });
      stateValues.otherServiceTypeOptions = [...bidRateSheetOther];
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },

    saveBidRateSheet(state) {
      state.isLoading = false;
    },

    setBidRateRowPage(state, action) {
      state.bidRateRowPage = action.payload;
    },

    setContinuBtnDisable(state, action) {
      state.addTabContinueBtn = action.payload;
    },

    setBidRateSheet(state, action) {
      const bidRateSheetList = [];

      action.payload.rows.forEach(obj => {
        bidRateSheetList.push({
          bidRateNumber: obj.bidrateNmbr,
          bidratesheetname: obj.name,
          lastmodifieddate: obj.updatedDate,
          caastatus: obj.caastatus,
        });
      });
      const stateValues = { ...state };
      stateValues.items = [...bidRateSheetList];
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },

    setBidRateSheetPagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = { ...stateValues.pagination, ...action.payload };
      return { ...stateValues };
    },

    setAddNewCodePagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    setBidRateSheetUpdatedData(state, action) {
      const responseData = action.payload;
      const stateValues = { ...state };
      stateValues.bidRateSheetDetailsData = responseData;
      return { ...stateValues };
    },

    setBidRateSheetDetails(state, action) {
      const responseData = action.payload;
      const stateValues = { ...state };
      stateValues.bidRateSheetDetailsList = responseData;
      stateValues.bidRateSheetDetailsData = responseData;
      stateValues.preferenceColumns = isEmptyVal(responseData.preference)
        ? stateValues.preferenceColumns
        : responseData.preference;
      stateValues.bidRateNumber = responseData.bidrateNmbr;
      stateValues.sheetSaved = true;

      return { ...stateValues };
    },

    setBidRatesData(state, action) {
      const stateValues = { ...state };
      stateValues.data = { ...stateValues.data, ...action.payload };
      state.isLoading = true;
      return { ...stateValues };
    },
    saveLaborRatesData(state) {
      state.isLoading = false;
    },
    setBidrateSheetSaved(state, action) {
      state.sheetSaved = action.payload;
    },
    saveBidRateSheetDetailsData(state) {
      state.isLoading = false;
      state.sheetSaved = true;
    },
    deleteBidRateSheetRecord(state) {
      state.sheetDeleted = false;
    },
    setDeletedBidRateSheet(state) {
      state.sheetDeleted = true;
    },
    setLaborRatesPreviewData(state, action) {
      const responseData = action.payload;
      const stateValues = { ...state };
      stateValues.isStatus = true;
      stateValues.sheetSaved = true;
      responseData.forEach(obj => {
        stateValues.bidRateNumber = obj.bidrateNmbr;
        stateValues.caaStatus = obj.caastatus;
      });

      return { ...stateValues };
    },

    setBidRateSheetWorkCategory(state, action) {
      const stateValues = { ...state };
      stateValues.workCategoryList = action.payload;
      return { ...stateValues };
    },

    setBidRateSheetLocation(state, action) {
      const stateValues = { ...state };
      stateValues.locationList = action.payload;
      return { ...stateValues };
    },

    setBidRateSheetEquipmentType(state, action) {
      const stateValues = { ...state };
      stateValues.equipmentType = action.payload;
      return { ...stateValues };
    },

    setBidRateSheetOwnershipType(state, action) {
      const stateValues = { ...state };
      stateValues.ownershipType = action.payload;
      return { ...stateValues };
    },
    // setBidRateSheetDetailsData(state, action) {
    //   const stateValues = { ...state };
    //   stateValues.isStatus = true;
    //   return { ...stateValues };
    // },

    setBidRateSheetObj(state, action) {
      const stateValues = { ...state };
      stateValues.isStatus = action.payload;
      stateValues.isLoading = true;
      return { ...stateValues };
    },

    setBidRateNumber(state, action) {
      const stateValues = { ...state };
      stateValues.bidRateNumber = action.payload;
      stateValues.isStatus = true;
      return { ...stateValues };
    },
    resetBidRateSheet() {
      return { ...initialState };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },

    setNewBidRateSheet(state, action) {
      const stateValues = { ...state };
      stateValues.newBidRateSheet = action.payload.newBidRateSheet;
      return { ...stateValues };
    },

    setPreferenceColumns(state, action) {
      const stateValues = { ...state };
      const prefColumns = _.cloneDeep(stateValues.preferenceColumns);
      prefColumns[action.payload.serviceType] = isEmptyVal(
        action.payload.valuesList,
      )
        ? []
        : action.payload.valuesList;
      stateValues.preferenceColumns = prefColumns;
      return { ...stateValues };
    },

    setAddNewInputFilterData(state, action) {
      const stateValues = { ...state };
      stateValues.inputList = [...stateValues.inputList, action.payload];
      return { ...stateValues };
    },

    setInputFilterData(state, action) {
      if (action.payload.length != 0) {
        state.inputList = action.payload;
      } else {
        const stateValues = { ...state };
        stateValues.inputList = [...initialState.inputList];
        return { ...stateValues };
      }
    },
    fetchSelectedBidRateSheetList(state) {
      state.isLoading = false;
    },
    setSelectedBidRateSheetRecords(state, action) {
      const selectedBidRateSheetRecords = [];
      action.payload?.forEach(obj => {
        let objValues = Object.values(obj);
        selectedBidRateSheetRecords.push({
          label: objValues[0],
          value: objValues[0],
        });
      });
      const stateValues = { ...state };
      stateValues.recordItems = [...selectedBidRateSheetRecords];
      return { ...stateValues };
    },

    setSelectedServiceTypeRecords(state, action) {
      try {
        const selectedBidRateSheetRecords = [];
        action.payload?.rows?.forEach(obj => {
          let objValues = Object.values(obj);
          selectedBidRateSheetRecords.push({
            label: objValues[0],
            value: objValues[0],
          });
        });

        const stateValues = { ...state };
        stateValues.recordItems = [...selectedBidRateSheetRecords];
        return { ...stateValues };
      } catch (e) {}
    },

    fetchServiceTypeOptions(state) {
      state.isLoading = false;
    },
    fetchServiceTypeOptionsLabor(state) {
      state.isLoading = false;
    },
    fetchServiceTypeOptionsEqp(state) {
      state.isLoading = false;
    },
    resetFilters(state) {
      const stateValues = { ...state };
      const heirarchypath = stateValues.pagination.heirarchypath;
      stateValues.pagination = {
        totalItems: 0,
        currentPage: 1,
        itemsPerPage: 10,
        sortBy: '',
        sortOrder: 'asc',
        heirarchypath: heirarchypath,
        caastatus: '',
        forceFetch: false,
      };
      stateValues.inputList = [
        {
          FilterValue: '',
          input_rank: null,
          selectFilterValue: '',
          selectFilterLabel: '',
          presentIcon: '',
          filerForm: false,
          FilterLabel: '',
          selectedFilterRecords: [],
        },
      ];
      return { ...stateValues };
    },
    fetchNewFiltersData(state) {
      state.isLoading = false;
    },
  },
});

export const {
  fetchBidRateSheetList,
  fetchBidRateSheetDetails,
  fetchLaborServiceTypeOptions,
  fetchEquipmentServiceTypeOptions,
  fetchOtherServiceTypeOptions,
  fetchBidRateWorkCategory,
  fetchBidRateLocations,
  fetchBidRateEquipmentTypes,
  fetchBidRateOwnershipTypes,
  setBidRateSheetWorkCategory,
  saveBidRateSheet,
  setLaborServiceTypeOptions,
  setBidRateSheetObj,
  setEquipmentServiceTypeOptions,
  setOtherServiceTypeOptions,
  setBidRateSheet,
  setBidRateRowPage,
  setContinuBtnDisable,
  setBidRateSheetDetails,
  setBidRateSheetPagination,
  setBidRateSheetDetailsData,
  setBidRatesData,
  setBidRateNumber,
  setAddNewCodePagination,
  setBidRateSheetLocation,
  setBidRateSheetEquipmentType,
  setBidRateSheetOwnershipType,
  saveLaborRatesData,
  setBidRateSheetUpdatedData,
  setBidRateSheetSuccess,
  fetchBidRateServiceTypeOptions,
  saveBidRateSheetDetailsData,
  deleteBidRateSheetRecord,
  setDeletedBidRateSheet,
  setLaborRatesPreviewData,
  resetBidRateSheet,
  setError,
  unsetError,
  setBidRateSheetSelectedTab,
  setNewBidRateSheet,
  setBidrateSheetSaved,
  setPreferenceColumns,
  setAddNewInputFilterData,
  setInputFilterData,
  fetchSelectedBidRateSheetList,
  setSelectedBidRateSheetRecords,
  fetchServiceTypeOptions,
  fetchServiceTypeOptionsLabor,
  fetchServiceTypeOptionsEqp,
  setSelectedServiceTypeRecords,
  resetFilters,
  fetchNewFiltersData,
  setMasterDataError,
  setMasterDataErrorExists,
  setShowValidate,
} = bidRateSheetSlice.actions;

export default bidRateSheetSlice.reducer;
