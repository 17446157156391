// @ts-nocheck
import { Divider } from 'components/common/Divider/Divider';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  fetchLaborServiceTypeOptions,
  fetchEquipmentServiceTypeOptions,
  fetchOtherServiceTypeOptions,
  fetchServiceTypeOptions,
  fetchServiceTypeOptionsEqp,
  fetchServiceTypeOptionsLabor,
  setAddNewCodePagination,
} from 'store/slices/BidRateSheets/bidRateSheetListSlice';
import { setNewCodeModalFilters } from 'store/slices/filterSlice';
import './BidRateSheetEntry.scss';
import { isEmptyVal } from 'utils/utils';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorIcon } from 'assets/images/ErrorIcon/ErrorIcon';
import { generateSchema } from 'pages/Timesheet/TimesheetEntry/formHelpers';

const AddNewCodePopup = ({
  show,
  setShowModal,
  handleAddNewCodeContinueClick,
  serviceType,
  autoFillColumnskeys,
}) => {
  const dispatch = useDispatch();
  const schema = Yup.object().shape({
    newCodeSearch: Yup.string()
      .required('Enter something to search')
      .min(3, 'Character length should be atleast 3'),
  });

  const [newCodeSearch, setNewCodeSearch] = useState('');
  const [codeTitle, setCodeTitle] = useState('');
  const [addNewCodeHeaders, setAddNewCodeHeaders] = useState([]);

  const methods = useForm({
    mode: 'onTouched',
    defaultValues: { newCodeSearch: '' },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });
  const {
    register,
    resetField,
    handleSubmit,
    watch,
    control,
    setValue,
    trigger,
    setError,
    formState: { errors },
  } = methods;
  const laborServiceTypeOptions = useSelector(
    state => state.bidRateSheetList.laborServiceTypeOptions,
  );
  const equipmentServiceTypeOptions = useSelector(
    state => state.bidRateSheetList.equipmentServiceTypeOptions,
  );
  const otherServiceTypeOptions = useSelector(
    state => state.bidRateSheetList.otherServiceTypeOptions,
  );
  const pagination = useSelector(state => state.bidRateSheetList.pagination);
  const { currentPage, sortBy, sortOrder, totalItems, itemsPerPage } =
    useSelector(state => state.bidRateSheetList.pagination);

  const types = {
    bidrateLaborRates: {
      title: 'Code/Description/Category',
      header: [
        { label: 'Code', value: 'craft' },
        { label: 'Description', value: 'description' },
        { label: 'Category', value: 'rescattype' },
      ],
    },
    bidrateEquipmentRates: {
      title: 'Code/Description/Category',
      header: [
        { label: 'Code', value: 'itemnum' },
        { label: 'Description', value: 'description' },
        { label: 'Category', value: 'eqpcategory' },
        { label: 'Unit of Measure', value: 'unitOfMeasure' },
      ],
    },
    otherServiceRates: {
      title: 'Code/Description',
      header: [
        { label: 'Code', value: 'itemnum' },
        { label: 'Description', value: 'description' },
        { label: 'Unit of Measure', value: 'unitOfMeasure' },
      ],
    },
  };

  useEffect(() => {
    if (types[serviceType]) {
      setCodeTitle(types[serviceType].title);
      setAddNewCodeHeaders(types[serviceType].header);
    } else {
      setCodeTitle(types.otherServiceRates.title);
      setAddNewCodeHeaders(types.otherServiceRates.header);
    }
  }, []);

  const handleShowModal = () => {
    setNewCodeSearch('');
    resetField('newCodeSearch');
    setShowModal(false);
  };

  const isReqPagination = () => {
    if (totalItems <= itemsPerPage - 1 && currentPage === 1) {
      return false;
    } else {
      return true;
    }
  };

  const handleNewCodeSearch = event => {
    const searchVal = event?.target.value;
    setValue('newCodeSearch', searchVal);
    trigger('newCodeSearch', searchVal);
    setNewCodeSearch(searchVal);
    if (searchVal.length >= 3) {
      if (serviceType == 'bidrateLaborRates') {
        dispatch(fetchLaborServiceTypeOptions({ search: searchVal }));
      } else if (serviceType == 'bidrateEquipmentRates') {
        dispatch(fetchEquipmentServiceTypeOptions({ search: searchVal }));
      } else {
        dispatch(
          fetchOtherServiceTypeOptions({
            search: searchVal,
          }),
        );
      }
    }
  };

  const handleCodeSubmit = (row, i) => {
    let obj = {};
    obj[i] = true;
    let sample = { ...row };
    if (
      serviceType != 'bidrateLaborRates' &&
      serviceType != 'bidrateEquipmentRates'
    ) {
      handleObjectkeyChange(sample, autoFillColumnskeys);
    }
    const codeDetails =
      serviceType != 'bidrateLaborRates' &&
      serviceType != 'bidrateEquipmentRates'
        ? sample
        : row;
    handleAddNewCodeContinueClick(codeDetails);
  };

  const handleTableData = () => {
    if (serviceType === 'bidrateLaborRates') {
      return (
        laborServiceTypeOptions != undefined &&
        laborServiceTypeOptions.map((row, indx) => (
          <tr
            key={indx}
            className="table-hover-row"
            onClick={e => handleCodeSubmit(row, indx)}
          >
            <td>{row.laborCode}</td>
            <td>{row.laborDescription}</td>
            <td>{row.laborCategory}</td>
          </tr>
        ))
      );
    } else if (serviceType === 'bidrateEquipmentRates') {
      return (
        equipmentServiceTypeOptions != undefined &&
        equipmentServiceTypeOptions.map((row, indx) => (
          <tr
            key={indx}
            className="table-hover-row"
            onClick={e => handleCodeSubmit(row, indx)}
          >
            <td>{row.eqpmntCode}</td>
            <td>{row.eqpmntDescription}</td>
            <td>{row.eqpmntCategory}</td>
            <td>{row.unitOfMeasure}</td>
          </tr>
        ))
      );
    } else {
      return (
        otherServiceTypeOptions != undefined &&
        otherServiceTypeOptions.map((row, indx) => (
          <tr
            key={indx}
            className="table-hover-row"
            onClick={e => handleCodeSubmit(row, indx)}
          >
            <td>{row.code}</td>
            <td>{row.description}</td>
            <td>{row.unitOfMeasure}</td>
          </tr>
        ))
      );
    }
  };

  let handleObjectkeyChange = (object, autoFillColumnskeys) => {
    Object.keys(autoFillColumnskeys).map(function (item) {
      let newkey = autoFillColumnskeys[item];
      if (newkey != item) {
        object[newkey] = object[item];
        delete object[item];
      }
    });

    return object;
  };

  return (
    <Modal
      show={show}
      onHide={handleShowModal}
      dialogClassName="bidRateSheetNewCode"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>Add New Code</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        <div className="aepContainer addNewCodeTable">
          <FormProvider {...methods}>
            <Row>
              <Col sm={12}>
                <Form.Group controlId="form.startDate">
                  <Form.Label>Search for {codeTitle}: </Form.Label>
                  <Controller
                    control={control}
                    name="code description"
                    defaultValue=""
                    render={({ field: { ref } }) => (
                      <Form.Control
                        value={newCodeSearch}
                        isInvalid={errors?.newCodeSearch?.['message']}
                        onChange={e => handleNewCodeSearch(e)}
                        name="newCodeSearch"
                        ref={ref}
                        type="text"
                      />
                    )}
                  />
                  {errors.newCodeSearch && (
                    <div className="errorMessage">
                      <ErrorIcon />
                      {errors.newCodeSearch['message']}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {totalItems >= 1 && (
              <div className="codeTable">
                <Table hover responsive striped bordered={false}>
                  <thead>
                    <tr>
                      {addNewCodeHeaders.map((header, idx) => (
                        <th key={idx}>{header.label}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{handleTableData()}</tbody>
                </Table>
              </div>
            )}
          </FormProvider>
        </div>
        {isReqPagination() && (
          <div className="float-right">
            <AEPPagination
              fetchItems={
                serviceType == 'bidrateLaborRates'
                  ? fetchLaborServiceTypeOptions
                  : serviceType == 'bidrateEquipmentRates'
                  ? fetchEquipmentServiceTypeOptions
                  : fetchOtherServiceTypeOptions
              }
              setPagination={setAddNewCodePagination}
              pagination={pagination}
              currentPage={currentPage}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export { AddNewCodePopup };
