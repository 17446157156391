import { AddClassificationIcon } from 'assets/images/AddClassificationIcon/AddClassificationIcon';
import { MaximizeIcon } from 'assets/images/MaximizeIcon/MaximizeIcon';
import { MinimizeIcon } from 'assets/images/MinimizeIcon/MinimizeIcon';
import { TimesheetAddIcon } from 'assets/images/TimesheetAddIcon/TimesheetAddIcon';
import {
  TimesheetLeftArrowDisableIcon,
  TimesheetLeftArrowEnableIcon,
  TimesheetRightArrowDisableIcon,
  TimesheetRightArrowEnableIcon,
} from 'assets/images/TimesheetArrows/TimesheetArrows';
import { TimesheetDeleteIcon } from 'assets/images/TimesheetDeleteIcon/TimesheetDeleteIcon';
import {
  TimesheetGridViewIconDefault,
  TimesheetGridViewIconSelected,
} from 'assets/images/TimesheetGridViewIcon/TimesheetGridViewIcon';
import {
  TimesheetListViewIconDefault,
  TimesheetListViewIconSelected,
} from 'assets/images/TimesheetListView/TimesheetListViewIcon';
import { ModuleACL } from 'enums/entitlements.ts';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
import { serviceTypeObject } from 'pages/Timesheet/utils';
import { Fragment, useRef, useState } from 'react';
import { Button, CloseButton, Col, Form, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import CreatableSelect from 'react-select/creatable';
import { IsAllowedACL } from 'utils/aclHelper';
import { isEmptyVal } from 'utils/utils';

const ClassificationFields = props => {
  const [listView, setListView] = useState(true);
  const user = useSelector(state => state.auth);
  const timesheet = useSelector(state => state.timesheetDetail);
  const lookup = useSelector(state => state.lookup);
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  const {
    control,
    errors,
    disableFields,
    serviceTypeOptions,
    deleteClassification,
    hideServiceType,
    handleRowClick,
    checkedRows,
    updateCheckedRows,
    deleteClassificationRows,
    displayUPTaskForm,
    isJobAuthUnitPriceTask,
    activityError,
    handleSelectOnChange,
    serviceTypeOptionList,
    handleSelectChange,
    getAddedClassification,
    setShowServiceTypes,
    handleFieldOnChange,
    showServiceTypes,
    milesCompleteError,
  } = props;

  let scrl = useRef(null);
  let timesheetLabors = useRef(null);
  let timesheetReimbursables = useRef(null);
  let timesheetEquipments = useRef(null);
  let timesheetUptasks = useRef(null);
  let timesheetCus = useRef(null);
  let timesheetLumpsums = useRef(null);

  const getRef = refname => {
    let ref = scrl;
    switch (refname) {
      case SERVICE_TYPES.LABOR:
        ref = timesheetLabors;
        break;
      case SERVICE_TYPES.REIMBURSABLE_MARKUP:
        ref = timesheetReimbursables;
        break;
      case SERVICE_TYPES.EQUIPMENT:
        ref = timesheetEquipments;
        break;
      case SERVICE_TYPES.UNIT_PRICE_TASK:
        ref = timesheetUptasks;
        break;
      case SERVICE_TYPES.CU:
        ref = timesheetCus;
        break;
      case SERVICE_TYPES.LUMPSUM:
        ref = timesheetLumpsums;
        break;
      default:
        ref = scrl;
    }
    return ref;
  };

  //Slide click
  const slide = (refName, shift) => {
    let ref = getRef(refName);
    ref.current.scrollLeft += shift;
    setscrollX(scrollX + shift);
    if (
      Math.floor(ref.current.scrollWidth - ref.current.scrollLeft) <=
      ref.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  // Method used to dynamically display fields for a service type while filling in the form
  const showField = (classificationName, fieldName) => {
    const fieldMappings = {
      [SERVICE_TYPES.LABOR]: {
        holidayPayHours: 'holidaypay',
        travelHours: 'travelrate',
        peakSeasonHours: 'peakseason',
        emerHours: 'emergencycallout',
        standbyHours: 'standby',
        mobUnits: 'mobilization',
        demobUnits: 'demobilization',
        perDiemUnits: 'perdiemrate',
        dailyHours: 'dailywage',
        weeklyHours: 'weeklywage',
        monthlyHours: 'monthlywage',
        rhxHours: 'rhxlaborrate',
        dtxHours: 'dtxlaborrate',
      },
      [SERVICE_TYPES.EQUIPMENT]: {
        opHours: 'operatingrate',
        stHours: 'standbyrate',
        otHours: 'otrate',
        miles: 'costpermile',
        mobUnits: 'mobilization',
        demobUnits: 'demobilization',
      },
    };

    let showField = false;
    if (fieldMappings?.[classificationName]?.hasOwnProperty(fieldName)) {
      purchaseOrder[classificationName]?.forEach(obj => {
        if (
          !isEmptyVal(obj[fieldMappings?.[classificationName]?.[fieldName]]) &&
          obj[fieldMappings?.[classificationName]?.[fieldName]] != 0
        ) {
          showField = true;
        }
      });
    } else {
      if (
        ['shift', 'regLaborHours', 'otLaborHours', 'dtLaborHours'].includes(
          fieldName,
        ) &&
        lookup?.shiftOptions?.length === 1
      ) {
        showField = false;
      } else {
        showField = true;
      }
    }
    return showField;
  };

  const getJobAuthError = (classification, rowIndex, colIndex) => {
    let error = '';
    if (isJobAuthUnitPriceTask) {
      const row = classification.values[rowIndex];
      if (row[colIndex]?.name === 'quantity') {
        const codeObj = row.find(obj => obj.name === 'code');
        const quantityObj = row.find(obj => obj.name === 'quantity');
        const jobAuthTaskList = timesheet?.jobAuthorization?.jobauthtasks;
        const jobAuthObj = jobAuthTaskList?.find(
          obj => obj.taskcode === codeObj?.value,
        );
        let savedQuantity = 0;
        if (timesheet.timesheetUptasks) {
          for (const timesheetUptask of timesheet.timesheetUptasks) {
            if (timesheetUptask.code == codeObj?.value) {
              savedQuantity = timesheetUptask.quantity;
            }
          }
        }
        const quantityValue = isEmptyVal(quantityObj?.value)
          ? 0
          : Number(quantityObj.value);

        if (
          Number(
            jobAuthObj?.currentTimesheetsQuantity -
              savedQuantity +
              quantityValue,
          ).toFixed(2) > jobAuthObj?.taskUnits ??
          0
        ) {
          error = 'Authorized Job units and Cost has exceeded';
        } else {
          error = '';
        }
      }
    }
    return error;
  };

  const addDisEqupRntlUnitStyle = (serviceName, rowIndex, colName) => {
    if (
      serviceName === SERVICE_TYPES.EQUIPMENT &&
      colName == 'equipmentRentalUnits' &&
      timesheet?.timesheetEquipments[rowIndex]?.isDiscounted == true
    ) {
      return 'disEqRentalUnitStyle';
    }
  };

  return (
    <>
      {timesheet?.classificationList?.length ||
      getAddedClassification().length < serviceTypeOptionList.length ||
      timesheet?.timesheetStatushistories?.length ? (
        <div className="aepContainer">
          {timesheet?.classificationList.map((classification, index) => {
            return (
              <div
                key={classification.name}
                className="classificationContainer"
              >
                <div className="serviceTypeDiv">
                  <div className="serviceTypeSection">
                    <Form.Group
                      controlId="form.serviceType"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Form.Label>Service Type</Form.Label>
                      <Form.Select
                        aria-label="Service Type"
                        className="serviceType"
                        value={classification.name}
                        onChange={() => {}}
                        disabled={disableFields(classification.name)}
                      >
                        {serviceTypeOptions.map((serviceType, servIndex) => {
                          return (
                            <option
                              key={servIndex}
                              value={serviceType.value}
                              disabled
                            >
                              {serviceType.label}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>

                    {isMobile && (
                      <div className="hideClassifications">
                        <div
                          title="Close"
                          onClick={e => deleteClassification(index)}
                        >
                          <CloseButton />
                        </div>

                        <div onClick={e => hideServiceType(index)}>
                          <div
                            className="sType"
                            id={`showMaxDiv${index}`}
                            style={{ display: 'none' }}
                            title="Maximize"
                          >
                            <MaximizeIcon />
                          </div>
                          <div
                            className="sType"
                            title="Minimize"
                            id={`showMinDiv${index}`}
                          >
                            <MinimizeIcon />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="iconDiv">
                    <div
                      onClick={() =>
                        !disableFields(classification.name)
                          ? handleRowClick(classification.name)
                          : null
                      }
                      title="Add Row"
                      className="timesheetAddIcon"
                      style={
                        !disableFields(classification.name) &&
                        classification.name !== SERVICE_TYPES.CU
                          ? {}
                          : { opacity: '0.5' }
                      }
                    >
                      <TimesheetAddIcon />
                    </div>
                    {/* <div onClick={e => copyClassificationRows(index)}>
                        <TimesheetCopyIcon />
                      </div> */}
                    <div
                      onClick={() =>
                        checkedRows[classification.name].length > 0
                          ? deleteClassificationRows(index)
                          : null
                      }
                      className="timesheetDeleteIcon"
                      title="Delete Row"
                      style={
                        checkedRows[classification.name].length > 0
                          ? {}
                          : { opacity: '0.5' }
                      }
                    >
                      <TimesheetDeleteIcon />
                    </div>
                    <div
                      onClick={e =>
                        !disableFields(classification.name)
                          ? deleteClassification(index)
                          : null
                      }
                      style={
                        !disableFields(classification.name)
                          ? {}
                          : { opacity: '0.5' }
                      }
                      className="removeService"
                    >
                      <span>Remove Service Type</span>
                    </div>
                  </div>
                  <div className="viewIcon">
                    {listView ? (
                      <div style={{ display: 'flex' }}>
                        <div title="List View">
                          <TimesheetListViewIconSelected />
                        </div>
                        <div
                          onClick={() => setListView(false)}
                          style={{ cursor: 'pointer' }}
                          title="Grid View"
                        >
                          <TimesheetGridViewIconDefault />
                        </div>
                      </div>
                    ) : (
                      <div style={{ display: 'flex' }}>
                        <div
                          onClick={() => setListView(true)}
                          style={{ cursor: 'pointer' }}
                          title="List View"
                        >
                          <TimesheetListViewIconDefault />
                        </div>
                        <div title="Grid View">
                          <TimesheetGridViewIconSelected />
                        </div>
                      </div>
                    )}
                  </div>

                  {!isMobile && (
                    <div
                      className="hideClassifications"
                      style={{ display: 'flex' }}
                    >
                      <div onClick={e => hideServiceType(index)}>
                        <div
                          className="sType"
                          id={`showMaxDiv${index}`}
                          style={{ display: 'none' }}
                          title="Maximize"
                        >
                          <MaximizeIcon />
                        </div>
                        <div
                          className="sType"
                          id={`showMinDiv${index}`}
                          title="Minimize"
                        >
                          <MinimizeIcon />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="addClassificationSection"
                  style={{ display: 'flex' }}
                >
                  {listView && (
                    <div>
                      {scrollX !== 0 ? (
                        <div
                          onClick={() =>
                            isMobile
                              ? slide(classification.name, -200)
                              : slide(classification.name, -1500)
                          }
                          title="Left"
                          className="leftRightArrow"
                        >
                          <TimesheetLeftArrowEnableIcon />
                        </div>
                      ) : (
                        <div className="leftRightArrow">
                          <TimesheetLeftArrowDisableIcon />
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className={
                      listView
                        ? 'serviceTypeHolderListView'
                        : 'serviceTypeHolderGridView'
                    }
                    id={`serviceType${index}`}
                    ref={getRef(classification.name)}
                  >
                    {classification.values.map((row, rowIndex) => (
                      <div
                        key={`${classification.name}-${rowIndex}`}
                        className="classification"
                      >
                        <div
                          className="checkDiv"
                          style={{
                            marginTop: '38px',
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={
                              checkedRows[classification.name].indexOf(
                                rowIndex,
                              ) >= 0
                            }
                            disabled={disableFields(classification.name)}
                            onChange={e =>
                              updateCheckedRows(
                                e,
                                classification.name,
                                rowIndex,
                              )
                            }
                          />
                        </div>
                        <div
                          className={listView ? 'formDivListView' : 'formDiv'}
                        >
                          {row.map(
                            (col, colIndex) =>
                              showField(classification.name, col.name) && (
                                <Form.Group
                                  key={`${classification.name}-${rowIndex}-${colIndex}`}
                                  className={addDisEqupRntlUnitStyle(
                                    classification.name,
                                    rowIndex,
                                    col.name,
                                  )}
                                >
                                  {
                                    <>
                                      {displayUPTaskForm(
                                        classification.name,
                                        row,
                                        col,
                                      ) && (
                                        <Form.Label>
                                          {classification.name ===
                                          SERVICE_TYPES.UNIT_PRICE_TASK
                                            ? isJobAuthUnitPriceTask &&
                                              col.name === 'totalPrice'
                                              ? 'Dollars/Unit'
                                              : isJobAuthUnitPriceTask &&
                                                col.name === 'lineAmount'
                                              ? 'Total Cost'
                                              : col.label
                                            : col.label}
                                        </Form.Label>
                                      )}

                                      {((classification.name ===
                                        SERVICE_TYPES.UNIT_PRICE_TASK &&
                                        ((isJobAuthUnitPriceTask &&
                                          col?.name === 'code') ||
                                          (!isJobAuthUnitPriceTask &&
                                            col.required))) ||
                                        (classification.name !==
                                          SERVICE_TYPES.UNIT_PRICE_TASK &&
                                          col.required)) && (
                                        <span
                                          style={{
                                            color: 'red',
                                            fontSize: '15px',
                                            marginLeft: '5px',
                                          }}
                                        >
                                          *
                                        </span>
                                      )}
                                    </>
                                  }

                                  {col.values?.length > 0 ? (
                                    <Controller
                                      key={`${classification.name}.${rowIndex}.${colIndex}`}
                                      control={control}
                                      name={`${classification.name}.${rowIndex}.${col.name}`}
                                      render={({
                                        onChange,
                                        value,
                                        name,
                                        ref,
                                      }) => (
                                        <Fragment
                                          key={`${classification.name}.${rowIndex}.${colIndex}.${col.name}`}
                                        >
                                          {classification.name ===
                                            SERVICE_TYPES.EQUIPMENT &&
                                          col.name === 'equipId' ? (
                                            disableFields(
                                              classification.name,
                                            ) ? (
                                              <Form.Select
                                                aria-label={col.label}
                                                className="customEqupId"
                                                value={col.value}
                                                disabled={disableFields(
                                                  classification.name,
                                                )}
                                              >
                                                {col.values.map(
                                                  (
                                                    selectValue,
                                                    selectIndex,
                                                  ) => (
                                                    <>
                                                      <option
                                                        key={`${classification.name}.${rowIndex}.${colIndex}.${selectIndex}`}
                                                        value={
                                                          selectValue.value
                                                        }
                                                      >
                                                        {selectValue.label}
                                                      </option>
                                                    </>
                                                  ),
                                                )}
                                              </Form.Select>
                                            ) : (
                                              // Equipment Id is a creatable select dropdown field.
                                              <CreatableSelect
                                                id={`equipId${rowIndex}${colIndex}`}
                                                className="mr-1"
                                                inputRef={ref}
                                                classNamePrefix="eqpSelect"
                                                options={col.values}
                                                value={col.values.find(
                                                  c => c.value === col.value,
                                                )}
                                                isClearable={true}
                                                menuPosition="absolute"
                                                menuPortalTarget={document.body}
                                                placeholder="Select a value or type in here to create a new one"
                                                onChange={e =>
                                                  handleSelectOnChange(
                                                    e,
                                                    classification.name,
                                                    index,
                                                    rowIndex,
                                                    colIndex,
                                                    undefined,
                                                    col.name,
                                                  )
                                                }
                                              />
                                            )
                                          ) : displayUPTaskForm(
                                              classification.name,
                                              row,
                                              col,
                                            ) ? (
                                            <Form.Select
                                              aria-label={col.label}
                                              value={col.value}
                                              disabled={disableFields(
                                                classification.name,
                                              )}
                                              onChange={e =>
                                                handleSelectOnChange(
                                                  e,
                                                  classification.name,
                                                  index,
                                                  rowIndex,
                                                  colIndex,
                                                  undefined,
                                                  col.name,
                                                )
                                              }
                                            >
                                              {col.values.map(
                                                (selectValue, selectIndex) => (
                                                  <>
                                                    <option
                                                      key={`${classification.name}.${rowIndex}.${colIndex}.${selectIndex}`}
                                                      value={selectValue.value}
                                                    >
                                                      {selectValue.label}
                                                    </option>
                                                  </>
                                                ),
                                              )}
                                            </Form.Select>
                                          ) : (
                                            <></>
                                          )}

                                          <div className="errorMessage">
                                            {
                                              errors?.[classification.name]?.[
                                                rowIndex
                                              ]?.[col.name]?.['message']
                                            }
                                          </div>
                                        </Fragment>
                                      )}
                                    />
                                  ) : /*
                                          `useFieldArray` automatically generates a unique identifier named id which is used for key prop.
                                          For more information why this is required: https://reactjs.org/docs/lists-and-keys.html#keys
                                          The field.id (and not index) must be added as the component key to prevent re-renders breaking the fields.
                                          */
                                  displayUPTaskForm(
                                      classification.name,
                                      row,
                                      col,
                                    ) ? (
                                    <Controller
                                      key={`${classification.name}.${rowIndex}.${colIndex}`}
                                      control={control}
                                      name={`${classification.name}.${rowIndex}.${col.name}`}
                                      defaultValue=""
                                      render={({
                                        field: { onChange, onBlur, value, ref },
                                      }) => (
                                        <Fragment
                                          key={`${classification.name}-${rowIndex}-${colIndex}-${col.name}`}
                                        >
                                          <Form.Control
                                            value={col.value}
                                            disabled={disableFields(
                                              classification.name,
                                              col.name,
                                            )}
                                            onChange={e =>
                                              handleFieldOnChange(
                                                e,
                                                classification.name,
                                                index,
                                                rowIndex,
                                                colIndex,
                                                undefined,
                                                col.name,
                                              )
                                            }
                                            ref={ref}
                                            type="text"
                                          />
                                          <div className="errorMessage">
                                            {getJobAuthError(
                                              classification,
                                              rowIndex,
                                              colIndex,
                                            )}
                                          </div>

                                          <div className="errorMessage">
                                            {
                                              errors?.[classification.name]?.[
                                                rowIndex
                                              ]?.[col.name]?.['message']
                                            }
                                          </div>

                                          <div className="errorMessage">
                                            {
                                              activityError?.[
                                                classification.name
                                              ]?.[rowIndex]?.[col.name]
                                            }
                                          </div>
                                          <div className="errorMessage">
                                            {col.name === 'milescomplete' &&
                                              !isEmptyVal(milesCompleteError) &&
                                              milesCompleteError}
                                          </div>
                                        </Fragment>
                                      )}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </Form.Group>
                              ),
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {listView && (
                    <div>
                      {!scrolEnd ? (
                        <div
                          className="leftRightArrow"
                          onClick={() =>
                            isMobile
                              ? slide(classification.name, 200)
                              : slide(classification.name, 1500)
                          }
                          title="Right"
                        >
                          <TimesheetRightArrowEnableIcon />
                        </div>
                      ) : (
                        <div className="leftRightArrow">
                          <TimesheetRightArrowDisableIcon />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {getAddedClassification().length < serviceTypeOptionList.length && (
            <>
              {showServiceTypes && (
                <div className="classification" style={{ paddingLeft: '0' }}>
                  <Row>
                    <Col md={3} sm={3}>
                      <Form.Group controlId="form.serviceType">
                        <Form.Label>Service Type</Form.Label>
                        <Form.Select
                          aria-label="Service Type"
                          className="serviceType"
                          onChange={e => handleSelectChange(e)}
                        >
                          <option value="selectServiceType">
                            Select Service Type
                          </option>
                          {serviceTypeOptionList
                            .filter(
                              elem => !getAddedClassification().includes(elem),
                            )
                            .map((serviceType, servIndx) => (
                              <option
                                key={`${servIndx}-${serviceType}`}
                                value={serviceType}
                                disabled={serviceType === SERVICE_TYPES.CU}
                              >
                                {serviceTypeObject[serviceType]}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              )}

              {IsAllowedACL(
                user,
                ModuleACL.TIMESHEET,
                'can_update_timesheet',
              ) && (
                <div className="addBtn addClassification">
                  {!disableFields(null) && (
                    <>
                      <Button
                        active={false}
                        variant="link"
                        onClick={e => setShowServiceTypes(true)}
                        disabled={disableFields(null)}
                      >
                        <div>
                          <AddClassificationIcon />
                          <span className="ml-2">Add New Classification</span>
                        </div>
                      </Button>
                    </>
                  )}
                </div>
              )}
            </>
          )}

          {timesheet?.timesheetStatushistories?.map((obj, index) => (
            <div>
              <Form.Group
                controlId="form.reviewerComment"
                className="reviewerComment"
              >
                {index === 0 && (
                  <Form.Label
                    className="reviewerCommentLabel"
                    style={{ marginLeft: '0', marginBottom: '12px' }}
                  >
                    Reviewers Comments
                  </Form.Label>
                )}
                {!isEmptyVal(obj.comments) && (
                  <Controller
                    control={control}
                    name="reviewerComments"
                    defaultValue=""
                    disabled={true}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Form.Control
                        style={{
                          width: '100%',
                          marginBottom: '12px',
                          backgroundColor: '#f3f3f3',
                        }}
                        value={obj.comments}
                        ref={ref}
                        type="text"
                      />
                    )}
                  />
                )}
              </Form.Group>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export { ClassificationFields };
