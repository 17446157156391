import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { DownloadIcon } from 'assets/images/DownloadIcon/DownloadIcon';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { Page } from 'enums/page.ts';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveCsvFile } from 'services/cuAdditionalService';
import {
  fetchCompanies,
  fetchContractors,
  fetchCu,
  fetchDistricts,
  resetCuDownload,
  setCuPagination,
} from 'store/slices/cuAdditionalLinkSlice';
import { setActivePage } from 'store/slices/pageSlice';
import './CuDownload.scss';
import { isEmptyVal } from 'utils/utils';
import { ClipLoader } from 'react-spinners';

const CuDownload = () => {
  const dispatch = useDispatch();
  const cuList = useSelector(state => state.cuAdditionalLink.cuList);
  const contractorList = useSelector(
    state => state.cuAdditionalLink.contractorList,
  );
  const companyList = useSelector(state => state.cuAdditionalLink.companyList);
  const districtList = useSelector(
    state => state.cuAdditionalLink.districtList,
  );

  const pagination = useSelector(state => state.cuAdditionalLink.pagination);
  const currentPage = useSelector(
    state => state.cuAdditionalLink.pagination.currentPage,
  );
  const sortBy = useSelector(state => state.cuAdditionalLink.pagination.sortBy);
  const sortOrder = useSelector(
    state => state.cuAdditionalLink.pagination.sortOrder,
  );

  const [contractor, setContractor] = useState('');
  const [company, setCompany] = useState('');
  const [district, setDistrict] = useState('');
  const [workRequest, setWorkRequest] = useState('');
  const [workOrder, setWorkOrder] = useState('');
  const [cuDownloadLoading, setCuDownloadLoading] = useState();
  const [checkedRows, setCheckedRows] = useState([]);
  const [selectAllRows, setSelectAllRows] = useState(false);
  const [showContents, setShowContents] = useState(false);
  const [allowDownload, setAllowDownload] = useState(false);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const headers = [
    { key: 'cdOrgUnit', value: 'District' },
    { key: 'wr', value: 'Work Request' },
    { key: 'street', value: 'Work Request Description' },
  ];
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(setActivePage(Page.ADDITIONAL_LINKS));
    dispatch(fetchContractors());
    dispatch(fetchCompanies());
    dispatch(fetchDistricts());
    dispatch(resetCuDownload());
  }, []);

  const handleCuDownloadClick = row => {};

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setCuPagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };

  const updateCheckedRows = (e, cuId) => {
    const tempCheckedRows = _.cloneDeep(checkedRows);

    if (e.target.checked) {
      tempCheckedRows.push(cuId);
      if (cuList.length == tempCheckedRows.length) {
        setSelectAllRows(true);
      }
      setAllowDownload(true);
    } else {
      const targetIndex = tempCheckedRows.indexOf(cuId);
      tempCheckedRows.splice(targetIndex, 1);
      setSelectAllRows(false);
    }
    if (!tempCheckedRows.length) {
      setAllowDownload(false);
    }
    tempCheckedRows.sort();
    setCheckedRows(tempCheckedRows);
  };

  const handleSelectAllRows = () => {
    setSelectAllRows(!selectAllRows);

    if (selectAllRows) {
      setCheckedRows([]);
      setAllowDownload(false);
    } else {
      const tempCheckedRows = [];
      for (let i = 0; i < cuList.length; ++i) {
        tempCheckedRows.push(cuList[i].md5);
      }
      setCheckedRows(tempCheckedRows);
      !tempCheckedRows.length
        ? setAllowDownload(false)
        : setAllowDownload(true);
    }
  };

  const handleDownloadCsv = () => {
    const qParams = {
      contractor,
      company,
      district,
      workOrder,
      workRequest,
    };
    if (selectAllRows) {
      qParams['checkedRows'] = [];
    } else {
      qParams['checkedRows'] = checkedRows;
    }
    setDownloadInProgress(true);
    retrieveCsvFile(qParams).then(
      // Success callback
      () => {
        setDownloadInProgress(false);
      },
      // Error callback
      () => {
        setDownloadInProgress(false);
      },
    );
  };

  return (
    <>
      <div className="aepContainer aepHeader">Additional Links</div>

      <div className="aepContainer aepBreadcrumb" style={{ display: 'flex' }}>
        <div className="backArrow" title="Back">
          <BackArrowIcon />
        </div>
        Compatible Unit Download
      </div>

      <div className="aepContainer">
        <div className="innerContainer">
          <Form>
            <Row className="firstRow">
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>
                    Contractor:{' '}
                    <span
                      style={{
                        color: 'red',
                        fontSize: '15px',
                        top: '12%',
                        marginLeft: '5px',
                        position: 'absolute',
                      }}
                    >
                      *
                    </span>
                  </Form.Label>

                  <Form.Select
                    aria-label="ContractorList"
                    className=""
                    value={contractor}
                    onChange={e => {
                      setContractor(e?.target?.value);
                      if (isEmptyVal(e?.target?.value)) {
                        setShowContents(false);
                      }
                    }}
                    style={{ display: 'block' }}
                  >
                    <option value="">Select</option>
                    {contractorList?.map((obj, index) => (
                      <option key={index} value={obj.vendorName}>
                        {obj.vendorName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>AEP Company: </Form.Label>
                  <Form.Select
                    aria-label="AEPCompanyList"
                    className=""
                    value={company}
                    onChange={e => setCompany(e?.target?.value)}
                    style={{ display: 'block' }}
                  >
                    <option value="">Select</option>
                    {companyList?.map((obj, index) => (
                      <option key={index} value={obj.company}>
                        {obj.company}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>District: </Form.Label>
                  <Form.Select
                    aria-label="DistrictList"
                    className=""
                    value={district}
                    onChange={e => setDistrict(e?.target?.value)}
                    style={{ display: 'block' }}
                  >
                    <option value="">Select</option>
                    {districtList?.map((obj, index) => (
                      <option key={index} value={obj.cdOrgUnit}>
                        {obj.cdOrgUnit}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Work Request:</Form.Label>
                  <Form.Control
                    onChange={e => setWorkRequest(e?.target?.value)}
                    name="workRequest"
                    value={workRequest}
                  />
                </Form.Group>
              </Col>

              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Work Order:</Form.Label>
                  <Form.Control
                    onChange={e => setWorkOrder(e?.target?.value)}
                    name="workOrder"
                    value={workOrder}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      <div className="submitDiv">
        <Button
          variant="primary"
          disabled={isEmptyVal(contractor)}
          onClick={e => {
            setLoader(true);
            dispatch(
              fetchCu({
                data: {
                  currentPage: 1,
                  contractor,
                  company,
                  district,
                  workOrder,
                  workRequest,
                },
                callback: async resp => {
                  setShowContents(true);
                  setSelectAllRows(false);
                  setCheckedRows([]);
                  setAllowDownload(false);
                  setLoader(false);
                },
              }),
            );
          }}
        >
          Submit
        </Button>
      </div>

      {loader && (
        <ClipLoader
          color="#009cde"
          cssOverride={{
            top: '50vh',
            left: '50vw',
            position: 'absolute',
            zIndex: 1,
          }}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
      {showContents && (
        <div className="aepContainer">
          <div className="downloadBtn">
            {downloadInProgress ? (
              <div style={{ height: '40px' }}>
                <ClipLoader
                  color="#009cde"
                  loading={downloadInProgress}
                  cssOverride={{
                    top: '49vh',
                    right: '4vw',
                    position: 'absolute',
                    zIndex: 1,
                  }}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div
                onClick={allowDownload && handleDownloadCsv}
                title="Dowload CSV"
                className={!allowDownload ? 'disable-icon' : ''}
              >
                <DownloadIcon />
              </div>
            )}
          </div>

          {!cuDownloadLoading && (
            <Table hover responsive striped bordered={false}>
              <thead>
                <tr>
                  <th>
                    <div className="checkDiv">
                      <input
                        type="checkbox"
                        checked={selectAllRows}
                        onChange={handleSelectAllRows}
                      />
                    </div>
                  </th>
                  {headers.map((header, idx) => (
                    <th key={idx}>
                      {sortOrder === 'asc' && (
                        <div
                          id={header.key}
                          onClick={e => handleSorting(header.key, 'desc')}
                        >
                          {header.value}
                          <CaretUp style={{ marginLeft: '12px' }} />
                        </div>
                      )}

                      {sortOrder === 'desc' && (
                        <div
                          id={header.key}
                          onClick={e => handleSorting(header.key, 'asc')}
                        >
                          {header.value}
                          <CaretDown style={{ marginLeft: '12px' }} />
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {cuList.map(row => (
                  <tr
                    key={row.md5}
                    className="tRow table-hover-row"
                    onClick={e => handleCuDownloadClick(row)}
                  >
                    <td>
                      <div className="checkDiv">
                        <input
                          type="checkbox"
                          checked={checkedRows.indexOf(row.md5) >= 0}
                          onChange={e => updateCheckedRows(e, row.md5)}
                        />
                      </div>
                    </td>
                    <td>{row.cdOrgUnit}</td>
                    <td>{row.wr}</td>
                    <td>
                      {row.street}, {row.city}, {row.state}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          <div id="pagination_download">
            <AEPPagination
              fetchItems={fetchCu}
              setPagination={setCuPagination}
              pagination={pagination}
              currentPage={currentPage}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
          </div>
        </div>
      )}
    </>
  );
};
export { CuDownload };
