import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  error: false,
  purchaseOrderObject: {},
  rateScheduleOptions: [],
  codeOptions: [],
  categoryOptions: [],
  shiftOptions: [],
  costActivityOptions: [],
  costActivityBySTOptions: [],
  equipmentIdOptions: [],
  timesheetDates: [],
  timesheetObjects: [],
  invoiceDates: [],
  invoiceData: {},
  userEntitlements: [],
  invoiceApprovers: [],
  userInvoiceEntitlements: [],
  jobAuthorizations: [],
  workArea: [],
  workType: [],
  crewType: [],
  operatingCompany: [],
  circuitList: [],
  circuitObjectList: [],
  materialStatus: [],
  serviceItems: [],
  unitOfMeasure: [],
  serviceItemsOptions: [],
  unitOfMeasureOptions: [],
};

export const lookupSlice = createSlice({
  name: 'lookupSlice',
  initialState,
  reducers: {
    fetchPORates(state) {},
    fetchTimesheetDates(state) {},
    fetchInvoiceDates(state) {},
    fetchInvoiceData(state) {},
    fetchEquipmentIdList(state, payload) {},
    fetchUserEntitlements(state) {},
    fetchInvoiceApprovers(state) {},
    fetchInvoiceUserEntitlements(state) {},
    fetchJobAuthorizations(state) {},
    fetchWorkArea(state) {},
    fetchCrewType(state) {},
    fetchWorkType(state) {},
    fetchOperatingCompany(state) {},
    fetchCircuit(state) {},
    fetchMaterialStatus(state) {},
    fetchServiceItems(state) {},
    fetchUnitOfMeasure(state) {},

    setTimesheetDates(state, action) {
      const stateValues = { ...state };
      stateValues.timesheetDates = action.payload;
      return { ...stateValues };
    },

    setPurchaseOrderObject(state, action) {
      const stateValues = { ...state };
      stateValues.purchaseOrderObject = action.payload;
      return { ...stateValues };
    },

    setInvoiceDates(state, action) {
      const stateValues = { ...state };
      stateValues.invoiceDates = action.payload;
      return { ...stateValues };
    },

    setRatescheduleOptions(state, action) {
      const stateValues = { ...state };
      stateValues.rateScheduleOptions = action.payload;
      return { ...stateValues };
    },

    setInvoiceUserEntitlements(state, action) {
      const stateValues = { ...state };
      stateValues.userInvoiceEntitlements = action.payload;
      return { ...stateValues };
    },

    setInvoiceData(state, action) {
      const stateValues = { ...state };
      stateValues.invoiceData = action.payload;
      return { ...stateValues };
    },

    setCodeOptions(state, action) {
      const stateValues = { ...state };
      stateValues.codeOptions = action.payload;
      return { ...stateValues };
    },

    setCategoryOptions(state, action) {
      const stateValues = { ...state };
      stateValues.categoryOptions = action.payload;
      return { ...stateValues };
    },

    setShiftOptions(state, action) {
      const stateValues = { ...state };
      stateValues.shiftOptions = action.payload;
      return { ...stateValues };
    },

    setCostActivityOptions(state, action) {
      const stateValues = { ...state };
      stateValues.costActivityOptions = action.payload;
      return { ...stateValues };
    },

    setCostActivityBySTOptions(state, action) {
      const stateValues = { ...state };
      stateValues.costActivityBySTOptions = action.payload;
      return { ...stateValues };
    },

    setEquipmentIdOptions(state, action) {
      const stateValues = { ...state };
      stateValues.equipmentIdOptions = action.payload;
      return { ...stateValues };
    },

    setUserEntitlements(state, action) {
      const stateValues = { ...state };
      stateValues.userEntitlements = action.payload;
      return { ...stateValues };
    },

    setInvoiceApprovers(state, action) {
      const stateValues = { ...state };
      stateValues.invoiceApprovers = action.payload;
      return { ...stateValues };
    },

    setJobAuthorizations(state, action) {
      const stateValues = { ...state };
      const jobAuths = [{ label: 'Select', value: '' }];
      action.payload.rows.map(obj => {
        jobAuths.push({
          label: obj.authorizationnum,
          value: '' + obj.jobauthorizationid,
        });
      });
      stateValues.jobAuthorizations = jobAuths;
      return { ...stateValues };
    },

    setWorkArea(state, action) {
      const stateValues = { ...state };
      stateValues.workArea = _.sortBy(action.payload.rows, ['workAreaDesc']);
      return { ...stateValues };
    },

    setOperatingCompany(state, action) {
      const stateValues = { ...state };
      stateValues.operatingCompany = action.payload.rows;
      return { ...stateValues };
    },

    setCircuit(state, action) {
      const stateValues = { ...state };
      const circuits = [{ label: 'Select', value: '', circuitid: null }];
      action.payload.rows.map(obj => {
        circuits.push({
          label: obj.circuitnum,
          value: '' + obj.circuitnum,
          circuitid: obj.circuitid,
        });
      });
      stateValues.circuitList = circuits;
      stateValues.circuitObjectList = action.payload.rows;
      return { ...stateValues };
    },

    setCrewType(state, action) {
      const stateValues = { ...state };
      stateValues.crewType = action.payload.rows;
      return { ...stateValues };
    },

    setWorkType(state, action) {
      const stateValues = { ...state };
      stateValues.workType = action.payload.rows;
      return { ...stateValues };
    },

    setMaterialStatus(state, action) {
      const stateValues = { ...state };
      stateValues.materialStatus = action.payload.rows;
      return { ...stateValues };
    },

    setServiceItems(state, action) {
      const stateValues = { ...state };
      stateValues.serviceItems = action.payload.rows;
      return { ...stateValues };
    },

    setUnitOfMeasure(state, action) {
      const stateValues = { ...state };
      stateValues.unitOfMeasure = action.payload;
      return { ...stateValues };
    },

    setServiceItemsOptions(state, action) {
      const stateValues = { ...state };
      stateValues.serviceItemsOptions = action.payload;
      return { ...stateValues };
    },

    setUnitOfMeasureOptions(state, action) {
      const stateValues = { ...state };
      stateValues.unitOfMeasureOptions = action.payload;
      return { ...stateValues };
    },

    setError(state, action) {
      state.error = action.payload;
    },

    unSetError() {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  setError,
  unSetError,
  fetchPORates,
  fetchTimesheetDates,
  fetchInvoiceDates,
  fetchInvoiceData,
  fetchEquipmentIdList,
  fetchUserEntitlements,
  fetchInvoiceApprovers,
  fetchInvoiceUserEntitlements,
  fetchJobAuthorizations,
  fetchWorkArea,
  fetchCrewType,
  fetchWorkType,
  fetchMaterialStatus,
  fetchOperatingCompany,
  fetchCircuit,
  fetchServiceItems,
  fetchUnitOfMeasure,
  setPurchaseOrderObject,
  setTimesheetDates,
  setInvoiceDates,
  setRatescheduleOptions,
  setInvoiceData,
  setCodeOptions,
  setEquipmentIdOptions,
  setCategoryOptions,
  setShiftOptions,
  setCostActivityOptions,
  setCostActivityBySTOptions,
  setUserEntitlements,
  setInvoiceApprovers,
  setInvoiceUserEntitlements,
  setJobAuthorizations,
  setWorkArea,
  setCrewType,
  setWorkType,
  setOperatingCompany,
  setCircuit,
  setMaterialStatus,
  setServiceItems,
  setUnitOfMeasure,
  setServiceItemsOptions,
  setUnitOfMeasureOptions,
} = lookupSlice.actions;

export default lookupSlice.reducer;
