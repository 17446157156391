import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import {
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';
import { confirm } from 'components/Dialogs/Confirm';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { Page } from 'enums/page.ts';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  fetchAdminWorkType,
  fetchSelectedAdminWorkTypetList,
  fetchSelectedWorkType,
  fetchWorkTypeDetails,
  setAdminWorktypePagination,
  updateWorkTypeData,
  addWorkTypeData,
  deleteWorkTypeData,
} from 'store/slices/AdminModules/workTypeSlice';
import { setWorkTypeFilters } from 'store/slices/filterSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { getSortOrder, isEmptyVal } from 'utils/utils';
import './AdminWorkType.scss';
import { AdminWorkTypeModel } from './AdminWorkTypeModel';
import { AddIcon } from 'assets/images/AddIcon/AddIcon';
import { DeleteIcon } from 'assets/images/DeleteIcon/DeleteIcon';
import { EditIcon } from 'assets/images/EditIcon/EditIcon';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';

const initialWorkTypeData = {
  abmsActivity: '',
  accountNmbr: '',
  costComponent: '',
  orgDeptGroup: '',
  overrideAuthFlag: '',
  planningCategory: '',
  reliabilityWork: '',
  status: '',
  stormIndicator: '',
  worktype: '',
  worktypeCategory: '',
  worktypeDesc: '',
  worktypeNmbr: null,
  worktypeOcs: [
    {
      opco: '',
      abmsProject: '',
      workOrder: '',
    },
  ],
};

const AdminWorkType = props => {
  const dispatch = useDispatch();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [workModal, setWorkModal] = useState(false);
  const [chipFilterValues, setChipFilterValues] = useState({});
  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);
  const [workTypeTitle, setWorkTypeTitle] = useState('');
  const [selectedWorkType, setSelectedWorkType] = useState(initialWorkTypeData);

  const { items, pagination, recordItems, updateFieldList } = useSelector(
    state => state.adminWorkType,
  );
  const { currentPage, sortBy, sortOrder } = pagination;
  const workTypeData = items;

  const inputListData = useSelector(state => state.filter.worktype);
  const { promiseInProgress: workTypeLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.getWorkType,
    delay: 0,
  });

  const workTypeFiltersValues = [
    { label: 'Work type', value: 'worktype' },
    { label: 'Account Number', value: 'accountNmbr' },
    { label: 'ABMS Activity', value: 'abmsActivity' },
    { label: 'Work Category', value: 'worktypeCategory' },
    { label: 'Org Group', value: 'orgDeptGroup' },
    { label: 'Action', value: '' },
  ];
  const headersWorkTypes = [
    { key: 'workType', value: 'Work Type', sortkey: 'worktype' },
    { key: 'description', value: 'Description', sortkey: 'worktype_desc' },
    { key: 'accountNumber', value: 'Account Number', sortkey: 'account_nmbr' },
    { key: 'abmsActivity', value: 'ABMS Activity', sortkey: 'abms_activity' },
    {
      key: 'workCategory',
      value: 'Work Category',
      sortkey: 'worktype_category',
    },
    { key: 'orgGroup', value: 'Org Group', sortkey: 'org_dept_group' },
    { key: 'status', value: 'Status', sortkey: 'status' },
    { key: 'action', value: 'Action', sortkey: '' },
  ];

  const handleWorkTypeClick = worktypeNmbr => {
    setSelectedWorkType({ ...initialWorkTypeData });
    if (worktypeNmbr) {
      setWorkTypeTitle('Edit Work Type');
      // Get latest data
      dispatch(
        fetchSelectedWorkType({
          workTypeId: worktypeNmbr,
          callback: data => {
            const selectedWorkTypeData = { ...data };
            const editedWorkTypeData = { ...initialWorkTypeData };
            for (const k in editedWorkTypeData) {
              if (k === 'worktypeOcs') {
                const opcoFields = [];
                for (const sk in selectedWorkTypeData[k]) {
                  opcoFields.push({
                    opco: `${selectedWorkTypeData[k][sk].ocNmbrOperatingCompany.companyAbbr}/${selectedWorkTypeData[k][sk].ocNmbrOperatingCompany.stateCode}`,
                    abmsProject: selectedWorkTypeData[k][sk].abmsProject,
                    workOrder: selectedWorkTypeData[k][sk].workOrder,
                  });
                }
                editedWorkTypeData[k] = opcoFields;
              } else if (selectedWorkTypeData.hasOwnProperty(k)) {
                editedWorkTypeData[k] = selectedWorkTypeData[k];
              }
            }
            setSelectedWorkType({ ...editedWorkTypeData });
            setWorkModal(true);
          },
        }),
      );
    } else {
      setWorkTypeTitle('Add Work Type');
      setWorkModal(true);
    }
  };

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setAdminWorktypePagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };

  const handleAdminWorkTypeCancel = () => setWorkModal(false);

  const handleAdminWorkTypeSave = wd => {
    const workNmbr = wd.worktypeNmbr;
    delete wd.worktypeNmbr;
    if (workNmbr) {
      // Updating
      dispatch(
        updateWorkTypeData({
          data: wd,
          workTypeId: workNmbr,
          callback: data => {
            setWorkModal(false);
            if (data?.error) {
              displayError(data?.message);
            } else {
              displaySuccess('Successfully Updated');
            }
          },
        }),
      );
    } else {
      // Adding
      wd.worktypeOcs = wd.worktypeOcs.map(a => {
        let opcostate = a.opco.split('/');
        const companyAbbr = opcostate[0];
        const stateCode = opcostate[1];
        const selectedOne = updateFieldList?.OP?.filter(
          j => j.companyAbbr === companyAbbr && j.stateCode === stateCode,
        )[0];
        return Object.assign({}, a, { ocNmbr: selectedOne.ocNmbr });
      });
      dispatch(
        addWorkTypeData({
          data: wd,
          callback: data => {
            setWorkModal(false);
            if (data?.error) {
              displayError(data?.message);
            } else {
              displaySuccess('Successfully Saved');
            }
          },
        }),
      );
    }
  };

  const objDecorate = obj => {
    let newObj = _.mapKeys(obj, (value, key) => {
      return key;
    });
    return newObj;
  };

  const handleContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });

    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });

    const selectedFilterDataDecorated = objDecorate(selectedFilterData);

    if (props.resetFilter) {
      dispatch(
        fetchAdminWorkType({
          currentPage: 1,
          abmsActivity: '',
          accountNmbr: '',
          orgDeptGroup: '',
          worktype: '',
          worktypeCategory: '',
        }),
      );
    } else {
      dispatch(fetchAdminWorkType(selectedFilterDataDecorated));
    }
    console.group({ selectedFilterDataDecorated });
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setFilterchip(true);
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendor' || val.FilterValue === 'vendorid') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setWorkTypeFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    // Check is using "vendorid"
    const filterchipDataDecorated = objDecorate(filterchipData);
    dispatch(fetchAdminWorkType(filterchipDataDecorated));
  };

  useEffect(() => {
    dispatch(setActivePage(Page.ADMIN_MODULES));
    if (props.resetFilter) {
      props.setResetFilter(false);
      // @ts-ignore
      handleContinueClick([
        {
          FilterValue: '',
          input_rank: null,
          selectFilterValue: '',
          selectFilterLabel: '',
          presentIcon: '',
          filerForm: false,
          FilterLabel: '',
          selectedFilterRecords: [],
        },
      ]);
    } else {
      handleContinueClick(inputListData);
    }
    dispatch(fetchWorkTypeDetails());
  }, [dispatch]);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(workTypeData.map(li => li.worktypeNmbr));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleCheckClick = e => {
    const { id, checked } = e.target;
    let chkid = Number(id);
    let count = chkid ? 1 : 0;
    if (checked) {
      // @ts-ignore
      setIsCheck([...isCheck, chkid]);
      if (workTypeData.length === isCheck.length + count) {
        setIsCheckAll(true);
      }
    } else {
      setIsCheck(isCheck.filter(item => item !== chkid));
      setIsCheckAll(false);
    }
  };

  const handleDeleteWorkType = async () => {
    if (!isCheck.length) {
      displayError('Please select records to delete!');
    } else {
      // @ts-ignore
      const isDeleteConfirm = await confirm({
        message: 'Are you sure you would like to delete the record(s)?',
        title: 'Delete Work Type',
      });
      if (isDeleteConfirm) {
        dispatch(
          deleteWorkTypeData({
            data: { worktypeNmbr: isCheck },
            callback: () => {
              setIsCheck([]);
              displaySuccess('Successfully Deleted');
            },
          }),
        );
      }
    }
  };

  return (
    <>
      <div className="aepContainer adminModuleWorkTypeContainer">
        <div className="tableContainer">
          <Row>
            <Col sm={10}>
              <>
                <div className="filterComponent">
                  <AEPFilterComponent
                    inputListData={inputListData}
                    filterValues={workTypeFiltersValues}
                    setInputFilterData={setWorkTypeFilters}
                    selectedFilterRecordList={recordItems}
                    fetchSelectedList={fetchSelectedAdminWorkTypetList}
                    handleContinueClick={handleContinueClick}
                    continueBtnDisable={continueBtnDisable}
                    handleFilterclose={handleFilterclose}
                    handleFilterChip={handleFilterChip}
                    filterChip={filterChip}
                    chipFilterValues={chipFilterValues}
                  />
                </div>
              </>
            </Col>
            <Col sm={2}>
              <div style={{ float: 'right' }}>
                <span onClick={() => handleWorkTypeClick(null)} title="Add Row">
                  <AddIcon />
                </span>
                <span
                  onClick={handleDeleteWorkType}
                  className="actionbtn"
                  title="Delete Row"
                >
                  <DeleteIcon />
                </span>
              </div>
            </Col>
          </Row>
          <Table hover responsive striped bordered={false}>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                    style={{ width: '24px' }}
                  />
                </th>
                {headersWorkTypes.map((header, idx) => (
                  <th key={idx}>
                    {header.sortkey && (
                      <div
                        id={header.sortkey}
                        onClick={e =>
                          handleSorting(header.sortkey, getSortOrder(sortOrder))
                        }
                      >
                        {header.value}
                        {sortOrder === 'asc' ? (
                          <CaretUp style={{ marginLeft: '12px' }} />
                        ) : (
                          <CaretDown style={{ marginLeft: '12px' }} />
                        )}
                      </div>
                    )}
                    {header.sortkey === '' && <div>{header.value}</div>}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {workTypeData !== 'undefined' &&
                !_.isEmpty(workTypeData) &&
                workTypeData.map((row, indx) => (
                  <tr key={indx} className="tRow table-hover-row">
                    <td>
                      <input
                        type="checkbox"
                        key={row.worktypeNmbr}
                        id={row.worktypeNmbr}
                        onClick={handleCheckClick}
                        // @ts-ignore
                        checked={isCheck.includes(row.worktypeNmbr)}
                        value={row.worktypeNmbr}
                        style={{ width: '24px', height: '24px' }}
                      />
                    </td>
                    <td>{row.worktype}</td>
                    <td>{row.worktypeDesc}</td>
                    <td>{row.accountNmbr}</td>
                    <td>{row.abmsActivity}</td>
                    <td>{row.worktypeCategory}</td>
                    <td>{row.orgDeptGroup}</td>
                    <td>{row.status}</td>
                    <td>
                      <span
                        className="editRow"
                        onClick={e => handleWorkTypeClick(row.worktypeNmbr)}
                        title="Edit Row"
                      >
                        <EditIcon />
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="float-right">
            <AEPPagination
              fetchItems={fetchAdminWorkType}
              setPagination={setAdminWorktypePagination}
              pagination={pagination}
              currentPage={currentPage}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
          </div>
          <AdminWorkTypeModel
            show={workModal}
            workTypeTitle={workTypeTitle}
            selectedWorkType={selectedWorkType}
            handleAdminWorkTypeSave={handleAdminWorkTypeSave}
            handleAdminWorkTypeCancel={handleAdminWorkTypeCancel}
            opstateList={updateFieldList}
          />
          <ClipLoader
            color="#009cde"
            loading={workTypeLoading}
            cssOverride={{
              top: '50vh',
              left: '50vw',
              position: 'absolute',
              zIndex: 1,
            }}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    </>
  );
};
export { AdminWorkType };
