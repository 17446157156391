import { exportBidrateSheet } from 'utils/excelHelper';
import {
  columnsArray as l_columnsArray,
  serviceType as l_serviceType,
  serviceTypeName as l_serviceTypeName,
} from '../../BidRateSheetEntry/Constant/LaborConstant';
import {
  columnsArray as e_columnsArray,
  serviceType as e_serviceType,
  serviceTypeName as e_serviceTypeName,
} from '../../BidRateSheetEntry/Constant/EquipmentConstant';
import {
  columnsArray as r_columnsArray,
  serviceType as r_serviceType,
  serviceTypeName as r_serviceTypeName,
} from '../../BidRateSheetEntry/Constant/ReimbursableConstant';
import {
  columnsArray as unit_columnsArray,
  serviceType as unit_serviceType,
  serviceTypeName as unit_serviceTypeName,
} from '../../BidRateSheetEntry/Constant/UnitPriceRatesConstant';
import {
  columnsArray as t_columnsArray,
  serviceType as t_serviceType,
  serviceTypeName as t_serviceTypeName,
} from '../../BidRateSheetEntry/Constant/TaskCodeRatesConstant';
import {
  columnsArray as c_columnsArray,
  serviceType as c_serviceType,
  serviceTypeName as c_serviceTypeName,
} from '../../BidRateSheetEntry/Constant/CompatibleUnitGroupConstant';
import {
  columnsArray as u_columnsArray,
  serviceType as u_serviceType,
  serviceTypeName as u_serviceTypeName,
} from '../../BidRateSheetEntry/Constant/UndergroundMultiRateConstant';
import {
  columnsArray as m_columnsArray,
  serviceType as m_serviceType,
  serviceTypeName as m_serviceTypeName,
} from '../../BidRateSheetEntry/Constant/MarkupsConstant';

const handleDownloadAllPDF = (tabs, bidRateNumber, bidRateCurrentSheet) => {
  if (tabs.length > 0) {
    let excelData = {};
    tabs.map(tab => {
      if (tab.key === 'laborRates') {
        excelData.serviceTypeName = l_serviceTypeName;
        excelData.serviceType = l_serviceType;
        excelData.columnHeader = l_columnsArray;
      } else if (tab.key === 'equipment') {
        excelData.serviceTypeName = e_serviceTypeName;
        excelData.serviceType = e_serviceType;
        excelData.columnHeader = e_columnsArray;
      } else if (tab.key === 'reimbursableMarkup') {
        excelData.serviceTypeName = r_serviceTypeName;
        excelData.serviceType = r_serviceType;
        excelData.columnHeader = r_columnsArray;
      } else if (tab.key === 'unitPriceRates') {
        excelData.serviceTypeName = unit_serviceTypeName;
        excelData.serviceType = unit_serviceType;
        excelData.columnHeader = unit_columnsArray;
      } else if (tab.key === 'taskCodeRates') {
        excelData.serviceTypeName = t_serviceTypeName;
        excelData.serviceType = t_serviceType;
        excelData.columnHeader = t_columnsArray;
      } else if (tab.key === 'compatibleUnitGroups') {
        excelData.serviceTypeName = c_serviceTypeName;
        excelData.serviceType = c_serviceType;
        excelData.columnHeader = c_columnsArray;
      } else if (tab.key === 'undergroundMultiplier') {
        excelData.serviceTypeName = u_serviceTypeName;
        excelData.serviceType = u_serviceType;
        excelData.columnHeader = u_columnsArray;
      } else if (tab.key === 'markups') {
        excelData.serviceTypeName = m_serviceTypeName;
        excelData.serviceType = m_serviceType;
        excelData.columnHeader = m_columnsArray;
      }
      exportBidrateSheet({
        type: 'Bidrate ' + excelData.serviceTypeName,
        worksheet: excelData.serviceTypeName,
        bidrateNmbr: bidRateNumber,
        bidratename: bidRateCurrentSheet.name,
        contractorname: bidRateCurrentSheet.contractorname,
        title: excelData.serviceTypeName,
        headersValues: excelData.columnHeader.filter(
          x => x.showHeader || x.required,
        ),
        records: bidRateCurrentSheet[excelData.serviceType],
        formName: getFormNameForSheet(excelData.serviceType),
      });
    });
  }
};

//function to set form name while downloading the sheet
const getFormNameForSheet = serviceType => {
  let formName = '';
  switch (serviceType) {
    case 'bidrateLaborRates':
      formName = 'ECA Labor Form Z';
      break;

    case 'bidrateEquipmentRates':
      formName = 'ECA Equipment Form Y';
      break;

    case 'bidrateUnionRates':
      formName = 'ECA Markups Form S';
      break;

    case 'bidrateReimbursibleMarkups':
      formName = 'ECA Reimbursable Markup Form X';
      break;

    case 'bidrateCus':
      formName = 'ECA Compatible Units Form U';
      break;

    case 'bidrateUnitPrices':
      formName = 'ECA Unit Price Form W';
      break;

    case 'bidrateUndergrndMltplrRates':
      formName = 'ECA Underground Mulitplier Form T';
      break;

    case 'bidrateTaskCodes':
      formName = 'ECA Task Code Form V';
      break;

    default:
      break;
  }
  return formName;
};

export { getFormNameForSheet, handleDownloadAllPDF };
