import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { InvoiceStatus } from 'enums/statuses.ts';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as lookupService from 'services/lookup';
import * as service from 'services/purchaseOrderService';
import * as userService from 'services/user';
import { setInvoiceDetails } from 'store/slices/Invoice/invoiceDetailSlice';
import {
  fetchCircuit,
  fetchCrewType,
  fetchEquipmentIdList,
  fetchInvoiceData,
  fetchInvoiceDates,
  fetchJobAuthorizations,
  fetchOperatingCompany,
  fetchPORates,
  fetchTimesheetDates,
  fetchUserEntitlements,
  fetchInvoiceApprovers,
  fetchInvoiceUserEntitlements,
  fetchWorkArea,
  fetchWorkType,
  fetchMaterialStatus,
  setCategoryOptions,
  setCircuit,
  setCodeOptions,
  setCostActivityOptions,
  setCostActivityBySTOptions,
  setCrewType,
  setEquipmentIdOptions,
  setError,
  setInvoiceData,
  setInvoiceDates,
  setJobAuthorizations,
  setOperatingCompany,
  setPurchaseOrderObject,
  setRatescheduleOptions,
  setShiftOptions,
  setTimesheetDates,
  setUserEntitlements,
  setInvoiceApprovers,
  setInvoiceUserEntitlements,
  setWorkArea,
  setWorkType,
  setMaterialStatus,
} from 'store/slices/lookupSlice';
import {
  formatInvoiceData,
  getCategoryOptions,
  getCodeOptions,
  getCostActivityBySTOptions,
  getCostActivityOptions,
  getRateScheduleOptions,
  getShiftOptions,
} from './utils';
import { isEmptyVal } from 'utils/utils';
import _ from 'lodash';

const poCostActivities = state => state.purchaseOrderDetails.costActivities;
const poCostActivityByST = state => state.purchaseOrderDetails.costActivityByST;
const poLines = state => state.purchaseOrderDetails.poLines;
const lookupSelector = state => state.lookup;

/** Promise trackers */
const trackInvoiceData = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.getInvoiceData);

const trackUserEntitlements = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.getUserEntitlements);

function* getPORates(action) {
  try {
    const response = yield call(f => service.retrievePORates(action.payload));
    const { data } = response;
    yield put(setPurchaseOrderObject(data));

    let costActivityOptionList = yield select(poCostActivities);
    let costActivityBySTOptionList = yield select(poCostActivityByST);
    let poLinesList = yield select(poLines);
    const costActivityOptions = getCostActivityOptions(
      costActivityOptionList,
      poLinesList,
      action.payload.isZeroLineCostHidden,
    );
    yield put(setCostActivityOptions(costActivityOptions ?? []));

    const costActivityBySTOptions = getCostActivityBySTOptions(
      costActivityBySTOptionList,
      poLinesList,
      action.payload.isZeroLineCostHidden,
    );
    yield put(setCostActivityBySTOptions(costActivityBySTOptions));

    const rateScheduleOptions = getRateScheduleOptions(data);
    yield put(setRatescheduleOptions(rateScheduleOptions));

    const codeOptions = getCodeOptions(data);
    yield put(setCodeOptions(codeOptions));

    const categoryOptions = getCategoryOptions(data);
    yield put(setCategoryOptions(categoryOptions));

    const shiftOptions = getShiftOptions(data);
    yield put(setShiftOptions(shiftOptions));

    const lookupData = yield select(lookupSelector);
    if (action.payload?.callback) {
      action.payload.callback({
        purchaseOrderObject: data,
        rateScheduleOptions,
        codeOptions,
        categoryOptions,
        shiftOptions,
        costActivityOptions,
        costActivityBySTOptions,
        equipmentIdOptions: lookupData?.equipmentIdOptions,
      });
    }
  } catch (e) {
    put(setError(true));
  }
}

function* getTimesheetDates(action) {
  try {
    const response = yield call(f =>
      service.retrieveTimesheetDates(action.payload.poid),
    );
    const { data } = response;
    yield put(setTimesheetDates(data));
    if (action?.payload?.callback) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
  }
}

function* getJobAuthorizations(action) {
  try {
    const response = yield call(f =>
      lookupService.retrieveJobAuthorizations(action.payload),
    );
    const { data } = response;
    yield put(setJobAuthorizations(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getCrewType() {
  try {
    const response = yield call(() => lookupService.retrieveCrewType());
    const { data } = response;
    yield put(setCrewType(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getWorkType() {
  try {
    const response = yield call(() => lookupService.retrieveWorkType());
    const { data } = response;
    yield put(setWorkType(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getOperatingCompany(action) {
  try {
    const response = yield call(() =>
      lookupService.retrieveOperatingCompany(action.payload),
    );
    const { data } = response;
    yield put(setOperatingCompany(data));
    if (action?.payload?.callback) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
  }
}

function* getWorkArea(action) {
  try {
    const response = yield call(() =>
      lookupService.retrieveWorkArea(action.payload),
    );
    const { data } = response;
    yield put(setWorkArea(data));
    if (action?.payload?.callback) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
  }
}

function* getCircuit(action) {
  try {
    const response = yield call(() =>
      lookupService.retrieveCircuit(action.payload),
    );
    const { data } = response;
    yield put(setCircuit(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getInvoiceDates(action) {
  try {
    const response = yield call(f =>
      service.retrieveInvoiceDates(action.payload),
    );
    const { data } = response;
    yield put(setInvoiceDates(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getInvoiceData(action) {
  try {
    const response = yield call(trackInvoiceData, f =>
      service.retrieveInvoiceData(action.payload),
    );
    if (response.status === 200) {
      const { data } = response;
      const formattedData = formatInvoiceData(data);
      formattedData.contractNmbr = data.contractNmbr;
      formattedData.caastatus = InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED;
      yield put(setInvoiceData(formattedData));
      yield put(setInvoiceDetails(formattedData));
    }
    if (action.payload?.callback) {
      action.payload.callback(response);
    }
  } catch (e) {
    put(setError(true));
  }
}

function* getEquipmentIdList(action) {
  try {
    const poid =
      typeof action.payload == 'object' ? action.payload.poid : action.payload;
    const response = yield call(f => service.retrieveEquipmentIdList(poid));
    const { data } = response;

    const eqIdList = [];
    data.forEach(obj => {
      if (obj.equip_id !== null) {
        eqIdList.push({ value: obj.equip_id, label: obj.equip_id });
      }
    });
    yield put(setEquipmentIdOptions(eqIdList));
    if (action.payload?.callback) {
      action.payload.callback(eqIdList);
    }
  } catch (e) {
    put(setError(true));
  }
}

function* getUserEntitlements(action) {
  try {
    const response = yield call(trackUserEntitlements, f =>
      userService.retrieveUserEntitlements({
        entitlement: action.payload.entitlement,
        poid: action.payload.poid,
        limit: -1,
        enteredBy: action.payload.enteredBy,
      }),
    );
    const { data } = response;
    if (response.status === 200) {
      yield put(setUserEntitlements(data?.rows));
      if (action.payload.callback) {
        action.payload.callback(isEmptyVal(data?.rows) ? [] : data?.rows);
      }
    }
  } catch (e) {
    put(setError(true));
  }
}

function* getInvoiceApprovers(action) {
  try {
    const response = yield call(trackUserEntitlements, f =>
      userService.retrieveUserEntitlements({
        entitlement: 'CAA-AEP-Invoice-Approver',
        limit: -1,
      }),
    );
    const { data } = response;
    if (response.status === 200) {
      yield put(setInvoiceApprovers(data?.rows));
      if (action?.payload?.callback) {
        action.payload.callback(isEmptyVal(data?.rows) ? [] : data?.rows);
      }
    }
  } catch (e) {
    put(setError(true));
  }
}

function* getInvoiceUserEntitlements(action) {
  try {
    const response = yield call(trackUserEntitlements, f =>
      userService.retrieveUserEntitlements({
        entitlement: action.payload.entitlement,
        limit: -1,
      }),
    );
    const { data } = response;
    if (response.status === 200) {
      yield put(setInvoiceUserEntitlements(data?.rows));
      if (action.payload.callback) {
        action.payload.callback(isEmptyVal(data?.rows) ? [] : data?.rows);
      }
    }
  } catch (e) {
    put(setError(true));
  }
}

function* getMaterialStatus() {
  try {
    const response = yield call(() => lookupService.retrieveMaterialStatus());
    const { data } = response;
    yield put(setMaterialStatus(data));
  } catch (e) {
    put(setError(true));
  }
}

function* watchGetPORates() {
  yield takeLatest(fetchPORates.type, getPORates);
}

function* watchGetTimesheetDates() {
  yield takeLatest(fetchTimesheetDates.type, getTimesheetDates);
}

function* watchGetInvoiceDates() {
  yield takeLatest(fetchInvoiceDates.type, getInvoiceDates);
}

function* watchFetchInvoiceData() {
  yield takeLatest(fetchInvoiceData.type, getInvoiceData);
}

function* watchGetEquipmentIdList() {
  yield takeLatest(fetchEquipmentIdList.type, getEquipmentIdList);
}

function* watchGetUserEntitlements() {
  yield takeLatest(fetchUserEntitlements.type, getUserEntitlements);
}

function* watchgetInvoiceApprovers() {
  yield takeLatest(fetchInvoiceApprovers.type, getInvoiceApprovers);
}

function* watchGetInvoiceUserEntitlements() {
  yield takeLatest(
    fetchInvoiceUserEntitlements.type,
    getInvoiceUserEntitlements,
  );
}

function* watchJobAuthorizations() {
  yield takeLatest(fetchJobAuthorizations.type, getJobAuthorizations);
}

function* watchGetWorkArea() {
  yield takeLatest(fetchWorkArea.type, getWorkArea);
}

function* watchGetCrewType() {
  yield takeLatest(fetchCrewType.type, getCrewType);
}

function* watchGetWorkType() {
  yield takeLatest(fetchWorkType.type, getWorkType);
}

function* watchGetOperatingCompany() {
  yield takeLatest(fetchOperatingCompany.type, getOperatingCompany);
}

function* watchGetCircuit() {
  yield takeLatest(fetchCircuit.type, getCircuit);
}

function* watchGetMaterialStatus() {
  yield takeLatest(fetchMaterialStatus.type, getMaterialStatus);
}

export function* lookupSaga() {
  yield all([
    watchGetPORates(),
    watchGetTimesheetDates(),
    watchFetchInvoiceData(),
    watchGetInvoiceDates(),
    watchGetEquipmentIdList(),
    watchGetUserEntitlements(),
    watchgetInvoiceApprovers(),
    watchGetInvoiceUserEntitlements(),
    watchJobAuthorizations(),
    watchGetWorkArea(),
    watchGetCrewType(),
    watchGetWorkType(),
    watchGetOperatingCompany(),
    watchGetCircuit(),
    watchGetMaterialStatus(),
  ]);
}
