import { useEffect, useState, useCallback, useRef } from 'react';
import { Table, Form, Row, Col } from 'react-bootstrap';
import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { AddIcon } from 'assets/images/AddIcon/AddIcon';
import { PreferenceIcon } from 'assets/images/PreferenceIcon/PreferenceIcon';
import { DeleteIcon } from 'assets/images/DeleteIcon/DeleteIcon';
import { UploadIcon } from 'assets/images/UploadIcon/UploadIcon';
import { useMediaQuery } from 'react-responsive';
import { DrillDownIcon } from 'assets/images/DrillDownIcon/DrillDownIcon';
import { DownloadIcon } from 'assets/images/DownloadIcon/DownloadIcon';
import _ from 'lodash';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { AddNewCodeModal } from '../../BidRateSheetEntry/AddNewCodeModal';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { ErrorIcon } from 'assets/images/ErrorIcon/ErrorIcon';
import {
  setBidRateSheetUpdatedData,
  setBidrateSheetSaved,
  setPreferenceColumns,
  fetchNewFiltersData,
  setMasterDataError,
  setMasterDataErrorExists,
  setShowValidate,
} from 'store/slices/BidRateSheets/bidRateSheetListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { UploadExcel } from '../../Common/UploadExcel/UploadExcel';
import '../../BidRateSheetEntry/BidRateSheetEntry.scss';
import './BidRateTable.scss';
import { Controller, FormProvider } from 'react-hook-form';
import { exportBidrateSheet } from 'utils/excelHelper';
import { ConfirmationModal } from 'components/common/modals/ConfirmatinModal';
import { isEmptyVal } from 'utils/utils';
import { masterCodeCheck } from 'services/bidRateSheetService';
import { getMidnightDate } from 'utils/utils';
import { getFormNameForSheet } from './BidRateUtils';
import { AddNewCodePopup } from 'pages/BidRateSheets/BidRateSheetEntry/AddNewCodePopup';

const BidRateTable = props => {
  const {
    bidRateRowPage,
    options,
    columnsArray,
    referenceObject,
    bidRatesDetailList,
    serviceType,
    serviceTypeName,
    downloadExcelServiceTypeName,
    setValue,
    trigger,
    errors,
    control,
    methods,
    autoFillColumnskeys,
  } = props;

  const dispatch = useDispatch();

  //local state declaration
  const [key, setKey] = useState(serviceType);
  const [rows, setRows] = useState([]); //contains visible rows as users scrolls down the screen
  const [allRows, setAllRows] = useState([]); //contains all the rows of bid rate sheet
  const [selectedIds, setSelectedIds] = useState(new Set());
  const [preferenceDropdown, setPreferenceDropdown] = useState(false);
  const [columnHeader, setColumnHeader] = useState([]);
  const [tableSelectedIds, setTableSelectedIds] = useState(new Set());
  const [tableRowsSelected, setTableRowsSelected] = useState([]);
  const [addNewCodeModalShow, setAddNewCodeModalShow] = useState(false);
  const [clickedRowIndex, setClickedRowIndex] = useState();
  const [clickedRowData, setClickedRowData] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [excelData, setExcelData] = useState({});
  const [dataFromUploadExcel, setDataFromUploadExcel] = useState(0);
  const [confirmDownload, setConfirmDownload] = useState(false);
  const [activeFields, setActiveFields] = useState([]);

  //bid rate table ref and batch size used for scroll fucntion
  const bidrateTableRef = useRef();
  const batchSize = 20;

  //'isMobile' for mobile view styling
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  //error message when master data validation fails
  const masterDataErrorMsg = 'Incorrect Master Data. Please select Code';

  //redux props declaration
  const workCategoryList = useSelector(
    state => state.bidRateSheetList.workCategoryList.rows,
  );
  const locationList = useSelector(
    state => state.bidRateSheetList.locationList.rows,
  );
  const equipmentType = useSelector(
    state => state.bidRateSheetList.equipmentType,
  );
  const ownershipType = useSelector(
    state => state.bidRateSheetList.ownershipType,
  );
  const newBidRateSheet = useSelector(
    state => state.bidRateSheetList.newBidRateSheet,
  );
  const bidrateSheetNumber = useSelector(
    state => state.bidRateSheetList.bidRateNumber,
  );
  const preferenceColumns = useSelector(
    state => state.bidRateSheetList.preferenceColumns,
  );
  const masterDataError = useSelector(
    state => state.bidRateSheetList.masterDataErrors,
  );
  const showValidate = useSelector(
    state => state.bidRateSheetList.showValidate,
  );
  const bidRateCurrentSheet = useSelector(
    state => state.bidRateSheetList.bidRateSheetDetailsData,
  );

  //call back function for form's handleSubmmit, when function encounters no form validation error
  const onValidate = (rows = null) => {
    methods.handleSubmit(
      data => {
        const tempData = _.cloneDeep(data);
        tempData['validate_only'] = true;
        props.handleBidRateSave(tempData);
      },
      data => onError(data),
    );

    if (!isEmptyVal(rows)) {
      validateMasterData(rows, errors);
    }
  };

  //call back function for from's handleSubmit, when function encounters any form validation error
  const onError = err => {
    validateMasterData(allRows, err); //to validate master data

    let updateShowValidate = { ...showValidate };
    if (Object.keys(err).length > 0) {
      updateShowValidate[serviceType] = true; //shows validate button if any form validation error occurs in service type upload flow
    } else {
      updateShowValidate[serviceType] = false; //hides validate button if no form validation error occurs in service type upload flow
      handleConfirmEditMode();
    }

    dispatch(setShowValidate(updateShowValidate)); //redux action to show/hide 'Validate' button
  };

  /*function calls on 'Validate' button click as well as on form's handleSubmit call
  Accepts 2 parameters -  all the rows needs to be validated and form validation errors*/
  const validateMasterData = async (rowData, yupErr) => {
    let output = _.cloneDeep(rowData);
    let serviceTypeMasterData; //keyword used to fetch master data from different master service type tables
    let hierarchyPath; //keyword used to fetch master data from master tables based on 'hierarchy path'
    let payload = []; //payload to be sent to API to validae master data on bid rate sheet
    let response; //bid rate service type's master data validation API response
    let bidrateMasterErr = { ...masterDataError }; //redux prop contains object of master data error's row index for each bid rate service type
    bidrateMasterErr[serviceType] = {};
    switch (serviceType) {
      case 'bidrateLaborRates':
        serviceTypeMasterData = 'craft';
        hierarchyPath = '';

        //creates payload of master data fields
        for (let i = 0; i < output.length; ++i) {
          const item = output[i];
          payload.push({
            description: item?.laborDescription,
            code: item?.laborCode ? item?.laborCode.toString() : null,
            measurement: item?.laborCategory || null,
          });
        }

        //api call to validate master data
        response = await masterCodeCheck(
          serviceTypeMasterData,
          payload,
          hierarchyPath,
        );
        bidrateMasterErr[serviceType]['laborCode'] = [];

        //if any master data fields are not valid
        response?.data.invalidRecords.map(item => {
          let index = output.findIndex(
            el =>
              el.laborCode == item.code &&
              el.laborDescription == item.description &&
              el.laborCategory == item.measurement,
          );
          bidrateMasterErr[serviceType]['laborCode'].push(index);
        });

        break;

      case 'bidrateEquipmentRates':
        serviceTypeMasterData = 'toolitem';
        hierarchyPath = '';

        //creates payload of master data fields
        for (let i = 0; i < output.length; ++i) {
          const item = output[i];
          payload.push({
            description: item?.eqpmntDescription,
            code: item?.eqpmntCode ? item?.eqpmntCode.toString() : null,
            eqpcategory: item?.eqpmntCategory,
            measurement: item?.unitOfMeasure,
            horsepower: item?.horsepower,
            volumecfm: item?.volumecfm,
            fuelgallonsperhour: item?.fuelGallonsPerHour,
            size: item?.size,
            eqpmntdrivetype: item?.eqpmntDriveType,
            volumeofwatergpm: item?.volumeOfWaterGpm,
          });
        }

        //api call to validate master data
        response = await masterCodeCheck(
          serviceTypeMasterData,
          payload,
          hierarchyPath,
        );
        bidrateMasterErr[serviceType]['eqpmntCode'] = [];

        //if any master data fields are not valid
        for (let i = 0; i < output.length; ++i) {
          const item = output[i];

          //find item in output
          let index = response?.data.invalidRecords.findIndex(
            el =>
              el.code == item.eqpmntCode &&
              el.description == item.eqpmntDescription &&
              el.measurement == item.unitOfMeasure &&
              el.eqpcategory == item.eqpmntCategory &&
              el.horsepower == item?.horsepower &&
              el.volumecfm == item?.volumecfm &&
              el.fuelgallonsperhour == item?.fuelGallonsPerHour &&
              el.size == item?.size &&
              el.eqpmntdrivetype == item?.eqpmntDriveType &&
              el.volumeofwatergpm == item?.volumeOfWaterGpm,
          );

          if (index != -1) {
            //the current UI row (i) has errors, push that row into bidrateMasterErr
            bidrateMasterErr[serviceType]['eqpmntCode'].push(i);
          }
        }
        break;

      case 'bidrateReimbursibleMarkups':
        serviceTypeMasterData = 'serviceitems';
        hierarchyPath = 'EXPENSES';
        //creates payload of master data fields
        for (let i = 0; i < output.length; ++i) {
          const item = output[i];
          payload.push({
            description: item?.description,
            code: item?.rmbrslMarkUpCode
              ? item?.rmbrslMarkUpCode.toString()
              : null,
            measurement: item?.unitOfMeasure,
          });
        }

        //api call to validate master data
        response = await masterCodeCheck(
          serviceTypeMasterData,
          payload,
          hierarchyPath,
        );
        bidrateMasterErr[serviceType]['rmbrslMarkUpCode'] = [];
        //if any master data fields are not valid
        response?.data.invalidRecords.map(item => {
          let index = output.findIndex(
            el =>
              el.rmbrslMarkUpCode == item.code &&
              el.description == item.description &&
              el.unitOfMeasure == item.measurement,
          );
          bidrateMasterErr[serviceType]['rmbrslMarkUpCode'].push(index);
        });
        break;

      case 'bidrateUnitPrices':
        serviceTypeMasterData = 'serviceitems';
        hierarchyPath = 'TASK/UNITPRICE';

        //creates payload of master data fields
        for (let i = 0; i < output.length; ++i) {
          const item = output[i];
          payload.push({
            description: item?.unitDescription,
            code: item?.unitCode ? item?.unitCode.toString() : null,
            measurement: item?.unitOfMeasure,
          });
        }

        //api call to validate master data
        response = await masterCodeCheck(
          serviceTypeMasterData,
          payload,
          hierarchyPath,
        );
        bidrateMasterErr[serviceType]['unitCode'] = [];

        //if any master data fields are not valid
        response?.data.invalidRecords.map(item => {
          let index = output.findIndex(
            el =>
              el.unitCode == item.code &&
              el.unitDescription == item.description &&
              el.unitOfMeasure == item.measurement,
          );
          bidrateMasterErr[serviceType]['unitCode'].push(index);
        });
        break;

      case 'bidrateTaskCodes':
        serviceTypeMasterData = 'serviceitems';
        hierarchyPath = 'TASK/UNITPRICE';
        for (let i = 0; i < output.length; ++i) {
          const item = output[i];
          payload.push({
            description: item?.taskDescription,
            code: item?.taskCode,
            measurement: item?.unitOfMeasure,
          });
        }

        response = await masterCodeCheck(
          serviceTypeMasterData,
          payload,
          hierarchyPath,
        );

        bidrateMasterErr[serviceType]['taskCode'] = [];
        response?.data.invalidRecords.map(item => {
          let index = output.findIndex(
            el =>
              el.taskCode == item.code &&
              el.taskDescription == item.description &&
              el.unitOfMeasure == item.measurement,
          );
          bidrateMasterErr[serviceType]['taskCode'].push(index);
        });
        break;

      case 'bidrateCus':
        serviceTypeMasterData = 'serviceitems';
        hierarchyPath = 'COMPATIBLEUNIT';
        for (let i = 0; i < output.length; ++i) {
          const item = output[i];
          payload.push({
            description: item?.unitDescription,
            code: item?.unitCode ? item?.unitCode.toString() : null,
            measurement: item?.unitOfMeasure,
          });
        }

        response = await masterCodeCheck(
          serviceTypeMasterData,
          payload,
          hierarchyPath,
        );
        bidrateMasterErr[serviceType]['unitCode'] = [];
        response?.data.invalidRecords.map(item => {
          let index = output.findIndex(
            el =>
              el.unitCode == item.code &&
              el.unitDescription == item.description &&
              el.unitOfMeasure == item.measurement,
          );

          bidrateMasterErr[serviceType]['unitCode'].push(index);
        });

        break;

      case 'bidrateUndergrndMltplrRates':
        serviceTypeMasterData = 'serviceitems';
        hierarchyPath = 'UGM';
        for (let i = 0; i < output.length; ++i) {
          const item = output[i];
          payload.push({
            description: item?.unitDescription,
            code: item?.code ? item?.code.toString() : null,
          });
        }

        response = await masterCodeCheck(
          serviceTypeMasterData,
          payload,
          hierarchyPath,
        );
        bidrateMasterErr[serviceType]['code'] = [];
        response?.data.invalidRecords.map(item => {
          let index = output.findIndex(
            el =>
              el.code == item.code && el.unitDescription == item.description,
          );
          bidrateMasterErr[serviceType]['code'].push(index);
        });

        break;

      case 'bidrateUnionRates':
        serviceTypeMasterData = 'serviceitems';
        hierarchyPath = 'BILLINGCODE';
        for (let i = 0; i < output.length; ++i) {
          const item = output[i];
          payload.push({
            description: item?.billingDescription,
            code: item?.billingCode ? item?.billingCode.toString() : null,
          });
        }

        response = await masterCodeCheck(
          serviceTypeMasterData,
          payload,
          hierarchyPath,
        );
        bidrateMasterErr[serviceType]['billingCode'] = [];
        response?.data.invalidRecords.map(item => {
          let index = output.findIndex(
            el =>
              el.code == item.billingCode &&
              el.billingDescription == item.description,
          );
          bidrateMasterErr[serviceType]['billingCode'].push(index);
        });
        break;

      default:
        break;
    }

    let updateShowValidate = { ...showValidate };
    if (response?.data.invalidRecords.length > 0) {
      dispatch(setMasterDataErrorExists(true));
      updateShowValidate[serviceType] = true;
    } else {
      if (Object.keys(errors).length > 0) {
        updateShowValidate[serviceType] = true;
      } else {
        updateShowValidate[serviceType] = false;
        handleConfirmEditMode();
      }
      bidrateMasterErr[serviceType] = {};
      dispatch(setMasterDataErrorExists(false));
    }
    dispatch(setShowValidate(updateShowValidate));
    dispatch(setMasterDataError(bidrateMasterErr));
  };

  /*onChange function when user enters the value in table field(s)
  Accepts 3 parameters - event, fieldname, index*/
  const updateState = (e, Editedid, key) => {
    let createObj = {};

    let fieldValue;
    let subStr = 'date';
    if (key.toLowerCase().includes(subStr)) {
      fieldValue = getMidnightDate(e);
    } else {
      fieldValue = e.target.value;
    }
    // INFO: Set value and Trigger error.

    setValue(`${serviceType}.${Editedid}.${key}`, fieldValue);
    trigger(`${serviceType}.${Editedid}.${key}`);

    let tempRows = _.cloneDeep(rows);
    let tempAllRows = _.cloneDeep(allRows);
    Object.assign(tempRows, JSON.parse(JSON.stringify(rows)));
    Object.assign(tempAllRows, JSON.parse(JSON.stringify(allRows)));
    tempRows[Editedid][key] = fieldValue;
    tempAllRows[Editedid][key] = fieldValue;

    setRows(tempRows);
    setAllRows(tempAllRows);
    let currentSheet = { ...bidRateCurrentSheet };
    currentSheet[serviceType] = tempAllRows;
    dispatch(setBidRateSheetUpdatedData(currentSheet)); //redux action to update the field value in 'bidRateSheetDetailsData' redux state
  };

  //function to add selected service type code on bid rate table
  const handleAddNewCodeContinueClick = codeDetails => {
    let serviceTypeSelected = [
      'bidrateUndergrndMltplrRates',
      'bidrateUnionRates',
    ];
    let visibleRows = _.cloneDeep(rows); //visible rows
    let allRowsArr = _.cloneDeep(allRows); //all rows

    delete codeDetails.heirarchypath; //hierarchy path is not need to be add on the screen

    //'unit of measure' is not rwquired for markup and UGM service types
    if (serviceTypeSelected.includes(serviceType)) {
      delete codeDetails.unitOfMeasure;
    }
    visibleRows[clickedRowIndex] = {
      ...allRowsArr[clickedRowIndex],
      ...codeDetails,
    };
    allRowsArr[clickedRowIndex] = {
      ...allRowsArr[clickedRowIndex],
      ...codeDetails,
    };

    setValue(serviceType, allRowsArr);

    setRows(visibleRows); //set master data in visible rows
    setAllRows(allRowsArr); //set master data in all rows
    setAddNewCodeModalShow(false);
    let currentSheet = { ...bidRateCurrentSheet };
    currentSheet[serviceType] = [...allRowsArr];
    dispatch(setBidRateSheetUpdatedData(currentSheet)); //redux action to update the code details value along with other master data values in 'bidRateSheetDetailsData' redux state
  };

  //function to check/uncheck row's check boxes
  const onCheckboxClick = useCallback(item => {
    setSelectedIds(selectedIds => {
      const result = new Set(selectedIds);
      if (!result.delete(item.key)) result.add(item.key);
      return result;
    });
  }, []);

  //function to check/uncheck checkbox present in each row
  const onTableCheckboxClick = useCallback((e, row) => {
    const selectedRows = [...tableRowsSelected];
    let rowSelected = selectedRows.indexOf(row);
    if (rowSelected !== -1) {
      selectedRows.splice(rowSelected, 1);
    } else {
      selectedRows.push(row);
    }
    setTableRowsSelected(selectedRows);
    setTableSelectedIds(tableSelectedIds => {
      const result = new Set(tableSelectedIds);
      if (!result.delete(row)) result.add(row);
      return result;
    });
  }, []);

  //function to check/uncheck table columns from preferences section
  const isPreferenceSelected = option => {
    let hasvalue = false;
    hasvalue = selectedIds.has(option.key);
    return hasvalue;
  };

  const IsTableSelected = value => {
    let tableSelectedId = tableSelectedIds;
    let hasvalue = false;
    hasvalue = tableSelectedId.has(value);
    return hasvalue;
  };

  //function to add row on bid rate sheet table
  const handleAddRow = () => {
    const item = referenceObject;
    let newArr = [...rows, item]; //row is added in existing array of rows

    setRows(newArr);
    setAllRows(newArr);

    //add the row at the end if sheet has pre existing rows
    if (newArr.length >= 1) {
      let arr = [];
      newArr.map((item, index) => {
        if (newArr.length - 1 === index) {
          arr.push(true);
        } else arr.push(false);
      });

      //add row in yup schema
      setValue(serviceType, []);
      newArr.map((item, index) => {
        Object.keys(item).map(element => {
          setValue(`${serviceType}.${index}.${element}`, item[element]);
        });
      });
      setActiveFields(arr); //activate edit mode on added row
    }
    dispatch(setBidrateSheetSaved(false));
  };

  //function to display columns on application present in excel
  const showColumnsInExcel = currentSheet => {
    let sheetColumns = _.cloneDeep(columnsArray);
    let sheetRow = {};
    if (currentSheet[serviceType]) {
      sheetRow = currentSheet[serviceType][0];
      sheetRow &&
        Object.entries(sheetRow).forEach(([key, value]) => {
          if (value !== undefined) {
            sheetColumns.map(item => {
              if (key === item.key) {
                item.showHeader = true;
              }
            });
          }
        });
      let prefArray = sheetColumns.filter(item => {
        return item.showHeader;
      });

      //enables column name in preferences section
      dispatch(
        setPreferenceColumns({
          serviceType: serviceType,
          valuesList: prefArray,
        }),
      );
      setColumnHeader(sheetColumns);
    }
  };

  //function to render rows as user scrolls down in upload flow
  const handleTableScroll = () => {
    const bidrateTable = bidrateTableRef.current;

    //Check if user scrolls to bottom
    if (
      bidrateTable.scrollTop + bidrateTable.clientHeight >=
      bidrateTable.scrollHeight - 50
    ) {
      const nextBatch = allRows.slice(rows.length, rows.length + batchSize);
      const updateRows = [...rows, ...nextBatch];
      setRows(updateRows);
    }
  };

  /*function to add excel data in bid rate table
  Accepts 3 parameters - excel data in array of object format, service type, contractor name present in excel sheet*/
  const handleAddExcelData = async (
    dataFromExcel,
    tabValue,
    contractorNameFromSheet,
  ) => {
    const output = _.cloneDeep(dataFromExcel);
    setAllRows(output);

    //activate edit mode for all rows
    let updatedActiveRows = Array(output.length).fill(true);

    //update yup scema with incoming data
    setValue(`${serviceType}`, output);

    //show 'Validate' button to validate master data
    const updateShowValidate = { ...showValidate };
    updateShowValidate[serviceType] = true;
    setActiveFields(updatedActiveRows);
    dispatch(setShowValidate(updateShowValidate));

    //create initial set of rows to display in case if excel conatins more than 20 rows
    const batchData = output.slice(0, batchSize);
    setRows(batchData);

    //update incoming excel data into redux state
    let currentSheet = { ...bidRateCurrentSheet };
    currentSheet[serviceType] = output;
    showColumnsInExcel(currentSheet); //function to show columns present in excel

    //if creating new bid rate sheet convert the undefined values to null
    if (newBidRateSheet) {
      currentSheet[serviceType] = output.map(item => {
        return convertUndefinedToNull(item);
      });
      currentSheet.contractorname = contractorNameFromSheet;
    }
    dispatch(setBidRateSheetUpdatedData(currentSheet));
  };

  /*function to conver excel cell value from undefined to null, if any value is empty in sheet it acts as undefined while uploading the sheet
    but to show that column on screen(especially if all cell values of that column is empty in excel) we need to explicitly convert it to null to handle error for undefined value(s) */
  const convertUndefinedToNull = obj => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === undefined) {
          obj[key] = null;
        }
      }
    }
    return obj;
  };

  //function to check columns to be visible on screen in case of existing sheet and upload flow
  const setPrefrencesCheck = cols => {
    cols.map(item => {
      if (item.showHeader) {
        var index = cols.findIndex(p => p.key == item.key);
        if (index > -1) {
          onCheckboxClick(item);
        }
        let isPreferenceColumn = preferenceColumns[props.serviceType]?.find(
          obj => obj.key === item,
        );
        isPreferenceColumn = !isEmptyVal(isPreferenceColumn);
        if (isPreferenceColumn) {
          onCheckboxClick(item);
        }
      }
    });
  };

  //function to highlight rows in edit mode
  const highlightRows = cIdx => {
    let diff = rows.slice(rows.length - dataFromUploadExcel);
    if (diff.length !== 0) {
      if (cIdx + dataFromUploadExcel !== diff.length - 1) {
      } else return true;
    } else {
      return false;
    }
  };

  // INFO: Check the selected key available autofill array,if yes gives true
  const checkAutofillKeys = rowDetail => {
    let shouldDisable = false;
    shouldDisable = Object.values(autoFillColumnskeys).includes(rowDetail);
    return shouldDisable;
  };

  // INFO: Check the selected key available in Mandatory array,if yes gives true
  const checkMandatoryKeys = rowDetail => {
    let shouldDisable = false;
    let arr1 = [];
    columnsArray.map(val => {
      arr1.push(val.key);
    });
    shouldDisable = arr1.includes(rowDetail);
    return shouldDisable;
  };

  //function delete selected row(s)
  const handleDeleteRow = () => {
    const tempRows = _.cloneDeep(rows);
    const tempAllRows = _.cloneDeep(allRows);
    const result = new Set(tableSelectedIds);
    const filteredRows = tempRows.filter((el, id) => !tableSelectedIds.has(id));
    const allFilteredRows = tempAllRows.filter(
      (el, id) => !tableSelectedIds.has(id),
    );
    tableSelectedIds.forEach((value, index) => {
      return result.delete(index);
    });

    // Clear validation errors for deleted rows
    // tableSelectedIds.forEach(index => {
    //   //updates visible rows
    //   filteredRows.map(item => {
    //     Object.keys(item).map(element => {
    //       setValue(`${serviceType}.${index}.${element}`, undefined);
    //     });
    //   });

    //   //updates all rows
    //   allFilteredRows.map(item => {
    //     Object.keys(item).map(element => {
    //       setValue(`${serviceType}.${index}.${element}`, undefined);
    //     });
    //   });
    // });

    //updated deleted row in yup schema
    setValue(serviceType, allFilteredRows);
    trigger(serviceType);

    setRows(filteredRows); //updates visible rows
    setAllRows(allFilteredRows); //updates all rows

    //master data validation for all remaining rows
    // validateMasterData(allFilteredRows, errors);
    onValidate(allFilteredRows);
    setTableSelectedIds(result);

    //update redux state
    let currentSheet = { ...bidRateCurrentSheet };
    currentSheet[serviceType] = _.cloneDeep(allFilteredRows);
    dispatch(setBidRateSheetUpdatedData(currentSheet));
    dispatch(setBidrateSheetSaved(false));
  };

  //function to render fields having drop down behavior
  const handleOptions = key => {
    if (key === 'location') {
      return (
        !_.isEmpty(locationList) &&
        locationList.map(item => (
          <option value={item.description}>{item.description}</option>
        ))
      );
    } else if (key === 'eqpmntType') {
      return (
        !_.isEmpty(equipmentType) &&
        equipmentType.map(item => <option value={item}>{item}</option>)
      );
    } else if (key === 'ownedOrLeased') {
      return (
        !_.isEmpty(ownershipType) &&
        ownershipType.map(item => <option value={item}>{item}</option>)
      );
    } else if (key === 'workCategory') {
      return (
        !_.isEmpty(workCategoryList) &&
        workCategoryList.map(item => (
          <option value={item.description}>
            {item.description.toString()}
          </option>
        ))
      );
    }
  };

  //variable to check header checkbox
  let allChecked =
    allRows.length > 0 && tableSelectedIds.size === allRows.length;

  //variable to check 'Select All' checkbox in preference section
  const preferenceAllChecked = selectedIds.size === columnsArray.length;

  //function to select all rows in one click
  const selectAll = e => {
    const allCheckIDs = allRows.map((el, id) => id);
    let allCheckedtable = false;
    if (!e.target.checked) {
      allCheckedtable = true;
    }

    if (allCheckIDs.length > 0)
      setTableSelectedIds(new Set(allCheckedtable ? [] : allCheckIDs));
  };

  //function to select all columns in preference section
  const preferenceSelectAll = e => {
    const preferredAllCheckedKeys = columnsArray.map((el, id) => el.key);
    let preferredallChecked = false;
    if (!e.target.checked) {
      preferredallChecked = true;
    }

    if (preferredAllCheckedKeys.length)
      setSelectedIds(
        new Set(preferredallChecked ? [] : preferredAllCheckedKeys),
      );
  };

  //function handle preference icon click
  const handlePreferenceClick = () => {
    let tempSelectedIds = new Set();
    preferenceColumns[props.serviceType].forEach(obj => {
      tempSelectedIds.add(obj.key);
    });

    setSelectedIds(tempSelectedIds);
    setPreferenceDropdown(!preferenceDropdown);
  };

  //function to handle 'Apply' button click in preference section
  const handleApplyClick = () => {
    let applySelectedId = _.cloneDeep(selectedIds);
    let applySelectedLabel = _.cloneDeep(columnsArray);

    // Reset columnarray to default value
    applySelectedLabel.map(value => {
      value.showHeader = value.required;
    });

    // Filter columns to show
    const applySelectedData = applySelectedLabel.filter((el, id) =>
      applySelectedId.has(el.key),
    );

    // Find and set showHeader property to true
    applySelectedData.map(
      (el, id) =>
        (applySelectedLabel.find(v => v.value === el.value).showHeader = true),
    );

    setColumnHeader(applySelectedLabel);
    setPreferenceDropdown(false);
    const optionalEmptyFields = applySelectedLabel.filter(
      obj => obj.showHeader && !obj.required,
    );
    dispatch(
      setPreferenceColumns({
        serviceType: props?.serviceType,
        valuesList: optionalEmptyFields,
      }),
    );
  };

  //function to handle 'Cancel' button click in preference section
  const handleCancelClick = () => {
    let tempSelectedIds = new Set();
    preferenceColumns[props.serviceType].forEach(obj => {
      tempSelectedIds.add(obj.key);
    });

    setSelectedIds(tempSelectedIds);
    setPreferenceDropdown(false);
  };

  //function to handle 'Cancel' button click on upload excel dialog box
  const handleCancelClickOnUploadedData = () => {
    let currentSheet = { ...bidRateCurrentSheet };

    setValue(`${serviceType}`, []);
    currentSheet[serviceType] = [];
    dispatch(setBidRateSheetUpdatedData(currentSheet));
    setRows(currentSheet[serviceType]);
    setAllRows(currentSheet[serviceType]);
  };

  //function to open 'Select code' dialog box
  const handleAddNewCodeClick = (clickedRowIndex, clickedRow) => {
    //api call to fetch codes related to selected service type tab
    dispatch(
      // @ts-ignore
      fetchNewFiltersData({
        callback: async () => {
          setClickedRowIndex(clickedRowIndex);
          setClickedRowData([clickedRow]);
          setAddNewCodeModalShow(true);
        },
      }),
    );
  };

  // function to download selected service type bid rate sheet in excel sheet format
  const handleDownloadPDF = bidrateNmbr => {
    setConfirmDownload(false);
    exportBidrateSheet({
      type: 'Bidrate ' + serviceTypeName,
      serviceType: serviceType,
      worksheet: serviceTypeName,
      bidrateNmbr: bidrateSheetNumber,
      bidratename: bidRateCurrentSheet.name,
      contractorname: bidRateCurrentSheet.contractorname,
      title: serviceTypeName,
      headersValues: columnHeader.filter(x => x.showHeader || x.required),
      records: bidRateCurrentSheet[serviceType],
      formName: getFormNameForSheet(serviceType),
    });
  };

  useEffect(() => {
    if (!newBidRateSheet) {
      columnsArray.map(value => {
        let isPreferenceColumn = preferenceColumns[props.serviceType]?.find(
          obj => obj.key === value.key,
        );
        isPreferenceColumn = !isEmptyVal(isPreferenceColumn);
        value.showHeader = value.required || isPreferenceColumn;
      });
      for (let i = 0; i < bidRateCurrentSheet[serviceType].length; i++) {
        Object.keys(bidRateCurrentSheet[serviceType][i]).map(item => {
          if (bidRateCurrentSheet[serviceType][i][item]) {
            // if key exist
            let indexOfKey = columnsArray.findIndex(x => x.key === item);
            if (indexOfKey >= 0) {
              columnsArray[indexOfKey].showHeader = true;
            }
          }
        });
      }

      setColumnHeader(_.cloneDeep(columnsArray));

      columnsArray.map(item => {
        if (item.showHeader) {
          var index = columnsArray.findIndex(p => p.key == item.key);
          if (index > -1) {
            onCheckboxClick(item);
          }

          let isPreferenceColumn = preferenceColumns[props.serviceType]?.find(
            obj => obj.key === item,
          );
          isPreferenceColumn = !isEmptyVal(isPreferenceColumn);
          if (isPreferenceColumn) {
            onCheckboxClick(item);
          }
        }
      });
    } else {
      let applySelectedLabel = [];
      let applySelectedFinalData = [];

      columnsArray.map(value => {
        let isPreferenceColumn = preferenceColumns[props.serviceType]?.find(
          obj => obj.key === value.key,
        );
        isPreferenceColumn = !isEmptyVal(isPreferenceColumn);
        value.showHeader = value.required || isPreferenceColumn;
      });

      for (let i = 0; i < bidRateCurrentSheet[serviceType]?.length; i++) {
        Object.keys(bidRateCurrentSheet[serviceType][i]).map(item => {
          if (bidRateCurrentSheet[serviceType][i][item]) {
            // if key exist
            if (!applySelectedFinalData.includes(item)) {
              // if key don't exist add it
              var index = columnsArray.findIndex(p => p.key == item);
              if (index >= 0) {
                // INFO: populate keys for radio in dropdown
                if (options[index]) {
                  // applySelectedLabel.push(options[index]);
                }
              }
            }
          }
        });
      }
      let arr = [...applySelectedLabel];
      const removeDupRecord = arr.reduce((acc, curr) => {
        const existingObj = acc.find(obj => obj.key === curr.key);
        if (!existingObj) {
          acc.push(curr);
        }
        return acc;
      }, []);

      setColumnHeader([...columnsArray, ...removeDupRecord]);

      columnsArray.map(item => {
        if (item.showHeader) {
          var index = columnsArray.findIndex(p => p.key == item.key);
          if (index > -1) {
            onCheckboxClick(item);
          }

          let isPreferenceColumn = preferenceColumns[props.serviceType]?.find(
            obj => obj.key === item,
          );
          isPreferenceColumn = !isEmptyVal(isPreferenceColumn);
          if (isPreferenceColumn) {
            onCheckboxClick(item);
          }
        }
      });
    }
  }, []);

  //function to disable upload button for individual service type
  const handleDisableUpload = () => {
    const setDisable =
      rows.length > 0 || bidRateCurrentSheet[serviceType]?.length > 0;
    return setDisable;
  };

  //function to disable download button for individual service type
  const handleDisableDownload = () => {
    return bidrateSheetNumber === '' || rows.length === 0;
  };

  //function to check field is date field
  const isInputDate = str => {
    let subStr = 'date';
    if (str.toLowerCase().replace(/\s/g, '').includes(subStr)) {
      return false;
    } else {
      return true;
    }
  };

  //function to check if field is of dropdown type
  const isDropDown = str => {
    if (str === 'Work Category') {
      return false;
    } else if (str === 'Location') {
      return false;
    } else if (str === 'Equipment Type (Standard, Specialty)') {
      return false;
    } else if (str === 'Owned or Leased (Owned, Rental)') {
      return false;
    } else {
      return true;
    }
  };

  // INFO: Check for options to auto populate master data  fields available for individual service type
  const checkOptions = key => {
    switch (key) {
      case 'Labor Code':
        return true;
        break;

      case 'Equipment code':
        return true;
        break;
      case 'Reimbursable Mark-Up Code':
        return true;
        break;

      case 'Unit Code':
        return true;
        break;
      case 'Code':
        return true;
        break;

      case 'Billing Code':
        return true;
        break;
      default:
        return false;
    }
  };

  //function to convert date in 'MM/DD'YYY' format
  const renderDateData = date => {
    if (date !== null) {
      return moment(date).format('MM/DD/YYYY').toString();
    } else {
      return date;
    }
  };

  //function to activate edit mode on double click of row
  const handleEditfeature = (clickedRowIndex, id) => {
    let arr = [...activeFields];
    arr.map((item, index) => {
      arr[index] = false;
    });
    arr[clickedRowIndex] = true;
    setPrefrencesCheck(columnHeader);
    setActiveFields(arr);
  };

  //function to display input fields and highlighted background for rows in edit mode
  const handleEditMode = (clickedRowIndex, id) => {
    return activeFields[clickedRowIndex];
  };

  //useEffect call at initial render of bid rate table component
  useEffect(() => {
    let arr = [];
    if (bidRateCurrentSheet[serviceType]?.length > 0) {
      arr = bidRateCurrentSheet[serviceType];
    }
    arr.map((item, index) => {
      Object.keys(item).map(element => {
        setValue(`${serviceType}.${index}.${element}`, item[element]);
      });
    });

    setRows(arr);
    setAllRows(arr);
    setPrefrencesCheck(columnHeader);
    setActiveFields([]);
  }, []);

  //function to deactivate edit mode of rows once all rows are validated and corrected succefully in upload flow
  const handleConfirmEditMode = () => {
    let arr = [...activeFields];
    arr.map((item, index) => {
      if (item) {
        arr[index] = false;
      }
    });
    setActiveFields(arr);
    setPrefrencesCheck(columnHeader);
  };

  //function to render each cel of bidrate table
  const renderCell = (column, idx, rowData) => {
    if (!isInputDate(column.value)) {
      return (
        <>
          <Controller
            control={control}
            key={`${serviceType}.${idx}.${column.key}`}
            name={`${serviceType}.${idx}.${column.key}`}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker
                onChange={e => updateState(e, idx, column.key)}
                disabled={checkAutofillKeys(column.key)}
                error={errors?.[serviceType]?.[idx]?.[column.key]?.['message']}
                selected={
                  rows[idx][column.key]
                    ? getMidnightDate(rows[idx][column.key])
                    : null
                }
                className="form-control date-picker-icon"
                customInput={
                  <InputMask
                    ref={ref}
                    mask="99/99/9999"
                    value={value}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        </>
      );
    } else if (!isDropDown(column.value)) {
      return (
        <>
          <Controller
            control={control}
            key={`${serviceType}.${idx}.${column.key}`}
            name={`${serviceType}.${idx}.${column.key}`}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Form.Select
                aria-label={`${serviceType}.${idx}.${column.key}`}
                className="select-box"
                onChange={e => updateState(e, idx, column.key)}
                value={value}
                style={
                  isMobile
                    ? {
                        display: 'block',
                        width: '100%',
                      }
                    : { display: 'block' }
                }
              >
                <option value=""></option>
                {handleOptions(column.key)}
              </Form.Select>
            )}
          />
        </>
      );
    } else {
      return (
        <>
          <Controller
            control={control}
            key={`${serviceType}.${idx}.${column.key}`}
            name={`${serviceType}.${idx}.${column.key}`}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Form.Control
                value={isEmptyVal(value) ? '' : value}
                onChange={e => updateState(e, idx, column.key)}
                isInvalid={
                  errors?.[serviceType]?.[idx]?.[column.key]?.['message']
                }
                disabled={checkAutofillKeys(column.key)}
                ref={ref}
                type="text"
              />
            )}
          />
          {checkOptions(column.value) && (
            <span onClick={e => handleAddNewCodeClick(idx, rowData)}>
              <DrillDownIcon />
            </span>
          )}
        </>
      );
    }
  };

  return (
    <>
      <div>
        <div className="tableContainer ">
          <div className="aepBreadcrumb laborRatesPreferences">
            <Row>
              <Col sm={12}>
                <div style={{ float: 'right' }}>
                  <span>Preferences</span>
                  <span
                    title="Preferences"
                    onClick={e => handlePreferenceClick()}
                  >
                    <PreferenceIcon />
                  </span>
                  <span
                    title="Download"
                    onClick={() =>
                      !handleDisableDownload() && handleDownloadPDF()
                    }
                    className={handleDisableDownload() ? 'disable-icon' : ''}
                  >
                    <DownloadIcon />
                  </span>
                  <span
                    title="Upload"
                    onClick={() =>
                      !handleDisableUpload() && setUploadModal(true)
                    }
                    className={handleDisableUpload() ? 'disable-icon' : ''}
                  >
                    <UploadIcon />
                  </span>
                  <span
                    title="Add New Row"
                    onClick={handleAddRow}
                    className="preferencebtn"
                  >
                    <AddIcon />
                  </span>

                  <span
                    title="Delete Row"
                    onClick={handleDeleteRow}
                    className={
                      tableSelectedIds.size > 0
                        ? 'preferencebtn'
                        : 'preferencebtn disable-icon'
                    }
                  >
                    <DeleteIcon />
                  </span>
                </div>
              </Col>
            </Row>
            {preferenceDropdown && (
              <div className="preferenceModalContainer">
                <div className="preferenceModalHeader">
                  Selected Columns will be Displayed in Table
                </div>
                <div className="preferenceModalSelectAll">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={preferenceAllChecked}
                      onChange={e => {
                        preferenceSelectAll(e);
                      }}
                    />
                    Select All
                  </label>
                </div>
                <div className="preferenceModalSelect">
                  <ul>
                    {columnHeader.map(
                      (option, preferenceId) =>
                        !option?.required && (
                          <li key={`${key}-${preferenceId}`}>
                            <label className="preferenceSelection">
                              <div>
                                <input
                                  className="form-check-input"
                                  checked={isPreferenceSelected(option)}
                                  onChange={e => onCheckboxClick(option)}
                                  type="checkbox"
                                />
                              </div>
                              <div>{option.value}</div>
                            </label>
                          </li>
                        ),
                    )}
                  </ul>
                </div>
                <div>
                  <ButtonArea
                    primaryLabel="Apply"
                    secondaryLabel="Cancel"
                    primaryOnClick={() => handleApplyClick()}
                    secondaryOnClick={() => handleCancelClick()}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="laborRatesScroll">
            <FormProvider {...methods}>
              <div
                className="bidratesheetForm"
                ref={bidrateTableRef}
                onScroll={handleTableScroll}
              >
                <Form>
                  <Table
                    hover
                    responsive
                    striped
                    bordered={false}
                    className="laborRatesPreview"
                  >
                    <thead>
                      <tr>
                        <th scope="row">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={allChecked}
                            onChange={e => {
                              selectAll(e);
                            }}
                          />
                        </th>
                        {columnHeader.map((header, idx) =>
                          header.showHeader || header.required ? (
                            <th key={idx}>
                              <div id={header.key}>
                                {header.value}
                                {checkMandatoryKeys(header.key) &&
                                  header.required && (
                                    <span
                                      style={{
                                        color: 'red',
                                        fontSize: '15px',
                                        marginLeft: '5px',
                                      }}
                                    >
                                      *
                                    </span>
                                  )}

                                <CaretDown style={{ marginLeft: '12px' }} />
                              </div>
                            </th>
                          ) : null,
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {rows.length == 0 && newBidRateSheet ? (
                        <tr className="table-hover-row">
                          <td colSpan="6">
                            <div className="add">
                              <div className=" aepHeader">
                                You haven't added any Items Yet
                              </div>
                              <div className=" aepBreadcrumb">
                                To get started, add new Row
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : newBidRateSheet ? (
                        rows.map((item, idx) => (
                          <tr
                            style={{
                              backgroundColor: highlightRows(idx)
                                ? '#e4f4fd'
                                : '',
                            }}
                            className={
                              handleEditMode(idx, rows[idx])
                                ? 'row-bg-color'
                                : 'table-hover-row'
                            }
                            onDoubleClick={() =>
                              handleEditfeature(idx, rows[idx])
                            }
                            key={idx}
                          >
                            <td scope="row">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={IsTableSelected(idx)}
                                onChange={e => onTableCheckboxClick(e, idx)}
                              />
                            </td>
                            {columnHeader.map((column, index) =>
                              column.required || column.showHeader ? (
                                <td key={`${index}-${idx}`}>
                                  {handleEditMode(idx, rows[index]) ? (
                                    <>
                                      <div
                                        className="laborColumnHeader"
                                        id={column.key}
                                      >
                                        {/* when data exist  */}

                                        {renderCell(column, idx, item)}
                                      </div>
                                      {errors?.[serviceType]?.[idx]?.[
                                        column.key
                                      ]?.['message'] ? (
                                        <div className="errorMessage">
                                          <ErrorIcon />
                                          {
                                            errors?.[serviceType]?.[idx]?.[
                                              column.key
                                            ]?.['message']
                                          }
                                        </div>
                                      ) : null}
                                      {masterDataError?.[serviceType]?.[
                                        column.key
                                      ]?.includes(idx) && (
                                        <div className="errorMessage">
                                          <ErrorIcon />
                                          {masterDataErrorMsg}
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="laborColumnHeader"
                                        id={column.key}
                                      >
                                        {isInputDate(column.key)
                                          ? rows[idx][column.key]
                                          : renderDateData(
                                              rows[idx][column.key],
                                            )}
                                      </div>
                                      {errors?.[serviceType]?.[idx]?.[
                                        column.key
                                      ]?.['message'] ? (
                                        <div className="errorMessage">
                                          <ErrorIcon />
                                          {
                                            errors?.[serviceType]?.[idx]?.[
                                              column.key
                                            ]?.['message']
                                          }
                                        </div>
                                      ) : null}
                                      {masterDataError?.[serviceType]?.[
                                        column.key
                                      ]?.includes(idx) && (
                                        <div className="errorMessage">
                                          <ErrorIcon />
                                          {masterDataErrorMsg}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </td>
                              ) : null,
                            )}
                          </tr>
                        ))
                      ) : (
                        rows.map((item, idx) => (
                          <tr
                            className={
                              handleEditMode(idx, rows[idx])
                                ? 'row-bg-color'
                                : 'table-hover-row'
                            }
                            onDoubleClick={() =>
                              handleEditfeature(idx, rows[idx])
                            }
                            key={idx}
                          >
                            <th scope="row">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={IsTableSelected(idx)}
                                onChange={e => onTableCheckboxClick(e, idx)}
                              />
                            </th>

                            {columnHeader.map((column, index) =>
                              column.required || column.showHeader ? (
                                <td key={index}>
                                  {handleEditMode(idx, rows[idx]) ? (
                                    <>
                                      <div
                                        className="laborColumnHeader"
                                        id={column.key}
                                      >
                                        {renderCell(column, idx, item)}
                                      </div>

                                      {errors?.[serviceType]?.[idx]?.[
                                        column.key
                                      ]?.['message'] ? (
                                        <div className="errorMessage">
                                          <ErrorIcon />
                                          {
                                            errors?.[serviceType]?.[idx]?.[
                                              column.key
                                            ]?.['message']
                                          }
                                        </div>
                                      ) : null}
                                      {masterDataError?.[serviceType]?.[
                                        column.key
                                      ]?.includes(idx) && (
                                        <div className="errorMessage">
                                          <ErrorIcon />
                                          {masterDataErrorMsg}
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="laborColumnHeader"
                                        id={column.key}
                                      >
                                        {isInputDate(column.key)
                                          ? rows[idx][column.key]
                                          : renderDateData(
                                              rows[idx][column.key],
                                            )}
                                      </div>
                                      {errors?.[serviceType]?.[idx]?.[
                                        column.key
                                      ]?.['message'] ? (
                                        <div className="errorMessage">
                                          <ErrorIcon />
                                          {
                                            errors?.[serviceType]?.[idx]?.[
                                              column.key
                                            ]?.['message']
                                          }
                                        </div>
                                      ) : null}
                                      {masterDataError?.[serviceType]?.[
                                        column.key
                                      ]?.includes(idx) ? (
                                        <div className="errorMessage">
                                          <ErrorIcon />
                                          {masterDataErrorMsg}
                                        </div>
                                      ) : null}
                                    </>
                                  )}
                                </td>
                              ) : null,
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </Form>
              </div>

              <div>
                {showValidate[serviceType] && (
                  <ButtonArea
                    primaryLabel="Validate"
                    secondaryLabel="Clear"
                    primaryOnClick={() => onValidate(allRows)}
                    secondaryOnClick={() => handleCancelClickOnUploadedData()}
                  />
                )}
              </div>
            </FormProvider>
            <AddNewCodePopup
              show={addNewCodeModalShow}
              setShowModal={setAddNewCodeModalShow}
              addNewCodeHeaders={columnsArray}
              clickedRowData={clickedRowData}
              handleAddNewCodeContinueClick={handleAddNewCodeContinueClick}
              autoFillColumnskeys={autoFillColumnskeys}
              serviceType={serviceType}
            />
          </div>
        </div>
      </div>
      <UploadExcel
        viewModal={uploadModal}
        setViewModal={setUploadModal}
        setData={setExcelData}
        serviceTypeName={serviceTypeName}
        downloadExcelServiceTypeName={downloadExcelServiceTypeName}
        onSubmit={handleAddRow}
        setRows={setRows}
        rows={rows}
        handleAddExcelData={handleAddExcelData}
        isInputDate={isInputDate}
        isDropDown={isDropDown}
        contractorName={bidRateCurrentSheet?.contractorname}
      />

      <ConfirmationModal
        showModal={confirmDownload}
        setShowModal={setConfirmDownload}
        handleContinueClick={() => handleDownloadPDF()}
        title="Confirm Download"
        body="Sheet has unsaved changes. Do you want to continue?"
      />
    </>
  );
};

export default BidRateTable;
