import { yupResolver } from '@hookform/resolvers/yup';
import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { confirm } from 'components/Dialogs/Confirm';
import { fileViewer } from 'components/Dialogs/FileViewer';
import {
  displayCompletionFormStatus,
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';
import { ConfirmationModal } from 'components/common/modals/ConfirmatinModal';
import { ReviewerModal } from 'components/common/modals/ReviewerModal';

import { ApprovedIcon } from 'assets/images/ApprovedIcon/ApprovedIcon';
import { Avatar } from 'assets/images/Avatar/Avatar';
import { PendingIcon } from 'assets/images/PendingIcon/PendingIcon';
import { BuPath } from 'constants/buPath';
import {
  LabelFieldMapping,
  POCategoryFieldMapping,
  PORateKeyMapping,
  PORateObjectKeyMapping,
  POCodeFieldMapping,
  POCodeMapping,
} from 'constants/poRates.ts';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { ROUTES_STRING } from 'constants/routes';
import { TimesheetStatusDisplayValue } from 'constants/statusValues';
import {
  differenceInDays,
  format,
  getDate,
  getDay,
  getMonth,
  getYear,
  lastDayOfMonth,
  set,
} from 'date-fns';
import { BuPathEnum } from 'enums/buPath.ts';
import { Day } from 'enums/dates.ts';
import { ModuleACL, UserType } from 'enums/entitlements.ts';
import { AuthType } from 'enums/jobAuth.ts';
import { Page } from 'enums/page.ts';
import { PricingMethod } from 'enums/pricingMethod.ts';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
import { InvoiceStatus, TimesheetStatus } from 'enums/statuses.ts';
import _ from 'lodash';
import { PreviewInvoiceModal } from 'pages/Timesheet/modals/PreviewInvoiceModal';
import {
  classificationList,
  resetAutoPopulatedFields,
  resetDropdownOptions,
  serviceTypeList,
  serviceTypes,
  setAutoPopulatedFields,
  setFilteredDropdownOptions,
} from 'pages/Timesheet/utils';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { FormProvider, useForm } from 'react-hook-form';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { components } from 'react-select';
import { ClipLoader } from 'react-spinners';
import { excelDownloadTimesheet } from 'services/excelDownloadTimesheet';
import { deleteFile, uploadFiles } from 'services/fileUploadService';
import { generateClassificationList } from 'store/sagas/timesheet/utils';
import { setTimesheetErrorObj } from 'store/slices/errorSlice';
import {
  fetchCircuit,
  fetchCrewType,
  fetchEquipmentIdList,
  fetchInvoiceApprovers,
  fetchInvoiceData,
  fetchInvoiceDates,
  fetchJobAuthorizations,
  fetchOperatingCompany,
  fetchPORates,
  fetchTimesheetDates,
  fetchUserEntitlements,
  fetchInvoiceUserEntitlements,
  fetchWorkArea,
  fetchWorkType,
  fetchMaterialStatus,
} from 'store/slices/lookupSlice';
import { setActivePage } from 'store/slices/pageSlice';
import {
  fetchJobAuthorization,
  fetchTimesheetDetail,
  getWorkOrderDetails,
  setApproveTimesheetError,
  setClassificationList,
  setTimesheetApprovals,
  setTimesheetDetail,
  setTimesheetSuccess,
  updateTimesheet,
  setApproveCircuitMileageError,
  setTriggerClassificationList,
  resetTriggerClassificationList,
} from 'store/slices/timesheet/timesheetDetailSlice';
import {
  returnTimesheets,
  saveTimesheet,
} from 'store/slices/timesheet/timesheetSlice';
import { IsAllowedACL } from 'utils/aclHelper';
import { getMidnightDate, getNextDayOccurrence, isEmptyVal } from 'utils/utils';
import { AttachmentModal } from '../modals/AttachmentModal';
import { InvoiceErrorModal } from '../modals/InvoiceErrorModal';
import { PDFModal } from '../modals/PDFModal';
import { TimesheetErrorModal } from '../modals/TimesheetErrorModal';
import { AttachmentArea } from './FormComponents/AttachmentArea';
import { ButtonComponent } from './FormComponents/ButtonComponent';
import { ClassificationFields } from './FormComponents/ClassificationFields';
import { DistributionAccounting } from './FormComponents/DistributionAccounting';
import { HeaderButtons } from './FormComponents/HeaderButtons';
import { TimesheetApproverField } from './FormComponents/TimesheetApproverField';
import { TimesheetHeader } from './FormComponents/TimesheetHeader';
import { TransmissionAccountingFields } from './FormComponents/TransmissionAccountingField';
import './TimesheetEntry.scss';
import {
  generateSchema,
  regenerateFormValues,
  transformEmptyStringToNull,
  transformNullToEmptyString,
} from './formHelpers';
import ShowEnteredByDate from './FormComponents/ShowEntereByDate';

const TimesheetEntry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector(state => state.auth);
  const timesheetErrorObj = useSelector(state => state.error.timesheet);
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);
  const lookup = useSelector(state => state.lookup);
  const timesheet = useSelector(state => state.timesheetDetail);
  const timesheetApprovals = useSelector(
    state => state.timesheetDetail.timesheetApprovals,
  );
  const approveTimesheetError = useSelector(
    state => state.timesheetDetail.approveTimesheetError,
  );
  const approveCircuitMileageError = useSelector(
    state => state.timesheetDetail.approveCircuitMileageError,
  );

  const isMobile = useMediaQuery({ query: '(max-width: 834px)' });

  const [fileUploadInprogress, setFileUploadInprogress] = useState(false);
  const [pdfDocumentUrl, setPdfDocumentUrl] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [weekEndDate, setWeekEndDate] = useState(new Date());
  const [isDuplicateDate, setIsDuplicateDate] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [startDateErrorMessage, setStartDateErrorMessage] = useState('');
  const [endDateError, setEndDateError] = useState(false);
  const [endDateErrorMessage, setEndDateErrorMessage] = useState('');
  const [weekEndDateError, setWeekEndDateError] = useState(false);
  const [weekEndDateErrorMessage, setWeekEndDateErrorMessage] = useState('');
  const [showServiceTypes, setShowServiceTypes] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showPreviewInvoiceModal, setShowPreviewInvoiceModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] =
    useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showReviewerAcceptModal, setShowReviewerAcceptModal] = useState(false);
  const [showReviewerDisagreeModal, setShowReviewerDisagreeModal] =
    useState(false);
  const [reviewerComment, setReviewerComment] = useState('');
  const [newTimesheet, setNewTimesheet] = useState(false);
  const [invoiceComments, setInvoiceComments] = useState(false);
  const [serviceTypeOptions, setServiceTypeOptions] = useState(
    _.cloneDeep(serviceTypes),
  );
  const [serviceTypeOptionList, setServiceTypeOptionList] = useState(
    _.cloneDeep(serviceTypeList),
  );
  const [timesheetApproverOptions, setTimesheetApproverOptions] = useState([
    { label: 'Select', value: '' },
  ]);

  const [showAttachmentPDF, setShowAttachmentPDF] = useState(false);
  const [showAttachment, setShowAttachment] = useState(false);
  const [uploadedFilesArray, setUploadedFilesArray] = useState([]);
  const [createInvoiceError, setCreateInvoiceError] = useState(false);
  const [createInvoiceErrorMessage, setCreateInvoiceErrorMesage] = useState([]);
  const [activityError, setActivityError] = useState({
    [SERVICE_TYPES.LABOR]: {},
    [SERVICE_TYPES.EQUIPMENT]: {},
  });
  const [checkedRows, setCheckedRows] = useState({
    [SERVICE_TYPES.LABOR]: [],
    [SERVICE_TYPES.EQUIPMENT]: [],
    [SERVICE_TYPES.REIMBURSABLE_MARKUP]: [],
    [SERVICE_TYPES.UNIT_PRICE_TASK]: [],
    [SERVICE_TYPES.CU]: [],
    [SERVICE_TYPES.LUMPSUM]: [],
    [SERVICE_TYPES.PRODUCTION_HOURS]: [],
  });
  const [defaultOpco, setDefaultOpco] = useState('');
  const [isActivityError, setIsActivityError] = useState(false);
  const [isChangeAccounting, setIsChangeAccounting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transmissionWO, setTransmissionWO] = useState('');
  const [workOrderExists, setWorkOrderExists] = useState(false);
  const [milesCompleteError, setMilesCompleteError] = useState('');
  const [isJobAuthUnitPriceTask, setIsJobAuthUnitPriceTask] = useState(false);
  const workOrderMsg =
    'System failed to find the WO. Do you still want to submit the timesheet with the existing Work Order';
  const accessToken = useSelector(state => state.auth.accessToken);
  const triggerClassificationList = useSelector(
    state => state.timesheetDetail.triggerClassificationList,
  );

  const fetchWorkOrder = () => {
    setIsLoading(true);
    dispatch(
      getWorkOrderDetails({
        workOrder: transmissionWO,
        callback: data => {
          setIsLoading(false);
          let timesheetAccountsValue = getValues('timesheetAccounts');
          let timesheetAccountsObj = {
            workorder: transmissionWO,
            abmsProject: '',
            accountNmbr: '',
            projectBusinessUnit: '',
            glbu: '',
            dept: '',
            costComponent: '',
            abmsActivity: '',
          };
          if (data?.Status?.StatusCode == 200) {
            setWorkOrderExists(true);
            timesheetAccountsObj = {
              workorder: transmissionWO,
              abmsProject: data?.abms_project,
              accountNmbr: Number(data?.account_nmbr),
              projectBusinessUnit: data?.project_business_unit,
              glbu: data?.glbu,
              dept: data?.dept,
              costComponent: data?.cost_component,
              abmsActivity: data?.abms_activity,
            };
          } else {
            setIsChangeAccounting(true);
            displayError('Work Order Not Found !');
          }

          if (timesheetAccountsValue?.length) {
            timesheetAccountsValue[0] = {
              ...timesheetAccountsValue[0],
              ...timesheetAccountsObj,
            };
          } else {
            timesheetAccountsValue = [{ ...timesheetAccountsObj }];
          }
          setValue('timesheetAccounts', timesheetAccountsValue);
        },
      }),
    );
  };

  const disableFields = (
    serviceType,
    fieldName = null,
    classificationIndex = null,
    rowIndex = null,
    colIndex = null,
  ) => {
    if (serviceType === SERVICE_TYPES.CU) {
      return true;
    }

    let shouldDisable = [
      TimesheetStatus?.TIMESHEET_APPROVED,
      TimesheetStatus?.TIMESHEET_CANCELLED,
      TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
      TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED,
      TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
      TimesheetStatus.TIMESHEET_INVOICED,
    ].includes(timesheet?.caastatus);

    if (
      [
        'equipUnitmeasure',
        'reimbUnitmeasure',
        'unitmeasure',
        'unitPriceEqptLabor',
        'unitPriceLabor',
        'unitPriceEqpt',
        'unitPriceMatl',
        'totalPrice',
        'lineAmount',
        'owned',
        'eqptype',
        'reimbPrice',
      ].includes(fieldName) &&
      serviceType !== SERVICE_TYPES.PRODUCTION_HOURS
    ) {
      shouldDisable = true;
    }

    if (!shouldDisable) {
      if (
        timesheet?.caastatus === TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED
      ) {
        if (user?.configObject?.userrole === 'admin') {
          shouldDisable = false;
        } else {
          shouldDisable = !IsAllowedACL(
            user,
            ModuleACL.TIMESHEET,
            'can_submit_new_timesheet',
          );
        }
      } else if (
        (timesheet?.caastatus ===
          TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED &&
          !timesheet?.submittedByAep &&
          UserType.AEP === user.userType) ||
        (timesheet?.caastatus ===
          TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED &&
          timesheet?.submittedByAep &&
          UserType.VENDOR === user.userType)
      ) {
        shouldDisable = true;
      }
    }

    if (
      !shouldDisable &&
      serviceType === SERVICE_TYPES.PRODUCTION_HOURS &&
      classificationIndex !== null &&
      rowIndex !== null
    ) {
      const upcodeFieldVal =
        timesheet?.classificationList[classificationIndex].values[rowIndex][0]
          .value;
      const qtyFieldVal =
        timesheet?.classificationList[classificationIndex].values[rowIndex][2]
          .value;
      const hoursFieldVal =
        timesheet?.classificationList[classificationIndex].values[rowIndex][3]
          .value;

      if (fieldName === 'unitmeasure') {
        shouldDisable = !upcodeFieldVal;
      } else if (fieldName === 'quantity') {
        shouldDisable = hoursFieldVal > 0 && !qtyFieldVal;
      } else if (fieldName === 'hours') {
        shouldDisable = qtyFieldVal > 0 && !hoursFieldVal;
      }
    }

    return shouldDisable;
  };

  const handleDuplicateDate = (startDt, endDt) => {
    let isDuplicate = false;
    let foundDuplicate = false;
    try {
      lookup?.timesheetDates?.forEach(obj => {
        if (!foundDuplicate) {
          if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
            isDuplicate =
              format(getMidnightDate(endDt), 'yyyy-MM-dd') ===
              format(getMidnightDate(obj.weekEndDate), 'yyyy-MM-dd');
          } else {
            isDuplicate =
              format(getMidnightDate(startDt), 'yyyy-MM-dd') ===
                format(getMidnightDate(obj.workStartDate), 'yyyy-MM-dd') &&
              format(getMidnightDate(endDt), 'yyyy-MM-dd') ===
                format(getMidnightDate(obj.workEndDate), 'yyyy-MM-dd');
          }

          if (isDuplicate) {
            foundDuplicate = true;
          }
        }
      });
    } catch (e) {
      isDuplicate = false;
    }
    setIsDuplicateDate(isDuplicate);
  };

  const handleDateError = (startDt = null, endDt = null) => {
    const isUvl = getValues('uvl');
    const today = getMidnightDate(new Date());
    const endOfMonthDate = getMidnightDate(lastDayOfMonth(today));

    if (startDt !== null) {
      // const isError = isUvl ? startDt > endOfMonthDate : startDt > today;
      const isError = isUvl
        ? differenceInDays(endOfMonthDate, startDt) < 0
        : differenceInDays(today, startDt) < 0;

      // const isPOError =
      // startDt < getMidnightDate(purchaseOrder?.startDate) ||
      // startDt > getMidnightDate(purchaseOrder?.endDate);
      const isPOError =
        differenceInDays(startDt, getMidnightDate(purchaseOrder?.startDate)) <
          0 ||
        differenceInDays(getMidnightDate(purchaseOrder?.endDate), startDt) < 0;

      if (isError) {
        setStartDateError(isError);
        const dateErrorMessage = isUvl
          ? 'UVL date cannot be for future months'
          : 'Start Date cannot be future date';
        setStartDateErrorMessage(dateErrorMessage);
      } else if (isPOError) {
        setStartDateError(isPOError);
        setStartDateErrorMessage(
          `Start date must be between purchase order start date (${format(
            getMidnightDate(purchaseOrder?.startDate),
            'MM/dd/yyyy',
          )}) and end date (${format(
            getMidnightDate(purchaseOrder?.endDate),
            'MM/dd/yyyy',
          )})`,
        );
      } else if (getMidnightDate(startDt) > getMidnightDate(endDate)) {
        // setStartDateError(true);
        // setStartDateErrorMessage('Start date cannot be greater than end date');
      } else {
        setStartDateError(false);
      }
    } else if (endDt !== null) {
      // const isError = isUvl ? endDt > endOfMonthDate : endDt > today;
      const isError = isUvl
        ? differenceInDays(endOfMonthDate, endDt) < 0
        : differenceInDays(today, endDt) < 0;

      // const isPOError =
      //   endDt < getMidnightDate(purchaseOrder?.startDate) ||
      //   endDt > getMidnightDate(purchaseOrder?.endDate);
      const isPOError =
        differenceInDays(endDt, getMidnightDate(purchaseOrder?.startDate)) <
          0 ||
        differenceInDays(getMidnightDate(purchaseOrder?.endDate), endDt) < 0;

      if (isError) {
        setEndDateError(isError);
        const dateErrorMessage = isUvl
          ? 'UVL date cannot be for future months'
          : 'End Date cannot be future date';
        setEndDateErrorMessage(dateErrorMessage);
      } else if (isPOError) {
        setEndDateError(isPOError);
        setEndDateErrorMessage(
          `End date must be between purchase order start date (${format(
            getMidnightDate(purchaseOrder?.startDate),
            'MM/dd/yyyy',
          )}) and end date (${format(
            getMidnightDate(purchaseOrder?.endDate),
            'MM/dd/yyyy',
          )})`,
        );
      } else if (getMidnightDate(startDate) > getMidnightDate(endDt)) {
        // setEndDateError(true);
        // setEndDateErrorMessage('End date cannot be lesser than start date');
      } else {
        setEndDateError(false);
      }
    }
  };

  const updateTimesheetWithCallbacks = (data, recordNmbr, caastatus) => {
    dispatch(
      // @ts-ignore
      updateTimesheet({
        data,
        recordNmbr,
        caastatus,
        callback: async recordNumber => {
          let approverUser = data?.timesheetApprovals?.find(
            obj => obj.approver,
          )?.approver;
          if (isEmptyVal(approverUser)) {
            approverUser = '';
          }
          if (
            data?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED &&
            BuPath.GEN.includes(timesheet?.bupath) &&
            approverUser?.toLowerCase() !== user?.userId?.toLowerCase()
          ) {
            displaySuccess('Timesheet Reviewed by Cost-Coordinator');
          }
          dispatch(
            fetchEquipmentIdList({
              poid: purchaseOrder.poid,
              callback: async eqIdList => {
                let tempLookup = _.cloneDeep(lookup);
                tempLookup.equipmentIdOptions = eqIdList;
                dispatch(
                  // @ts-ignore
                  fetchTimesheetDetail({
                    recordNumber,
                    callback: data => {
                      displayServiceTypesDynamically(tempLookup, data);
                    },
                  }),
                );
              },
            }),
          );
        },
      }),
    );
  };

  const { promiseInProgress: timesheetLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.getTimesheetDetail,
    delay: 0,
  });

  const { promiseInProgress: invoiceDataLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.getInvoiceData,
    delay: 0,
  });

  const { promiseInProgress: modifytimesheetLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.modifyTimesheet,
    delay: 0,
  });

  const { promiseInProgress: getWorkType } = usePromiseTracker({
    area: PROMISE_TRACKER.getWorkType,
    delay: 0,
  });

  const isTransmission = purchaseOrder?.buPath === BuPath.TRANS;
  const isGeneration = BuPath.GEN.includes(purchaseOrder.buPath);
  const isDistribution = BuPath.DIST.includes(purchaseOrder.buPath);

  // Schema for yup
  let schema = generateSchema(
    isJobAuthUnitPriceTask,
    isDistribution,
    lookup.unitOfMeasure,
  );

  const defaultValues = {
    workStartDate: getMidnightDate(new Date(), 6, 'sub'),
    workEndDate: getMidnightDate(new Date()),
    weekEndDate: getNextDayOccurrence(Day.SATURDAY),
    comments: '',
    uvl: false,
    uvlEstimateNo: true,
    submittedByAep: null,

    // Distribution timesheet fields
    contRefNumeric: '',
    vendorTimesheet: '',
    crewType: '',
    crewNmbr: '',
    workAreaId: '',
    materialStatusId: '',
    worktype: '',
    ocNmbr: '',
    masterProjectNumber: '',
    authType: '',
    authNmbr: '',
    circuitnum: '',
    subcircuit: '',
    milescomplete: '',
    timesheetAccounts: [
      {
        workorder: '',
        abmsProject: '',
        accountNmbr: null,
        projectBusinessUnit: !isTransmission
          ? purchaseOrder?.poLines[0]?.gldebitacct?.split('-')?.[4] ?? ''
          : '',
        glbu: !isTransmission
          ? purchaseOrder?.poLines[0]?.gldebitacct?.split('-')?.[0] ?? ''
          : '',
        dept: '',
        costComponent: '',
        abmsActivity: '',
      },
    ],
    /************************** */

    [SERVICE_TYPES.LABOR]: [],
    [SERVICE_TYPES.EQUIPMENT]: [],
    [SERVICE_TYPES.REIMBURSABLE_MARKUP]: [],
    [SERVICE_TYPES.UNIT_PRICE_TASK]: [],
    [SERVICE_TYPES.LUMPSUM]: [],
    [SERVICE_TYPES.PRODUCTION_HOURS]: [],
  };

  const methods = useForm({
    mode: 'onTouched',
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    trigger,
    setError,
    formState: { errors },
  } = methods;

  const allFormFields = watch();

  const validateCostLines = data => {
    for (const item of data) {
      if (!item.workorder || !item.accountNmbr || !item.glbu || !item.dept) {
        return false;
      }
    }
    return true;
  };

  const onSubmit = data => {
    const submitForm = data['submit'];
    const acceptTimesheet = data['accept'];
    delete data['submit'];
    delete data['accept'];
    if (!BuPath.DIST.includes(purchaseOrder?.buPath) && acceptTimesheet) {
      setShowReviewerAcceptModal(true);
      return;
    }

    if (isActivityError && purchaseOrder?.buPath === BuPath.TRANS) {
      return;
    }

    const payloadData = _.cloneDeep(data);
    if (
      payloadData.hasOwnProperty('circuitnum') &&
      !isEmptyVal(payloadData['circuitnum'])
    ) {
      const circuitObj = lookup?.circuitList.find(
        c => c.value === payloadData['circuitnum'],
      );
      if (!isEmptyVal(circuitObj)) {
        payloadData['circuitid'] = circuitObj?.circuitid;
      }
    }
    payloadData['poid'] = purchaseOrder.poid;
    payloadData['bupath'] = purchaseOrder.buPath;
    payloadData['contractNmbr'] = purchaseOrder.contractRefnum;
    payloadData['invoiceNmbr'] = null;
    payloadData['orgid'] = purchaseOrder.organization;
    payloadData['circuitcomplete'] = 'N';
    payloadData['circuitmilesid'] = 1;
    payloadData['resetmiles'] = 'N';
    if (_.isNil(payloadData['weekEndDate'])) {
      payloadData['weekEndDate'] = new Date(data.weekEndDate);
    }
    if (_.isNil(payloadData['milescomplete'])) {
      payloadData['milescomplete'] = 0;
    }

    if (purchaseOrder?.poLines?.length > 0) {
      purchaseOrder.poLines.map((el, id) => {
        if (purchaseOrder.poid == el.poid) {
          if (el.pricingmethod == 'EWO') {
            payloadData['ewoTimesheet'] = 'true';
          }
        }
      });
    }

    if (data?.uvl) {
      payloadData['caastatus'] =
        TimesheetStatus.UVL_TIMESHEET_SAVED_NOT_SUBMITTED;
      payloadData['estimate'] = 'Y';
      payloadData['uvlMonth'] = getMonth(startDate) + 1;
      payloadData['uvlYear'] = getYear(startDate);
    } else if (BuPath.DIST.includes(purchaseOrder?.buPath) && acceptTimesheet) {
      payloadData['caastatus'] =
        TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED;
    } else {
      payloadData['caastatus'] = TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED;
    }

    if (
      [BuPath.TRANS, ...BuPath.DIST].includes(purchaseOrder?.buPath) ||
      BuPath.GEN.includes(purchaseOrder?.buPath)
    ) {
      payloadData['timesheetApprovals'] = _.cloneDeep(
        timesheet?.timesheetApprovals,
      );
    }

    regenerateFormValues(
      payloadData,
      purchaseOrder,
      lookup?.purchaseOrderObject,
    );
    transformEmptyStringToNull(payloadData);

    delete payloadData.uvlEstimateNo;
    delete payloadData.unitPriceMarkup;
    delete payloadData.union;

    if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
      payloadData['workStartDate'] = getMidnightDate(weekEndDate, 6, 'sub');
      payloadData['workEndDate'] = getMidnightDate(weekEndDate);
    }

    // Convert date object to date string
    payloadData['workStartDate'] = format(
      payloadData['workStartDate'],
      'yyyy-MM-dd',
    );
    payloadData['workEndDate'] = format(
      payloadData['workEndDate'],
      'yyyy-MM-dd',
    );
    try {
      payloadData['weekEndDate'] = format(
        payloadData['weekEndDate'],
        'yyyy-MM-dd',
      );
    } catch (e) {}

    if (!isEmptyVal(timesheet?.recordNmbr)) {
      // Update timesheet
      // Add primary key to the child tables to update the existing
      // data instead of creating a new one
      timesheet[SERVICE_TYPES.LABOR]?.forEach((obj, index) => {
        if (index < payloadData[SERVICE_TYPES.LABOR].length) {
          payloadData[SERVICE_TYPES.LABOR][index].timeLaborNmbr =
            obj.timeLaborNmbr;
        }
      });
      timesheet[SERVICE_TYPES.EQUIPMENT]?.forEach((obj, index) => {
        if (index < payloadData[SERVICE_TYPES.EQUIPMENT].length) {
          payloadData[SERVICE_TYPES.EQUIPMENT][index].timeEquipmentNmbr =
            obj.timeEquipmentNmbr;
        }
      });
      timesheet[SERVICE_TYPES.REIMBURSABLE_MARKUP]?.forEach((obj, index) => {
        if (index < payloadData[SERVICE_TYPES.REIMBURSABLE_MARKUP].length) {
          payloadData[SERVICE_TYPES.REIMBURSABLE_MARKUP][
            index
          ].timeExpenseNmbr = obj.timeExpenseNmbr;
        }
      });
      timesheet[SERVICE_TYPES.UNIT_PRICE_TASK]?.forEach((obj, index) => {
        if (index < payloadData[SERVICE_TYPES.UNIT_PRICE_TASK].length) {
          payloadData[SERVICE_TYPES.UNIT_PRICE_TASK][index].timeTaskNmbr =
            obj.timeTaskNmbr;
        }
      });
      timesheet[SERVICE_TYPES.LUMPSUM]?.forEach((obj, index) => {
        if (index < payloadData[SERVICE_TYPES.LUMPSUM].length) {
          payloadData[SERVICE_TYPES.LUMPSUM][index].timesheetLumpsumNmbr =
            obj.timesheetLumpsumNmbr;
        }
      });
      timesheet[SERVICE_TYPES.CU]?.forEach((obj, index) => {
        if (index < payloadData[SERVICE_TYPES.CU].length) {
          payloadData[SERVICE_TYPES.CU][index].timeCuNmbr = obj.timeCuNmbr;
        }
      });
      timesheet['timesheetAccounts']?.forEach((obj, index) => {
        if (
          payloadData.hasOwnProperty('timesheetAccounts') &&
          index < payloadData['timesheetAccounts']?.length
        ) {
          payloadData['timesheetAccounts'][index].timeAccountNmbr =
            obj.timeAccountNmbr;
        }
      });
      timesheet['timesheetApprovals']?.forEach((obj, index) => {
        if (
          payloadData.hasOwnProperty('timesheetApprovals') &&
          index < payloadData['timesheetApprovals']?.length
        ) {
          payloadData['timesheetApprovals'][index].timeApprovalNmbr =
            obj.timeApprovalNmbr;
        }
      });
      timesheet[SERVICE_TYPES.PRODUCTION_HOURS]?.forEach((obj, index) => {
        if (index < payloadData[SERVICE_TYPES.PRODUCTION_HOURS].length) {
          payloadData[SERVICE_TYPES.PRODUCTION_HOURS][index].timeTaskNmbr =
            obj.timeTaskNmbr;
        }
      });

      const payloadDataCloned = _.cloneDeep(payloadData);
      if (BuPath.GEN.includes(purchaseOrder?.buPath)) {
        payloadDataCloned.timesheetAccounts =
          _.cloneDeep(timesheet?.timesheetAccounts) ?? [];
      }

      const isValid = validateCostLines(payloadDataCloned['timesheetAccounts']);
      if (!isValid && acceptTimesheet) {
        displayError('Accounting Details can not be blank.');
        return;
      }

      delete payloadDataCloned['submittedByAep'];
      let canProceedToSave = true;
      let payloadClonedForCostActivityCheck = _.cloneDeep(payloadDataCloned);
      if (
        payloadDataCloned.authNmbr &&
        payloadDataCloned.timesheetUptasks?.length
      ) {
        payloadClonedForCostActivityCheck.timesheetUptasks = [];
      } else if (
        payloadDataCloned.bupath === 'FORESTRY' &&
        payloadDataCloned.authType === AuthType.TIME_AND_MATERIAL &&
        payloadDataCloned.timesheetProductionhours?.length
      ) {
        payloadClonedForCostActivityCheck.timesheetProductionhours = [];
      }
      timesheet.classificationList.forEach(item => {
        payloadClonedForCostActivityCheck[item.name].forEach(tsItem => {
          if (
            !_.find(lookup?.costActivityBySTOptions[item.name], {
              value: tsItem.costactivityid,
            })
          )
            canProceedToSave = false;
          return;
        });
      });
      if (!canProceedToSave) {
        displayError('please select cost activity');
        return;
      }
      dispatch(
        // @ts-ignore
        updateTimesheet({
          data: payloadDataCloned,
          recordNmbr: timesheet.recordNmbr,
          caastatus: timesheet?.caastatus,
          callback: async recordNumber => {
            dispatch(
              fetchEquipmentIdList({
                poid: purchaseOrder.poid,
                callback: async eqIdList => {
                  if (uploadedFilesArray.length) {
                    await uploadFilesToServer(recordNumber);
                  }
                  if (payloadData.uvl) {
                    dispatch(setTimesheetErrorObj({ isUvlSuccess: true }));
                  } else {
                    dispatch(setTimesheetErrorObj({ isSuccess: true }));
                  }
                  if (
                    purchaseOrder?.buPath === BuPath.TRANS &&
                    !workOrderExists
                  ) {
                    displayError(workOrderMsg);
                  }
                  let tempLookup = _.cloneDeep(lookup);
                  tempLookup.equipmentIdOptions = eqIdList;
                  // @ts-ignore
                  dispatch(
                    // @ts-ignore
                    fetchTimesheetDetail({
                      recordNumber: recordNumber,
                      callback: data => {
                        displayServiceTypesDynamically(tempLookup, data);
                        if (submitForm) {
                          confirmSubmitTimesheet();
                        } else if (
                          acceptTimesheet &&
                          BuPath.DIST.includes(purchaseOrder?.buPath)
                        ) {
                          setShowReviewerAcceptModal(true);
                        }
                      },
                    }),
                  );
                },
              }),
            );
          },
        }),
      );
    } else {
      // Create a new timesheet
      if (
        BuPath.DIST.includes(purchaseOrder?.buPath) &&
        isJobAuthUnitPriceTask
      ) {
        const activityNum = purchaseOrder?.costActivities?.length
          ? purchaseOrder.costActivities[0].activitynum
          : null;
        const description = purchaseOrder?.costActivities?.length
          ? purchaseOrder.costActivities[0].description
          : null;
        const costActivityId = purchaseOrder?.costActivities?.length
          ? purchaseOrder.costActivities[0].costactivityid
          : null;
        const polineNum = purchaseOrder?.costActivities?.length
          ? purchaseOrder.costActivities[0].polinenum
          : null;
        payloadData[SERVICE_TYPES.UNIT_PRICE_TASK].forEach(obj => {
          obj.activitynum = activityNum;
          obj.activityDescription = description;
          obj.costactivityid = costActivityId;
          obj.polinenum = polineNum;
        });
      } else if (
        payloadData.bupath === 'FORESTRY' &&
        payloadData.authType === AuthType.TIME_AND_MATERIAL
      ) {
        const activityNum = purchaseOrder?.costActivities?.length
          ? purchaseOrder.costActivities[0].activitynum
          : null;
        const description = purchaseOrder?.costActivities?.length
          ? purchaseOrder.costActivities[0].description
          : null;
        const costActivityId = purchaseOrder?.costActivities?.length
          ? purchaseOrder.costActivities[0].costactivityid
          : null;
        payloadData[SERVICE_TYPES.PRODUCTION_HOURS].forEach(obj => {
          obj.activitynum = activityNum;
          obj.activityDescription = description;
          obj.costactivityid = costActivityId;
        });
      }

      const payloadDataCloned = _.cloneDeep(payloadData);
      payloadDataCloned['submittedByAep'] = user.userType === UserType.AEP;
      let tSheetAccts = payloadDataCloned.timesheetAccounts;

      if (BuPath.GEN.includes(payloadData['bupath'])) {
        tSheetAccts = [];
        let costActivityIds = [];

        payloadData[SERVICE_TYPES.EQUIPMENT].forEach(element => {
          if (!costActivityIds.includes(element.costactivityid))
            costActivityIds.push(element.costactivityid);
        });
        payloadData[SERVICE_TYPES.LABOR].forEach(element => {
          if (!costActivityIds.includes(element.costactivityid))
            costActivityIds.push(element.costactivityid);
        });
        payloadData[SERVICE_TYPES.REIMBURSABLE_MARKUP].forEach(element => {
          if (!costActivityIds.includes(element.costactivityid))
            costActivityIds.push(element.costactivityid);
        });
        payloadData[SERVICE_TYPES.UNIT_PRICE_TASK].forEach(element => {
          if (!costActivityIds.includes(element.costactivityid))
            costActivityIds.push(element.costactivityid);
        });
        payloadData[SERVICE_TYPES.LUMPSUM].forEach(element => {
          if (!costActivityIds.includes(element.costactivityid))
            costActivityIds.push(element.costactivityid);
        });

        if (costActivityIds.length) {
          costActivityIds.forEach((element, idx) => {
            let costActivitie = purchaseOrder.costActivities.find(
              obj => obj.costactivityid === element,
            );
            if (costActivitie) {
              let polineData = purchaseOrder.poLines.find(
                obj => obj.polinenum == costActivitie.polinenum,
              );
              let gldebitacctData = polineData?.gldebitacct?.split('-');
              if (!isEmptyVal(gldebitacctData) && gldebitacctData.length) {
                const timeSheetAccountsObj = {
                  glbu: gldebitacctData[0] ?? ' ',
                  dept: gldebitacctData[1] ?? ' ',
                  accountNmbr: gldebitacctData[2] ?? ' ',
                  workorder: gldebitacctData[3] ?? ' ',
                  projectBusinessUnit: gldebitacctData[4] ?? ' ',
                  abmsProject: gldebitacctData[5] ?? ' ',
                  abmsActivity: gldebitacctData[6] ?? ' ',
                  costComponent: gldebitacctData[7] ?? ' ',
                  statejuris: gldebitacctData[8] ?? ' ',
                  productcode: gldebitacctData[9] ?? ' ',
                  resourcesubcategory: gldebitacctData[10] ?? ' ',
                };
                tSheetAccts.push(timeSheetAccountsObj);
              }
            }
          });
        }
      }

      payloadDataCloned.timesheetAccounts = tSheetAccts;

      /****************************************************** */
      /** Remove pk of child tables in case the data is copied
      /** by duplicating a timesheet
      /***************************************************** */
      timesheet[SERVICE_TYPES.LABOR]?.forEach((obj, index) => {
        if (index < payloadDataCloned[SERVICE_TYPES.LABOR].length) {
          delete payloadDataCloned[SERVICE_TYPES.LABOR][index].timeLaborNmbr;
        }
      });
      timesheet[SERVICE_TYPES.EQUIPMENT]?.forEach((obj, index) => {
        if (index < payloadDataCloned[SERVICE_TYPES.EQUIPMENT].length) {
          delete payloadDataCloned[SERVICE_TYPES.EQUIPMENT][index]
            .timeEquipmentNmbr;
        }
      });
      timesheet[SERVICE_TYPES.REIMBURSABLE_MARKUP]?.forEach((obj, index) => {
        if (
          index < payloadDataCloned[SERVICE_TYPES.REIMBURSABLE_MARKUP].length
        ) {
          delete payloadDataCloned[SERVICE_TYPES.REIMBURSABLE_MARKUP][index]
            .timeExpenseNmbr;
        }
      });
      timesheet[SERVICE_TYPES.UNIT_PRICE_TASK]?.forEach((obj, index) => {
        if (index < payloadDataCloned[SERVICE_TYPES.UNIT_PRICE_TASK].length) {
          delete payloadDataCloned[SERVICE_TYPES.UNIT_PRICE_TASK][index]
            .timeTaskNmbr;
        }
      });
      timesheet[SERVICE_TYPES.LUMPSUM]?.forEach((obj, index) => {
        if (index < payloadDataCloned[SERVICE_TYPES.LUMPSUM].length) {
          delete payloadDataCloned[SERVICE_TYPES.LUMPSUM][index]
            .timesheetLumpsumNmbr;
        }
      });
      timesheet['timesheetAccounts']?.forEach((obj, index) => {
        if (
          payloadDataCloned.hasOwnProperty('timesheetAccounts') &&
          index < payloadDataCloned['timesheetAccounts']?.length
        ) {
          delete payloadDataCloned['timesheetAccounts'][index].timeAccountNmbr;
        }
      });
      timesheet['timesheetApprovals']?.forEach((obj, index) => {
        if (
          payloadDataCloned.hasOwnProperty('timesheetApprovals') &&
          index < payloadDataCloned['timesheetApprovals']?.length
        ) {
          delete payloadDataCloned['timesheetApprovals'][index]
            .timeApprovalNmbr;
        }
      });
      timesheet[SERVICE_TYPES.PRODUCTION_HOURS]?.forEach((obj, index) => {
        if (index < payloadDataCloned[SERVICE_TYPES.PRODUCTION_HOURS].length) {
          delete payloadDataCloned[SERVICE_TYPES.PRODUCTION_HOURS][index]
            .timeTaskNmbr;
        }
      });
      /***************************************************************** */

      dispatch(
        // @ts-ignore
        saveTimesheet({
          data: [payloadDataCloned],
          callback: async data => {
            setNewTimesheet(false);
            dispatch(
              fetchEquipmentIdList({
                poid: purchaseOrder.poid,
                callback: async eqIdList => {
                  if (uploadedFilesArray.length) {
                    await uploadFilesToServer(data.recordNmbr);
                  }
                  let tempLookup = _.cloneDeep(lookup);
                  tempLookup.equipmentIdOptions = eqIdList;
                  // @ts-ignore
                  dispatch(
                    // @ts-ignore
                    fetchTimesheetDetail({
                      recordNumber: data.recordNmbr,
                      callback: timesheetData => {
                        displayServiceTypesDynamically(
                          tempLookup,
                          timesheetData,
                        );
                        if (submitForm) {
                          confirmSubmitTimesheet();
                        }
                      },
                    }),
                  );
                  submitForm && setShowModal(true);
                },
              }),
            );
          },
        }),
      );
    }
  };

  const onError = data => {
    console.log('error', data);
    if (_.size(data) === 1 && data.hasOwnProperty('comments')) {
      // Don't display error message
    } else {
      displayError('Please fix the validation error(s) before proceeding.');
    }
  };

  useEffect(() => {
    if (timesheet.isSuccess) {
      dispatch(setTimesheetSuccess(false));
      if (location.pathname.includes('new')) {
        navigate(`/${ROUTES_STRING.timesheets}/${timesheet.recordNmbr}`);
      }
    }

    if (timesheetErrorObj.isSuccess) {
      if (purchaseOrder?.buPath === BuPath.TRANS && !workOrderExists) {
        displayError(workOrderMsg);
      }
      dispatch(setTimesheetErrorObj({ isSuccess: false }));
      displaySuccess('Successfully saved the timesheet');
    } else if (timesheetErrorObj.isUvlSuccess) {
      if (purchaseOrder?.buPath === BuPath.TRANS && !workOrderExists) {
        displayError(workOrderMsg);
      }
      dispatch(setTimesheetErrorObj({ isUvlSuccess: false }));
      displaySuccess('Successfully saved UVL timesheet');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheet.isSuccess, timesheetErrorObj]);

  useEffect(() => {
    const recordNumArray = location.pathname.split('/');
    if (recordNumArray?.length) {
      let tSEntitlement = 'CAA-AEP-Timesheet-Approvers';

      if (BuPath.GEN.includes(purchaseOrder?.buPath)) {
        // Pass
      } else if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
        if (user.userType === UserType.AEP) {
          tSEntitlement = 'CAA-Timesheet-Approver';
        } else {
          tSEntitlement = 'CAA-AEP-Timesheet-Approvers';
        }
      } else if (BuPath.TRANS === purchaseOrder?.buPath) {
        if (user.userType === UserType.AEP) {
          tSEntitlement = 'CAA-Timesheet-Approver';
        } else {
          tSEntitlement = 'CAA-AEP-Timesheet-Approvers';
        }
      }

      let recordNum = recordNumArray[recordNumArray.length - 1];
      if (recordNum === 'new' && isEmptyVal(location?.state)) {
        /* ****************/
        /* New Timesheet */
        /* **************/
        setNewTimesheet(true);

        dispatch(
          // @ts-ignore
          fetchUserEntitlements({
            entitlement: tSEntitlement,
            poid: purchaseOrder?.poid,
            enteredBy: timesheet?.enteredBy,
            callback: entitlements => {
              const startDate = getMidnightDate(new Date(), 6, 'sub');
              const endDate = getMidnightDate(new Date());
              setStartDate(startDate);
              setEndDate(endDate);
              setValue('workStartDate', startDate);
              setValue('workEndDate', endDate);

              if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
                const weekEndDate = getValues('weekEndDate');
                const workStartDate = getMidnightDate(weekEndDate, 6, 'sub');
                const month = getMonth(weekEndDate) + 1; // months are 0 indexed.
                const day = getDate(weekEndDate);
                const dayNum = getDay(weekEndDate);

                if (month === 12 && day >= 25 && dayNum !== Day.SATURDAY) {
                  set(weekEndDate, { date: 31 });
                }

                setWeekEndDate(weekEndDate);
                setValue('weekEndDate', weekEndDate);
                setValue('workStartDate', workStartDate);
                setValue('workEndDate', weekEndDate);
                setIsLoading(true);
                dispatch(
                  // @ts-ignore
                  fetchPORates({
                    poId: purchaseOrder?.poid,
                    workStartDate: format(workStartDate, 'yyyy-MM-dd'),
                    workEndDate: format(weekEndDate, 'yyyy-MM-dd'),
                    isZeroLineCostHidden: true,
                    callback: respData => {
                      displayServiceTypesDynamically(
                        respData,
                        null,
                        entitlements,
                      );
                      setIsLoading(false);
                    },
                  }),
                );
                if (purchaseOrder.opco !== '') {
                  dispatch(
                    // @ts-ignore
                    fetchOperatingCompany({
                      ponum: purchaseOrder?.ponum,
                      callback: responseData => {
                        const filteredOpco = responseData?.rows?.filter(
                          res => res.opcostate === purchaseOrder.opco,
                        );

                        setDefaultOpco(filteredOpco[0]?.ocNmbr);
                        setValue('ocNmbr', filteredOpco[0]?.ocNmbr);
                        dispatch(
                          // @ts-ignore
                          fetchWorkArea({
                            ponum: purchaseOrder?.ponum,
                            opco: filteredOpco[0]?.ocNmbr,
                          }),
                        );
                      },
                    }),
                  );
                }
              } else {
                handleDateError(startDate, null);
                handleDateError(null, endDate);
                setIsLoading(true);
                dispatch(
                  // @ts-ignore
                  fetchPORates({
                    poId: purchaseOrder?.poid,
                    workStartDate: format(startDate, 'yyyy-MM-dd'),
                    workEndDate: format(endDate, 'yyyy-MM-dd'),
                    isZeroLineCostHidden: true,
                    callback: respData => {
                      displayServiceTypesDynamically(
                        respData,
                        null,
                        entitlements,
                      );
                      setIsLoading(false);
                    },
                  }),
                );
              }

              if (BuPath.TRANS.includes(purchaseOrder?.buPath)) {
                const parts = purchaseOrder?.poLines[0].gldebitacct.split('-');
                const accountingObject = {
                  workorder: parts[3],
                  abmsProject: parts[5],
                  accountNmbr: parts[2],
                  projectBusinessUnit: parts[4],
                  glbu: parts[0],
                  dept: parts[1],
                  costComponent: parts[7],
                  abmsActivity: parts[6],
                };
                setValue('timesheetAccounts', [accountingObject]);
                setWorkOrderExists(true);
              }

              dispatch(
                // @ts-ignore
                fetchTimesheetDates({
                  poid: purchaseOrder?.poid,
                  callback: respData => {
                    handleDuplicateDate(startDate, endDate);
                  },
                }),
              );
            },
          }),
        );
      } else {
        /* ************************************************* */
        /* Existing record, fetch timesheet by record number */
        /* ************************************************* */
        if (isEmptyVal(location?.state)) {
          setNewTimesheet(false);
        } else if (
          !isEmptyVal(location?.state) &&
          location?.state?.isDuplicateTimesheet
        ) {
          recordNum = location?.state?.recordNmbr;
          setNewTimesheet(true);
        }

        dispatch(
          // @ts-ignore
          fetchTimesheetDetail({
            recordNumber: recordNum,
            callback: async data => {
              let enteredByQParam = '';
              if (
                BuPath.DIST.includes(purchaseOrder?.buPath) ||
                BuPath.TRANS === purchaseOrder?.buPath
              ) {
                enteredByQParam = data?.enteredBy;
              }

              if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
                dispatch(fetchCrewType());
                dispatch(fetchWorkType());
                dispatch(fetchMaterialStatus());

                dispatch(
                  fetchJobAuthorizations({
                    ponum: purchaseOrder?.ponum,
                    limit: 500,
                    jobauthorizationid: data?.authNmbr,
                  }),
                );
              }

              dispatch(
                // @ts-ignore
                fetchUserEntitlements({
                  entitlement: tSEntitlement,
                  poid: purchaseOrder?.poid,
                  enteredBy: enteredByQParam,
                  callback: entitlements => {
                    const {
                      workStartDate,
                      workEndDate,
                      weekEndDate,
                      comments,
                      vendorTimesheet,
                      contRefNumeric,
                      uvl,
                      crewType,
                      crewNmbr,
                      workAreaId,
                      worktype,
                      ocNmbr,
                      masterProjectNumber,
                      authType,
                      authNmbr,
                      circuitnum,
                      subcircuit,
                      milescomplete,
                      timesheetAccounts,
                      wr,
                      jobDescription,
                      jobCity,
                      materialStatusId,
                    } = data;
                    // Set form fields
                    setValue('comments', comments);
                    setValue('contRefNumeric', contRefNumeric);
                    setValue('vendorTimesheet', vendorTimesheet);
                    setValue('uvl', uvl);
                    setValue('uvlEstimateNo', !data?.uvl);
                    if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
                      if (!isEmptyVal(authNmbr) && !isEmptyVal(authType)) {
                        setIsJobAuthUnitPriceTask(true);
                      }

                      const wkEndDate = getMidnightDate(weekEndDate);
                      const wkStartate = getMidnightDate(workStartDate);
                      setWeekEndDate(wkEndDate);
                      setStartDate(wkStartate);
                      setEndDate(wkEndDate);
                      setValue('weekEndDate', wkEndDate);

                      let mlsComplete = isNaN(parseFloat(milescomplete))
                        ? ''
                        : parseFloat(milescomplete);
                      mlsComplete = mlsComplete === 'null' ? null : mlsComplete;
                      setValue('milescomplete', String(mlsComplete));

                      setValue('wr', wr);
                      setValue('jobDescription', jobDescription);
                      setValue('jobCity', jobCity);

                      const tSheetAccounts = _.cloneDeep(timesheetAccounts);
                      const accNmber = isNaN(
                        parseInt(tSheetAccounts?.[0]?.['accountNmbr']),
                      )
                        ? null
                        : parseInt(tSheetAccounts?.[0]?.['accountNmbr']);
                      if (tSheetAccounts?.length) {
                        tSheetAccounts[0]['accountNmbr'] = accNmber;
                      }
                      setValue('timesheetAccounts', tSheetAccounts);

                      setValue('crewType', crewType);
                      setValue('crewNmbr', crewNmbr);
                      setValue('workAreaId', workAreaId);
                      setValue('worktype', worktype);
                      setValue('ocNmbr', ocNmbr);
                      setValue('masterProjectNumber', masterProjectNumber);
                      setValue('authType', authType);
                      setValue('authNmbr', authNmbr);
                      setValue('circuitnum', circuitnum);
                      setValue('subcircuit', subcircuit);
                      setValue('materialStatusId', materialStatusId);
                      dispatch(
                        // @ts-ignore
                        fetchJobAuthorization({
                          jobAuthorizationId: authNmbr,
                          callback: responseData => {
                            dispatch(
                              // @ts-ignore
                              fetchCircuit({
                                workAreaId: workAreaId,
                                poNum: purchaseOrder?.ponum,
                                authNmbr: authNmbr,
                                opco: getValues('ocNmbr'),
                                limit: -1,
                              }),
                            );
                          },
                        }),
                      );

                      setIsLoading(true);
                      let isZeroLineCostHidden = true;
                      if (
                        [
                          TimesheetStatus.TIMESHEET_APPROVED,
                          TimesheetStatus.TIMESHEET_INVOICED,
                          TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
                          TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED,
                          TimesheetStatus.TIMESHEET_CANCELLED,
                        ].includes(data.caastatus) &&
                        !location?.state?.isDuplicateTimesheet
                      ) {
                        isZeroLineCostHidden = false;
                      }
                      dispatch(
                        // @ts-ignore
                        fetchPORates({
                          poId: data?.poid,
                          workStartDate: format(
                            getMidnightDate(weekEndDate, 6, 'sub'),
                            'yyyy-MM-dd',
                          ),
                          workEndDate: format(
                            getMidnightDate(weekEndDate),
                            'yyyy-MM-dd',
                          ),
                          isZeroLineCostHidden: isZeroLineCostHidden,
                          callback: respData => {
                            displayServiceTypesDynamically(
                              respData,
                              data,
                              entitlements,
                            );
                            setIsLoading(false);
                          },
                        }),
                      );
                    } else {
                      if (purchaseOrder?.buPath === BuPath.TRANS) {
                        const tSheetAccounts = _.cloneDeep(timesheetAccounts);
                        if (tSheetAccounts && tSheetAccounts[0]) {
                          const accNmber = isNaN(
                            parseInt(tSheetAccounts[0]['accountNmbr']),
                          )
                            ? null
                            : parseInt(tSheetAccounts[0]['accountNmbr']);
                          if (tSheetAccounts?.length) {
                            tSheetAccounts[0]['accountNmbr'] = accNmber;
                          }
                          setValue('timesheetAccounts', tSheetAccounts);
                          if (tSheetAccounts[0].workorder) {
                            setWorkOrderExists(true);
                          }
                        }
                      } else {
                        setValue('timesheetAccounts', []);
                      }
                      const startDate = getMidnightDate(workStartDate);
                      setStartDate(startDate);
                      setValue('workStartDate', startDate);

                      const endDate = getMidnightDate(workEndDate);
                      setEndDate(endDate);
                      setValue('workEndDate', endDate);

                      handleDateError(startDate);
                      handleDateError(null, endDate);

                      setIsLoading(true);
                      let isZeroLineCostHidden = true;
                      if (
                        [
                          TimesheetStatus.TIMESHEET_APPROVED,
                          TimesheetStatus.TIMESHEET_INVOICED,
                          TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
                          TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED,
                          TimesheetStatus.TIMESHEET_CANCELLED,
                        ].includes(data.caastatus) &&
                        !location?.state?.isDuplicateTimesheet
                      ) {
                        isZeroLineCostHidden = false;
                      }
                      dispatch(
                        // @ts-ignore
                        fetchPORates({
                          poId: data?.poid,
                          workStartDate: format(
                            getMidnightDate(workStartDate),
                            'yyyy-MM-dd',
                          ),
                          workEndDate: format(
                            getMidnightDate(workEndDate),
                            'yyyy-MM-dd',
                          ),
                          isZeroLineCostHidden: isZeroLineCostHidden,
                          callback: respData => {
                            displayServiceTypesDynamically(
                              respData,
                              data,
                              entitlements,
                            );
                            setIsLoading(false);
                          },
                        }),
                      );
                    }

                    transformNullToEmptyString(allFormFields);

                    dispatch(
                      // @ts-ignore
                      fetchTimesheetDates({
                        poid: data?.poid,
                        callback: respData => {
                          handleDuplicateDate(startDate, endDate);
                        },
                      }),
                    );
                    dispatch(fetchInvoiceDates(data?.poid));

                    if (
                      !isEmptyVal(location?.state) &&
                      location?.state?.isDuplicateTimesheet
                    ) {
                      dispatch(
                        setTimesheetDetail({
                          recordNmbr: '',
                          caastatus:
                            TimesheetStatus.TIMESHEET_ENTERED_NOT_SAVED,
                        }),
                      );
                    }

                    if (purchaseOrder.opco !== '') {
                      dispatch(
                        // @ts-ignore
                        fetchOperatingCompany({
                          ponum: purchaseOrder?.ponum,
                          callback: responseData => {
                            const filteredOpco = responseData?.rows?.filter(
                              res => res.opcostate === purchaseOrder.opco,
                            );

                            _.isNull(ocNmbr) &&
                              setDefaultOpco(filteredOpco[0]?.ocNmbr);
                            setValue('ocNmbr', filteredOpco[0]?.ocNmbr);
                            dispatch(
                              // @ts-ignore
                              fetchWorkArea({
                                ponum: purchaseOrder?.ponum,
                                opco: filteredOpco[0]?.ocNmbr,
                              }),
                            );
                          },
                        }),
                      );
                    }
                  },
                }),
              );
            },
          }),
        );
      }
    }
    dispatch(setActivePage(Page.TIMESHEET));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (
      BuPath.GEN.includes(purchaseOrder?.buPath) &&
      timesheet?.caastatus === TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED
    ) {
      getTimesheetApproverOptions(timesheet?.classificationList, timesheet);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheet?.caastatus]);

  useEffect(() => {
    setValue(`timesheetLabors`, []);
    setValue(`timesheetReimbursables`, []);
    setValue(`timesheetEquipments`, []);
    setValue(`timesheetUptasks`, []);
    setValue(`timesheetCus`, []);
    setValue(`timesheetLumpsums`, []);
  }, []);

  useEffect(() => {
    const prodHourIndex = timesheet?.classificationList.findIndex(
      obj => obj.name === SERVICE_TYPES.PRODUCTION_HOURS,
    );
    deleteClassification(prodHourIndex);
    dispatch(resetTriggerClassificationList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerClassificationList]);

  const getTimesheetApproverOptions = (
    classificationList,
    timesheetObject,
    entitlements = null,
    timesheetApprovalByCostActivity,
  ) => {
    let timesheetApproverOptions = [{ label: 'Select', value: '' }];
    const entitlementList = isEmptyVal(entitlements)
      ? lookup?.userEntitlements
      : entitlements;
    if (purchaseOrder?.buPath === BuPath.TRANS) {
      entitlementList?.forEach(userEntitlement =>
        timesheetApproverOptions.push({
          label: userEntitlement.name,
          value: userEntitlement.userid,
        }),
      );

      const poLinesApprovers = [];
      purchaseOrder?.poLines?.forEach(obj => {
        if (!isEmptyVal(obj)) {
          const userObject = lookup?.userEntitlements.find(
            userObj => userObj.userid === obj?.timesheetapprover,
          );
          if (!isEmptyVal(userObject)) {
            poLinesApprovers.push({
              label: userObject?.name,
              value: userObject?.userid,
            });
          }
        }
      });

      if (poLinesApprovers.length) {
        timesheetApproverOptions = [
          ...timesheetApproverOptions,
          ...poLinesApprovers,
        ];
      }

      const tSheetApprovals =
        _.isEmpty(timesheetObject?.timesheetApprovals) ||
        isEmptyVal(timesheetObject?.timesheetApprovals)
          ? [
              {
                approver: '',
                approved: 'P',
                bupath: purchaseOrder.buPath,
              },
            ]
          : timesheetObject?.timesheetApprovals;
      dispatch(setTimesheetApprovals(_.cloneDeep(tSheetApprovals)));
    } else if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
      entitlementList?.forEach(userEntitlement =>
        timesheetApproverOptions.push({
          label: userEntitlement.name,
          value: userEntitlement.userid,
        }),
      );

      const tSheetApprovals =
        _.isEmpty(timesheetObject?.timesheetApprovals) ||
        isEmptyVal(timesheetObject?.timesheetApprovals)
          ? [
              {
                approver: '',
                approved: 'P',
                bupath: purchaseOrder.buPath,
              },
            ]
          : timesheetObject?.timesheetApprovals;

      dispatch(setTimesheetApprovals(_.cloneDeep(tSheetApprovals)));
    } else if (BuPath.GEN.includes(purchaseOrder?.buPath)) {
      // Add approvers from cost activity
      let activityIdList = [];
      classificationList.forEach(obj => {
        obj.values.forEach(row => {
          const activityIdFilteredList = row.filter(
            actNum => actNum.name === 'activitynum',
          );

          if (activityIdFilteredList.length) {
            activityIdList.push(activityIdFilteredList[0].value);
          }
        });
      });
      const tSheetApprovals = [];
      activityIdList = _.uniq(activityIdList);
      activityIdList.forEach(costActivityId => {
        const poCostActivityList = purchaseOrder.costActivities?.filter(
          obj => String(obj.costactivityid) === String(costActivityId),
        );
        if (poCostActivityList.length) {
          let obj = poCostActivityList[0];
          if (timesheetApprovalByCostActivity) {
            timesheetApprovalByCostActivity.forEach((item, index) => {
              if (
                ![
                  TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
                  TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
                  TimesheetStatus.TIMESHEET_APPROVED,
                ].includes(timesheetObject?.caastatus)
              ) {
                let index = _.findIndex(timesheetApprovalByCostActivity, {
                  source: obj.activitynum,
                });
                if (index >= 0 && obj?.timesheetapprover) {
                  timesheetApprovalByCostActivity[index].approver =
                    obj?.timesheetapprover?.toLowerCase();
                }
                // else if (index >= 0 && !obj?.timesheetapprover) {
                //   // timesheetApprovalByCostActivity[index].approver = null;
                // }
              }
            });
            dispatch(
              setTimesheetApprovals(
                _.cloneDeep(
                  _.uniqBy(timesheetApprovalByCostActivity, 'source'),
                ),
              ),
            );
          } else {
            if (
              ![
                TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
                TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
                TimesheetStatus.TIMESHEET_APPROVED,
              ].includes(timesheetObject?.caastatus)
            ) {
              let actNum = _.find(timesheetObject.timesheetApprovals, {
                source: obj.activitynum,
              });
              // if (actNum) {
              tSheetApprovals.push({
                bupath: BuPathEnum.GEN,
                approver: actNum?.approver
                  ? actNum?.approver?.toLowerCase()
                  : obj?.timesheetapprover?.toLowerCase(),
                role: 'costActivityApprover',
                approved: 'P',
                source: actNum?.source,
              });
              // }
              dispatch(
                setTimesheetApprovals(
                  _.cloneDeep(_.uniqBy(tSheetApprovals, 'source')),
                ),
              );
            }
          }

          if (!isEmptyVal(obj?.timesheetapprover)) {
            // Check for uniqueness
            const approverList = timesheetApproverOptions.filter(
              appr => appr.value === obj.timesheetapprover,
            );
            if (!approverList.length) {
              const approverObj = lookup?.userEntitlements.find(
                uEnt => uEnt.userid === obj.timesheetapprover,
              );
              if (!isEmptyVal(approverObj)) {
                timesheetApproverOptions.push({
                  label: approverObj.name,
                  value: approverObj.userid,
                });
              }
            }
          }
        }
      });

      // Also add entitlement users
      entitlementList?.forEach(userEntitlement =>
        timesheetApproverOptions.push({
          label: userEntitlement.name,
          value: userEntitlement.userid,
        }),
      );
    }

    if (
      timesheetObject?.caastatus &&
      ![
        TimesheetStatus.TIMESHEET_ENTERED_NOT_SAVED,
        TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED,
      ].includes(timesheetObject?.caastatus)
    ) {
      const userObject = timesheetApproverOptions.find(
        obj => obj.value === user?.userId,
      );
      if (isEmptyVal(userObject)) {
        timesheetApproverOptions.push({
          label: user?.userDisplayname,
          value: user?.userId,
        });
      }
    }
    let tApproverOptions = _.cloneDeep(timesheetApproverOptions);
    if (
      timesheetObject?.caastatus ===
      TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED
    ) {
      tApproverOptions = tApproverOptions.filter(
        obj => obj.value !== user?.userId,
      );
    }
    setTimesheetApproverOptions(tApproverOptions);
  };

  const handleTimesheetApproval = (value, index) => {
    const tempTimesheetApprovals = _.cloneDeep(timesheet.timesheetApprovals);
    if (!isEmptyVal(value)) {
      tempTimesheetApprovals[index].approver = value;
      dispatch(setTimesheetApprovals(tempTimesheetApprovals));
    } else {
      tempTimesheetApprovals[index].approver = '';
      dispatch(setTimesheetApprovals(tempTimesheetApprovals));
    }
  };

  const updateFormValues = (srvTypeOptionList, classifications) => {
    srvTypeOptionList.forEach(serviceType => {
      const timesheetObj = classifications.filter(
        obj => obj.name === serviceType,
      );
      const sTypeList = [];
      if (timesheetObj?.length) {
        timesheetObj[0].values.map(valList => {
          const rowObj = {};
          valList.forEach(valObj => {
            const fieldName = valObj['name'];
            rowObj[fieldName] = valObj['value'];
          });
          sTypeList.push({ ...rowObj });
        });
      }
      setValue(`${serviceType}`, sTypeList);
    });
    transformNullToEmptyString(allFormFields);
  };

  const getDynamicCondition = (
    serviceType,
    lookupData,
    timesheetObj,
    otherPOLine,
    lerPOLine,
    upPOLine,
    lumpsumPOLine,
  ) => {
    let condition = false;

    if (otherPOLine) {
      if (
        [
          SERVICE_TYPES.LABOR,
          SERVICE_TYPES.EQUIPMENT,
          SERVICE_TYPES.REIMBURSABLE_MARKUP,
        ].includes(serviceType)
      ) {
        if (
          lookupData?.costActivityBySTOptions[serviceType]?.length <= 1 ||
          lookupData?.rateScheduleOptions[serviceType]?.length <= 1 ||
          lookupData?.codeOptions[serviceType]?.length <= 1
        ) {
          condition = true;
        }
      } else if (serviceType === SERVICE_TYPES.UNIT_PRICE_TASK) {
        if (
          getValues('authType') === AuthType.UNIT_PRICE &&
          !isEmptyVal(getValues('authNmbr'))
        ) {
          condition = false;
        } else {
          if (
            lookupData?.costActivityBySTOptions[serviceType]?.length <= 1 ||
            lookupData?.rateScheduleOptions[serviceType]?.length <= 1 ||
            lookupData?.codeOptions[serviceType]?.length <= 1
          ) {
            condition = true;
          }
        }
      } else if (serviceType === SERVICE_TYPES.CU) {
        if (timesheetObj?.timesheetType?.trim() !== 'F') {
          condition = true;
        } else if (
          lookupData?.costActivityBySTOptions[serviceType]?.length <= 1 ||
          lookupData?.rateScheduleOptions[serviceType]?.length <= 1 ||
          lookupData?.codeOptions[serviceType]?.length <= 1
        ) {
          condition = true;
        } else {
          condition =
            (timesheetObj?.hasOwnProperty(SERVICE_TYPES.CU) &&
              !timesheetObj[SERVICE_TYPES.CU]?.length) ||
            !timesheetObj?.hasOwnProperty(SERVICE_TYPES.CU);
        }
      } else if (serviceType === SERVICE_TYPES.LUMPSUM) {
        if (lookupData?.costActivityBySTOptions[serviceType]?.length <= 1) {
          condition = true;
        }
      } else if (serviceType === SERVICE_TYPES.PRODUCTION_HOURS) {
        if (
          purchaseOrder?.buPath !== 'FORESTRY' ||
          getValues('authType') !== AuthType.TIME_AND_MATERIAL
        ) {
          condition = true;
        }
      }
    } else {
      if (serviceType === SERVICE_TYPES.LABOR) {
        if (
          lookupData?.costActivityBySTOptions[serviceType]?.length <= 1 ||
          lookupData?.rateScheduleOptions[serviceType]?.length <= 1 ||
          lookupData?.codeOptions[serviceType]?.length <= 1
        ) {
          condition = true;
        } else {
          condition = !lerPOLine;
        }
      } else if (serviceType === SERVICE_TYPES.EQUIPMENT) {
        if (
          lookupData?.costActivityBySTOptions[serviceType]?.length <= 1 ||
          lookupData?.rateScheduleOptions[serviceType]?.length <= 1 ||
          lookupData?.codeOptions[serviceType]?.length <= 1
        ) {
          condition = true;
        } else {
          condition = !lerPOLine;
        }
      } else if (serviceType === SERVICE_TYPES.REIMBURSABLE_MARKUP) {
        if (
          lookupData?.costActivityBySTOptions[serviceType]?.length <= 1 ||
          lookupData?.rateScheduleOptions[serviceType]?.length <= 1 ||
          lookupData?.codeOptions[serviceType]?.length <= 1
        ) {
          condition = true;
        } else {
          condition = !lerPOLine;
        }
      } else if (serviceType === SERVICE_TYPES.UNIT_PRICE_TASK) {
        if (
          getValues('authType') === AuthType.UNIT_PRICE &&
          !isEmptyVal(getValues('authNmbr'))
        ) {
          condition = false;
        } else {
          if (
            lookupData?.costActivityBySTOptions[serviceType]?.length <= 1 ||
            lookupData?.rateScheduleOptions[serviceType]?.length <= 1 ||
            lookupData?.codeOptions[serviceType]?.length <= 1
          ) {
            condition = true;
          } else {
            condition = !upPOLine;
          }
        }
      } else if (serviceType === SERVICE_TYPES.CU) {
        if (timesheetObj?.timesheetType?.trim() !== 'F') {
          condition = true;
        } else if (
          lookupData?.costActivityBySTOptions[serviceType]?.length <= 1 ||
          lookupData?.rateScheduleOptions[serviceType]?.length <= 1 ||
          lookupData?.codeOptions[serviceType]?.length <= 1
        ) {
          condition = true;
        } else {
          condition =
            (timesheetObj?.hasOwnProperty(SERVICE_TYPES.CU) &&
              !timesheetObj[SERVICE_TYPES.CU]?.length) ||
            !timesheetObj?.hasOwnProperty(SERVICE_TYPES.CU);
        }
      } else if (serviceType === SERVICE_TYPES.LUMPSUM) {
        if (lookupData?.costActivityBySTOptions[serviceType]?.length <= 1) {
          condition = true;
        } else {
          condition = !lumpsumPOLine;
        }
      } else if (serviceType === SERVICE_TYPES.PRODUCTION_HOURS) {
        if (
          purchaseOrder?.buPath !== 'FORESTRY' ||
          getValues('authType') !== AuthType.TIME_AND_MATERIAL
        ) {
          condition = true;
        }
      }
    }
    return condition;
  };

  const displayServiceTypesDynamically = (
    lookupData,
    timesheetObj = null,
    entitlements = null,
  ) => {
    // Dynamic display of service types in the select dropdown
    //  while adding a new classification
    let tempServiceTypeOptions = _.cloneDeep(serviceTypes);
    let tempServiceTypeOptionList = _.cloneDeep(serviceTypeList);

    let otherPOLine = false;
    let upPOLine = false;
    let lerPOLine = false;
    let lumpsumPOLine = false;
    let poLinesFilter = purchaseOrder?.poLines;
    if (!timesheetObj) {
      poLinesFilter = _.filter(
        purchaseOrder?.poLines,
        item => item.linecost > 0,
      );
    }
    poLinesFilter?.forEach(lineObject => {
      if (isEmptyVal(lineObject?.pricingmethod)) {
        if (purchaseOrder?.pricingMethod === PricingMethod.UNIT_PRICE) {
          upPOLine = true;
        } else if (
          [
            PricingMethod.COST_PLUS,
            PricingMethod.TIME_AND_MATERIAL,
            PricingMethod.HOURLY,
          ].includes(purchaseOrder?.pricingMethod)
        ) {
          lerPOLine = true;
        } else if (
          [PricingMethod.LUMPSUM, PricingMethod.FIXED_PRICE].includes(
            purchaseOrder?.pricingMethod,
          ) ||
          getValues('uvl')
        ) {
          lumpsumPOLine = true;
        } else if (purchaseOrder?.pricingMethod === PricingMethod.OTHER) {
          otherPOLine = true;
        }
      } else {
        if (lineObject?.pricingmethod === PricingMethod.UNIT_PRICE) {
          upPOLine = true;
        } else if (
          [
            PricingMethod.COST_PLUS,
            PricingMethod.TIME_AND_MATERIAL,
            PricingMethod.HOURLY,
          ].includes(lineObject?.pricingmethod)
        ) {
          lerPOLine = true;
        } else if (
          [PricingMethod.LUMPSUM, PricingMethod.FIXED_PRICE].includes(
            lineObject?.pricingmethod,
          ) ||
          getValues('uvl')
        ) {
          lumpsumPOLine = true;
        } else if (lineObject?.pricingmethod === PricingMethod.OTHER) {
          otherPOLine = true;
        }
      }
    });

    const tSheetObject = timesheetObj === null ? timesheet : timesheetObj;

    dispatch(
      // @ts-ignore
      fetchJobAuthorization({
        jobAuthorizationId: tSheetObject.authNmbr,
        callback: jobAuthTaskObj => {
          const jobAuthTasks = jobAuthTaskObj?.jobauthtasks ?? [];
          let timesheetCopy = _.cloneDeep(tSheetObject);
          //WAM-22839 Doing below process if the rates are not found && TS status is 'W'.Also Fetching within the weekenddate
          serviceTypeList.forEach(serviceType => {
            tSheetObject[serviceType]?.map((obj, index) => {
              const rateIdKey = PORateObjectKeyMapping[serviceType];
              const checkRateObject = lookupData?.purchaseOrderObject[
                PORateKeyMapping[serviceType]
              ]?.find(poObj => poObj[rateIdKey] === obj[rateIdKey]);
              //Doing below process if the rates are not found within the effectivetodt
              if (
                isEmptyVal(checkRateObject) &&
                tSheetObject.caastatus == 'W'
              ) {
                let code = POCodeFieldMapping[serviceType];
                if (code) {
                  const findRateObject = _.find(
                    lookupData?.purchaseOrderObject[
                      PORateKeyMapping[serviceType]
                    ],
                    {
                      // effectivetodt: timesheetObj.weekEndDate,
                      poid: tSheetObject.poid,
                      [code]: obj[POCodeMapping[code]],
                    },
                  );
                  if (findRateObject) {
                    timesheetCopy[serviceType][index][
                      PORateObjectKeyMapping[serviceType]
                    ] = findRateObject[PORateObjectKeyMapping[serviceType]];
                  }
                }
              }
            });
          });
          const tempClassifications = generateClassificationList(
            timesheetCopy,
            lookupData,
            jobAuthTasks,
          );
          _.cloneDeep(serviceTypeList).forEach(serviceType => {
            const condition = getDynamicCondition(
              serviceType,
              lookupData,
              tSheetObject,
              otherPOLine,
              lerPOLine,
              upPOLine,
              lumpsumPOLine,
            );
            if (condition) {
              // Delete it
              tempServiceTypeOptions = tempServiceTypeOptions.filter(
                obj => obj.value !== serviceType,
              );
              tempServiceTypeOptionList = tempServiceTypeOptionList.filter(
                sType => sType !== serviceType,
              );
              // Remove already added classification
              const index = _.findIndex(
                tempClassifications,
                obj => obj.name === serviceType,
              );
              if (index >= 0) {
                // Delete classification
                const deletedClassification = tempClassifications.splice(
                  index,
                  1,
                );

                if (errors?.hasOwnProperty(deletedClassification[0].name)) {
                  delete errors[deletedClassification[0].name];
                }

                setValue(`${serviceType}`, []);
                updateFormValues(
                  tempServiceTypeOptionList,
                  tempClassifications,
                );
              }
            }
          });

          setServiceTypeOptions(tempServiceTypeOptions);
          setServiceTypeOptionList(tempServiceTypeOptionList);
          getTimesheetApproverOptions(
            tempClassifications,
            tSheetObject,
            entitlements,
          );

          dispatch(setClassificationList(_.cloneDeep(tempClassifications)));
          updateFormValues(tempServiceTypeOptionList, tempClassifications);
        },
      }),
    );
  };

  const getAddedClassification = () => {
    const addedClassifications = [];
    for (let i = 0; i < timesheet?.classificationList.length; ++i) {
      addedClassifications.push(timesheet?.classificationList[i]['name']);
    }
    return addedClassifications;
  };

  const handleSelectChange = e => {
    // CU service type cannot be added from UI.
    // It comes as a part of storm timesheet via api.
    if (e?.target?.value !== SERVICE_TYPES.CU) {
      addClassification(e.target.value);
      setShowServiceTypes(false);
    }
  };

  const addSelectValues = (
    classification,
    classificationList,
    clIndex,
    objIndex,
  ) => {
    if (classification === SERVICE_TYPES.LUMPSUM) {
      classificationList[clIndex]['values'][objIndex][0]['values'] =
        lookup?.costActivityBySTOptions[classification];
      if (lookup?.costActivityBySTOptions[classification].length === 2) {
        classificationList[clIndex]['values'][objIndex][0]['value'] =
          lookup?.costActivityBySTOptions[classification][1].value;
        const fieldName =
          classificationList[clIndex]['values'][objIndex][0]['name'];
        setValue(
          // @ts-ignore
          `${classification}.${objIndex}.${fieldName}`,
          lookup?.costActivityBySTOptions[classification][1].value,
        );
      }
    } else {
      classificationList[clIndex]['values'][objIndex][0]['values'] =
        lookup?.rateScheduleOptions[classification];
      classificationList[clIndex]['values'][objIndex][1]['values'] =
        lookup?.codeOptions[classification];

      if (classification === SERVICE_TYPES.LABOR) {
        classificationList[clIndex]['values'][objIndex][7]['values'] =
          lookup?.shiftOptions;
      }

      if (
        classification === SERVICE_TYPES.LABOR ||
        classification === SERVICE_TYPES.EQUIPMENT
      ) {
        classificationList[clIndex]['values'][objIndex][2]['values'] =
          lookup?.categoryOptions[classification];
        classificationList[clIndex]['values'][objIndex][3]['values'] =
          lookup?.costActivityBySTOptions[classification];
        if (lookup?.costActivityBySTOptions[classification]?.length === 2) {
          classificationList[clIndex]['values'][objIndex][3]['value'] =
            lookup?.costActivityBySTOptions[classification][1].value;
          const fieldName =
            classificationList[clIndex]['values'][objIndex][3]['name'];
          setValue(
            // @ts-ignore
            `${classification}.${objIndex}.${fieldName}`,
            lookup?.costActivityBySTOptions[classification][1].value,
          );
        }
      } else if (classification === SERVICE_TYPES.UNIT_PRICE_TASK) {
        if (
          getValues('authType') === AuthType.UNIT_PRICE &&
          !isEmptyVal(getValues('authNmbr'))
        ) {
          const codeOptions = [{ label: 'Select', value: '' }];
          timesheet?.jobAuthorization?.jobauthtasks?.forEach(obj => {
            codeOptions.push({ label: obj?.codelabel, value: obj?.taskcode });
          });
          classificationList[clIndex]['values'][objIndex][1]['values'] =
            codeOptions;
        } else {
          classificationList[clIndex]['values'][objIndex][2]['values'] =
            lookup?.costActivityBySTOptions[classification];
          if (lookup?.costActivityBySTOptions[classification].length === 2) {
            classificationList[clIndex]['values'][objIndex][2]['value'] =
              lookup?.costActivityBySTOptions[classification][1].value;
            const fieldName =
              classificationList[clIndex]['values'][objIndex][2]['name'];
            setValue(
              // @ts-ignore
              `${classification}.${objIndex}.${fieldName}`,
              lookup?.costActivityBySTOptions[classification][1].value,
            );
          }
        }
      } else if (classification === SERVICE_TYPES.REIMBURSABLE_MARKUP) {
        classificationList[clIndex]['values'][objIndex][2]['values'] =
          lookup?.costActivityBySTOptions[classification];
        if (lookup?.costActivityBySTOptions[classification].length === 2) {
          classificationList[clIndex]['values'][objIndex][2]['value'] =
            lookup?.costActivityBySTOptions[classification][1].value;
          const fieldName =
            classificationList[clIndex]['values'][objIndex][2]['name'];
          setValue(
            // @ts-ignore
            `${classification}.${objIndex}.${fieldName}`,
            lookup?.costActivityBySTOptions[classification][1].value,
          );
        }
      } else if (
        purchaseOrder?.buPath === 'FORESTRY' &&
        classification === SERVICE_TYPES.PRODUCTION_HOURS &&
        getValues('authType') === AuthType.TIME_AND_MATERIAL
      ) {
        classificationList[clIndex]['values'][objIndex][0]['values'] =
          lookup?.serviceItemsOptions[classification];
        classificationList[clIndex]['values'][objIndex][1]['values'] =
          lookup?.unitOfMeasureOptions[classification];
      }

      if (classification === SERVICE_TYPES.EQUIPMENT) {
        classificationList[clIndex]['values'][objIndex][8]['values'] = lookup
          ?.equipmentIdOptions.length
          ? lookup?.equipmentIdOptions
          : [{ label: 'Select', value: '' }];
      }
    }
  };

  const handleRowClick = classification => {
    // CU service type (or its rows) cannot be added from UI.
    // It comes as a part of storm timesheet via api.
    if (classification !== SERVICE_TYPES.CU) {
      const classificationsCopy = _.cloneDeep(timesheet?.classificationList);
      const index = classificationsCopy.findIndex(
        item => item?.name === classification,
      );

      classificationsCopy[index]['values'].push(
        _.cloneDeep(classificationList[classification]),
      );

      addSelectValues(
        classification,
        classificationsCopy,
        index,
        classificationsCopy[index]['values'].length - 1,
      );

      dispatch(setClassificationList(classificationsCopy));
      updateFormValues(serviceTypeOptionList, classificationsCopy);
    }
  };

  const addClassification = classification => {
    const classificationsCopy = _.cloneDeep(timesheet?.classificationList);
    const classificationValues = [
      _.cloneDeep(classificationList[classification]),
    ];
    classificationsCopy.push({
      name: classification,
      values: classificationValues,
    });

    addSelectValues(
      classification,
      classificationsCopy,
      classificationsCopy.length - 1,
      0,
    );

    dispatch(setClassificationList(classificationsCopy));
    updateFormValues(serviceTypeOptionList, classificationsCopy);
  };

  const deleteClassification = index => {
    if (index >= 0) {
      const tempClassifications = _.cloneDeep(timesheet?.classificationList);
      let deletedClassification = tempClassifications.splice(index, 1);
      let timesheetApproval = _.cloneDeep(timesheet.timesheetApprovals);
      if (BuPath.GEN.includes(purchaseOrder.buPath)) {
        let tempActivityList = [];
        tempClassifications.forEach(item => {
          let activityIndex =
            item.name == 'timesheetReimbursables'
              ? 2
              : item.name == 'timesheetLumpsums'
              ? 0
              : 3;
          if (item.name == 'timesheetUptasks') {
            activityIndex = 2;
          }
          item.values.forEach(activityObj => {
            if (activityObj[activityIndex].value) {
              let activityNum = _.find(activityObj[activityIndex].values, {
                value: Number(activityObj[activityIndex].value),
              });
              if (activityNum) {
                let activity = activityNum?.label?.split('-');
                if (activity?.length) tempActivityList.push(activity[0].trim());
              }
            }
          });
        });
        deletedClassification.forEach((item, index) => {
          let actIndex =
            item.name == 'timesheetReimbursables'
              ? 2
              : item.name == 'timesheetLumpsums'
              ? 0
              : 3;
          if (item.name == 'timesheetUptasks') {
            actIndex = 2;
          }
          item.values.forEach(activity => {
            let costActId = activity[actIndex].value;
            let actLabel = _.find(activity[actIndex].values, {
              value: Number(costActId),
            });
            actLabel = actLabel?.label?.split('-');
            let indexNum = _.findIndex(timesheetApproval, {
              source: actLabel?.length ? actLabel[0].trim() : null,
            });
            if (
              indexNum >= 0 &&
              !tempActivityList.includes(timesheetApproval[indexNum].source)
            )
              timesheetApproval.splice(indexNum, 1);
          });
        });
        dispatch(setTimesheetApprovals(_.cloneDeep(timesheetApproval)));
      }
      if (
        deletedClassification[0]?.name === SERVICE_TYPES.CU ||
        deletedClassification[0]?.name === SERVICE_TYPES.PRODUCTION_HOURS
      ) {
        displayServiceTypesDynamically(lookup);
      }

      dispatch(setClassificationList(tempClassifications));
      if (errors?.hasOwnProperty(deletedClassification[0]?.name)) {
        delete errors[deletedClassification[0]?.name];
      }

      updateFormValues(serviceTypeOptionList, tempClassifications);
      getTimesheetApproverOptions(
        tempClassifications,
        timesheet,
        null,
        timesheetApproval,
      );
    }
  };

  const copyClassificationRows = classificationIndex => {
    const tempClassifications = _.cloneDeep(timesheet?.classificationList);
    const classification = tempClassifications[classificationIndex];
    const classificationRowsLength = classification.values.length;
    const chkdRows = _.cloneDeep(checkedRows);

    const valuesArr = classification.values.filter(
      (value, index) => chkdRows[classification.name].indexOf(index) >= 0,
    );

    // if (classification.name === SERVICE_TYPES.LABOR) {
    chkdRows[classification.name].forEach((itemIndex, indx) => {
      // const item = _.cloneDeep(allFormFields[classification.name][itemIndex]);

      // _.range(23).forEach(idx => {
      //   const fieldName = valuesArr[indx][idx]['name'];
      //   valuesArr[indx][idx]['value'] = item[fieldName];
      //   // Update form fields
      //   setValue(
      //     // @ts-ignore
      //     `${classification.name}.${
      //       classificationRowsLength + indx
      //     }.${fieldName}`,
      //     item[fieldName],
      //   );
      // });

      // Update classification data
      classification.values = [...classification.values, ...valuesArr];
      dispatch(setClassificationList(tempClassifications));
      updateFormValues(serviceTypeOptionList, tempClassifications);
    });
    // }
    // reset(allFormFields);
    chkdRows[classification.name] = [];
    setCheckedRows(chkdRows);
  };

  const deleteClassificationRows = classificationIndex => {
    const tempClassifications = _.cloneDeep(timesheet?.classificationList);
    const classification = tempClassifications[classificationIndex];
    const chkdRows = _.cloneDeep(checkedRows);
    let timesheetApproval = _.cloneDeep(timesheet.timesheetApprovals);
    if (BuPath.GEN.includes(timesheet?.bupath)) {
      let actIndex =
        classification.name == 'timesheetReimbursables'
          ? 2
          : classification.name == 'timesheetLumpsums'
          ? 0
          : 3;
      if (classification.name == 'timesheetUptasks') {
        actIndex = 2;
      }
      let costActivityNumList = [];
      tempClassifications.forEach(item => {
        let activityIndex =
          item.name == 'timesheetReimbursables'
            ? 2
            : item.name == 'timesheetLumpsums'
            ? 0
            : 3;
        if (item.name == 'timesheetUptasks') {
          activityIndex = 2;
        }
        item.values.forEach(activityObj => {
          if (activityObj[activityIndex].value) {
            let activityLabel = _.find(activityObj[activityIndex].values, {
              value: Number(activityObj[activityIndex].value),
            });
            if (activityLabel && activityLabel.label) {
              activityLabel = activityLabel.label.split('-');
              costActivityNumList.push(activityLabel[0]?.trim());
            }
          }
        });
      });

      chkdRows[classification.name].forEach(deleteIndex => {
        // let ind = chkdRows[classification.name][0];
        let costActId = classification.values[deleteIndex][actIndex].value;
        let actLabel = _.find(
          classification.values[deleteIndex][actIndex].values,
          {
            value: Number(costActId),
          },
        );
        actLabel = actLabel?.label?.split('-');
        if (actLabel) {
          let indexNum = _.findIndex(timesheetApproval, {
            source: actLabel[0].trim(),
          });
          let indexNumInOrginalCostAct = _.indexOf(
            costActivityNumList,
            actLabel[0].trim(),
          );
          if (indexNumInOrginalCostAct >= 0) {
            costActivityNumList.splice(indexNumInOrginalCostAct, 1);
          }
          if (
            indexNum >= 0 &&
            !costActivityNumList.includes(timesheetApproval[indexNum].source)
          ) {
            timesheetApproval.splice(indexNum, 1);
          }
        }
      });
    }
    const valuesArr = classification.values.filter(
      (value, index) => chkdRows[classification.name].indexOf(index) === -1,
    );

    classification.values = _.cloneDeep(valuesArr);
    dispatch(setTimesheetApprovals(_.cloneDeep(timesheetApproval)));
    chkdRows[classification.name] = [];
    setCheckedRows(chkdRows);

    if (!tempClassifications[classificationIndex].values.length) {
      deleteClassification(classificationIndex);
    } else {
      dispatch(setClassificationList(tempClassifications));
      updateFormValues(serviceTypeOptionList, tempClassifications);
    }
  };

  const updateCheckedRows = (e, classification, index) => {
    const tempCheckedRows = _.cloneDeep(checkedRows);

    if (e.target.checked) {
      tempCheckedRows[classification].push(index);
    } else {
      const targetIndex = tempCheckedRows[classification].indexOf(index);
      tempCheckedRows[classification].splice(targetIndex, 1);
    }
    tempCheckedRows[classification].sort();
    setCheckedRows(tempCheckedRows);
  };

  const handleStartDate = date => {
    if (!BuPath.DIST.includes(purchaseOrder?.buPath)) {
      let tempEndDate = endDate;
      setStartDateError(false);
      setStartDate(date);
      setValue('workStartDate', date);
      let rangeEndValue = getMidnightDate(date, 30, 'add');

      if (BuPath.GEN.includes(purchaseOrder?.buPath)) {
        const monthEndDay = lastDayOfMonth(date);
        if (rangeEndValue > monthEndDay) {
          rangeEndValue = monthEndDay;
        }
      }

      if (endDate < date || endDate > rangeEndValue) {
        setEndDate(rangeEndValue);
        setValue('workEndDate', rangeEndValue);
        tempEndDate = rangeEndValue;
      }

      if (timesheet.poid !== '' || purchaseOrder.poid !== '') {
        setIsLoading(true);
        dispatch(
          // @ts-ignore
          fetchPORates({
            poId:
              timesheet.poid !== ''
                ? timesheet.poid ?? purchaseOrder.poid
                : purchaseOrder.poid,
            workStartDate: format(date, 'yyyy-MM-dd'),
            workEndDate: format(tempEndDate, 'yyyy-MM-dd'),
            isZeroLineCostHidden: true,
            callback: respData => {
              displayServiceTypesDynamically(respData);
              setIsLoading(false);
              handleDateError(date, null);
              handleDateError(null, tempEndDate);
              handleDuplicateDate(date, tempEndDate);
            },
          }),
        );
      }
    }
  };

  const handleEndDate = date => {
    if (!BuPath.DIST.includes(purchaseOrder?.buPath)) {
      setEndDate(date);
      setValue('workEndDate', date);
      const monthStartDay = getMidnightDate(format(date, 'yyyy-MM-01'));
      if (startDate > date) {
        setStartDate(monthStartDay);
        setValue('workStartDate', monthStartDay);
      }
      if (BuPath.GEN.includes(purchaseOrder?.buPath)) {
        if (startDate < monthStartDay) {
          setStartDate(monthStartDay);
          setValue('workStartDate', monthStartDay);
        }
      }

      setIsLoading(true);
      dispatch(
        // @ts-ignore
        fetchPORates({
          poId:
            timesheet.poid !== ''
              ? timesheet.poid ?? purchaseOrder.poid
              : purchaseOrder.poid,
          workStartDate: format(startDate, 'yyyy-MM-dd'),
          workEndDate: format(date, 'yyyy-MM-dd'),
          isZeroLineCostHidden: true,
          callback: respData => {
            displayServiceTypesDynamically(respData);
            setIsLoading(false);
            handleDateError(null, date);
            handleDuplicateDate(startDate, date);
          },
        }),
      );
    }
  };

  const handleWeekEndDate = date => {
    setWeekEndDate(date);
    setValue('weekEndDate', date);
    const month = getMonth(date) + 1; // months are 0 indexed.
    const dayNum = getDay(date);

    if (month === 12) {
      if (dayNum !== Day.SATURDAY) {
        const diffDays = Day.SATURDAY - dayNum < 0 ? 6 : Day.SATURDAY - dayNum;
        const nextSaturday = getMidnightDate(date, diffDays, 'add');
        const monthOfNextSaturday = getMonth(nextSaturday) + 1;

        if (monthOfNextSaturday !== 12) {
          set(date, { date: 31 });
          setWeekEndDateError(false);
          setWeekEndDate(date);
          setValue('weekEndDate', date);
        } else {
          setWeekEndDateError(true);
          setWeekEndDateErrorMessage('Weekend Date must be a Saturday');
        }
      } else {
        setWeekEndDateError(false);
        setWeekEndDate(date);
        setValue('weekEndDate', date);
      }
    } else {
      setWeekEndDateError(dayNum !== Day.SATURDAY);
      setWeekEndDateErrorMessage('Weekend Date must be a Saturday');
    }

    const isUvl = getValues('uvl');
    const today = getMidnightDate(new Date());
    const endOfMonth = lastDayOfMonth(today);

    // const isError = isUvl ? date > endOfMonth : date > today;
    // const isError = isUvl
    //   ? differenceInDays(endOfMonth, date) < 0
    //   : differenceInDays(today, date) < 0;

    // if (isError) {
    //   setWeekEndDateError(true);
    //   const dateErrorMessage = isUvl
    //     ? 'UVL date cannot be for future months'
    //     : 'Weekend Date must not be future date';
    //   setWeekEndDateErrorMessage(dateErrorMessage);
    // }

    handleDuplicateDate(null, date);

    setIsLoading(true);
    setValue('workStartDate', getMidnightDate(date, 6, 'sub'));
    setValue('workEndDate', date);
    setStartDate(getMidnightDate(date, 6, 'sub'));
    setEndDate(date);
    dispatch(
      // @ts-ignore
      fetchPORates({
        poId: purchaseOrder?.poid,
        workStartDate: format(getMidnightDate(date, 6, 'sub'), 'yyyy-MM-dd'),
        workEndDate: format(date, 'yyyy-MM-dd'),
        isZeroLineCostHidden: true,
        callback: respData => {
          displayServiceTypesDynamically(respData);
          setIsLoading(false);
        },
      }),
    );
  };

  const handleUvlEstimateChange = e => {
    if (e.target.value === 'uvl') {
      setValue('uvl', true);
      setValue('uvlEstimateNo', false);
    } else if (e.target.value === 'uvlEstimateNo') {
      setValue('uvlEstimateNo', true);
      setValue('uvl', false);
    }
    if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
      handleWeekEndDate(weekEndDate);
    } else {
      handleStartDate(startDate);
    }
  };

  const handleSelectOnChange = (
    e,
    classificationName,
    classificationIndex,
    rowIndex,
    colIndex,
    chunkIndex,
    name,
  ) => {
    let activityList = [];
    if (name == 'activitynum' && BuPath.GEN.includes(timesheet.bupath)) {
      timesheet?.classificationList.forEach(item => {
        let activityIndex =
          item.name == 'timesheetReimbursables'
            ? 2
            : item.name == 'timesheetLumpsums'
            ? 0
            : 3;
        if (item.name == 'timesheetUptasks') {
          activityIndex = 2;
        }
        item.values.forEach(activityObj => {
          if (activityObj[activityIndex].value) {
            activityList.push(Number(activityObj[activityIndex].value));
          }
        });
      });
      activityList = _.uniq(activityList);
    }
    let fieldValue = '';

    if (chunkIndex === 1) {
      colIndex = colIndex + 5;
    } else if (chunkIndex === 2) {
      colIndex = colIndex + 10;
    } else if (chunkIndex === 3) {
      colIndex = colIndex + 15;
    } else if (chunkIndex === 4) {
      colIndex = colIndex + 20;
    } else {
      colIndex = colIndex;
    }

    if (classificationName === SERVICE_TYPES.EQUIPMENT && name === 'equipId') {
      fieldValue = e?.value || '';
      const el = document.getElementById(`equipId${rowIndex}${colIndex}`);
      try {
        if (fieldValue === '') {
          // @ts-ignore
          el.lastElementChild.firstElementChild.lastElementChild
            .querySelectorAll('input')[0]
            .click();
        }
      } catch (ex) {}
    } else {
      fieldValue = e.target.value;
    }
    const tempClassifications = _.cloneDeep(timesheet?.classificationList);
    tempClassifications[classificationIndex].values[rowIndex][colIndex][
      'value'
    ] = fieldValue;

    // @ts-ignore
    setValue(`${classificationName}.${rowIndex}.${name}`, fieldValue);

    const rateschFieldName =
      tempClassifications[classificationIndex].values[rowIndex][0]['name'];
    const rateschValue = getValues(
      // @ts-ignore
      `${classificationName}.${rowIndex}.${rateschFieldName}`,
    );

    const codeFieldName =
      tempClassifications[classificationIndex].values[rowIndex][1]['name'];
    const codeValue = getValues(
      // @ts-ignore
      `${classificationName}.${rowIndex}.${codeFieldName}`,
    );

    const categoryFieldName =
      tempClassifications[classificationIndex].values[rowIndex][2]['name'];
    const categoryValue = getValues(
      // @ts-ignore
      `${classificationName}.${rowIndex}.${categoryFieldName}`,
    );

    if (fieldValue === '' && classificationName !== SERVICE_TYPES.LUMPSUM) {
      resetDropdownOptions(
        lookup,
        tempClassifications,
        classificationName,
        classificationIndex,
        rowIndex,
        setValue,
      );

      resetAutoPopulatedFields(
        tempClassifications,
        classificationName,
        classificationIndex,
        rowIndex,
        setValue,
      );
    } else {
      //* **************************************************************************************** */
      //* Filter dropdown options and auto populate text fields based on selection of other fields */
      //* **************************************************************************************** */
      switch (classificationName) {
        case SERVICE_TYPES.LABOR: {
          if (colIndex === 0) {
            if (isEmptyVal(codeValue) && isEmptyVal(categoryValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj => obj[LabelFieldMapping['rateSchLabel']] === fieldValue,
              );

              // Generate the dropdown options
              let codeOptions = [];
              let categoryOptions = [];
              rateFilteredList.forEach(obj => {
                codeOptions.push({
                  value: obj[LabelFieldMapping['codeLabel']],
                  label: obj[LabelFieldMapping['codeLabel']],
                });

                categoryOptions.push({
                  value: obj[POCategoryFieldMapping[classificationName]],
                  label: obj[POCategoryFieldMapping[classificationName]],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //codeOptions = _.uniqBy(codeOptions, 'label');
              codeOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(codeOptions, 'label'), ['label']),
              ];
              //categoryOptions = _.uniqBy(categoryOptions, 'label');
              categoryOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(categoryOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][1][
                'values'
              ] = codeOptions;
              tempClassifications[classificationIndex].values[rowIndex][2][
                'values'
              ] = categoryOptions;
            } else if (isEmptyVal(codeValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === fieldValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    categoryValue,
              );

              // Generate the dropdown options
              let codeOptions = [];
              rateFilteredList.forEach(obj => {
                codeOptions.push({
                  value: obj[LabelFieldMapping['codeLabel']],
                  label: obj[LabelFieldMapping['codeLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //codeOptions = _.uniqBy(codeOptions, 'label');
              codeOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(codeOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][1][
                'values'
              ] = codeOptions;
            } else if (isEmptyVal(categoryValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === fieldValue &&
                  obj[LabelFieldMapping['codeLabel']] === codeValue,
              );

              // Generate the dropdown options
              let categoryOptions = [];
              rateFilteredList.forEach(obj => {
                categoryOptions.push({
                  value: obj[POCategoryFieldMapping[classificationName]],
                  label: obj[POCategoryFieldMapping[classificationName]],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //categoryOptions = _.uniqBy(categoryOptions, 'label');
              categoryOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(categoryOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][2][
                'values'
              ] = categoryOptions;
            } else {
              const rateObj = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].find(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[LabelFieldMapping['codeLabel']] === codeValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    categoryValue,
              );

              if (isEmptyVal(rateObj)) {
                resetDropdownOptions(
                  lookup,
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  setValue,
                );
              } else {
                setFilteredDropdownOptions(
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  rateObj,
                );
              }
            }
          }

          if (colIndex === 1) {
            if (isEmptyVal(rateschValue) && isEmptyVal(categoryValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj => obj[LabelFieldMapping['codeLabel']] === fieldValue,
              );

              // Generate the dropdown options
              let rateschOptions = [];
              let categoryOptions = [];
              rateFilteredList.forEach(obj => {
                rateschOptions.push({
                  value: obj[LabelFieldMapping['rateSchLabel']],
                  label: obj[LabelFieldMapping['rateSchLabel']],
                });

                categoryOptions.push({
                  value: obj[POCategoryFieldMapping[classificationName]],
                  label: obj[POCategoryFieldMapping[classificationName]],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //rateschOptions = _.uniqBy(rateschOptions, 'label');
              rateschOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(rateschOptions, 'label'), ['label']),
              ];
              // categoryOptions = _.uniqBy(categoryOptions, 'label');
              categoryOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(categoryOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][0][
                'values'
              ] = rateschOptions;
              tempClassifications[classificationIndex].values[rowIndex][2][
                'values'
              ] = categoryOptions;
            } else if (isEmptyVal(rateschValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[LabelFieldMapping['codeLabel']] === fieldValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    categoryValue,
              );

              // Generate the dropdown options
              let rateschOptions = [];
              rateFilteredList.forEach(obj => {
                rateschOptions.push({
                  value: obj[LabelFieldMapping['rateSchLabel']],
                  label: obj[LabelFieldMapping['rateSchLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //rateschOptions = _.uniqBy(rateschOptions, 'label');
              rateschOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(rateschOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][0][
                'values'
              ] = rateschOptions;
            } else if (isEmptyVal(categoryValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[LabelFieldMapping['codeLabel']] === fieldValue,
              );

              // Generate the dropdown options
              let categoryOptions = [];
              rateFilteredList.forEach(obj => {
                categoryOptions.push({
                  value: obj[POCategoryFieldMapping[classificationName]],
                  label: obj[POCategoryFieldMapping[classificationName]],
                });
              });

              // Assign the dropdown options to corresponding select fields
              // categoryOptions = _.uniqBy(categoryOptions, 'label');
              categoryOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(categoryOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][2][
                'values'
              ] = categoryOptions;
            } else {
              const rateObj = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].find(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[LabelFieldMapping['codeLabel']] === codeValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    categoryValue,
              );

              if (isEmptyVal(rateObj)) {
                resetDropdownOptions(
                  lookup,
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  setValue,
                );
              } else {
                setFilteredDropdownOptions(
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  rateObj,
                );
              }
            }
          }

          if (colIndex === 2) {
            if (isEmptyVal(rateschValue) && isEmptyVal(codeValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[POCategoryFieldMapping[classificationName]] ===
                  fieldValue,
              );

              // Generate the dropdown options
              let rateschOptions = [];
              let codeOptions = [];
              rateFilteredList.forEach(obj => {
                rateschOptions.push({
                  value: obj[LabelFieldMapping['rateSchLabel']],
                  label: obj[LabelFieldMapping['rateSchLabel']],
                });
                codeOptions.push({
                  value: obj[LabelFieldMapping['codeLabel']],
                  label: obj[LabelFieldMapping['codeLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //rateschOptions = _.uniqBy(rateschOptions, 'label');
              rateschOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(rateschOptions, 'label'), ['label']),
              ];
              //codeOptions = _.uniqBy(codeOptions, 'label');
              codeOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(codeOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][0][
                'values'
              ] = rateschOptions;
              tempClassifications[classificationIndex].values[rowIndex][1][
                'values'
              ] = codeOptions;
            } else if (isEmptyVal(rateschValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[LabelFieldMapping['codeLabel']] === codeValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    fieldValue,
              );

              // Generate the dropdown options
              let rateschOptions = [];
              rateFilteredList.forEach(obj => {
                rateschOptions.push({
                  value: obj[LabelFieldMapping['rateSchLabel']],
                  label: obj[LabelFieldMapping['rateSchLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //rateschOptions = _.uniqBy(rateschOptions, 'label');
              rateschOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(rateschOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][0][
                'values'
              ] = rateschOptions;
            } else if (isEmptyVal(codeValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    fieldValue,
              );

              // Generate the dropdown options
              let codeOptions = [];
              rateFilteredList.forEach(obj => {
                codeOptions.push({
                  value: obj[LabelFieldMapping['codeLabel']],
                  label: obj[LabelFieldMapping['codeLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //codeOptions = _.uniqBy(codeOptions, 'label');
              codeOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(codeOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][1][
                'values'
              ] = codeOptions;
            } else {
              const rateObj = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].find(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[LabelFieldMapping['codeLabel']] === codeValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    categoryValue,
              );

              if (isEmptyVal(rateObj)) {
                resetDropdownOptions(
                  lookup,
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  setValue,
                );
              } else {
                setFilteredDropdownOptions(
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  rateObj,
                );
              }
            }
          }

          break;
        }

        case SERVICE_TYPES.EQUIPMENT: {
          if (colIndex === 0) {
            if (isEmptyVal(codeValue) && isEmptyVal(categoryValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj => obj[LabelFieldMapping['rateSchLabel']] === fieldValue,
              );

              // Generate the dropdown options
              let codeOptions = [];
              let categoryOptions = [];
              rateFilteredList.forEach(obj => {
                codeOptions.push({
                  value: obj[LabelFieldMapping['codeLabel']],
                  label: obj[LabelFieldMapping['codeLabel']],
                });

                categoryOptions.push({
                  value: obj[POCategoryFieldMapping[classificationName]],
                  label: obj[POCategoryFieldMapping[classificationName]],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //codeOptions = _.uniqBy(codeOptions, 'label');
              codeOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(codeOptions, 'label'), ['label']),
              ];
              //categoryOptions = _.uniqBy(categoryOptions, 'label');
              categoryOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(categoryOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][1][
                'values'
              ] = codeOptions;
              tempClassifications[classificationIndex].values[rowIndex][2][
                'values'
              ] = categoryOptions;
            } else if (isEmptyVal(codeValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === fieldValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    categoryValue,
              );

              // Generate the dropdown options
              let codeOptions = [];
              rateFilteredList.forEach(obj => {
                codeOptions.push({
                  value: obj[LabelFieldMapping['codeLabel']],
                  label: obj[LabelFieldMapping['codeLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              // codeOptions = _.uniqBy(codeOptions, 'label');
              codeOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(codeOptions, 'label'), ['label']),
              ];

              tempClassifications[classificationIndex].values[rowIndex][1][
                'values'
              ] = codeOptions;
            } else if (isEmptyVal(categoryValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === fieldValue &&
                  obj[LabelFieldMapping['codeLabel']] === codeValue,
              );

              // Generate the dropdown options
              let categoryOptions = [];
              rateFilteredList.forEach(obj => {
                categoryOptions.push({
                  value: obj[POCategoryFieldMapping[classificationName]],
                  label: obj[POCategoryFieldMapping[classificationName]],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //categoryOptions = _.uniqBy(categoryOptions, 'label');
              categoryOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(categoryOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][2][
                'values'
              ] = categoryOptions;
            } else {
              const rateObj = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].find(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[LabelFieldMapping['codeLabel']] === codeValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    categoryValue,
              );

              if (isEmptyVal(rateObj)) {
                resetDropdownOptions(
                  lookup,
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  setValue,
                );
              } else {
                setFilteredDropdownOptions(
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  rateObj,
                );
              }
            }
          }

          if (colIndex === 1) {
            if (isEmptyVal(rateschValue) && isEmptyVal(categoryValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj => obj[LabelFieldMapping['codeLabel']] === fieldValue,
              );

              // Generate the dropdown options
              let rateschOptions = [];
              let categoryOptions = [];
              rateFilteredList.forEach(obj => {
                rateschOptions.push({
                  value: obj[LabelFieldMapping['rateSchLabel']],
                  label: obj[LabelFieldMapping['rateSchLabel']],
                });

                categoryOptions.push({
                  value: obj[POCategoryFieldMapping[classificationName]],
                  label: obj[POCategoryFieldMapping[classificationName]],
                });
              });

              // Assign the dropdown options to corresponding select fields
              // rateschOptions = _.uniqBy(rateschOptions, 'label');
              rateschOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(rateschOptions, 'label'), ['label']),
              ];
              //categoryOptions = _.uniqBy(categoryOptions, 'label');
              categoryOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(categoryOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][0][
                'values'
              ] = rateschOptions;
              tempClassifications[classificationIndex].values[rowIndex][2][
                'values'
              ] = categoryOptions;
            } else if (isEmptyVal(rateschValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[LabelFieldMapping['codeLabel']] === fieldValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    categoryValue,
              );

              // Generate the dropdown options
              let rateschOptions = [];
              rateFilteredList.forEach(obj => {
                rateschOptions.push({
                  value: obj[LabelFieldMapping['rateSchLabel']],
                  label: obj[LabelFieldMapping['rateSchLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //rateschOptions = _.uniqBy(rateschOptions, 'label');
              rateschOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(rateschOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][0][
                'values'
              ] = rateschOptions;
            } else if (isEmptyVal(categoryValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[LabelFieldMapping['codeLabel']] === fieldValue,
              );

              // Generate the dropdown options
              let categoryOptions = [];
              rateFilteredList.forEach(obj => {
                categoryOptions.push({
                  value: obj[POCategoryFieldMapping[classificationName]],
                  label: obj[POCategoryFieldMapping[classificationName]],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //categoryOptions = _.uniqBy(categoryOptions, 'label');
              categoryOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(categoryOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][2][
                'values'
              ] = categoryOptions;
            } else {
              const rateObj = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].find(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[LabelFieldMapping['codeLabel']] === codeValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    categoryValue,
              );

              if (isEmptyVal(rateObj)) {
                resetDropdownOptions(
                  lookup,
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  setValue,
                );
              } else {
                setFilteredDropdownOptions(
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  rateObj,
                );
              }
            }
          }

          if (colIndex === 2) {
            if (isEmptyVal(rateschValue) && isEmptyVal(codeValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[POCategoryFieldMapping[classificationName]] ===
                  fieldValue,
              );

              // Generate the dropdown options
              let rateschOptions = [];
              let codeOptions = [];
              rateFilteredList.forEach(obj => {
                rateschOptions.push({
                  value: obj[LabelFieldMapping['rateSchLabel']],
                  label: obj[LabelFieldMapping['rateSchLabel']],
                });
                codeOptions.push({
                  value: obj[LabelFieldMapping['codeLabel']],
                  label: obj[LabelFieldMapping['codeLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //rateschOptions = _.uniqBy(rateschOptions, 'label');
              rateschOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(rateschOptions, 'label'), ['label']),
              ];
              // codeOptions = _.uniqBy(codeOptions, 'label');
              codeOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(codeOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][0][
                'values'
              ] = rateschOptions;
              tempClassifications[classificationIndex].values[rowIndex][1][
                'values'
              ] = codeOptions;
            } else if (isEmptyVal(rateschValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[LabelFieldMapping['codeLabel']] === codeValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    fieldValue,
              );

              // Generate the dropdown options
              let rateschOptions = [];
              rateFilteredList.forEach(obj => {
                rateschOptions.push({
                  value: obj[LabelFieldMapping['rateSchLabel']],
                  label: obj[LabelFieldMapping['rateSchLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //rateschOptions = _.uniqBy(rateschOptions, 'label');
              rateschOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(rateschOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][0][
                'values'
              ] = rateschOptions;
            } else if (isEmptyVal(codeValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    fieldValue,
              );

              // Generate the dropdown options
              let codeOptions = [];
              rateFilteredList.forEach(obj => {
                codeOptions.push({
                  value: obj[LabelFieldMapping['codeLabel']],
                  label: obj[LabelFieldMapping['codeLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //codeOptions = _.uniqBy(codeOptions, 'label');
              codeOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(codeOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][1][
                'values'
              ] = codeOptions;
            } else {
              const rateObj = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].find(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[LabelFieldMapping['codeLabel']] === codeValue &&
                  obj[POCategoryFieldMapping[classificationName]] ===
                    categoryValue,
              );

              if (isEmptyVal(rateObj)) {
                resetDropdownOptions(
                  lookup,
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  setValue,
                );
              } else {
                setFilteredDropdownOptions(
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  rateObj,
                );
              }
            }
          }

          if (
            [0, 1, 2].includes(colIndex) &&
            !isEmptyVal(rateschValue) &&
            !isEmptyVal(codeValue)
          ) {
            setAutoPopulatedFields(
              lookup?.purchaseOrderObject,
              tempClassifications,
              classificationName,
              classificationIndex,
              rowIndex,
              rateschValue,
              codeValue,
              categoryValue,
              setValue,
              getValues,
            );
          }

          break;
        }

        case SERVICE_TYPES.REIMBURSABLE_MARKUP: {
          if (colIndex === 0) {
            if (isEmptyVal(codeValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj => obj[LabelFieldMapping['rateSchLabel']] === fieldValue,
              );

              // Generate the dropdown options
              let codeOptions = [];
              rateFilteredList.forEach(obj => {
                codeOptions.push({
                  value: obj[LabelFieldMapping['codeLabel']],
                  label: obj[LabelFieldMapping['codeLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //codeOptions = _.uniqBy(codeOptions, 'label');
              codeOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(codeOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][1][
                'values'
              ] = codeOptions;
            } else {
              const rateObj = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].find(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[LabelFieldMapping['codeLabel']] === codeValue,
              );

              if (isEmptyVal(rateObj)) {
                resetDropdownOptions(
                  lookup,
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  setValue,
                );
              } else {
                setFilteredDropdownOptions(
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  rateObj,
                );
              }
            }
          }

          if (colIndex === 1) {
            if (isEmptyVal(rateschValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj => obj[LabelFieldMapping['codeLabel']] === fieldValue,
              );

              // Generate the dropdown options
              let rateschOptions = [];
              rateFilteredList.forEach(obj => {
                rateschOptions.push({
                  value: obj[LabelFieldMapping['rateSchLabel']],
                  label: obj[LabelFieldMapping['rateSchLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              //rateschOptions = _.uniqBy(rateschOptions, 'label');
              rateschOptions = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(rateschOptions, 'label'), ['label']),
              ];
              tempClassifications[classificationIndex].values[rowIndex][0][
                'values'
              ] = rateschOptions;
            } else {
              const rateObj = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].find(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[LabelFieldMapping['codeLabel']] === codeValue,
              );

              if (isEmptyVal(rateObj)) {
                resetDropdownOptions(
                  lookup,
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  setValue,
                );
              } else {
                setFilteredDropdownOptions(
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  rateObj,
                );
              }
            }
          }

          if (
            [0, 1].includes(colIndex) &&
            !isEmptyVal(rateschValue) &&
            !isEmptyVal(codeValue)
          ) {
            setAutoPopulatedFields(
              lookup?.purchaseOrderObject,
              tempClassifications,
              classificationName,
              classificationIndex,
              rowIndex,
              rateschValue,
              codeValue,
              null,
              setValue,
              getValues,
            );
          }

          break;
        }

        case SERVICE_TYPES.UNIT_PRICE_TASK: {
          if (
            getValues('authType') === AuthType.UNIT_PRICE &&
            !isEmptyVal(getValues('authNmbr'))
          ) {
            const jobAuthTasks = timesheet?.jobAuthorization?.jobauthtasks;
            let index = tempClassifications.findIndex(
              obj => obj.name === classificationName,
            );

            // Auto populate fields
            const jobAuthTask = jobAuthTasks.find(
              obj => obj.taskcode === fieldValue,
            );
            tempClassifications[index].values[rowIndex][1]['value'] =
              jobAuthTask?.taskcode;
            tempClassifications[index].values[rowIndex][4]['value'] =
              jobAuthTask?.unitype;
            tempClassifications[index].values[rowIndex][5]['value'] = 0;
            tempClassifications[index].values[rowIndex][10]['value'] =
              jobAuthTask?.dollarsPerUnit;
            tempClassifications[index].values[rowIndex][11]['value'] = 0;
          } else {
            if (colIndex === 0) {
              if (isEmptyVal(codeValue)) {
                const rateFilteredList = lookup?.purchaseOrderObject[
                  PORateKeyMapping[classificationName]
                ].filter(
                  obj => obj[LabelFieldMapping['rateSchLabel']] === fieldValue,
                );

                // Generate the dropdown options
                let codeOptions = [];
                rateFilteredList.forEach(obj => {
                  codeOptions.push({
                    value: obj[LabelFieldMapping['codeLabel']],
                    label: obj[LabelFieldMapping['codeLabel']],
                  });
                });

                // Assign the dropdown options to corresponding select fields
                //codeOptions = _.uniqBy(codeOptions, 'label');
                codeOptions = [
                  { value: '', label: 'Select' },
                  ..._.sortBy(_.uniqBy(codeOptions, 'label'), ['label']),
                ];
                tempClassifications[classificationIndex].values[rowIndex][1][
                  'values'
                ] = codeOptions;
              } else {
                const rateObj = lookup?.purchaseOrderObject[
                  PORateKeyMapping[classificationName]
                ].find(
                  obj =>
                    obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                    obj[LabelFieldMapping['codeLabel']] === codeValue,
                );

                if (isEmptyVal(rateObj)) {
                  resetDropdownOptions(
                    lookup,
                    tempClassifications,
                    classificationName,
                    classificationIndex,
                    rowIndex,
                    setValue,
                  );
                } else {
                  setFilteredDropdownOptions(
                    tempClassifications,
                    classificationName,
                    classificationIndex,
                    rowIndex,
                    rateObj,
                  );
                }
              }
            }

            if (colIndex === 1) {
              if (isEmptyVal(rateschValue)) {
                const rateFilteredList = lookup?.purchaseOrderObject[
                  PORateKeyMapping[classificationName]
                ].filter(
                  obj => obj[LabelFieldMapping['codeLabel']] === fieldValue,
                );

                // Generate the dropdown options
                let rateschOptions = [];
                rateFilteredList.forEach(obj => {
                  rateschOptions.push({
                    value: obj[LabelFieldMapping['rateSchLabel']],
                    label: obj[LabelFieldMapping['rateSchLabel']],
                  });
                });

                // Assign the dropdown options to corresponding select fields
                // rateschOptions = _.uniqBy(rateschOptions, 'label');
                rateschOptions = [
                  { value: '', label: 'Select' },
                  ..._.sortBy(_.uniqBy(rateschOptions, 'label'), ['label']),
                ];
                tempClassifications[classificationIndex].values[rowIndex][0][
                  'values'
                ] = rateschOptions;
              } else {
                const rateObj = lookup?.purchaseOrderObject[
                  PORateKeyMapping[classificationName]
                ].find(
                  obj =>
                    obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                    obj[LabelFieldMapping['codeLabel']] === codeValue,
                );

                if (isEmptyVal(rateObj)) {
                  resetDropdownOptions(
                    lookup,
                    tempClassifications,
                    classificationName,
                    classificationIndex,
                    rowIndex,
                    setValue,
                  );
                } else {
                  setFilteredDropdownOptions(
                    tempClassifications,
                    classificationName,
                    classificationIndex,
                    rowIndex,
                    rateObj,
                  );
                }
              }
              trigger(`${classificationName}.${rowIndex}.${name}`);
            }

            if (
              [0, 1].includes(colIndex) &&
              !isEmptyVal(rateschValue) &&
              !isEmptyVal(codeValue)
            ) {
              setAutoPopulatedFields(
                lookup?.purchaseOrderObject,
                tempClassifications,
                classificationName,
                classificationIndex,
                rowIndex,
                rateschValue,
                codeValue,
                null,
                setValue,
                getValues,
              );
            }
          }
          break;
        }

        case SERVICE_TYPES.CU: {
          if (colIndex === 0) {
            if (isEmptyVal(codeValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj => obj[LabelFieldMapping['rateSchLabel']] === fieldValue,
              );

              // Generate the dropdown options
              let codeOptions = [{ value: '', label: 'Select' }];
              rateFilteredList.forEach(obj => {
                codeOptions.push({
                  value: obj[LabelFieldMapping['codeLabel']],
                  label: obj[LabelFieldMapping['codeLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              codeOptions = _.uniqBy(codeOptions, 'label');
              tempClassifications[classificationIndex].values[rowIndex][1][
                'values'
              ] = codeOptions;
            } else {
              const rateObj = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].find(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[LabelFieldMapping['codeLabel']] === codeValue,
              );

              if (isEmptyVal(rateObj)) {
                resetDropdownOptions(
                  lookup,
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  setValue,
                );
              } else {
                setFilteredDropdownOptions(
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  rateObj,
                );
              }
            }
          }

          if (colIndex === 1) {
            if (isEmptyVal(rateschValue)) {
              const rateFilteredList = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].filter(
                obj => obj[LabelFieldMapping['codeLabel']] === fieldValue,
              );

              // Generate the dropdown options
              let rateschOptions = [{ value: '', label: 'Select' }];
              rateFilteredList.forEach(obj => {
                rateschOptions.push({
                  value: obj[LabelFieldMapping['rateSchLabel']],
                  label: obj[LabelFieldMapping['rateSchLabel']],
                });
              });

              // Assign the dropdown options to corresponding select fields
              rateschOptions = _.uniqBy(rateschOptions, 'label');
              tempClassifications[classificationIndex].values[rowIndex][0][
                'values'
              ] = rateschOptions;
            } else {
              const rateObj = lookup?.purchaseOrderObject[
                PORateKeyMapping[classificationName]
              ].find(
                obj =>
                  obj[LabelFieldMapping['rateSchLabel']] === rateschValue &&
                  obj[LabelFieldMapping['codeLabel']] === codeValue,
              );

              if (isEmptyVal(rateObj)) {
                resetDropdownOptions(
                  lookup,
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  setValue,
                );
              } else {
                setFilteredDropdownOptions(
                  tempClassifications,
                  classificationName,
                  classificationIndex,
                  rowIndex,
                  rateObj,
                );
              }
            }
          }

          if (
            [0, 1].includes(colIndex) &&
            !isEmptyVal(rateschValue) &&
            !isEmptyVal(codeValue)
          ) {
            setAutoPopulatedFields(
              lookup?.purchaseOrderObject,
              tempClassifications,
              classificationName,
              classificationIndex,
              rowIndex,
              rateschValue,
              codeValue,
              null,
              setValue,
              getValues,
            );
          }

          break;
        }

        case SERVICE_TYPES.PRODUCTION_HOURS: {
          if (
            purchaseOrder?.buPath === 'FORESTRY' &&
            getValues('authType') === AuthType.TIME_AND_MATERIAL
          ) {
            if (name === 'code') {
              const unitOfMeasureList = lookup?.unitOfMeasure?.filter(e =>
                e.taskcodes?.includes(fieldValue),
              );

              const uomSelection = [
                { value: '', label: 'Select' },
                ..._.sortBy(_.uniqBy(unitOfMeasureList, 'label'), ['label']),
              ];

              tempClassifications[classificationIndex].values[rowIndex][1][
                'values'
              ] = uomSelection;
            }
          }
          break;
        }

        default:
        //pass
      }
    }
    let timesheetApproval = _.cloneDeep(timesheet.timesheetApprovals);
    if (name == 'activitynum' && BuPath.GEN.includes(timesheet.bupath)) {
      let tempActivityList = [];
      tempClassifications.forEach(item => {
        let activityIndex =
          item.name == 'timesheetReimbursables'
            ? 2
            : item.name == 'timesheetLumpsums'
            ? 0
            : 3;
        if (item.name == 'timesheetUptasks') {
          activityIndex = 2;
        }
        item.values.forEach(activityObj => {
          if (activityObj[activityIndex].value) {
            tempActivityList.push(Number(activityObj[activityIndex].value));
          }
        });
      });
      tempActivityList = _.uniq(tempActivityList);
      let changedIndex = _.findIndex(
        activityList,
        (value, index) => value !== tempActivityList[index],
      );
      let hasSameValues = _.isEqual(
        _.sortBy(activityList),
        _.sortBy(tempActivityList),
      );
      //if both original activity list and temactivitylist same then do nothing to tsApproval
      if (hasSameValues) changedIndex = -1;
      tempClassifications.forEach(item => {
        let activityIndex =
          item.name == 'timesheetReimbursables'
            ? 2
            : item.name == 'timesheetLumpsums'
            ? 0
            : 3;
        if (item.name == 'timesheetUptasks') {
          activityIndex = 2;
        }
        item.values.forEach((activityObj, index) => {
          //original activityList
          /*doing if original activityList length is same and tempActivityList length is same then
           * it means only activity dropdown is changed, and so updating source in timesheetApproval
           * */
          if (
            activityList.length == tempActivityList.length &&
            tempActivityList[changedIndex] == activityObj[activityIndex].value
          ) {
            let activityNum = _.find(activityObj[activityIndex].values, {
              value: Number(activityObj[activityIndex].value),
            });
            if (activityNum) {
              let activity = activityNum?.label?.split('-');
              timesheetApproval[changedIndex].source = activity[0].trim();
            }
          }
        });
      });
      /**
       * If new cost activity is added means that activity will be found in tempActivityList
       * then original activityList and tempActivityList length will not be same
       */
      if (activityList.length != tempActivityList.length) {
        let activityNum;
        let indexVal = null;
        /**
         * In if part, For TS having Labor, Equipment, Reimbursable and user added new activity to Equipment
         * then getting the changed Index from original activityList
         * In the else part If the new cost activity is added to Reimbursable then fetching the last index from tempActivityList
         */
        if (changedIndex >= 0) {
          indexVal = changedIndex;
        } else {
          indexVal = tempActivityList.length - 1;
        }
        activityNum = _.find(purchaseOrder.costActivities, {
          costactivityid: Number(tempActivityList[indexVal]),
        });
        //tempActivityList length is less than activityList, it means it has duplicate activity in tempActivityList
        if (
          (!tempActivityList[changedIndex] && changedIndex >= 0) ||
          tempActivityList.length < activityList.length
        ) {
          activityNum = _.find(purchaseOrder.costActivities, {
            costactivityid: Number(activityList[changedIndex]),
          });
          if (activityNum?.activitynum) {
            let tsApprovalIndex = _.findIndex(timesheetApproval, {
              source: activityNum.activitynum,
            });
            timesheetApproval.splice(tsApprovalIndex, 1);
          }
          activityNum = null;
        }

        if (activityNum) {
          timesheetApproval.push({
            bupath: BuPathEnum.GEN,
            approver: null,
            role: 'costActivityApprover',
            approved: 'P',
            source: activityNum.activitynum,
          });
        }
      }
      dispatch(
        setTimesheetApprovals(
          _.cloneDeep(_.uniqBy(timesheetApproval, 'source')),
        ),
      );
      getTimesheetApproverOptions(
        _.cloneDeep(tempClassifications),
        timesheet,
        null,
        _.uniqBy(timesheetApproval, 'source'),
      );
    } else {
      getTimesheetApproverOptions(_.cloneDeep(tempClassifications), timesheet);
    }

    dispatch(setClassificationList(_.cloneDeep(tempClassifications)));
    updateFormValues(serviceTypeOptionList, tempClassifications);
  };

  const handleFieldOnChange = (
    e,
    classificationName,
    classificationIndex,
    rowIndex,
    colIndex,
    chunkIndex,
    name,
  ) => {
    const tempClassifications = _.cloneDeep(timesheet?.classificationList);

    if (chunkIndex === 1) {
      colIndex = colIndex + 5;
    } else if (chunkIndex === 2) {
      colIndex = colIndex + 10;
    } else if (chunkIndex === 3) {
      colIndex = colIndex + 15;
    } else if (chunkIndex === 4) {
      colIndex = colIndex + 20;
    } else {
      colIndex = colIndex;
    }

    tempClassifications[classificationIndex].values[rowIndex][colIndex][
      'value'
    ] = e.target.value;

    // @ts-ignore
    setValue(`${classificationName}.${rowIndex}.${name}`, e.target.value);
    trigger(`${classificationName}.${rowIndex}.${name}`);

    // Autopolulate Total Line Price based on quantity
    if (
      classificationName === SERVICE_TYPES.UNIT_PRICE_TASK &&
      name === 'quantity'
    ) {
      const qty = getValues(`${classificationName}.${rowIndex}.quantity`);
      const totlPrice = getValues(
        `${classificationName}.${rowIndex}.totalPrice`,
      );
      let linePrice = null;
      try {
        linePrice = (qty * totlPrice).toFixed(2);
      } catch (e) {}
      linePrice = isNaN(linePrice) ? null : linePrice;

      const linePriceList = tempClassifications[classificationIndex].values[
        rowIndex
      ].filter(obj => obj.name === 'lineAmount');
      if (linePriceList.length) {
        linePriceList[0].value = linePrice;
        setValue(`${classificationName}.${rowIndex}.lineAmount`, linePrice);
      }
    }
    dispatch(setClassificationList(tempClassifications));
    updateFormValues(serviceTypeOptionList, tempClassifications);

    /****************************************** */
    /** Validation for activity amount exceeded */
    /****************************************** */

    //----------------------------------------------
    // IMPORTANT: DO NOT DELETE BELOW COMMENTED CODE
    //----------------------------------------------

    // if (purchaseOrder?.buPath === BuPath.TRANS) {
    //   const fieldValue = parseFloat(e.target.value ?? 0);
    //   const rateschFieldName =
    //     tempClassifications[classificationIndex].values[rowIndex][0]['name'];
    //   const rateschValue = getValues(
    //     // @ts-ignore
    //     `${classificationName}.${rowIndex}.${rateschFieldName}`,
    //   );

    //   const codeFieldName =
    //     tempClassifications[classificationIndex].values[rowIndex][1]['name'];
    //   const codeValue = getValues(
    //     // @ts-ignore
    //     `${classificationName}.${rowIndex}.${codeFieldName}`,
    //   );

    //   const categoryFieldName =
    //     tempClassifications[classificationIndex].values[rowIndex][2]['name'];
    //   const categoryValue = getValues(
    //     // @ts-ignore
    //     `${classificationName}.${rowIndex}.${categoryFieldName}`,
    //   );

    //   const poCostActivityList =
    //     purchaseOrder?.[PORateKeyMapping['poActivity']];

    //   switch (classificationName) {
    //     case SERVICE_TYPES.LABOR: {
    //       const selectedCostActivity = getValues(
    //         // @ts-ignore
    //         `${SERVICE_TYPES.LABOR}.${rowIndex}.activitynum`,
    //       );

    //       if (isEmptyVal(selectedCostActivity)) {
    //         // Delete cost activity error object
    //       } else {
    //         const selectedCostActivityObject = poCostActivityList.filter(
    //           laborObj =>
    //             String(laborObj[PORateObjectKeyMapping['activity']]) ===
    //             String(selectedCostActivity),
    //         );

    //         if (!_.isEmpty(lookup?.purchaseOrderObject)) {
    //           const poObject = getPORateObject(
    //             lookup?.purchaseOrderObject,
    //             classificationName,
    //             rateschValue,
    //             codeValue,
    //             categoryValue,
    //           );

    //           if (!isEmptyVal(poObject)) {
    //             let rateValue = 0;

    //             if (colIndex === 8)
    //               rateValue = parseFloat(poObject?.stlaborrate ?? 0);
    //             if (colIndex === 9)
    //               rateValue = parseFloat(poObject?.otlaborrate ?? 0);
    //             if (colIndex === 10)
    //               rateValue = parseFloat(poObject?.dtlaborrate ?? 0);

    //             const tempActivityError = _.cloneDeep(activityError);
    //             if (colIndex === 8 || colIndex === 9 || colIndex === 10) {
    //               const remainingDollar = parseFloat(
    //                 selectedCostActivityObject[0].remainingdollar ?? 0,
    //               );
    //               const exceededAmount =
    //                 fieldValue * rateValue - remainingDollar;
    //               const errorMessage = `Allowed amount of activity have been exceeded by ${exceededAmount} dollar amount`;

    //               if (selectedCostActivityObject.length && exceededAmount > 0) {
    //                 if (
    //                   tempActivityError[classificationName].hasOwnProperty(
    //                     rowIndex,
    //                   )
    //                 ) {
    //                   tempActivityError[classificationName][rowIndex][name] =
    //                     errorMessage;
    //                 } else {
    //                   tempActivityError[classificationName][rowIndex] = {
    //                     [name]: errorMessage,
    //                   };
    //                 }
    //               } else {
    //                 if (
    //                   tempActivityError[classificationName].hasOwnProperty(
    //                     rowIndex,
    //                   )
    //                 ) {
    //                   if (
    //                     tempActivityError[classificationName][
    //                       rowIndex
    //                     ].hasOwnProperty(name)
    //                   ) {
    //                     delete tempActivityError[classificationName][rowIndex][
    //                       name
    //                     ];
    //                   }

    //                   if (
    //                     _.isEmpty(
    //                       tempActivityError[classificationName][rowIndex],
    //                     )
    //                   ) {
    //                     delete tempActivityError[classificationName][rowIndex];
    //                   }
    //                 }
    //               }
    //             }
    //             setActivityError(tempActivityError);
    //             const isActivityErr =
    //               !_.isEmpty(tempActivityError[SERVICE_TYPES.LABOR]) ||
    //               !_.isEmpty(tempActivityError[SERVICE_TYPES.EQUIPMENT]);
    //             setIsActivityError(isActivityErr);
    //           }
    //         }
    //       }
    //       break;
    //     }

    //     case SERVICE_TYPES.EQUIPMENT: {
    //       const selectedCostActivity = getValues(
    //         // @ts-ignore
    //         `${SERVICE_TYPES.EQUIPMENT}.${rowIndex}.activitynum`,
    //       );

    //       if (isEmptyVal(selectedCostActivity)) {
    //         // Delete cost activity error object
    //       } else {
    //         const selectedCostActivityObject = poCostActivityList.filter(
    //           laborObj =>
    //             String(laborObj[PORateObjectKeyMapping['activity']]) ===
    //             String(selectedCostActivity),
    //         );

    //         if (!_.isEmpty(lookup?.purchaseOrderObject)) {
    //           const poObject = getPORateObject(
    //             lookup?.purchaseOrderObject,
    //             classificationName,
    //             rateschValue,
    //             codeValue,
    //             categoryValue,
    //           );

    //           if (!isEmptyVal(poObject)) {
    //             let rateValue = 0;
    //             if (name === 'opHours')
    //               rateValue = parseFloat(poObject?.operatingrate ?? 0);
    //             if (name === 'stHours')
    //               rateValue = parseFloat(poObject?.standbyrate ?? 0);
    //             if (name === 'otHours')
    //               rateValue = parseFloat(poObject?.otrate ?? 0);
    //             if (name === 'mobUnits')
    //               rateValue = parseFloat(poObject?.mobilization ?? 0);
    //             if (name === 'demobUnits')
    //               rateValue = parseFloat(poObject?.demobilization ?? 0);

    //             const tempActivityError = _.cloneDeep(activityError);
    //             if (
    //               name === 'opHours' ||
    //               name === 'stHours' ||
    //               name === 'otHours' ||
    //               name === 'mobUnits' ||
    //               name === 'demobUnits'
    //             ) {
    //               const remainingDollar = parseFloat(
    //                 selectedCostActivityObject[0].remainingdollar ?? 0,
    //               );
    //               const exceededAmount =
    //                 fieldValue * rateValue - remainingDollar;
    //               const errorMessage = `Allowed amount of activity have been exceeded by ${exceededAmount} dollar amount`;

    //               if (selectedCostActivityObject.length && exceededAmount > 0) {
    //                 if (
    //                   tempActivityError[classificationName].hasOwnProperty(
    //                     rowIndex,
    //                   )
    //                 ) {
    //                   tempActivityError[classificationName][rowIndex][name] =
    //                     errorMessage;
    //                 } else {
    //                   tempActivityError[classificationName][rowIndex] = {
    //                     [name]: errorMessage,
    //                   };
    //                 }
    //               } else {
    //                 if (
    //                   tempActivityError[classificationName].hasOwnProperty(
    //                     rowIndex,
    //                   )
    //                 ) {
    //                   if (
    //                     tempActivityError[classificationName][
    //                       rowIndex
    //                     ].hasOwnProperty(name)
    //                   ) {
    //                     delete tempActivityError[classificationName][rowIndex][
    //                       name
    //                     ];
    //                   }

    //                   if (
    //                     _.isEmpty(
    //                       tempActivityError[classificationName][rowIndex],
    //                     )
    //                   ) {
    //                     delete tempActivityError[classificationName][rowIndex];
    //                   }
    //                 }
    //               }
    //             }
    //             setActivityError(tempActivityError);
    //             const isActivityErr =
    //               !_.isEmpty(tempActivityError[SERVICE_TYPES.LABOR]) ||
    //               !_.isEmpty(tempActivityError[SERVICE_TYPES.EQUIPMENT]);
    //             setIsActivityError(isActivityErr);
    //           }
    //         }
    //       }
    //       break;
    //     }
    //     default:
    //     // Pass
    //   }
    // }
  };

  const isFutureDate = () => {
    let isFuture = false;
    const today = getMidnightDate(new Date());
    const endOfMonth = lastDayOfMonth(today);

    const isUvl = getValues('uvl');
    if (isUvl) {
      if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
        // weekEndDate > endOfMonth;
        isFuture = differenceInDays(endOfMonth, weekEndDate) < 0;
      } else {
        // startDate > endOfMonth || endDate > endOfMonth;
        isFuture =
          differenceInDays(endOfMonth, startDate) < 0 ||
          differenceInDays(endOfMonth, endDate) < 0;
      }
    } else {
      if ([BuPath.TRANS, ...BuPath.GEN].includes(purchaseOrder?.buPath)) {
        // startDate > today || endDate > today;
        isFuture =
          differenceInDays(today, startDate) < 0 ||
          differenceInDays(today, endDate) < 0;
      }
    }
    return isFuture;
  };

  const dateOutsidePORange = () => {
    if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
      return (
        weekEndDate < getMidnightDate(purchaseOrder?.startDate) ||
        weekEndDate > getMidnightDate(purchaseOrder?.endDate)
      );
    } else {
      return (
        startDate < getMidnightDate(purchaseOrder?.startDate) ||
        startDate > getMidnightDate(purchaseOrder?.endDate) ||
        endDate < getMidnightDate(purchaseOrder?.startDate) ||
        endDate > getMidnightDate(purchaseOrder?.endDate)
      );
    }
  };

  const hideServiceType = index => {
    const sTypeDiv = document.getElementById('serviceType' + index);
    const maxDiv = document.getElementById('showMaxDiv' + index);
    const minDiv = document.getElementById('showMinDiv' + index);

    if (sTypeDiv !== null || sTypeDiv != undefined) {
      const divDisplay = sTypeDiv.style.display;
      if (divDisplay === 'none') {
        sTypeDiv.style.display = '';
        if (maxDiv !== undefined && minDiv != undefined) {
          maxDiv.style.display = 'none';
          minDiv.style.display = '';
        }
      } else {
        sTypeDiv.style.display = 'none';
        if (maxDiv !== undefined && minDiv != undefined) {
          maxDiv.style.display = '';
          minDiv.style.display = 'none';
        }
      }
    }
  };

  const deleteDisEqRow = teq => {
    const equCodeIDArr = [];
    teq.map(({ equipCode, equipId }) => {
      if (equipCode != '' && equipId != '') {
        equCodeIDArr.push({ equipCode, equipId });
      }
    });
    const finaleqCodeIdArr = equCodeIDArr.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          t => t.equipCode === item.equipCode && t.equipId === item.equipId,
        ),
    );
    let adjustedTEqs = [];
    for (const fEqKey in finaleqCodeIdArr) {
      const selectedEqupArr = teq.filter(
        teqVal =>
          teqVal.equipCode == finaleqCodeIdArr[fEqKey].equipCode &&
          teqVal.equipId == finaleqCodeIdArr[fEqKey].equipId,
      );
      let isDisFalseArr = selectedEqupArr.filter(v => v.isDiscounted === false);
      let isDisTrueArr = selectedEqupArr.filter(v => v.isDiscounted === true);
      // remove row and adjust units in prev rows
      let lastIsDisFalseArr = {};
      let firstIsDisTrueArr = {};
      if (isDisFalseArr.length > 0 && isDisTrueArr.length > 0) {
        lastIsDisFalseArr = { ...isDisFalseArr.pop() };
        firstIsDisTrueArr = { ...isDisTrueArr.splice(0, 1)[0] };
        adjustedTEqs.push(...isDisFalseArr);
        lastIsDisFalseArr.equipmentRentalUnits +=
          firstIsDisTrueArr.equipmentRentalUnits;
        adjustedTEqs.push(lastIsDisFalseArr);
        if (isDisTrueArr.length > 0) {
          isDisTrueArr = isDisTrueArr.map(a =>
            Object.assign({}, a, { isDiscounted: false }),
          );
          adjustedTEqs.push(...isDisTrueArr);
        }
      } else if (isDisFalseArr.length > 0) {
        isDisFalseArr = isDisFalseArr.map(a =>
          Object.assign({}, a, { isDiscounted: false }),
        );
        adjustedTEqs.push(...isDisFalseArr);
      } else if (isDisTrueArr.length > 0) {
        isDisTrueArr = isDisTrueArr.map(a =>
          Object.assign({}, a, { isDiscounted: false }),
        );
        adjustedTEqs.push(...isDisTrueArr);
      }
    }
    return adjustedTEqs;
  };

  const handleTimesheetStatus = updateType => {
    switch (updateType) {
      case 'cancelTimesheet':
        if (
          newTimesheet ||
          timesheet?.caastatus === null ||
          timesheet?.caastatus === undefined ||
          timesheet?.caastatus === ''
        ) {
          navigate(`/${ROUTES_STRING.timesheets}`, {
            state: { from: 'timesheetDetail' },
          });
        } else if (
          timesheet?.caastatus ===
            TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED ||
          timesheet?.caastatus ===
            TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED
        ) {
          updateTimesheetWithCallbacks(
            { caastatus: TimesheetStatus.TIMESHEET_CANCELLED },
            timesheet.recordNmbr,
            timesheet?.caastatus,
          );
        } else if (
          timesheet?.caastatus ===
            TimesheetStatus.UVL_TIMESHEET_SAVED_NOT_SUBMITTED ||
          timesheet?.caastatus === TimesheetStatus.UVL_TIMESHEET_SUBMITTED
        ) {
          updateTimesheetWithCallbacks(
            { caastatus: TimesheetStatus.UVL_TIMESHEET_CANCELLED },
            timesheet.recordNmbr,
            timesheet?.caastatus,
          );
        }
        setShowCancelModal(false);
        break;

      case 'handleAccept':
        dispatch(setApproveTimesheetError(false));
        if (
          [
            TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
            TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
            TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED,
          ].includes(timesheet?.caastatus)
        ) {
          if (BuPath.GEN.includes(purchaseOrder?.buPath)) {
            const timesheetApprovals = [];
            if (
              timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED
            ) {
              let approverSelected = true;
              timesheet?.timesheetApprovals.forEach(value => {
                if (isEmptyVal(value?.approver)) {
                  approverSelected = false;
                }
                timesheetApprovals.push({
                  bupath: BuPathEnum.GEN,
                  approver: value?.approver,
                  role: value.role,
                  approved: 'P',
                  source: value?.source,
                });
              });

              if (!approverSelected) {
                setShowReviewerAcceptModal(false);
                displayError('Approver is required.');
                return;
              }
            } else if (
              [
                TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
                TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
              ].includes(timesheet?.caastatus)
            ) {
              timesheet?.timesheetApprovals.forEach(value => {
                const approverObject = {
                  bupath: BuPathEnum.GEN,
                  approver: value?.approver,
                  role: value.role,
                  approved: value?.approved == 'Y' ? 'Y' : 'P',
                };
                if (
                  value?.approver?.toLowerCase() === user?.userId?.toLowerCase()
                ) {
                  approverObject.approved = 'Y';
                }
                timesheetApprovals.push(approverObject);
              });
            }
            let allApproved = true;
            timesheetApprovals.forEach(tAppr => {
              if (tAppr.approved !== 'Y') {
                allApproved = false;
              }
            });

            let timesheetStatus =
              timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED
                ? TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED
                : allApproved
                ? TimesheetStatus.TIMESHEET_APPROVED
                : TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED;

            const timesheetStatushistories = [
              ..._.cloneDeep(timesheet?.timesheetStatushistories),
              {
                caastatus: timesheetStatus,
                comments: reviewerComment,
              },
            ];

            updateTimesheetWithCallbacks(
              {
                caastatus: timesheetStatus,
                reviewerComment: reviewerComment,
                timesheetApprovals,
                timesheetStatushistories,
              },
              timesheet.recordNmbr,
              timesheet?.caastatus,
            );
          } else {
            const timesheetApprovals = _.cloneDeep(
              timesheet?.timesheetApprovals,
            );
            timesheetApprovals[0].approved = 'Y';
            timesheetApprovals[0].approver = user.userId;
            updateTimesheetWithCallbacks(
              {
                caastatus: TimesheetStatus.TIMESHEET_APPROVED,
                reviewerComment: reviewerComment,
                timesheetApprovals,
                timesheetStatushistories: [
                  {
                    caastatus: TimesheetStatus.TIMESHEET_APPROVED,
                    comments: reviewerComment,
                  },
                ],
              },
              timesheet.recordNmbr,
              timesheet?.caastatus,
            );
          }
        }
        setShowReviewerAcceptModal(false);
        break;

      case 'handleDisagree':
        if (
          [
            TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
            TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
            TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED,
          ].includes(timesheet?.caastatus)
        ) {
          if (BuPath.GEN.includes(purchaseOrder?.buPath)) {
            const timesheetApprovals = [];
            if (
              timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED
            ) {
              timesheet?.timesheetApprovals.forEach(value => {
                timesheetApprovals.push({
                  bupath: BuPathEnum.GEN,
                  approver: value?.approver,
                  role: value.role,
                  approved: 'P',
                });
              });
            }

            const timesheetStatus =
              timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED
                ? TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED
                : TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED;

            const timesheetStatushistories = [
              ..._.cloneDeep(timesheet?.timesheetStatushistories),
              {
                caastatus: timesheetStatus,
                comments: reviewerComment,
              },
            ];

            updateTimesheetWithCallbacks(
              {
                caastatus: timesheetStatus,
                reviewerComment: reviewerComment,
                timesheetApprovals,
                timesheetStatushistories,
              },
              timesheet.recordNmbr,
              timesheet?.caastatus,
            );
          } else {
            const timesheetApprovals = _.cloneDeep(
              timesheet?.timesheetApprovals,
            );
            timesheetApprovals[0].approved = 'N';

            updateTimesheetWithCallbacks(
              {
                caastatus: TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED,
                reviewerComment: reviewerComment,
                timesheetApprovals,
                timesheetStatushistories: [
                  {
                    caastatus: TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED,
                    comments: reviewerComment,
                  },
                ],
              },
              timesheet.recordNmbr,
              timesheet?.caastatus,
            );
          }
        }
        setShowReviewerDisagreeModal(false);
        displayError('Timesheet is returned');
        break;

      case 'handleRecall':
        if (
          [
            TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
            TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
            TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED,
            TimesheetStatus.TIMESHEET_APPROVED,
          ].includes(timesheet?.caastatus)
        ) {
          // Check Equipment - Delete discounted record
          const recallTimesheetData = {
            caastatus: TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED,
          };
          if (timesheet?.timesheetEquipments.length > 0) {
            const adjustedTimesheetEq = deleteDisEqRow(
              timesheet.timesheetEquipments,
            );
            if (adjustedTimesheetEq && adjustedTimesheetEq.length > 0) {
              recallTimesheetData.timesheetEquipments = adjustedTimesheetEq;
            }
          }
          updateTimesheetWithCallbacks(
            recallTimesheetData,
            timesheet.recordNmbr,
            timesheet?.caastatus,
          );
        }
        break;

      case 'handleUpdate':
        updateTimesheetWithCallbacks(
          { caastatus: TimesheetStatus.TIMESHEET_APPROVED },
          timesheet.recordNmbr,
          timesheet?.caastatus,
        );
        break;

      case 'handleTimesheetSubmit':
        if (
          _.isEmpty(errors) &&
          !startDateError &&
          !endDateError &&
          !weekEndDateError &&
          !dateOutsidePORange() &&
          !isFutureDate() &&
          [
            TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED,
            TimesheetStatus.UVL_TIMESHEET_SAVED_NOT_SUBMITTED,
            TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED,
          ].includes(timesheet?.caastatus)
        ) {
          let timesheetStatus = TimesheetStatus.TIMESHEET_APPROVED;
          if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
            timesheetStatus = getValues('uvl')
              ? TimesheetStatus.UVL_TIMESHEET_SUBMITTED
              : TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED;
          } else if (BuPath.GEN.includes(purchaseOrder?.buPath)) {
            timesheetStatus = getValues('uvl')
              ? TimesheetStatus.UVL_TIMESHEET_SUBMITTED
              : purchaseOrder.reqtsappr
              ? TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED
              : TimesheetStatus.TIMESHEET_APPROVED;

            //   if (isEmptyVal(purchaseOrder?.costcoord)) {
            //     displayError('Cost coordinator on Purchase Order is not set.');
            //     return;
            //   }
            //   // else default to approver from cost activity
            // console.log({ payloadDataCloned });

            // const timesheetApprovals = [];

            // timesheet?.classificationList.forEach(value => {
            //   timesheetApprovals.push({
            //     bupath: BuPathEnum.GEN,
            //     approver: value.approver,
            //     role: value.role,
            //     approved: 'P',
            //   });
            // });

            // payloadDataCloned['caastatus'] =
            //   TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED;
            // payloadDataCloned['timesheetApprovals'] = timesheetApprovals;
          } else {
            timesheetStatus = getValues('uvl')
              ? TimesheetStatus.UVL_TIMESHEET_SUBMITTED
              : purchaseOrder.reqtsappr
              ? TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED
              : TimesheetStatus.TIMESHEET_APPROVED;
          }

          let timeStartDate = startDate;
          let timeEndDate = endDate;
          if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
            timeStartDate = getMidnightDate(weekEndDate, 6, 'sub');
            timeEndDate = getMidnightDate(weekEndDate);
          }

          updateTimesheetWithCallbacks(
            {
              caastatus: timesheetStatus,
              workStartDate: timeStartDate,
              workEndDate: timeEndDate,
              estimate: 'Y',
              uvlMonth: getMonth(startDate) + 1,
              uvlYear: getYear(startDate),
            },
            timesheet.recordNmbr,
            timesheet?.caastatus,
          );

          setShowModal(false);
        }
        break;

      default:
      //pass
    }
  };

  const handleDuplicateTimesheet = () => {
    setShowDuplicateModal(false);
    navigate(`/${ROUTES_STRING.timesheets}/new`, {
      state: { isDuplicateTimesheet: true, recordNmbr: timesheet?.recordNmbr },
    });
  };

  const handlePreviewInvoice = () => {
    setShowPreviewInvoiceModal(false);
    dispatch(
      // @ts-ignore
      fetchInvoiceData({
        poId: purchaseOrder?.poid,
        timesheetIds: timesheet?.recordNmbr,
        callback: () => {
          setShowInvoiceModal(true);
        },
      }),
    );
  };

  const handleGenerateInvoiceModal = () => {
    setCreateInvoiceError(false);
    if (
      startDate < getMidnightDate(purchaseOrder?.startDate) ||
      startDate > getMidnightDate(purchaseOrder?.endDate) ||
      endDate < getMidnightDate(purchaseOrder?.startDate) ||
      endDate > getMidnightDate(purchaseOrder?.endDate)
    ) {
      displayError('Out of PO Date Range');
      setShowGenerateInvoiceModal(false);
      return;
    }

    lookup?.invoiceDates.forEach(obj => {
      const isDuplicate =
        startDate === getMidnightDate(obj.week_start_date) &&
        endDate === getMidnightDate(obj.week_end_date);
      if (isDuplicate) {
        displayError(
          'Invoices have already been initiated for same dates worked',
        );
        setShowGenerateInvoiceModal(false);
        return;
      }
    });

    setShowGenerateInvoiceModal(true);
  };

  const hideErrorModel = () => {
    setCreateInvoiceError(false);
  };

  const returnTimesheet = () => {
    let timesheetIds = [timesheet?.recordNmbr];
    dispatch(
      returnTimesheets({
        timesheetIds,
        callback: respData => {
          if (respData?.data?.error) {
            setCreateInvoiceError(true);
          } else {
            setCreateInvoiceError(false);
            displayCompletionFormStatus(
              'Timesheet associated with the Invoice has been returned',
            );
            dispatch(
              // @ts-ignore
              fetchTimesheetDetail({
                recordNumber: timesheet.recordNmbr,
              }),
            );
          }
        },
      }),
    );
  };

  const handleGenerateInvoice = () => {
    setShowGenerateInvoiceModal(false);
    dispatch(
      // @ts-ignore
      fetchInvoiceUserEntitlements({
        entitlement: 'CAA-AEP-Invoice-Verifier',
        callback: () => {
          dispatch(fetchInvoiceApprovers());
        },
      }),
    );

    dispatch(
      // @ts-ignore
      fetchInvoiceData({
        poId: purchaseOrder?.poid,
        timesheetIds: timesheet?.recordNmbr,
        validateAccounts: true,
        callback: respData => {
          if (respData?.status !== 200) {
            if (respData?.data?.details) {
              setCreateInvoiceError(true);
              setCreateInvoiceErrorMesage(respData?.data?.details);
            }
          } else {
            navigate(
              {
                pathname: `/${ROUTES_STRING.invoices}/new`,
                search: `${createSearchParams({
                  poId: purchaseOrder?.poid,
                  timesheetIds: timesheet?.recordNmbr,
                  includeComments: String(invoiceComments),
                })}`,
              },
              {
                state: { status: InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED },
              },
            );
          }
        },
      }),
    );
  };

  const confirmSubmitTimesheet = () => {
    if (
      timesheet?.[SERVICE_TYPES.REIMBURSABLE_MARKUP].length &&
      !timesheet?.timesheetAttachments.length &&
      !uploadedFilesArray.length
    ) {
      displayError('There is no attachment for the timesheet.');
    }
    setShowModal(true);
  };

  const handleBack = () => {
    navigate(`/${ROUTES_STRING.timesheets}`, {
      state: { from: 'timesheetDetail' },
    });
  };

  const computeMilesCompleteError = () => {
    const selectedCircuit = getValues('circuitnum');
    const selectedCircuitObject = lookup?.circuitObjectList?.find(
      obj => obj.circuitnum === selectedCircuit,
    );
    if (!isEmptyVal(selectedCircuitObject)) {
      const lineMiles = selectedCircuitObject?.linemiles ?? 0;
      const completedMiles = selectedCircuitObject?.completedmiles ?? 0;
      const enteredMiles = getValues('milescomplete') ?? 0;
      const exceededBy = (lineMiles - completedMiles - enteredMiles).toFixed(2);

      if (exceededBy < 0) {
        setMilesCompleteError(
          `Miles complete have exceeded total by ${exceededBy.replace(
            '-',
            '',
          )}`,
        );
      } else {
        setMilesCompleteError('');
      }

      if (isEmptyVal(enteredMiles)) {
        setMilesCompleteError('');
      }
    }
  };

  const addUnitPriceTask = responseData => {
    let tempServiceTypeOptions = _.cloneDeep(serviceTypeOptions);
    let tempServiceTypeOptionList = _.cloneDeep(serviceTypeOptionList);
    const upTaskObj = serviceTypeOptions.find(
      obj => obj.value === SERVICE_TYPES.UNIT_PRICE_TASK,
    );
    const upTask = serviceTypeOptionList.indexOf(SERVICE_TYPES.UNIT_PRICE_TASK);

    if (isEmptyVal(upTaskObj)) {
      tempServiceTypeOptions.push(serviceTypes[3]);
    }
    if (upTask < 0) {
      tempServiceTypeOptionList.push(serviceTypeList[3]);
    }
    setServiceTypeOptions(tempServiceTypeOptions);
    setServiceTypeOptionList(tempServiceTypeOptionList);

    const jobAuthTasks = responseData?.jobauthtasks ?? [];
    const classification = SERVICE_TYPES.UNIT_PRICE_TASK;
    let tempClassifications = _.cloneDeep(timesheet?.classificationList);
    let index = tempClassifications.findIndex(
      obj => obj.name === classification,
    );

    if (jobAuthTasks?.length) {
      if (index < 0) {
        tempClassifications.push({
          name: classification,
          values: [],
        });
      }

      index = tempClassifications.findIndex(obj => obj.name === classification);
      tempClassifications[index]['values'] = [];
      const codeOptions = [{ label: 'Select', value: '' }];
      jobAuthTasks.forEach(obj => {
        codeOptions.push({ label: obj?.codelabel, value: obj?.taskcode });
        tempClassifications[index]['values'].push(
          _.cloneDeep(classificationList[classification]),
        );
      });

      // Auto populate fields
      index = tempClassifications.findIndex(
        obj => obj.name === SERVICE_TYPES.UNIT_PRICE_TASK,
      );
      jobAuthTasks.forEach((jobAuthTask, idx) => {
        tempClassifications[index].values[idx][1]['values'] = codeOptions;
        tempClassifications[index].values[idx][1]['value'] =
          jobAuthTask?.taskcode;
        tempClassifications[index].values[idx][2]['value'] =
          purchaseOrder?.costActivities?.[0]?.costactivityid;
        tempClassifications[index].values[idx][4]['value'] =
          jobAuthTask?.unitype;
        tempClassifications[index].values[idx][5]['value'] = 0;
        tempClassifications[index].values[idx][10]['value'] =
          jobAuthTask?.dollarsPerUnit;
        tempClassifications[index].values[idx][11]['value'] = 0;
      });
      dispatch(setClassificationList(_.cloneDeep(tempClassifications)));
      updateFormValues(tempServiceTypeOptionList, tempClassifications);
      getTimesheetApproverOptions(_.cloneDeep(tempClassifications), timesheet);
    }
  };

  const addProdHoursTask = () => {
    let tempServiceTypeOptions = _.cloneDeep(serviceTypeOptions);
    let tempServiceTypeOptionList = _.cloneDeep(serviceTypeOptionList);
    const serviceType = SERVICE_TYPES.PRODUCTION_HOURS;
    const prodHourObj = serviceTypeOptions.find(
      obj => obj.value === serviceType,
    );
    const srvcTypeOptionProdHrIdx = serviceTypeOptionList.indexOf(serviceType);

    if (isEmptyVal(prodHourObj)) {
      const prodHourIdx = serviceTypes.findIndex(e => e.value === serviceType);
      tempServiceTypeOptions.push(serviceTypes[prodHourIdx]);
    }
    if (srvcTypeOptionProdHrIdx < 0) {
      const prodHourIdx = serviceTypeList.findIndex(e => e === serviceType);
      tempServiceTypeOptionList.push(serviceTypeList[prodHourIdx]);
    }

    setServiceTypeOptions(tempServiceTypeOptions);
    setServiceTypeOptionList(tempServiceTypeOptionList);

    let tempClassifications = _.cloneDeep(timesheet?.classificationList);
    let index = tempClassifications.findIndex(obj => obj.name === serviceType);

    if (index < 0) {
      tempClassifications.push({
        name: serviceType,
        values: [],
      });
    }
  };

  const removeServiceTypeOption = serviceType => {
    let tempServiceTypeOptions = _.cloneDeep(serviceTypeOptions);
    let tempServiceTypeOptionList = _.cloneDeep(serviceTypeOptionList);
    const srvcTypeOptionIdx = serviceTypeOptions.indexOf(serviceType);
    const srvcTypeOptionListIdx = serviceTypeOptionList.indexOf(serviceType);

    if (srvcTypeOptionIdx >= 0) {
      tempServiceTypeOptions.splice(srvcTypeOptionIdx, 1);
      setServiceTypeOptions(tempServiceTypeOptions);
    }

    if (srvcTypeOptionListIdx >= 0) {
      tempServiceTypeOptionList.splice(srvcTypeOptionListIdx, 1);
      setServiceTypeOptionList(tempServiceTypeOptionList);
    }
  };

  const displayUPTaskForm = (classification, row, col) => {
    let display = true;
    if (classification === SERVICE_TYPES.UNIT_PRICE_TASK) {
      if (!isJobAuthUnitPriceTask && col.name === 'hours') {
        display = false;
      } else if (isJobAuthUnitPriceTask) {
        if (
          [
            'code',
            'unitmeasure',
            'quantity',
            'totalPrice',
            'lineAmount',
          ].includes(col.name)
        ) {
          display = true;
        } else if (col.name === 'hours') {
          display = ['BC', 'BS', 'TR', 'TT', 'WP'].includes(row[1].value);
        } else {
          display = false;
        }
      }
    }
    return display;
  };

  const handleAuthorizationChange = (e, authorizationType) => {
    setValue('circuitnum', '');
    let isUnitPriceTask = _.cloneDeep(isJobAuthUnitPriceTask);
    if (authorizationType === 'authType') {
      setValue('authType', e.target.value);
      if (
        e?.target?.value === AuthType.UNIT_PRICE &&
        !isEmptyVal(getValues('authNmbr'))
      ) {
        isUnitPriceTask = true;
      } else {
        isUnitPriceTask = false;
      }

      if (
        e?.target?.value?.trim() === AuthType.TIME_AND_MATERIAL ||
        isEmptyVal(e?.target?.value)
      ) {
        setValue('authNmbr', '');
        isUnitPriceTask = false;
        schema = generateSchema(isUnitPriceTask);
        setIsJobAuthUnitPriceTask(isUnitPriceTask);

        // Delete classification
        const upTaskIndex = timesheet?.classificationList.findIndex(
          obj => obj.name === SERVICE_TYPES.UNIT_PRICE_TASK,
        );
        deleteClassification(upTaskIndex);

        if (
          purchaseOrder?.buPath === 'FORESTRY' &&
          e?.target?.value?.trim() === AuthType.TIME_AND_MATERIAL
        ) {
          let timesheetCopy = _.cloneDeep(timesheet);

          if (_.isNil(timesheetCopy['authType'])) {
            timesheetCopy['authType'] = AuthType.TIME_AND_MATERIAL;
          }
          addProdHoursTask();
        }
      }

      if (e?.target?.value === AuthType.UNIT_PRICE) {
        // Delete classification
        const upTaskIndex = timesheet?.classificationList.findIndex(
          obj => obj.name === SERVICE_TYPES.UNIT_PRICE_TASK,
        );
        deleteClassification(upTaskIndex);

        // Delete Prod Hour classification
        const prodHourIndex = timesheet?.classificationList.findIndex(
          obj => obj.name === SERVICE_TYPES.PRODUCTION_HOURS,
        );
        if (prodHourIndex >= 0) {
          // Remove prod hours classification and service type
          // from the form.
          dispatch(setTriggerClassificationList(true));
        } else {
          // Ensure valid service types are displayed
          removeServiceTypeOption(SERVICE_TYPES.PRODUCTION_HOURS);
        }
      }
    } else if (authorizationType === 'authNmbr') {
      setValue('authNmbr', e?.value);
      if (
        getValues('authType') === AuthType.UNIT_PRICE &&
        !isEmptyVal(e?.value)
      ) {
        isUnitPriceTask = true;
      } else {
        isUnitPriceTask = false;
        // Delete classification
        const upTaskIndex = timesheet?.classificationList.findIndex(
          obj => obj.name === SERVICE_TYPES.UNIT_PRICE_TASK,
        );
        deleteClassification(upTaskIndex);
      }

      setIsJobAuthUnitPriceTask(isUnitPriceTask);
      schema = generateSchema(isUnitPriceTask);

      if (!isEmptyVal(e?.value)) {
        dispatch(
          // @ts-ignore
          fetchJobAuthorization({
            jobAuthorizationId: e.value,
            limit: 500,
            callback: responseData => {
              addUnitPriceTask(responseData);
              dispatch(
                // @ts-ignore
                fetchCircuit({
                  workAreaId: responseData.workAreaId,
                  poNum: purchaseOrder?.ponum,
                  authNmbr: e.label,
                  opco: getValues('ocNmbr'),
                  limit: -1,
                }),
              );
            },
          }),
        );
      }
    }
    trigger('authType');
  };

  const handleAuthInputChange = value => {
    if (!isEmptyVal(value)) {
      dispatch(
        // @ts-ignore
        fetchJobAuthorizations({
          ponum: purchaseOrder?.ponum,
          limit: 500,
          search: value,
        }),
      );
    }
  };
  const debouncedAuthorizationChange = _.debounce(handleAuthInputChange, 500);

  const handleCircuitChange = value => {
    if (!isEmptyVal(value) && !isEmptyVal(getValues('authNmbr'))) {
      dispatch(
        // @ts-ignore
        fetchJobAuthorization({
          jobAuthorizationId: getValues('authNmbr'),
          callback: responseData => {
            dispatch(
              // @ts-ignore
              fetchCircuit({
                workAreaId: responseData.workAreaId,
                search: value,
                limit: -1,
              }),
            );
          },
        }),
      );
    }
  };
  const debouncedCircuitChange = _.debounce(handleCircuitChange, 500);

  const handleWorkArea = e => {
    // @ts-ignore
    setValue('workAreaId', Number(e.target.value));
    if (
      (!getValues('authNmbr') && !getValues('circuitnum')) ||
      (!getValues('authNmbr') && getValues('circuitnum'))
    ) {
      setValue('circuitnum', '');
    }
    /********************************************** */
    /*** Auto populate accounting field `dept` *** */
    /******************************************** */
    // Event index starts from 1, so subtract 1 to get zero based index
    const selectedIndex = e.target.value;
    const values = getValues('timesheetAccounts');
    const workAreaObj = lookup?.workArea?.filter(
      item => item.workAreaId == selectedIndex,
    );

    if (purchaseOrder?.buPath == 'FORESTRY' && workAreaObj[0]?.approver) {
      if (!timesheetApprovals[0]?.approver) {
        handleTimesheetApproval(workAreaObj[0]?.approver, 0);
      }
    }

    if (values.length) {
      if (isEmptyVal(workAreaObj)) {
        values[0].dept = '';
      } else {
        values[0].dept = _.isNull(workAreaObj[0]?.orgDept?.departmentId)
          ? ''
          : workAreaObj[0]?.orgDept?.departmentId;
      }
    }
    dispatch(
      // @ts-ignore
      fetchCircuit({
        workAreaId: getValues('workAreaId'),
        opco: getValues('ocNmbr'),
        poNum: purchaseOrder?.ponum,
        authNmbr: getValues('authNmbr'),
        limit: -1,
      }),
    );
    setValue('timesheetAccounts', _.cloneDeep(values));
  };

  const handleOpco = e => {
    setDefaultOpco('');
    setValue('workAreaId', '');
    if (
      (!getValues('authNmbr') && !getValues('circuitnum')) ||
      (!getValues('authNmbr') && getValues('circuitnum'))
    ) {
      setValue('circuitnum', '');
    }

    setValue('ocNmbr', Number(e.target.value));
    dispatch(
      // @ts-ignore
      fetchWorkArea({
        ponum: purchaseOrder?.ponum,
        opco: getValues('ocNmbr'),
      }),
    );
  };

  const handleWorkType = e => {
    setValue('worktype', e.target.value);

    /**************************************** */
    /*** Auto populate accounting fields *** */
    /************************************** */
    // Event index starts from 1, so subtract 1 to get zero based index
    let timesheetType = timesheet?.timesheetType?.trim();
    // fetch accounting details if not STORMS Timesheet
    if (timesheetType !== 'F') {
      const selectedIndex = e.target.selectedIndex - 1;
      const values = getValues('timesheetAccounts');
      let ocnum = getValues('ocNmbr') ? getValues('ocNmbr') : defaultOpco;
      const workTypeObj = lookup?.workType[selectedIndex];
      let getWorkTypeObj = workTypeObj?.worktypeOcs?.findIndex(
        ele => ocnum === ele.ocNmbr,
      );
      let getWorkTypeObjIdx = getWorkTypeObj != -1 ? getWorkTypeObj : 0;
      if (values.length) {
        if (isEmptyVal(workTypeObj)) {
          values[0].workorder = '';
          values[0].abmsProject = '';
          values[0].accountNmbr = null;
          values[0].costComponent = '';
          values[0].abmsActivity = '';
        } else {
          values[0].workorder =
            workTypeObj.worktypeOcs?.[getWorkTypeObjIdx]?.workOrder ?? '';
          values[0].abmsProject =
            workTypeObj.worktypeOcs?.[getWorkTypeObjIdx]?.abmsProject ?? '';
          values[0].accountNmbr = Number(workTypeObj.accountNmbr) ?? null;
          values[0].costComponent = workTypeObj.costComponent ?? '';
          values[0].abmsActivity = workTypeObj.abmsActivity ?? '';
        }
      }
      setValue('timesheetAccounts', _.cloneDeep(values));
    }
  };

  const isCostCoordinator = () => {
    let isCostCoord = false;
    if (
      (user?.configObject?.userrole === 'admin' ||
        purchaseOrder?.costcoord?.toLowerCase() ===
          user?.userId?.toLowerCase()) &&
      IsAllowedACL(
        user,
        ModuleACL.TIMESHEET,
        'can_review_costcord_timesheet',
      ) &&
      timesheet?.caastatus === TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED
    ) {
      isCostCoord = true;
    }
    return isCostCoord;
  };

  const getApproverMenuOptions = () => {
    const options = [];
    timesheetApproverOptions.forEach((approver, index) => {
      options.push({
        label: approver.label,
        value: approver.value,
      });
    });

    let sortedValue = options
      ?.slice()
      .sort((option1, option2) =>
        String(option1.label)
          .trim()
          .localeCompare(String(option2.label).trim()),
      );

    return sortedValue;
  };

  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <Form.Group>
            <Avatar />
            <label style={{ marginLeft: '10px', fontSize: '16px' }}>
              {props.label}
            </label>
          </Form.Group>
        </components.Option>
      </div>
    );
  };

  const SingleValue = props => {
    const approver = timesheet?.timesheetApprovals.find(
      obj => obj?.approver === props.value,
    );
    let isApproved = isEmptyVal(approver) ? false : approver.approved === 'Y';
    return (
      <div style={{ position: 'absolute', top: '7px' }}>
        <components.Option {...props}>
          <Form.Group>
            <Avatar />
            <label style={{ margin: '0 10px', fontSize: '16px' }}>
              {props.data.label}
            </label>
            {isApproved ? <ApprovedIcon /> : <PendingIcon />}
          </Form.Group>
        </components.Option>
      </div>
    );
  };

  /***************************************** */
  /**** Timesheet Attachment helpers ****** */
  /*************************************** */
  const handleFileUpload = files => {
    const tempUploadedFilesArray = [...uploadedFilesArray, ...files];
    setUploadedFilesArray(tempUploadedFilesArray);
  };

  const handleDeleteFileFromArray = index => {
    const tempUploadedFilesArray = _.cloneDeep(uploadedFilesArray);
    tempUploadedFilesArray.splice(index, 1);
    setUploadedFilesArray(tempUploadedFilesArray);
  };

  const hideApproverErrorModel = () => {
    dispatch(setApproveTimesheetError(false));
    dispatch(setApproveCircuitMileageError(false));
  };

  const uploadFilesToServer = async recordNmbr => {
    const uploadService = [];
    for (const fileToUpload of uploadedFilesArray) {
      uploadService.push(uploadFiles(fileToUpload, recordNmbr, 'timesheets'));
    }
    const fileInfo = await Promise.all(uploadService);
    setUploadedFilesArray([]);
    return fileInfo;
  };

  const handleDeleteFileFromServer = async (item, index) => {
    const canSave = await confirm({
      message:
        'Are you sure you would like to delete the saved file from timesheet ?',
      title: 'Confirm Delete',
    });
    if (!canSave) {
      return;
    }
    const deleted = await deleteFile(
      item.timeAttachmentNmbr,
      timesheet.recordNmbr,
      'timesheets',
    );
    timesheet?.timesheetAttachments.slice(index, 0);
    // @ts-ignore
    dispatch(fetchTimesheetDetail({ recordNumber: timesheet.recordNmbr }));
  };

  const viewFileTimesheet = async fileInfo => {
    await viewFile(
      'timesheets/' +
        fileInfo.recordNmbr +
        '/attachments/' +
        fileInfo.timeAttachmentNmbr,
      fileInfo.fileName,
    );
  };

  const viewFile = async (url, filename, file) => {
    await fileViewer({ url, filename, file });
  };

  const handleTimeSheetDownload = timesheetRecordNmbr => {
    let url = {
      name: `Timesheet_${timesheetRecordNmbr}`,
      url: `/timesheets/export/${timesheetRecordNmbr}`,
    };
    excelDownloadTimesheet(url, accessToken);
  };

  const getMobileHeaderView = () => {
    return (
      <>
        {timesheet?.vendorVendor?.vendor === purchaseOrder?.vendorID && (
          <Row>
            <Col style={{ display: 'flex', margin: '5' }}>
              <span style={{ color: '#DA291C', marginLeft: '38px' }}>
                <b>{timesheet?.vendorVendor?.vendorName}</b>
              </span>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={6} style={{ display: 'flex', margin: '5' }}>
            <div className="backArrow" title="Back" onClick={handleBack}>
              <BackArrowIcon />
            </div>
            Contract No: {purchaseOrder.contractRefnum} | PO:{' '}
            {purchaseOrder.ponum}
          </Col>
          <Col sm={6} style={{ margin: '5' }}>
            <Row className="float-left">
              <div className="statusIcon" style={{ marginRight: '18px' }}></div>
              Status:&nbsp;
              {newTimesheet
                ? TimesheetStatusDisplayValue[
                    TimesheetStatus.TIMESHEET_ENTERED_NOT_SAVED
                  ]
                : TimesheetStatusDisplayValue[timesheet.caastatus]}
            </Row>
          </Col>
        </Row>
        <Row>
          {location.pathname.split('/').length &&
            location.pathname.split('/')[
              location.pathname.split('/').length - 1
            ] !== 'new' && (
              <Col sm={12}>
                <div style={{ marginLeft: '38px' }}></div>
                <span style={{ fontSize: '16px', marginLeft: '45px' }}>
                  <b>
                    Timesheet Record{' '}
                    {
                      location.pathname.split('/')[
                        location.pathname.split('/').length - 1
                      ]
                    }
                  </b>
                </span>
              </Col>
            )}
        </Row>
      </>
    );
  };

  return (
    <>
      <div className="aepContainer aepHeader">
        <Row className="justify-content-between">
          <Col sm={6}>Timesheets</Col>
          <Col sm={6}>
            <HeaderButtons
              setShowReviewerDisagreeModal={setShowReviewerDisagreeModal}
              setShowPreviewInvoiceModal={setShowPreviewInvoiceModal}
              setShowDuplicateModal={setShowDuplicateModal}
              newTimesheet={newTimesheet}
              handleTimeSheetDownload={handleTimeSheetDownload}
            />
          </Col>
        </Row>
      </div>
      <div className="aepContainer aepBreadcrumb">
        {isMobile ? (
          getMobileHeaderView()
        ) : (
          <>
            {timesheet?.vendorVendor?.vendor === purchaseOrder?.vendorID && (
              <Row>
                <Col style={{ display: 'flex' }}>
                  <span style={{ color: '#DA291C', marginLeft: '38px' }}>
                    <b>{timesheet?.vendorVendor?.vendorName}</b>
                  </span>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={6} style={{ display: 'flex' }}>
                <div className="backArrow" title="Back" onClick={handleBack}>
                  <BackArrowIcon />
                </div>
                Contract No: {purchaseOrder.contractRefnum} | PO:{' '}
                {purchaseOrder.ponum}
              </Col>
              <Col sm={6}>
                <Row className="float-right">
                  <div className="statusIcon"></div>Status:&nbsp;
                  {newTimesheet
                    ? TimesheetStatusDisplayValue[
                        TimesheetStatus.TIMESHEET_ENTERED_NOT_SAVED
                      ]
                    : TimesheetStatusDisplayValue[timesheet.caastatus]}
                </Row>
              </Col>
            </Row>
            <Row>
              {location.pathname.split('/').length &&
                location.pathname.split('/')[
                  location.pathname.split('/').length - 1
                ] !== 'new' && (
                  <Col sm={12}>
                    <div style={{ marginLeft: '38px' }}></div>
                    <span style={{ fontSize: '16px', marginLeft: '26px' }}>
                      <b>
                        Timesheet Record{' '}
                        {
                          location.pathname.split('/')[
                            location.pathname.split('/').length - 1
                          ]
                        }
                      </b>
                    </span>
                  </Col>
                )}
            </Row>
          </>
        )}
      </div>

      {!timesheetLoading &&
        !invoiceDataLoading &&
        !modifytimesheetLoading &&
        !isLoading && (
          <FormProvider {...methods}>
            <Form>
              <TimesheetHeader
                control={control}
                trigger={trigger}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                selectedOpco={defaultOpco}
                weekEndDate={weekEndDate}
                handleWeekEndDate={handleWeekEndDate}
                weekEndDateError={weekEndDateError}
                weekEndDateErrorMessage={weekEndDateErrorMessage}
                startDate={startDate}
                handleStartDate={handleStartDate}
                startDateError={startDateError}
                startDateErrorMessage={startDateErrorMessage}
                endDate={endDate}
                handleEndDate={handleEndDate}
                endDateError={endDateError}
                endDateErrorMessage={endDateErrorMessage}
                isDuplicateDate={isDuplicateDate}
                handleAuthorizationChange={handleAuthorizationChange}
                handleWorkArea={handleWorkArea}
                handleOpco={handleOpco}
                handleWorkType={handleWorkType}
                milesCompleteError={milesCompleteError}
                computeMilesCompleteError={computeMilesCompleteError}
                newTimesheet={newTimesheet}
                disableFields={disableFields}
                handleTimesheetApproval={handleTimesheetApproval}
                timesheetApproverOptions={timesheetApproverOptions}
                handleUvlEstimateChange={handleUvlEstimateChange}
                debouncedAuthorizationChange={debouncedAuthorizationChange}
                debouncedCircuitChange={debouncedCircuitChange}
                getApproverMenuOptions={getApproverMenuOptions}
                isCostCoordinator={isCostCoordinator}
                Option={Option}
                setError={setError}
              />

              {isDistribution && (
                <DistributionAccounting
                  control={control}
                  trigger={trigger}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                />
              )}

              {isGeneration && (
                <TimesheetApproverField
                  control={control}
                  getApproverMenuOptions={getApproverMenuOptions}
                  handleTimesheetApproval={handleTimesheetApproval}
                  timesheetApproverOptions={timesheetApproverOptions}
                  Option={Option}
                  SingleValue={SingleValue}
                  newTimesheet={newTimesheet}
                />
              )}

              {isTransmission && (
                <TransmissionAccountingFields
                  control={control}
                  trigger={trigger}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  transmissionWO={transmissionWO}
                  setTransmissionWO={setTransmissionWO}
                  fetchWorkOrder={fetchWorkOrder}
                />
              )}

              <ClassificationFields
                control={control}
                errors={errors}
                disableFields={disableFields}
                serviceTypeOptions={serviceTypeOptions}
                deleteClassification={deleteClassification}
                hideServiceType={hideServiceType}
                handleRowClick={handleRowClick}
                checkedRows={checkedRows}
                updateCheckedRows={updateCheckedRows}
                deleteClassificationRows={deleteClassificationRows}
                displayUPTaskForm={displayUPTaskForm}
                isJobAuthUnitPriceTask={isJobAuthUnitPriceTask}
                activityError={activityError}
                handleSelectOnChange={handleSelectOnChange}
                serviceTypeOptionList={serviceTypeOptionList}
                handleSelectChange={handleSelectChange}
                getAddedClassification={getAddedClassification}
                setShowServiceTypes={setShowServiceTypes}
                handleFieldOnChange={handleFieldOnChange}
                showServiceTypes={showServiceTypes}
              />

              <AttachmentArea
                disableFields={disableFields}
                setShowAttachment={setShowAttachment}
                uploadedFilesArray={uploadedFilesArray}
                handleDeleteFileFromArray={handleDeleteFileFromArray}
                viewFileTimesheet={viewFileTimesheet}
                viewFile={viewFile}
                handleDeleteFileFromServer={handleDeleteFileFromServer}
              />

              {(timesheet.enteredBy ||
                timesheet.enteredDate ||
                timesheet.updatedBy ||
                timesheet.updatedDate) && (
                <ShowEnteredByDate
                  enteredBy={timesheet.enteredBy}
                  enteredDate={timesheet.enteredDate}
                  updatedBy={timesheet.updatedBy}
                  updatedDate={timesheet.updatedDate}
                />
              )}

              <ButtonComponent
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                onError={onError}
                startDateError={startDateError}
                endDateError={endDateError}
                weekEndDateError={weekEndDateError}
                dateOutsidePORange={dateOutsidePORange}
                isFutureDate={isFutureDate}
                milesCompleteError={milesCompleteError}
                isActivityError={isActivityError}
                disableFields={disableFields}
                newTimesheet={newTimesheet}
                timesheetApprovals={timesheetApprovals}
                isCostCoordinator={isCostCoordinator}
                invoiceComments={invoiceComments}
                setInvoiceComments={setInvoiceComments}
                setShowCancelModal={setShowCancelModal}
                setShowReviewerDisagreeModal={setShowReviewerDisagreeModal}
                handleTimesheetStatus={handleTimesheetStatus}
                handleGenerateInvoiceModal={handleGenerateInvoiceModal}
                isJobAuthUnitPriceTask={isJobAuthUnitPriceTask}
              />
            </Form>
          </FormProvider>
        )}

      <ClipLoader
        color="#009cde"
        loading={
          timesheetLoading ||
          invoiceDataLoading ||
          modifytimesheetLoading ||
          getWorkType ||
          isLoading
        }
        cssOverride={{
          top: '50vh',
          left: '50vw',
          position: 'absolute',
          zIndex: 1,
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />

      {getValues('uvl') ? (
        <ConfirmationModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleContinueClick={() =>
            handleTimesheetStatus('handleTimesheetSubmit')
          }
          title="Submit UVL Estimate"
          body="Submit UVL Estimate Record Submission?"
        />
      ) : (
        <ConfirmationModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleContinueClick={() =>
            handleTimesheetStatus('handleTimesheetSubmit')
          }
          title="Submit Timesheet"
          body={
            isChangeAccounting
              ? 'System failed to find the WO. Do you still want to submit the timesheet with the existing Work Order.'
              : 'Submit New Timesheet?'
          }
        />
      )}

      <ConfirmationModal
        showModal={showDuplicateModal}
        setShowModal={setShowDuplicateModal}
        handleContinueClick={handleDuplicateTimesheet}
        title="Duplicate Timesheet"
        body="Do you want to duplicate the Timesheet?"
      />

      <ConfirmationModal
        showModal={showCancelModal}
        setShowModal={setShowCancelModal}
        handleContinueClick={() => handleTimesheetStatus('cancelTimesheet')}
        title="Cancel Timesheet"
        body={'Are you sure you want to cancel ?'}
      />

      <ConfirmationModal
        showModal={showGenerateInvoiceModal}
        setShowModal={setShowGenerateInvoiceModal}
        handleContinueClick={handleGenerateInvoice}
        title="Generate Invoice"
        body="Do you want to generate invoice?"
      />

      <ConfirmationModal
        showModal={showPreviewInvoiceModal}
        setShowModal={setShowPreviewInvoiceModal}
        handleContinueClick={handlePreviewInvoice}
        title="Preview Invoice?"
        body="Any unsaved changes won't be used for invoice computations."
      />

      <PreviewInvoiceModal
        showModal={showInvoiceModal}
        setShowModal={setShowInvoiceModal}
      />

      <ReviewerModal
        control={control}
        showModal={showReviewerAcceptModal}
        setShowModal={setShowReviewerAcceptModal}
        reviewerComment={reviewerComment}
        setReviewerComment={setReviewerComment}
        handleContinueClick={() => handleTimesheetStatus('handleAccept')}
        title="Accept Record Submission?"
        buttonType="Accept"
      />

      <ReviewerModal
        control={control}
        showModal={showReviewerDisagreeModal}
        setShowModal={setShowReviewerDisagreeModal}
        reviewerComment={reviewerComment}
        setReviewerComment={setReviewerComment}
        handleContinueClick={() => handleTimesheetStatus('handleDisagree')}
        title="Disagree Record Submission?"
        buttonType="Disagree"
      />

      <AttachmentModal
        showModal={showAttachment}
        setShowModal={setShowAttachment}
        setFileUploadInprogress={setFileUploadInprogress}
        uploadedFilesArray={uploadedFilesArray}
        handleFileUpload={handleFileUpload}
        handleDeleteFileFromArray={handleDeleteFileFromArray}
        fileUploadInprogress={fileUploadInprogress}
      />

      <PDFModal
        showModal={showAttachmentPDF}
        setShowModal={setShowAttachmentPDF}
        pdfDocumentUrl={pdfDocumentUrl}
      />

      <TimesheetErrorModal
        onShow={approveTimesheetError || approveCircuitMileageError}
        onHide={hideApproverErrorModel}
        handleTimesheetStatus={handleTimesheetStatus}
        approveTimesheetError={approveTimesheetError}
        approveCircuitMileageError={approveCircuitMileageError}
      />

      <InvoiceErrorModal
        onShow={createInvoiceError}
        onHide={hideErrorModel}
        returnTimesheet={returnTimesheet}
        handleGenerateInvoiceModal={handleGenerateInvoiceModal}
        invoiceErrorMessage={createInvoiceErrorMessage}
      />
    </>
  );
};

export { TimesheetEntry };
