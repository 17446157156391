import { SERVICE_TYPES } from 'enums/serviceTypes.ts';

export const PORateKeyMapping = {
  poActivity: 'costActivities',
  activity: 'pocostactivities',
  [SERVICE_TYPES.LABOR]: 'polaborrates',
  [SERVICE_TYPES.EQUIPMENT]: 'poeqptrates',
  [SERVICE_TYPES.REIMBURSABLE_MARKUP]: 'poexprates',
  [SERVICE_TYPES.UNIT_PRICE_TASK]: 'pouprates',
  [SERVICE_TYPES.CU]: 'pocurates',
};

export const PORateObjectKeyMapping = {
  activity: 'costactivityid',
  [SERVICE_TYPES.LABOR]: 'polaborrateid',
  [SERVICE_TYPES.EQUIPMENT]: 'poeqptrateid',
  [SERVICE_TYPES.REIMBURSABLE_MARKUP]: 'poexprateid',
  [SERVICE_TYPES.UNIT_PRICE_TASK]: 'pouprateid',
  [SERVICE_TYPES.CU]: 'pocurateid',
};

export const POCodeFieldMapping = {
  [SERVICE_TYPES.LABOR]: 'restype1',
  [SERVICE_TYPES.EQUIPMENT]: 'rentalitemno',
  [SERVICE_TYPES.REIMBURSABLE_MARKUP]: 'expcode',
  [SERVICE_TYPES.UNIT_PRICE_TASK]: 'taskcode',
  [SERVICE_TYPES.CU]: 'cugroupcode',
};

export const POFieldMapping = {
  activitynum: 'activitynum',
  rateschecode: 'rateschecode',
  laborCode: 'restype1',
  laborCategory: 'laborcategory',
  equipCode: 'rentalitemno',
  equipCategory: 'eqptcat',
  reimbCode: 'expcode',
  code: 'taskcode',
  cuCode: 'cugroupcode',
};

export const LabelFieldMapping = {
  rateSchLabel: 'rateschlabel',
  codeLabel: 'codelabel',
};

export const POCategoryFieldMapping = {
  [SERVICE_TYPES.LABOR]: 'laborcategory',
  [SERVICE_TYPES.EQUIPMENT]: 'eqptcat',
};

export const POCodeMapping = {
  restype1: 'laborCode',
  rentalitemno: 'equipCode',
  expcode: 'reimbCode',
  code: 'taskcode',
  cugroupcode: 'cuCode',
};
