import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as service from 'services/purchaseOrderService';
import {
  fetchPurchaseOrderDetails,
  addPOContact,
  requestVendorCompletionForm,
  completionFormSubmitRequest,
  approveVendorSubmitionForm,
  addInvoiceSignature,
  updatePOContact,
  deletePOContact,
  setPurchaseOrderDetails,
  setError,
  updatePurchaseOrder,
  modifyPurchaseOrder,
  initialReviewResponsePurchaseOrder,
  pendingRatesResponsePurchaseOrder,
  setLoading,
  setApprovedButtonStatus,
} from 'store/slices/purchaseOrder/purchaseOrderDetailsSlice';
import {
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { isEmptyVal } from 'utils/utils';
import _ from 'lodash';
import { trackPromise } from 'react-promise-tracker';

/** Promise trackers */
const trackPODetail = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.getPODetail);

/** Saga functions**/
function* getPurchaseOrderDetails(action) {
  try {
    let poId = action.payload.poId;
    let fetchAllCostactivities = action.payload?.fetchAllCostactivities;
    if (isEmptyVal(poId)) {
      poId = action.payload;
    }
    if (isEmptyVal(fetchAllCostactivities)) {
      fetchAllCostactivities = false;
    }

    const response = yield call(
      trackPODetail,
      f => service.retrievePurchaseOrderDetails(poId, fetchAllCostactivities),
      action.payload,
    );
    const { data } = response;
    yield put(setPurchaseOrderDetails(data));

    if (!isEmptyVal(action.payload.callback)) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* addPOContactDetails(action) {
  try {
    const response = yield call(
      f => service.addPOContacts(action.payload),
      action.payload,
    );
    const { data } = response;
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* postInvoiceSignature(action) {
  try {
    const response = yield call(
      f => service.addInvoiceSignature(action.payload),
      action.payload,
    );
    const { data } = response;
    if (action.payload.callback) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
  }
}

function* updatePOContactDetails(action) {
  try {
    const response = yield call(
      f => service.updatePOContacts(action.payload),
      action.payload,
    );
    const { data } = response;
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* requestVendorCompletionFormDetails(action) {
  try {
    const response = yield call(
      f => service.requestVendorCompletionForm(action.payload),
      action.payload,
    );
    const { data } = response;
    if (response.status === 200) {
      yield put(setApprovedButtonStatus('F'));
    } else {
      yield put(setApprovedButtonStatus('V'));
    }
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* completionFormSubmitRequestDetails(action) {
  try {
    const response = yield call(
      f => service.completionFormSubmitRequest(action.payload),
      action.payload,
    );
    const { data } = response;
    if (response.status === 200) {
      displaySuccess('Completion Form Submitted');
      yield put(setApprovedButtonStatus('C'));
    } else {
      displayError('Something went wrong!');
      yield put(setApprovedButtonStatus('F'));
    }
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* approveVendorSubmitionFormDetails(action) {
  try {
    const response = yield call(
      f => service.approveVendorSubmitionForm(action.payload),
      action.payload,
    );
    if (response.status === 200) {
      displaySuccess('Approved Purchase Order Completion Form successful');
      yield put(setApprovedButtonStatus('A'));
    } else {
      displayError('Something went wrong!');
      yield put(setApprovedButtonStatus('C'));
    }
    yield put(setLoading(false));
  } catch (error) {
    displayError('Something went wrong!');
    yield put(setLoading(false));
    yield put(setApprovedButtonStatus('C'));
  }
}

function* deletePOContactDetails(action) {
  try {
    const response = yield call(
      f => service.deletePOContacts(action.payload),
      action.payload,
    );
    const { data } = response;
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* updPurchaseOrder(action) {
  try {
    const response = yield call(
      f => service.updatePurchaseOrder(action.payload),
      action.payload,
    );
    const { data } = response;
    if (data.error) {
      yield put(setError(true));
    } else {
      yield put(setPurchaseOrderDetails(data));
    }
    if (action.payload.callback) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* modPurchaseOrder(action) {
  try {
    const response = yield call(
      f => service.modifyPurchaseOrder(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setPurchaseOrderDetails(data));
    if (action.payload.callback) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* initReviewResponsePurchaseOrder(action) {
  try {
    const response = yield call(
      f => service.initialReviewResponsePurchaseOrder(action.payload),
      action.payload,
    );
    const { data } = response;
    if (response.status === 200) {
      let dataUpdated = data;
      if (data['isUpdated']) {
        dataUpdated = data['isUpdated'][0];
      }
      if (_.isObject(dataUpdated)) {
        yield put(setPurchaseOrderDetails(dataUpdated));
      }
      if (action.payload.callback) {
        action.payload.callback(dataUpdated);
      }
    } else {
      yield put(setError(true));
      yield put(setLoading(false));
      if (action.payload.callback) {
        action.payload.callback(response);
      }
    }
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* pendRatesResponsePurchaseOrder(action) {
  try {
    const response = yield call(
      f => service.pendingRatesResponsePurchaseOrder(action.payload),
      action.payload,
    );
    const { data } = response;
    if (response.status === 200) {
      let dataUpdated = data;
      if (data['isUpdated']) {
        dataUpdated = data['isUpdated'][0];
      }
      if (_.isObject(dataUpdated)) {
        yield put(setPurchaseOrderDetails(dataUpdated));
      }
      if (action.payload.callback) {
        action.payload.callback(dataUpdated);
      }
    } else {
      displayError('Something went wrong!');
      if (action.payload.callback) {
        action.payload.callback(response);
      }
      yield put(setError(true));
    }
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* watchAddPOContact() {
  yield takeLatest(addPOContact.type, addPOContactDetails);
}

function* watchRequestVendorCompletionForm() {
  yield takeLatest(
    requestVendorCompletionForm.type,
    requestVendorCompletionFormDetails,
  );
}

function* watchCompletionFormSubmitRequest() {
  yield takeLatest(
    completionFormSubmitRequest.type,
    completionFormSubmitRequestDetails,
  );
}

function* watchapproveVendorSubmitionForm() {
  yield takeLatest(
    approveVendorSubmitionForm.type,
    approveVendorSubmitionFormDetails,
  );
}

function* watchPostInvoiceSignature() {
  yield takeLatest(addInvoiceSignature.type, postInvoiceSignature);
}

function* watchUpdatePOContact() {
  yield takeLatest(updatePOContact.type, updatePOContactDetails);
}

function* watchDeletePOContact() {
  yield takeLatest(deletePOContact.type, deletePOContactDetails);
}

function* watchFetchPurchaseOrderDetails() {
  yield takeLatest(fetchPurchaseOrderDetails.type, getPurchaseOrderDetails);
}

function* watchUpdatePurchaseOrder() {
  yield takeLatest(updatePurchaseOrder.type, updPurchaseOrder);
}

function* watchModifyPurchaseOrder() {
  yield takeLatest(modifyPurchaseOrder.type, modPurchaseOrder);
}

function* watchInitialReviewResponsePurchaseOrder() {
  yield takeLatest(
    initialReviewResponsePurchaseOrder.type,
    initReviewResponsePurchaseOrder,
  );
}

function* watchPendingRatesResponsePurchaseOrder() {
  yield takeLatest(
    pendingRatesResponsePurchaseOrder.type,
    pendRatesResponsePurchaseOrder,
  );
}

export function* purchaseOrderDetailsSaga() {
  yield all([
    watchFetchPurchaseOrderDetails(),
    watchAddPOContact(),
    watchRequestVendorCompletionForm(),
    watchCompletionFormSubmitRequest(),
    watchapproveVendorSubmitionForm(),
    watchUpdatePOContact(),
    watchDeletePOContact(),
    watchPostInvoiceSignature(),
    watchUpdatePurchaseOrder(),
    watchModifyPurchaseOrder(),
    watchInitialReviewResponsePurchaseOrder(),
    watchPendingRatesResponsePurchaseOrder(),
  ]);
}
