import { createSlice } from '@reduxjs/toolkit';
import { POLineStatus } from 'enums/poLineStatus.ts';
import { PricingMethod } from 'enums/pricingMethod.ts';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
import _ from 'lodash';
import { isEmptyVal } from 'utils/utils';

const initialState = {
  originalData: {},
  bucketpo: false,
  isLoading: false,
  isPDFLoading: false,
  error: false,
  errorResponse: {},
  poid: '',
  ponum: '',
  startDate: '',
  endDate: '',
  contractID: '',
  contractorName: '',
  contractor: '',
  status: '',
  reqtsappr: false,
  site: '',
  scopeOfWork: '',
  pricingMethod: '',
  revisionNumber: '',
  contractRefnum: '',
  statusDate: '',
  retentionPercentage: '',
  opco: '',
  billTo: '',
  buPath: '',
  shipTo: '',
  totalcost: '',
  totalvalue: '',
  vendorID: '',
  siteID: '',
  organization: '',
  contractAnalyst: '',
  companyName: '',
  companyAddress1: '',
  companyAddress2: '',
  companyAddress3: '',
  companyAddress4: '',
  companyAddress5: '',
  remitcompany: '',
  remitcompanyaddress1: '',
  remitcompanyphone: '',
  apglbu: '',
  vndrrejt: '',
  vndrrejcomments: '',
  vndracpt: '',
  completionFormData: '',
  costcoord: '',
  approver: '',
  conteemail: '',
  contacts: [],
  poLines: [],
  poratehdrs: [],
  poAttachments: [],
  costActivities: [],
  filteredCostActivity: [],
  costActivityByST: {
    [SERVICE_TYPES.LABOR]: [],
    [SERVICE_TYPES.EQUIPMENT]: [],
    [SERVICE_TYPES.REIMBURSABLE_MARKUP]: [],
    [SERVICE_TYPES.UNIT_PRICE_TASK]: [],
    [SERVICE_TYPES.LUMPSUM]: [],
    [SERVICE_TYPES.CU]: [],
  },
  poworklogs: [],
  [SERVICE_TYPES.LABOR]: [],
  [SERVICE_TYPES.EQUIPMENT]: [],
  [SERVICE_TYPES.REIMBURSABLE_MARKUP]: [],
  [SERVICE_TYPES.UNIT_PRICE_TASK]: [],
  [SERVICE_TYPES.CU]: [],
  [SERVICE_TYPES.LUMPSUM]: [],
  [SERVICE_TYPES.RATES]: [],
  responseData: true,
  ewotk: 0,
};

const removeTags = data => {
  let newData = '';
  if (!_.isNil(data) && !_.isEmpty(data)) {
    newData = data.toString();
    newData = newData.replace(/(<([^>]+)>)/gi, '');
  }
  return newData;
};

const categorizeCostActivities = (priceMethod, costActivities, polines) => {
  const costActivityByST = {
    [SERVICE_TYPES.LABOR]: [],
    [SERVICE_TYPES.EQUIPMENT]: [],
    [SERVICE_TYPES.REIMBURSABLE_MARKUP]: [],
    [SERVICE_TYPES.UNIT_PRICE_TASK]: [],
    [SERVICE_TYPES.LUMPSUM]: [],
    [SERVICE_TYPES.CU]: [],
  };
  if (!isEmptyVal(costActivities) && !isEmptyVal(polines)) {
    let otherPOLineIdList = [];
    let upPOLineIdList = [];
    let lerPOLineIdList = [];
    let lumpsumPOLineIdList = [];
    polines.forEach((lineObject, idx) => {
      if (
        ![
          POLineStatus.PAID,
          POLineStatus.COMPLETED,
          POLineStatus.CANCELLED,
        ].includes(lineObject?.status)
      ) {
        if (isEmptyVal(lineObject?.pricingmethod)) {
          if (priceMethod === PricingMethod.UNIT_PRICE) {
            upPOLineIdList.push(lineObject.polinenum);
          } else if (
            [
              PricingMethod.COST_PLUS,
              PricingMethod.TIME_AND_MATERIAL,
              PricingMethod.HOURLY,
            ].includes(priceMethod)
          ) {
            lerPOLineIdList.push(lineObject.polinenum);
          } else if (
            [PricingMethod.LUMPSUM, PricingMethod.FIXED_PRICE].includes(
              priceMethod,
            )
          ) {
            lumpsumPOLineIdList.push(lineObject.polinenum);
          } else if (priceMethod === PricingMethod.OTHER) {
            otherPOLineIdList.push(lineObject.polinenum);
          }
        } else {
          if (lineObject?.pricingmethod === PricingMethod.UNIT_PRICE) {
            upPOLineIdList.push(lineObject.polinenum);
          } else if (
            [
              PricingMethod.COST_PLUS,
              PricingMethod.TIME_AND_MATERIAL,
              PricingMethod.HOURLY,
            ].includes(lineObject?.pricingmethod)
          ) {
            lerPOLineIdList.push(lineObject.polinenum);
          } else if (
            [PricingMethod.LUMPSUM, PricingMethod.FIXED_PRICE].includes(
              lineObject?.pricingmethod,
            )
          ) {
            lumpsumPOLineIdList.push(lineObject.polinenum);
          } else if (lineObject?.pricingmethod === PricingMethod.OTHER) {
            otherPOLineIdList.push(lineObject.polinenum);
          }
        }
      }
    });

    costActivities.forEach(caObject => {
      if (otherPOLineIdList.includes(caObject?.polinenum)) {
        costActivityByST[SERVICE_TYPES.LABOR].push(caObject);
        costActivityByST[SERVICE_TYPES.EQUIPMENT].push(caObject);
        costActivityByST[SERVICE_TYPES.REIMBURSABLE_MARKUP].push(caObject);
        costActivityByST[SERVICE_TYPES.UNIT_PRICE_TASK].push(caObject);
        costActivityByST[SERVICE_TYPES.LUMPSUM].push(caObject);
      } else if (upPOLineIdList.includes(caObject?.polinenum)) {
        costActivityByST[SERVICE_TYPES.UNIT_PRICE_TASK].push(caObject);
      } else if (lerPOLineIdList.includes(caObject?.polinenum)) {
        costActivityByST[SERVICE_TYPES.LABOR].push(caObject);
        costActivityByST[SERVICE_TYPES.EQUIPMENT].push(caObject);
        costActivityByST[SERVICE_TYPES.REIMBURSABLE_MARKUP].push(caObject);
      } else if (lumpsumPOLineIdList.includes(caObject?.polinenum)) {
        costActivityByST[SERVICE_TYPES.LUMPSUM].push(caObject);
      }
      costActivityByST[SERVICE_TYPES.CU].push(caObject);
    });
  }

  return costActivityByST;
};

const filterCostActivity = (priceMethod, costActivities, polines) => {
  const filteredCostActivity = [];

  if (!isEmptyVal(costActivities) && !isEmptyVal(polines)) {
    let poLineIdList = [];

    polines.forEach((lineObject, idx) => {
      if (
        lineObject.itemnum !== 'EWO' ||
        (lineObject.itemnum === 'EWO' &&
          ![
            POLineStatus.PAID,
            POLineStatus.COMPLETED,
            POLineStatus.CANCELLED,
          ].includes(lineObject?.status))
      ) {
        if (isEmptyVal(lineObject?.pricingmethod)) {
          if (priceMethod !== PricingMethod.ALLOWANCE) {
            poLineIdList.push(lineObject.polinenum);
          }
        } else {
          if (lineObject?.pricingmethod !== PricingMethod.ALLOWANCE) {
            poLineIdList.push(lineObject.polinenum);
          }
        }
      }
    });

    costActivities.forEach(caObject => {
      if (poLineIdList.includes(caObject?.polinenum)) {
        filteredCostActivity.push(caObject);
      }
    });
  }
  return filteredCostActivity;
};
const purchaseOrderDetailsSlice = createSlice({
  name: 'purchaseOrderDetailsSlice',
  initialState,
  reducers: {
    fetchPurchaseOrderDetails(state) {
      state.isLoading = false;
    },
    addPOContact(state) {
      state.isLoading = false;
    },
    requestVendorCompletionForm(state, action) {
      state.responseData = action.payload;
    },
    completionFormSubmitRequest(state, action) {
      state.responseData = action.payload;
    },
    approveVendorSubmitionForm(state, action) {
      state.responseData = action.payload;
    },
    updatePOContact(state) {
      state.isLoading = false;
    },
    deletePOContact(state) {
      state.isLoading = false;
    },
    setPurchaseOrderDetails(state, action) {
      const responseData = action.payload;
      const stateValues = { ...state };
      let ewotk = 0;
      stateValues.originalData = responseData;
      stateValues.approver = responseData.approver;
      stateValues.conteemail = responseData.contemail;
      stateValues.bucketpo = responseData.bucketpo;
      stateValues.poid = responseData.poid;
      stateValues.ponum = responseData.ponum;
      stateValues.startDate = responseData.startdate;
      stateValues.endDate = responseData.enddate;
      stateValues.contractID = responseData.contractrefid;
      stateValues.contractorName = responseData.contractorname;
      stateValues.vendorID = responseData.vendor;
      stateValues.siteID = responseData.siteid;
      stateValues.revisionNumber = responseData.revisionnum;
      stateValues.contractRefnum = responseData.contractrefnum;
      stateValues.statusDate = responseData.statusdate;
      stateValues.organization = responseData.orgid;
      stateValues.contractAnalyst = responseData.contractadmin;
      stateValues.retentionPercentage = responseData.retentionpct;
      stateValues.opco = responseData.opco;
      stateValues.billTo = responseData.billto;
      stateValues.buPath = responseData.bupath;
      stateValues.shipTo = responseData.shipto;
      stateValues.totalcost = responseData.totalcost;
      stateValues.totalvalue = responseData.totalvalue;
      stateValues.status = responseData.caastatus;
      stateValues.reqtsappr = responseData.reqtsappr;
      stateValues.site = responseData.siteid;
      stateValues.scopeOfWork = removeTags(responseData.sow);
      stateValues.pricingMethod = responseData.pricemethod;
      stateValues.contacts = responseData.pocontacts;
      stateValues.poLines = responseData.polines;
      stateValues.poratehdrs = responseData.poratehdrs;
      stateValues.poAttachments = responseData.poattachments;
      stateValues.companyName = responseData.companyname;
      stateValues.companyAddress1 = responseData.companyaddress1;
      stateValues.companyAddress2 = responseData.companyaddress2;
      stateValues.companyAddress3 = responseData.companyaddress3;
      stateValues.companyAddress4 = responseData.companyaddress4;
      stateValues.companyAddress5 = responseData.companyaddress5;
      stateValues.remitcompany = responseData.remitcompany;
      stateValues.remitcompanyaddress1 = responseData.remitcompanyaddress1;
      stateValues.remitcompanyphone = responseData.remitcompanyphone;
      stateValues.costActivities = responseData.pocostactivities ?? [];
      stateValues.costActivityByST = categorizeCostActivities(
        responseData.pricemethod,
        responseData.pocostactivities,
        responseData.polines,
      );
      stateValues.filteredCostActivity = filterCostActivity(
        responseData.pricemethod,
        responseData.pocostactivities,
        responseData.polines,
      );
      stateValues.apglbu = responseData.apglbu;
      stateValues.vndrrejt = responseData.vndrrejt;
      stateValues.vndrrejcomments = responseData.vndrrejcomments;
      stateValues.vndracpt = responseData.vndracpt;
      stateValues.poworklogs = responseData.poworklogs;
      stateValues.completionFormData = responseData.completionFormData;
      stateValues.costcoord = responseData.costcoord;

      if (!isEmptyVal(responseData.polines)) {
        responseData.polines.forEach((lineObject, idx) => {
          if (lineObject.itemnum == 'EWO' && lineObject.timekeepingonly) {
            ewotk = 1;
          }
        });
      }
      stateValues.ewotk = ewotk;
      stateValues[SERVICE_TYPES.LABOR] = responseData.polaborrates;
      stateValues[SERVICE_TYPES.EQUIPMENT] = responseData.poeqptrates;
      stateValues[SERVICE_TYPES.REIMBURSABLE_MARKUP] = responseData.poexprates;
      stateValues[SERVICE_TYPES.CU] = responseData.pocurates;
      stateValues[SERVICE_TYPES.UNIT_PRICE_TASK] = responseData.pouprates;
      stateValues[SERVICE_TYPES.RATES] = responseData.porates;

      return { ...stateValues };
    },
    setResponseState(state, action) {
      state.responseData = action.payload;
    },
    updatePurchaseOrder(state, action) {
      state.responseData = action.payload;
    },
    modifyPurchaseOrder(state, action) {
      state.responseData = action.payload;
    },
    initialReviewResponsePurchaseOrder(state, action) {
      state.responseData = action.payload;
    },
    pendingRatesResponsePurchaseOrder(state, action) {
      state.responseData = action.payload;
    },
    addInvoiceSignature(state, action) {
      state.isLoading = false;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setApprovedButtonStatus(state, action) {
      state.status = action.payload;
    },
    setIsPDFLoading(state, action) {
      state.isPDFLoading = action.payload;
    },
    resetPurchaseOrderDetails() {
      return { ...initialState };
    },
    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },
    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchPurchaseOrderDetails,
  addPOContact,
  requestVendorCompletionForm,
  completionFormSubmitRequest,
  approveVendorSubmitionForm,
  updatePOContact,
  deletePOContact,
  setPurchaseOrderDetails,
  setResponseState,
  resetPurchaseOrderDetails,
  addInvoiceSignature,
  setError,
  unsetError,
  updatePurchaseOrder,
  modifyPurchaseOrder,
  initialReviewResponsePurchaseOrder,
  pendingRatesResponsePurchaseOrder,
  setLoading,
  setApprovedButtonStatus,
  setIsPDFLoading,
} = purchaseOrderDetailsSlice.actions;

export default purchaseOrderDetailsSlice.reducer;
