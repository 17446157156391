import { BuPath } from 'constants/buPath';
import { Divider } from 'components/common/Divider/Divider';
import { format, lastDayOfMonth } from 'date-fns';
import { ModuleACL, UserType } from 'enums/entitlements.ts';
import { TimesheetStatus } from 'enums/statuses.ts';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { updateTimesheet } from 'store/slices/timesheet/timesheetDetailSlice';
import { IsAllowedACL } from 'utils/aclHelper';
import { getMidnightDate } from 'utils/utils';
import { DistributionAccountingHeader } from './DistributionAccountingHeader';
import { isEmptyVal } from 'utils/utils';

const TimesheetHeader = props => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth);
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);
  const timesheet = useSelector(state => state.timesheetDetail);
  const timesheetApprovals = useSelector(
    state => state.timesheetDetail.timesheetApprovals,
  );

  const isMobile = useMediaQuery({ query: '(max-width: 834px)' });

  const handleComment = e => {
    const comment = e?.target?.value ?? '';
    setValue('comments', e.target.value);

    if (comment.length > 255) {
      setError('comments', {
        type: 'manual',
        message: 'Length must not be more than 255 characters',
      });
    } else {
      delete errors['comments'];
    }
  };

  const handleContractRef = e => {
    const contRefNumeric = e?.target?.value ?? '';
    setValue('contRefNumeric', e.target.value);

    if (contRefNumeric.length > 10) {
      setError('contRefNumeric', {
        type: 'manual',
        message: 'Length must not be more than 10 characters',
      });
    } else {
      delete errors['contRefNumeric'];
    }
  };

  const handleVendorTimesheet = e => {
    const vendorTimesheet = e?.target?.value ?? '';
    setValue('vendorTimesheet', e.target.value);

    if (vendorTimesheet.length > 25) {
      setError('vendorTimesheet', {
        type: 'manual',
        message: 'Length must not be more than 25 characters',
      });
    } else {
      delete errors['vendorTimesheet'];
    }
  };

  const {
    control,
    trigger,
    errors,
    setValue,
    getValues,
    selectedOpco,
    weekEndDate,
    handleWeekEndDate,
    weekEndDateError,
    weekEndDateErrorMessage,
    startDate,
    handleStartDate,
    startDateError,
    startDateErrorMessage,
    endDate,
    handleEndDate,
    endDateError,
    endDateErrorMessage,
    isDuplicateDate,
    handleAuthorizationChange,
    handleWorkArea,
    handleOpco,
    handleWorkType,
    milesCompleteError,
    computeMilesCompleteError,
    newTimesheet,
    disableFields,
    handleTimesheetApproval,
    timesheetApproverOptions,
    handleUvlEstimateChange,
    debouncedAuthorizationChange,
    debouncedCircuitChange,
    getApproverMenuOptions,
    isCostCoordinator,
    Option,
    setError,
  } = props;

  const isDisabled = () => {
    let condition = false;

    if (
      timesheet?.caastatus === TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED
    ) {
      if (user?.configObject?.userrole === 'admin') {
        condition = false;
      } else {
        condition = !IsAllowedACL(
          user,
          ModuleACL.TIMESHEET,
          'can_submit_new_timesheet',
        );
      }
    } else if (
      (user.userType === UserType.VENDOR &&
        timesheet?.caastatus ===
          TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED &&
        timesheet?.submittedByAep) ||
      (user.userType === UserType.AEP &&
        timesheet?.caastatus ===
          TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED &&
        !timesheet?.submittedByAep)
    ) {
      condition = true;
    } else {
      condition =
        [
          TimesheetStatus.TIMESHEET_APPROVED,
          TimesheetStatus.TIMESHEET_INVOICED,
        ].includes(timesheet?.caastatus) ||
        ([
          TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
          TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
        ].includes(timesheet?.caastatus) &&
          user?.userType === UserType.VENDOR);
    }
    return condition;
  };

  const isDisabledHeader = () => {
    if (
      (user.userType === UserType.VENDOR &&
        timesheet?.caastatus ===
          TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED &&
        timesheet?.submittedByAep) ||
      (user.userType === UserType.AEP &&
        timesheet?.caastatus ===
          TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED &&
        !timesheet?.submittedByAep)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const disableVendorRef = () => {
    let disableEdit = true;
    let acl = IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_approve_timesheet');
    if (newTimesheet) {
      disableEdit = false;
    } else if (
      [
        TimesheetStatus.TIMESHEET_ENTERED_NOT_SAVED,
        TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED,
        TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED,
      ].includes(timesheet?.caastatus)
    ) {
      disableEdit = false;
    } else if (
      [
        TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
        TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED,
        TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
      ].includes(timesheet?.caastatus)
    ) {
      const timesheetApproverIndex = timesheet?.timesheetApprovals.findIndex(
        appr => user?.userId?.toLowerCase() == appr?.approver?.toLowerCase(),
      );
      if (user?.configObject?.userrole === 'admin') {
        disableEdit = false;
      } else {
        if (
          BuPath.DIST.includes(purchaseOrder?.buPath) ||
          BuPath.TRANS === purchaseOrder?.buPath
        ) {
          if (![timesheet?.enteredBy].includes(user?.userId)) {
            if (
              timesheet?.caastatus ==
                TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED &&
              timesheetApprovals[0].approver === user?.userId &&
              timesheetApprovals[0].approved !== 'N'
            ) {
              disableEdit = false;
            } else if (
              timesheetApprovals[0].approver === user?.userId &&
              timesheet?.caastatus ==
                TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED
            ) {
              disableEdit = false;
            }
          } else {
            if ([timesheet?.enteredBy].includes(user?.userId)) {
              if (
                TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED ===
                timesheet?.caastatus
              ) {
                disableEdit = false;
              }
            }
          }
        } else if (BuPath.GEN.includes(purchaseOrder?.buPath)) {
          if (
            (timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED &&
              (isCostCoordinator() ||
                (user?.userType === UserType.AEP &&
                  timesheet?.submittedByAep))) ||
            (timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED &&
              timesheetApproverIndex !== -1)
          ) {
            disableEdit = false;
          } else if (
            [timesheet?.enteredBy].includes(user?.userId) &&
            timesheet?.caastatus ==
              TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED
          ) {
            disableEdit = false;
          }
        } else {
          disableEdit = true;
        }
      }
    }
    return disableEdit;
  };

  return (
    <>
      <div className="aepContainer">
        <div className="innerContainer transGenHeader">
          <div style={{ display: 'flex' }}>
            <Row>
              {BuPath.TRANS === purchaseOrder.buPath && (
                <>
                  <Col className="sm2">
                    <Form.Group>
                      <Form.Label>Timesheet Start Date:</Form.Label>
                      <Controller
                        name="workStartDate"
                        render={({ field }) => {
                          return (
                            <DatePicker
                              className="date-picker-icon"
                              {...field}
                              selected={startDate}
                              onChange={date =>
                                handleStartDate(getMidnightDate(date))
                              }
                              disabled={disableFields(null)}
                              onKeyDown={e => {
                                e.preventDefault();
                              }}
                            />
                          );
                        }}
                      />
                    </Form.Group>

                    {startDateError && (
                      <div className="errorMessage">
                        {startDateErrorMessage}
                      </div>
                    )}

                    {isDuplicateDate && (
                      <div className="errorMessage">
                        Dates already submitted or approved
                      </div>
                    )}
                  </Col>
                  <Col sm={isMobile ? 4 : 2} className="dateField">
                    <Form.Group>
                      <Form.Label>Timesheet End Date:</Form.Label>
                      <Controller
                        name="workEndDate"
                        render={({ field }) => {
                          return (
                            <DatePicker
                              className="date-picker-icon"
                              {...field}
                              selected={endDate}
                              onChange={date =>
                                handleEndDate(getMidnightDate(date))
                              }
                              disabled={disableFields(null)}
                              onKeyDown={e => {
                                e.preventDefault();
                              }}
                            />
                          );
                        }}
                      />
                    </Form.Group>
                    {endDateError && (
                      <div className="errorMessage">{endDateErrorMessage}</div>
                    )}
                  </Col>
                </>
              )}
              {BuPath.GEN.includes(purchaseOrder.buPath) && (
                <>
                  <Col className="sm2">
                    <Form.Group>
                      <Form.Label>Timesheet Start Date:</Form.Label>
                      <Controller
                        name="workStartDate"
                        render={({ field }) => {
                          return (
                            <DatePicker
                              className="date-picker-icon"
                              {...field}
                              selected={startDate}
                              onChange={date =>
                                handleStartDate(getMidnightDate(date))
                              }
                              disabled={disableFields(null)}
                              onKeyDown={e => {
                                e.preventDefault();
                              }}
                              maxDate={lastDayOfMonth(
                                getMidnightDate(new Date()),
                              )}
                            />
                          );
                        }}
                      />
                    </Form.Group>

                    {startDateError && (
                      <div className="errorMessage">
                        {startDateErrorMessage}
                      </div>
                    )}

                    {isDuplicateDate && (
                      <div className="errorMessage">
                        Dates already submitted or approved
                      </div>
                    )}
                  </Col>
                  <Col className="sm2">
                    <Form.Group>
                      <Form.Label>Timesheet End Date:</Form.Label>
                      <Controller
                        name="workEndDate"
                        render={({ field }) => {
                          return (
                            <DatePicker
                              className="date-picker-icon"
                              {...field}
                              selected={endDate}
                              onChange={date =>
                                handleEndDate(getMidnightDate(date))
                              }
                              disabled={disableFields(null)}
                              onKeyDown={e => {
                                e.preventDefault();
                              }}
                              maxDate={lastDayOfMonth(
                                getMidnightDate(startDate),
                              )}
                            />
                          );
                        }}
                      />
                    </Form.Group>
                    {endDateError && (
                      <div className="errorMessage">{endDateErrorMessage}</div>
                    )}
                  </Col>
                </>
              )}
              {BuPath.DIST.includes(purchaseOrder?.buPath) && (
                <>
                  <Col className="sm2">
                    <Form.Group className="weekEndDate">
                      <Form.Label>Week End Date:</Form.Label>
                      <Controller
                        name="weekEndDate"
                        render={({ field }) => {
                          return (
                            <DatePicker
                              className="date-picker-icon"
                              {...field}
                              selected={weekEndDate}
                              onChange={date =>
                                handleWeekEndDate(getMidnightDate(date))
                              }
                              disabled={
                                isDisabled() ||
                                (!newTimesheet &&
                                  timesheet?.caastatus !==
                                    TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED &&
                                  IsAllowedACL(
                                    user,
                                    ModuleACL.TIMESHEET,
                                    'can_submit_new_timesheet',
                                  ))
                              }
                              onKeyDown={e => {
                                e.preventDefault();
                              }}
                            />
                          );
                        }}
                      />
                    </Form.Group>
                    {weekEndDateError && (
                      <div className="errorMessage">
                        {weekEndDateErrorMessage}
                      </div>
                    )}

                    {(weekEndDate < getMidnightDate(purchaseOrder?.startDate) ||
                      weekEndDate >
                        getMidnightDate(purchaseOrder?.endDate)) && (
                      <div className="errorMessage">
                        Weekend date must be between purchase order start date (
                        {format(
                          getMidnightDate(purchaseOrder?.startDate),
                          'MM/dd/yyyy',
                        )}
                        ) and end date (
                        {format(
                          getMidnightDate(purchaseOrder?.endDate),
                          'MM/dd/yyyy',
                        )}
                        )
                      </div>
                    )}

                    {isDuplicateDate && (
                      <div className="errorMessage">
                        Date already submitted or approved
                      </div>
                    )}
                  </Col>
                </>
              )}
              <Col className="sm2">
                <Form.Group controlId="form.contRefNumeric">
                  <Form.Label>Vendor Ref Number</Form.Label>
                  <Controller
                    control={control}
                    name="contRefNumeric"
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Form.Control
                        type="text"
                        value={value}
                        onChange={handleContractRef}
                        ref={ref}
                        disabled={disableVendorRef()}
                      />
                    )}
                  />
                  <div className="errorMessage">
                    {errors?.['contRefNumeric']?.['message']}
                  </div>
                </Form.Group>
              </Col>
              <Col className="sm2">
                <Form.Group controlId="form.vendorTimesheet">
                  <Form.Label>Vendor Timesheet</Form.Label>
                  <Controller
                    control={control}
                    name="vendorTimesheet"
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Form.Control
                        type="text"
                        value={value}
                        onChange={handleVendorTimesheet}
                        ref={ref}
                        disabled={disableVendorRef()}
                      />
                    )}
                  />
                  <div className="errorMessage">
                    {errors?.['vendorTimesheet']?.['message']}
                  </div>
                </Form.Group>
              </Col>
              <Col className="sm2">
                {timesheet?.caastatus ===
                TimesheetStatus.UVL_TIMESHEET_SUBMITTED ? (
                  <div className="aepContainer uvlEstimate">
                    <Form.Group controlId="form.uvlEstimateConvert">
                      <Form.Label className="uvlLabel">UVL Estimate</Form.Label>
                      <div>
                        {IsAllowedACL(
                          user,
                          ModuleACL.TIMESHEET,
                          'can_submit_new_timesheet',
                        ) && (
                          <Button
                            variant="primary"
                            onClick={e => {
                              setValue('uvl', false);
                              setValue('uvlEstimateNo', true);
                              dispatch(
                                // @ts-ignore
                                updateTimesheet({
                                  data: {
                                    caastatus:
                                      TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED,
                                    uvl: false,
                                  },
                                  recordNmbr: timesheet.recordNmbr,
                                }),
                              );
                            }}
                          >
                            Convert to Timesheet
                          </Button>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                ) : (
                  <div>
                    <Form.Group controlId="form.uvlEstimate">
                      <Form.Label>UVL Estimate {getValues('uvl')}</Form.Label>
                      <Controller
                        control={control}
                        name="uvl"
                        render={({ field: { onChange, value } }) => (
                          <Form.Select
                            onChange={e => handleUvlEstimateChange(e)}
                            disabled={disableFields(null)}
                            className="uvlSection"
                            value={getValues('uvl') ? 'uvl' : 'uvlEstimateNo'}
                          >
                            <option value="uvl">Yes</option>
                            <option value="uvlEstimateNo" selected>
                              No
                            </option>
                          </Form.Select>
                        )}
                      />
                    </Form.Group>
                  </div>
                )}
              </Col>
              {(purchaseOrder?.buPath === BuPath.TRANS &&
                purchaseOrder?.reqtsappr) ||
              BuPath.DIST.includes(purchaseOrder.buPath) ? (
                timesheet?.timesheetApprovals.map((tSheetApprover, index) => (
                  <Col className="sm2" key={index}>
                    <Form.Group controlId="timesheetApprover">
                      <Form.Label>Approver List</Form.Label>
                      <Controller
                        control={control}
                        name={`approver${index}`}
                        render={({ onChange, value, name, ref }) => (
                          <>
                            <ReactSelect
                              id="approverList"
                              classNamePrefix="tSheetApprovers"
                              value={getApproverMenuOptions().find(
                                obj => obj.value === tSheetApprover?.approver,
                              )}
                              components={{
                                Option,
                              }}
                              isClearable={false}
                              isSearchable={true}
                              isDisabled={
                                ([
                                  TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
                                  TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
                                  TimesheetStatus.TIMESHEET_APPROVED,
                                  TimesheetStatus.TIMESHEET_INVOICED,
                                ].includes(timesheet?.caastatus) &&
                                  IsAllowedACL(
                                    user,
                                    ModuleACL.TIMESHEET,
                                    'can_submit_new_timesheet',
                                  )) ||
                                isDisabledHeader()
                              }
                              placeholder="Select"
                              options={getApproverMenuOptions()}
                              menuPosition="absolute"
                              onChange={e =>
                                handleTimesheetApproval(e?.value, index)
                              }
                              closeMenuOnSelect={true}
                              hideSelectedOptions={true}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  height: '46px',
                                  margin: '0px 0px 0px 0px !important',
                                  textAlign: 'left',
                                  width: '95% !important',
                                  fontSize: '17px',
                                  border: '0.1px solid',
                                }),
                                menu: (provided, state) => ({
                                  ...provided,
                                }),
                              }}
                            />
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                ))
              ) : (
                <></>
              )}
            </Row>
          </div>
          <Divider style={{ margin: '40px 40px 20px 0' }} />
          <div>
            <Row>
              <Col className="sm5">
                <Form.Group controlId="form.uvlEstimateComment">
                  <Form.Label>Comments</Form.Label>
                  <Controller
                    control={control}
                    name="comments"
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Form.Control
                        type="text"
                        value={value}
                        onChange={handleComment}
                        ref={ref}
                        disabled={disableFields(null) || isDisabledHeader()}
                      />
                    )}
                  />
                  <div className="errorMessage">
                    {errors?.['comments']?.['message']}
                  </div>
                </Form.Group>
              </Col>
              <Col></Col>
            </Row>
          </div>
          {BuPath.DIST.includes(purchaseOrder?.buPath) && (
            <DistributionAccountingHeader
              control={control}
              trigger={trigger}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              selectedOpco={selectedOpco}
              handleAuthorizationChange={handleAuthorizationChange}
              handleWorkArea={handleWorkArea}
              handleOpco={handleOpco}
              handleWorkType={handleWorkType}
              milesCompleteError={milesCompleteError}
              computeMilesCompleteError={computeMilesCompleteError}
              debouncedAuthorizationChange={debouncedAuthorizationChange}
              debouncedCircuitChange={debouncedCircuitChange}
            />
          )}
        </div>
      </div>
    </>
  );
};
export { TimesheetHeader };
