import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { EditIcon } from 'assets/images/EditIcon/EditIcon';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { Page } from 'enums/page.ts';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSelectedAdminVendortList,
  fetchVendorList,
  fetchVendorcompanyList,
  setAdminVendorPagination,
  updateVendorCompany,
} from 'store/slices/AdminModules/vendorSlice';
import { setVendorFilters } from 'store/slices/filterSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { getSortOrder, isEmptyVal } from 'utils/utils';
import './AdminVendor.scss';
import { EditVendorModal } from './EditVendorModal';

const AdminVendor = props => {
  const dispatch = useDispatch();
  const [editVenodorModal, setEditVenodorModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [companyList, setCompanyList] = useState();
  const [seletedVendorCompanyKey, setSeletedVendorCompanyKey] = useState();
  const [defaultCompanyList, setDefaultCompanyList] = useState();
  const [chipFilterValues, setChipFilterValues] = useState({});
  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);

  const pagination = useSelector(state => state.adminVendor.pagination);
  const vendorList = useSelector(state => state.adminVendor.vendorList);
  const inputListData = useSelector(state => state.filter.vendor);
  const vendorCompanyList = useSelector(state => state.adminVendor.items);
  const selectedFilterRecordList = useSelector(
    state => state.adminVendor.recordItems,
  );
  const currentPage = useSelector(
    state => state.adminVendor.pagination.currentPage,
  );
  const sortBy = useSelector(state => state.adminVendor.pagination.sortBy);
  const sortOrder = useSelector(
    state => state.adminVendor.pagination.sortOrder,
  );

  const vendorFiltersValues = [
    { label: 'Vendor User', value: 'User' },
    { label: 'Vendor Company', value: 'Company' },
  ];

  const headersWorkTypes = [
    { key: 'vendorName', value: 'Vendor User' },
    { key: 'roles', value: 'Roles' },
    { key: 'vendorCompany', value: 'Vendor Company' },
    { key: 'vendorAddress', value: 'Vendor Address' },
    { key: '', value: 'Action' },
  ];

  const objDecorate = obj => {
    let newObj = _.mapKeys(obj, (value, key) => {
      return key;
    });
    return newObj;
  };

  const handleEditClick = (editKey, rowDetail) => {
    setSeletedVendorCompanyKey(rowDetail.userid);
    let arr = [];

    // Filtering already selected Value
    let vendorKeyChange = [];
    vendorCompanyList?.map(item => {
      if (item.vendor !== rowDetail.vendorCompanyDetailDefault.vendor) {
        vendorKeyChange.push({
          label: `${item.vendor} - ${item.vendorName}`,
          value: `${item.vendor} - ${item.vendorName}`,
        });
      }
    });

    rowDetail.vendorCompanyDetail.forEach(arr2Item => {
      arr.push({
        label: `${arr2Item.vendorVendor.vendor} - ${arr2Item.vendorVendor.vendorName}`,
        value: `${arr2Item.vendorVendor.vendor} - ${arr2Item.vendorVendor.vendorName}`,
      });
    });

    setDefaultCompanyList(arr);
    setCompanyList(vendorKeyChange);
    setSelectedRow(rowDetail);
    setEditVenodorModal(true);
  };

  const handleSorting = (sortBy, sortOrder) => {
    // dispatch(
    //   setAdminVendorPagination({
    //     ...pagination,
    //     currentPage: 1,
    //     sortBy,
    //     sortOrder,
    //   }),
    // );
  };

  const handleContinueClick = selectedVendor => {
    let obj = {};
    let tempVendorArray = selectedVendor.map(ele => {
      let splitData = ele.split('-');
      return splitData[0].trim();
    });
    obj.vendors = tempVendorArray;

    dispatch(
      updateVendorCompany({
        data: obj,
        userId: seletedVendorCompanyKey,
      }),
    );
    dispatch(fetchVendorList());
    setEditVenodorModal(false);
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendor' || val.FilterValue === 'vendorid') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setVendorFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    // Check is using "vendorid"
    const filterchipDataDecorated = objDecorate(filterchipData);
    dispatch(fetchVendorList(filterchipDataDecorated));
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const handleFilterContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });

    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });

    const selectedFilterDataDecorated = objDecorate(selectedFilterData);

    if (props.resetFilter) {
      dispatch(fetchVendorList({ currentPage: 1, User: '', Company: '' }));
    } else {
      dispatch(fetchVendorList(selectedFilterDataDecorated));
    }
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setFilterchip(true);
  };

  useEffect(() => {
    dispatch(setActivePage(Page.ADMIN_MODULES));
    dispatch(fetchVendorcompanyList());

    if (props.resetFilter) {
      props.setResetFilter(false);
      handleFilterContinueClick([
        {
          FilterValue: '',
          input_rank: null,
          selectFilterValue: '',
          selectFilterLabel: '',
          presentIcon: '',
          filerForm: false,
          FilterLabel: '',
          selectedFilterRecords: [],
        },
      ]);
    } else {
      handleFilterContinueClick(inputListData);
    }
  }, [dispatch]);

  return (
    <>
      <div className="aepContainer adminModuleVendorContainer">
        <div className="tableContainer">
          <Row>
            <Col sm={11}>
              <>
                <div className="filterComponent">
                  <AEPFilterComponent
                    inputListData={inputListData}
                    filterValues={vendorFiltersValues}
                    setInputFilterData={setVendorFilters}
                    selectedFilterRecordList={selectedFilterRecordList}
                    fetchSelectedList={fetchSelectedAdminVendortList}
                    handleContinueClick={handleFilterContinueClick}
                    continueBtnDisable={continueBtnDisable}
                    handleFilterclose={handleFilterclose}
                    handleFilterChip={handleFilterChip}
                    filterChip={filterChip}
                    chipFilterValues={chipFilterValues}
                  />
                </div>
              </>
            </Col>
          </Row>
          <Table hover responsive striped bordered={false}>
            <thead>
              <tr>
                {headersWorkTypes.map((header, idx) => (
                  <th key={idx}>
                    <div>{header.value}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {vendorList.map((row, indx) => (
                <tr key={indx} className="tRow table-hover-row">
                  <td>{row.vendorName}</td>
                  <td>
                    {row.Roles.map((role, indx) => (
                      <span>{role.entitlement}</span>
                    ))}
                  </td>
                  <td>
                    <span className="vendorDefaultSpace">
                      {row.vendorCompanyDetailDefault?.vendorName}
                    </span>

                    <div className="vendorCompanyDetail">
                      {row.vendorCompanyDetail?.map((access, index) => (
                        <span className="vendorSpace">
                          {access.vendorVendor.vendor} -{' '}
                          {access.vendorVendor.vendorName}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td>
                    <div>
                      <div>
                        {row.vendorCompanyDetailDefault?.address1}{' '}
                        {row.vendorCompanyDetailDefault?.address2},{' '}
                        {row.vendorCompanyDetailDefault?.address3}{' '}
                        {row.vendorCompanyDetailDefault?.address4}
                      </div>
                    </div>
                    {row.vendorCompanyDetail?.map((access, index) => (
                      <div key={index}>
                        <div>
                          {access.vendorVendor?.address1}{' '}
                          {access.vendorVendor?.address2},{' '}
                          {access.vendorVendor?.address3}{' '}
                          {access.vendorVendor?.address4}
                        </div>
                      </div>
                    ))}
                  </td>
                  <td className="addNewbtn">
                    <div
                      className="editIcon"
                      onClick={e => handleEditClick(indx, row)}
                      title="Edit Row"
                    >
                      <EditIcon />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div>
            <AEPPagination
              fetchItems={fetchVendorList}
              setPagination={setAdminVendorPagination}
              pagination={pagination}
              currentPage={currentPage}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
          </div>
        </div>
      </div>
      {selectedRow !== null && (
        <EditVendorModal
          show={editVenodorModal}
          setShowModal={setEditVenodorModal}
          selectedList={selectedRow}
          companyList={companyList}
          defaultCompanyList={defaultCompanyList}
          handleContinueClick={handleContinueClick}
        />
      )}
    </>
  );
};
export { AdminVendor };
