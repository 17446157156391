import { http } from 'services/helpers/BaseApi.ts';
import queryString from 'query-string';
import _ from 'lodash';
import { tracking } from './helpers/Tracking.ts';
import { OperationEnum } from 'enums/operation.ts';

const rootURI = '/bidratesheets';
const retrieveBidRateSheet = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.caastatus = qParams.caastatus.toString();
  if (queryParams.bidrateNmbr) {
    queryParams.bidrateNmbr = qParams.bidrateNmbr.toString();
  }
  if (queryParams.name) {
    queryParams.name = qParams.name.toString();
  }

  try {
    return await http.get(
      `/bidratesheets?${queryString.stringify(queryParams)}`,
    );
  } catch (error) {
    return error;
  }
};

const retrieveWorkCategory = async () => {
  try {
    return await http.get('/work-category?limit=-1');
  } catch (error) {
    return error;
  }
};

const retrieveLocations = async () => {
  try {
    return await http.get(
      '/work-location?limit=-1&sortBy=description&sortOrder=asc',
    );
  } catch (error) {
    return error;
  }
};

const retrieveBidRateSheetDetails = async bidRateno => {
  try {
    return await http.get(`/bidratesheets/${bidRateno}`);
  } catch (error) {
    return error;
  }
};

const postLaborRatesData = async payload => {
  try {
    tracking.setTrackingData(payload, 'bitRateNumber');
    return await http.post('/bidratesheets', payload.newBidRateSheet);
  } catch (error) {
    return error;
  }
};

const masterCodeCheck = async (serviceType, payload, hierarchyPath) => {
  try {
    return await http.post(
      hierarchyPath === ''
        ? `/serviceitems/validateCodes/${serviceType}`
        : `/serviceitems/validateCodes/${serviceType}?heirarchypath=${hierarchyPath}`,
      payload,
    );
  } catch (error) {
    return error;
  }
};

const retrieveBidRateServiceTypeOptions = async (serviceType, qParams) => {
  let url;
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.fetchAll = 'true';
  if (queryParams.itemnum) {
    queryParams.itemnum = qParams.itemnum.toString();
  }
  if (queryParams.description) {
    queryParams.description = qParams.description.toString();
  }
  if (queryParams.craft) {
    queryParams.craft = qParams.craft.toString();
  }

  if (serviceType == 'Labor') {
    url = `/craft/?${queryString.stringify(queryParams)}`;
  } else if (serviceType == 'Equipment') {
    url = `/toolitem/?${queryString.stringify(queryParams)}`;
  } else {
    url = `/serviceitems/?${queryString.stringify(queryParams)}`;
  }
  try {
    return await http.get(url);
  } catch (error) {
    return error;
  }
};

const saveBidRateSheetData = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(
      payload.bidRateSheetFinalData,
      'bitRateNumber',
      OperationEnum.UPDATE,
    );
    return await http.put(
      `/bidratesheets/${payload.bitRateNumber}`,
      payload.bidRateSheetFinalData,
    );
  } catch (error) {
    return error;
  }
};

const deleteBidRateSheetData = async payload => {
  try {
    return await http.delete(`/bidratesheets/${payload.bidRateNumber}`);
  } catch (error) {
    return error;
  }
};

const retreiveServiceTypes = async (qParams, serviceType) => {
  let url;
  const queryParams = _.cloneDeep(qParams);
  queryParams.limit = 500;
  queryParams.fetchAll = 'true';

  if (serviceType == 'Labor') {
    url = `/craft/?${queryString.stringify(queryParams)}`;
  } else if (serviceType == 'Equipment') {
    url = `/toolitem/?${queryString.stringify(queryParams)}`;
  } else {
    url = `/serviceitems/?${queryString.stringify(queryParams)}`;
  }
  try {
    return await http.get(url);
  } catch (error) {
    return error;
  }
};

export const retreiveSelectedBidRateSheetList = async data => {
  const queryParams = {
    limit: 500,
  };
  if (data.hasOwnProperty('search')) {
    queryParams.search = data.search;
  }

  let url;
  url = `${rootURI}/distinct/records/${data.columnname}?${queryString.stringify(
    queryParams,
  )}`;
  try {
    return await http.get(url);
  } catch (error) {
    return error;
  }
};

export {
  retrieveBidRateSheet,
  postLaborRatesData,
  retrieveBidRateSheetDetails,
  saveBidRateSheetData,
  deleteBidRateSheetData,
  retrieveBidRateServiceTypeOptions,
  retrieveWorkCategory,
  retrieveLocations,
  masterCodeCheck,
  retreiveServiceTypes,
};
