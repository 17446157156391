import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
import { useEffect, useState } from 'react';
import { DropdownHeader, Tab, Table, Tabs } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import PORatesHeader from './PORatesHeader';
import { setPOPagination } from 'store/slices/purchaseOrder/purchaseOrderSlice';

const PurchaseOrderRates = () => {
  const [key, setKey] = useState('');
  const dispatch = useDispatch();
  const pagination = useSelector(state => state.purchaseOrder.pagination);

  const sortOrder = useSelector(
    state => state.purchaseOrder.pagination.sortOrder,
  );

  const headers = [
    { key: 'activityNumber', value: 'Activity Number' },
    {
      key: 'activityDescription',
      value: 'Activity Description',
    },
  ];

  const laborRatesheaders = [
    { key: 'laborCode', value: 'Labor Code' },
    { key: 'laborDescription', value: 'Labor Description' },
    { key: 'rateScheduleCode', value: 'Rate Schedule Code' },
    { key: 'laborCategory', value: 'Labor Category' },
    { key: 'effectivefromdt', value: 'Effective From Date' },
    { key: 'effectivetodt', value: 'Effective To Date' },
    { key: 'baseHrlyWage', value: 'Base Hourly Wage' },
    { key: 'fringes', value: 'Fringes' },
    { key: 'smallToolSadder', value: 'Small Tool Adder Amount' },
    { key: 'craftToolAdder', value: 'Craft Hourly Adder Amount' },
    { key: 'ppeAdder', value: 'PPE Adder Amount' },
    { key: 'stLaborRate', value: 'Straight Time' },
    { key: 'otLaborRate', value: 'Over Time' },
    { key: 'dtLaborRate', value: 'Double Time' },
    { key: 'straightTimeShift1', value: 'Straight Time 1st Shift($)' },
    { key: 'overTimeShift1', value: 'Overtime 1st Shift($)' },
    { key: 'doubleTimeShift1', value: 'Double-Time 1st Shift($)' },
    { key: 'straightTimeShift2', value: 'Straight Time 2nd Shift($)' },
    { key: 'overTimeShift2', value: 'Overtime 2nd Shift($)' },
    { key: 'doubleTimeShift2', value: 'Double-Time 2nd Shift($)' },
    { key: 'straightTimeShift3', value: 'Straight Time 3rd Shift($)' },
    { key: 'overTimeShift3', value: 'Overtime 3rd Shift($)' },
    { key: 'doubleTimeShift3', value: 'Double-Time 3rd Shift($)' },
    { key: 'holidayPay', value: 'Holiday Pay' },
    { key: 'travel', value: 'Travel' },
    { key: 'peakSeason', value: 'Peak Season' },
    { key: 'emergencyCallout', value: 'Emergency Call-out' },
    { key: 'standby', value: 'Standby' },
    { key: 'mobilization', value: 'Mobilization' },
    { key: 'demobilization', value: 'Demobilization' },
    { key: 'perDiem', value: 'Per diem' },
    { key: 'daily', value: 'Daily' },
    { key: 'weekly', value: 'Weekly' },
    { key: 'monthly', value: 'Monthly' },
    { key: 'rhx', value: 'RHX' },
    { key: 'dtx', value: 'DTX' },
    { key: 'unioneRate', value: 'Union Rate' },
  ];

  const equipmentheaders = [
    { key: 'equipmentCode', value: 'Equipment Code' },
    { key: 'equipmentDescription', value: 'Equipment Description' },
    { key: 'rateScheduleCode', value: 'Rate Schedule Code' },
    { key: 'effectivefromdt', value: 'Effective From Date' },
    { key: 'effectivetodt', value: 'Effective To Date' },
    { key: 'operatingRate', value: 'Operating Rate' },
    { key: 'standbyRate', value: 'Standby Rate' },
    { key: 'overtimeRate', value: 'Overtime Rate' },
    { key: 'mobilization', value: 'Mobilization' },
    { key: 'demobilization', value: 'Demobilization' },
    { key: 'baseFuelRate', value: 'Base Fuel Rate' },
    { key: 'equipmentFuelPerHour', value: 'Equipment	Fuel per hour' },
  ];

  const reimbursableMarkupHeaders = [
    { key: 'reimbursableMarkupCode', value: 'Reimbursable Markup Code' },
    {
      key: 'reimbursableMarkupDescription',
      value: 'Reimbursable Markup Description',
    },
    { key: 'rateScheduleCode', value: 'Rate Schedule Code' },
    { key: 'unitOfMeasure', value: 'Unit of Measure' },
    { key: 'effectivefromdt', value: 'Effective From Date' },
    { key: 'effectivetodt', value: 'Effective To Date' },
    { key: 'price', value: 'Price' },
    { key: 'basePriceMarkup', value: 'Base Price Markup' },
    { key: 'deliveryMarkup', value: 'Delivery Markup' },
    { key: 'miscellaneousMarkup', value: 'Miscellaneous Markup' },
  ];
  const unitPriceHeaders = [
    { key: 'code', value: 'Code' },
    { key: 'description', value: 'Description' },
    { key: 'unitOfMeasure', value: 'Unit of Measure' },
    { key: 'effectivefromdt', value: 'Effective From Date' },
    { key: 'effectivetodt', value: 'Effective To Date' },
    { key: 'equipLaborUnitPrice', value: 'Equip. & Labor Unit Price' },
    { key: 'laborUnitPrice', value: 'Labor Unit Price' },
    { key: 'equipUnitPrice', value: 'Equip Unit Price' },
    { key: 'materialUnitPrice', value: 'Material Unit Price' },
    { key: 'totalUnitPrice', value: 'Total Unit Price' },
  ];

  const compatibleUnitHeaders = [
    { key: 'cuCode', value: 'CU Code' },
    { key: 'cuDescription', value: 'CU Description' },
    { key: 'compFactor', value: 'Complexity Factor' },
    { key: 'installRate', value: 'Install Rate' },
    { key: 'removeRate', value: 'Remove Rate' },
    { key: 'transferRate', value: 'Transfer Rate' },
    { key: 'effectivefromdt', value: 'Effective From Date' },
    { key: 'effectivetodt', value: 'Effective To Date' },
  ];
  const undergroundMultiplierheaders = [
    { key: 'code', value: 'Code' },
    { key: 'description', value: 'Description' },
    { key: 'complexityFactor', value: 'Complexity Factor' },
    { key: 'multiplier', value: 'Multiplier' },
    { key: 'effectivefromdt', value: 'Effective From Date' },
    { key: 'effectivetodt', value: 'Effective To Date' },
  ];

  // Interface: data
  interface IRatesCostActivity {
    activityNumber: string;
    activityDescription: string;
  }
  interface ILaborRates {
    laborCode: String;
    laborDescription: String;
    rateScheduleCode: String;
    laborCategory: String;
    baseHrlyWage: String;
    fringes: String;
    smallToolSadder: String;
    craftToolAdder: String;
    ppeAdder: String;
    stLaborRate: String;
    dtLaborRate: String;
    otLaborRate: String;
    straightTimeShift1: String;
    straightTimeShift2: String;
    straightTimeShift3: String;
    overTimeShift1: String;
    overTimeShift2: String;
    overTimeShift3: String;
    doubleTimeShift1: String;
    doubleTimeShift2: String;
    doubleTimeShift3: String;
    holidayPay: String;
    travel: String;
    peakSeason: String;
    emergencyCallout: String;
    standby: String;
    mobilization: String;
    demobilization: String;
    perDiem: String;
    daily: String;
    weekly: String;
    monthly: String;
    rhx: string;
    dtx: string;
    unioneRate: String;
    effectivefromdt: String;
    effectivetodt: String;
  }
  interface IRatesEquipment {
    equipmentCode: String;
    equipmentDescription: String;
    rateScheduleCode: String;
    operatingRate: String;
    standbyRate: String;
    overtimeRate: String;
    mobilization: String;
    demobilization: String;
    baseFuelRate: String;
    equipmentFuelPerHour: String;
  }
  interface IReimbursableMarkup {
    reimbursableMarkupCode: String;
    reimbursableMarkupDescription: String;
    rateScheduleCode: String;
    unitOfMeasure: String;
    price: String;
    basePriceMarkup: String;
    deliveryMarkup: String;
    miscellaneousMarkup: String;
  }
  interface IUnitPriceTasks {
    code: String;
    description: String;
    unitOfMeasure: String;
    equipLaborUnitPrice: String;
    laborUnitPrice: String;
    equipUnitPrice: String;
    materialUnitPrice: String;
    totalUnitPrice: String;
  }
  interface ICompatibleUnit {
    cuCode: String;
    cuDescription: String;
    installRate: String;
    removeRate: String;
    transferRate: String;
  }
  interface IUndergroundMultiplier {
    code: String;
    description: String;
    complexityFactor: String;
    multiplier: String;
  }

  // useStates
  const [ratesCostActivityList, setRatesCostActivityList] = useState([]);
  const [laborRatesList, setLaborRatesList] = useState([]);
  const [ratesEquipmentList, setRatesEquipmentList] = useState([]);
  const [reimbursableMarkupList, setReimbursableMarkupList] = useState([]);
  const [unitPriceTasksList, setUnitPriceTasksList] = useState([]);
  const [compatibleUnitList, setCompatibleUnitList] = useState([]);
  const [undergroundMultiplierList, setUndergroundMultiplierList] = useState(
    [],
  );

  // Retrieve data
  const purchaseOrderDetail = useSelector(state => state.purchaseOrderDetails);
  const polaborrates = purchaseOrderDetail[SERVICE_TYPES.LABOR];
  const poeqptrates = purchaseOrderDetail[SERVICE_TYPES.EQUIPMENT];
  const pocurates = purchaseOrderDetail[SERVICE_TYPES.CU];
  const poexprates = purchaseOrderDetail[SERVICE_TYPES.REIMBURSABLE_MARKUP];
  const pouprates = purchaseOrderDetail[SERVICE_TYPES.UNIT_PRICE_TASK];
  const porates = purchaseOrderDetail[SERVICE_TYPES.RATES];

  const isNull = field => {
    if (field === '' || field === null || field === undefined) {
      return true;
    } else {
      return false;
    }
  };

  const sortData = (data, sortBy, sortOrder) => {
    if (sortOrder === 'asc') {
      let sortedValue = data
        ?.slice()
        .sort((option1, option2) =>
          String(option1[sortBy])
            .trim()
            .localeCompare(String(option2[sortBy]).trim()),
        );
      return sortedValue;
    } else {
      let sortedValue = data
        ?.slice()
        .sort((option1, option2) =>
          String(option2[sortBy])
            .trim()
            .localeCompare(String(option1[sortBy]).trim()),
        );
      return sortedValue;
    }
  };

  const handleSorting = (tab, sortBy, sortOrder) => {
    dispatch(
      setPOPagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );

    switch (tab) {
      case 'costActivity':
        {
          const sortedActivities = sortData(
            ratesCostActivityList,
            sortBy,
            sortOrder,
          );
          setRatesCostActivityList(sortedActivities);
        }
        break;
      case 'laborRates':
        {
          const sortedActivities = sortData(laborRatesList, sortBy, sortOrder);
          setLaborRatesList(sortedActivities);
        }

        break;
      case 'equipment':
        {
          const sortedActivities = sortData(
            ratesEquipmentList,
            sortBy,
            sortOrder,
          );
          setRatesEquipmentList(sortedActivities);
        }
        break;
      case 'reimbursableMarkup':
        {
          const sortedActivities = sortData(
            reimbursableMarkupList,
            sortBy,
            sortOrder,
          );
          setReimbursableMarkupList(sortedActivities);
        }
        break;
      case 'unitPriceTask':
        {
          const sortedActivities = sortData(
            unitPriceTasksList,
            sortBy,
            sortOrder,
          );
          setUnitPriceTasksList(sortedActivities);
        }
        break;
      case 'undergroundMultiplier':
        {
          const sortedActivities = sortData(
            undergroundMultiplierList,
            sortBy,
            sortOrder,
          );
          setUndergroundMultiplierList(sortedActivities);
        }
        break;
      case 'compatibleUnit':
        {
          const sortedActivities = sortData(
            compatibleUnitList,
            sortBy,
            sortOrder,
          );
          setCompatibleUnitList(sortedActivities);
        }
        break;
      default:
    }
  };

  useEffect(() => {
    if (purchaseOrderDetail != null) {
      const updatedCostActivity = [];
      purchaseOrderDetail?.filteredCostActivity?.map(costactivity => {
        // Load: ratesCostActivity
        const ratesCostActivity: IRatesCostActivity = {};
        ratesCostActivity.activityNumber = costactivity.activitynum;
        ratesCostActivity.activityDescription = costactivity.description;
        updatedCostActivity.push(ratesCostActivity);
      });
      setRatesCostActivityList(updatedCostActivity);

      polaborrates?.map(polaborrate => {
        // Load: laborRate
        let bUse: boolean = true;
        const laborRates: ILaborRates = {};

        // Check: union rates / accept

        if (!isNull(polaborrate.local)) {
          const iAux = porates.findIndex(
            porate =>
              porate.poid === polaborrate.poid &&
              porate.ponum === polaborrate.ponum &&
              porate.rateschecode === polaborrate.rateschecode &&
              porate.ratesch === polaborrate.rateschedule &&
              porate.rateschrevno === polaborrate.rateschrevno &&
              Math.round(porate.poratenum) ===
                Math.round(polaborrate.poratenum) &&
              porate.vndracpt === 1 &&
              !isNull(porate.local),
          );
          bUse = iAux === -1 ? false : true;
        }

        if (bUse) {
          laborRates.laborCode = isNull(polaborrate.local)
            ? polaborrate.restype1
            : polaborrate.restype;
          laborRates.laborDescription = polaborrate.description;
          laborRates.rateScheduleCode = polaborrate.rateschecode;
          laborRates.laborCategory = polaborrate.laborcategory;
          laborRates.baseHrlyWage = polaborrate.basehrlywage;
          laborRates.fringes = polaborrate.fringes;
          laborRates.smallToolSadder = polaborrate.smalltoolsadder;
          laborRates.craftToolAdder = polaborrate.hrlyadder;
          laborRates.ppeAdder = polaborrate.ppeadder;
          laborRates.stLaborRate = polaborrate.stlaborrate;
          laborRates.dtLaborRate = polaborrate.dtlaborrate;
          laborRates.otLaborRate = polaborrate.otlaborrate;
          laborRates.straightTimeShift1 = polaborrate.stloadedrate;
          laborRates.overTimeShift1 = polaborrate.thloadedrate;
          laborRates.doubleTimeShift1 = polaborrate.dtloadedrate;
          laborRates.straightTimeShift2 = polaborrate.scndshftstloadedrate;
          laborRates.overTimeShift2 = polaborrate.scndshftthloadedrate;
          laborRates.doubleTimeShift2 = polaborrate.scndshftdtloadedrate;
          laborRates.straightTimeShift3 = polaborrate.thrdshftstloadedrate;
          laborRates.overTimeShift3 = polaborrate.thrdshftthloadedrate;
          laborRates.doubleTimeShift3 = polaborrate.thrdshftdtloadedrate;
          laborRates.holidayPay = polaborrate.holidaypay;
          laborRates.travel = polaborrate.travelrate;
          laborRates.peakSeason = polaborrate.peakseason;
          laborRates.emergencyCallout = polaborrate.emergencycallout;
          laborRates.standby = polaborrate.standby;
          laborRates.mobilization = polaborrate.mobilization;
          laborRates.demobilization = polaborrate.demobilization;
          laborRates.perDiem = polaborrate.perdiemrate;
          laborRates.daily = polaborrate.dailywage;
          laborRates.weekly = polaborrate.weeklywage;
          laborRates.monthly = polaborrate.monthlywage;
          laborRates.rhx = polaborrate.rhxlaborrate;
          laborRates.dtx = polaborrate.dtxlaborrate;
          laborRates.unioneRate = !isNull(polaborrate.local) ? 'Yes' : 'No';
          laborRates.effectivefromdt = polaborrate.effectivefromdt;
          laborRates.effectivetodt = polaborrate.effectivetodt;
          laborRatesList.push(laborRates);
          setLaborRatesList(laborRatesList);
        }
      });

      poeqptrates?.map(poeqptrate => {
        // Load: ratesEquipment
        const ratesEquipment: IRatesEquipment = {};
        ratesEquipment.equipmentCode = poeqptrate.rentalitemno;
        ratesEquipment.equipmentDescription = poeqptrate.description;
        ratesEquipment.rateScheduleCode = poeqptrate.rateschecode;
        ratesEquipment.operatingRate = poeqptrate.operatingrate;
        ratesEquipment.standbyRate = poeqptrate.standbyrate;
        ratesEquipment.overtimeRate = poeqptrate.otrate;
        ratesEquipment.mobilization = poeqptrate.mobilization;
        ratesEquipment.demobilization = poeqptrate.demobilization;
        ratesEquipment.baseFuelRate = poeqptrate.basefuelrate;
        ratesEquipment.equipmentFuelPerHour = poeqptrate.galperhr;
        ratesEquipment.effectivefromdt = poeqptrate.effectivefromdt;
        ratesEquipment.effectivetodt = poeqptrate.effectivetodt;
        ratesEquipmentList.push(ratesEquipment);
        setRatesEquipmentList(ratesEquipmentList);
      });

      pocurates?.map(pocurate => {
        // Load: compatibleUnit
        if (pocurate.underground !== '1') {
          const compatibleUnit: ICompatibleUnit = {};
          compatibleUnit.cuCode = pocurate.cugroupcode;
          compatibleUnit.cuDescription = pocurate.description;
          compatibleUnit.compFactor = pocurate.compfactorid;
          compatibleUnit.installRate = pocurate.installrate;
          compatibleUnit.removeRate = pocurate.removerate;
          compatibleUnit.transferRate = pocurate.transferrate;
          compatibleUnit.effectivefromdt = pocurate.effectivefromdt;
          compatibleUnit.effectivetodt = pocurate.effectivetodt;
          compatibleUnitList.push(compatibleUnit);
          setCompatibleUnitList(compatibleUnitList);
        } else {
          // Load: undergroundMultiplier
          const undergroundMultiplier: IUndergroundMultiplier = {};
          undergroundMultiplier.code = pocurate.cugroupcode;
          undergroundMultiplier.description = pocurate.description;
          undergroundMultiplier.complexityFactor = pocurate.compfactorid;
          undergroundMultiplier.multiplier = pocurate.multiplier;
          undergroundMultiplier.effectivefromdt = pocurate.effectivefromdt;
          undergroundMultiplier.effectivetodt = pocurate.effectivetodt;
          undergroundMultiplierList.push(undergroundMultiplier);
          setUndergroundMultiplierList(undergroundMultiplierList);
        }
      });

      pouprates?.map(pouprate => {
        // Load: unitPriceTasks
        const unitPriceTasks: IUnitPriceTasks = {};
        unitPriceTasks.code = pouprate.taskcode;
        unitPriceTasks.description = pouprate.description;
        unitPriceTasks.unitOfMeasure = pouprate.unitmeasure;
        unitPriceTasks.equipLaborUnitPrice = pouprate.eqptlabunitprice;
        unitPriceTasks.laborUnitPrice = pouprate.unplabor;
        unitPriceTasks.equipUnitPrice = pouprate.eqpuntprice;
        unitPriceTasks.materialUnitPrice = pouprate.upmatl;
        unitPriceTasks.totalUnitPrice = pouprate.totalunitprice;
        unitPriceTasks.effectivefromdt = pouprate.effectivefromdt;
        unitPriceTasks.effectivetodt = pouprate.effectivetodt;
        unitPriceTasksList.push(unitPriceTasks);
        setUnitPriceTasksList(unitPriceTasksList);
      });

      poexprates?.map(poexprate => {
        // Load: reimbursableMarkup
        const reimbursableMarkup: IReimbursableMarkup = {};
        reimbursableMarkup.reimbursableMarkupCode = poexprate.expcode;
        reimbursableMarkup.reimbursableMarkupDescription =
          poexprate.description;
        reimbursableMarkup.rateScheduleCode = poexprate.rateschecode;
        reimbursableMarkup.unitOfMeasure = poexprate.unitmeasure;
        reimbursableMarkup.price = poexprate.price;
        reimbursableMarkup.basePriceMarkup = poexprate.basepricemarkup;
        reimbursableMarkup.deliveryMarkup = poexprate.deliverymarkup;
        reimbursableMarkup.miscellaneousMarkup = poexprate.miscellaneousmarkup;
        reimbursableMarkup.effectivefromdt = poexprate.effectivefromdt;
        reimbursableMarkup.effectivetodt = poexprate.effectivetodt;
        reimbursableMarkupList.push(reimbursableMarkup);
        setReimbursableMarkupList(reimbursableMarkupList);
      });
    }
  }, []);

  const tabs = [
    {
      key: 'costActivity',
      value: 'Cost Activity',
      data: ratesCostActivityList,
    },
    { key: 'laborRates', value: 'Labor', data: laborRatesList },
    { key: 'equipment', value: 'Equipment', data: ratesEquipmentList },
    {
      key: 'reimbursableMarkup',
      value: 'Reimbursable Mark-Ups',
      data: reimbursableMarkupList,
    },
    {
      key: 'unitPriceTask',
      value: 'Unit Price Task',
      data: unitPriceTasksList,
    },
    {
      key: 'compatibleUnit',
      value: 'Compatible Unit',
      data: compatibleUnitList,
    },
    {
      key: 'undergroundMultiplier',
      value: 'Underground Multiplier Rates',
      data: undergroundMultiplierList,
    },
  ];

  return (
    <>
      {purchaseOrderDetail.poratehdrs?.length > 0 && (
        <PORatesHeader POHeaderDetails={purchaseOrderDetail.poratehdrs} />
      )}
      <Tabs
        id="poDetailsRatesPage"
        className="poDetailsRatesPage"
        onSelect={k => setKey(k)}
      >
        {tabs.map(
          (tab, index) =>
            tab.data.length > 0 && (
              <Tab
                eventKey={tab.key}
                title={tab.value}
                key={index}
                tabClassName="tab-item"
              >
                {tab.key === 'costActivity' && (
                  <div className="tableContainer">
                    <Table hover responsive striped bordered={false}>
                      <thead>
                        <tr>
                          {headers.map((header, idx) => (
                            <th key={idx}>
                              {sortOrder === 'asc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'desc')
                                  }
                                >
                                  {header.value}
                                  <CaretUp style={{ marginLeft: '12px' }} />
                                </div>
                              )}

                              {sortOrder === 'desc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'asc')
                                  }
                                >
                                  {header.value}
                                  <CaretDown style={{ marginLeft: '12px' }} />
                                </div>
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {ratesCostActivityList.map(
                          (row: IRatesCostActivity, indx) => (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row.activityNumber}</td>
                              <td>{row.activityDescription}</td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
                {tab.key === 'laborRates' && (
                  <div className="tableContainer">
                    <Table
                      hover
                      responsive
                      striped
                      bordered={false}
                      className="poRates"
                    >
                      <thead>
                        <tr>
                          {laborRatesheaders.map((header, idx) => (
                            <th key={idx}>
                              {sortOrder === 'asc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'desc')
                                  }
                                >
                                  {header.value}
                                  <CaretUp style={{ marginLeft: '12px' }} />
                                </div>
                              )}

                              {sortOrder === 'desc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'asc')
                                  }
                                >
                                  {header.value}
                                  <CaretDown style={{ marginLeft: '12px' }} />
                                </div>
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {laborRatesList.map((rowrates: ILaborRates, indx) => (
                          <tr key={indx} className="tRow table-hover-row">
                            <td>{rowrates.laborCode}</td>
                            <td>{rowrates.laborDescription}</td>
                            <td>{rowrates.rateScheduleCode}</td>
                            <td>{rowrates.laborCategory}</td>
                            <td>{rowrates.effectivefromdt}</td>
                            <td>{rowrates.effectivetodt}</td>
                            <td>{rowrates.baseHrlyWage}</td>
                            <td>{rowrates.fringes}</td>
                            <td>{rowrates.smallToolSadder}</td>
                            <td>{rowrates.craftToolAdder}</td>
                            <td>{rowrates.ppeAdder}</td>
                            <td>{rowrates.stLaborRate}</td>
                            <td>{rowrates.otLaborRate}</td>
                            <td>{rowrates.dtLaborRate}</td>
                            <td>{rowrates.straightTimeShift1}</td>
                            <td>{rowrates.overTimeShift1}</td>
                            <td>{rowrates.doubleTimeShift1}</td>
                            <td>{rowrates.straightTimeShift2}</td>
                            <td>{rowrates.overTimeShift2}</td>
                            <td>{rowrates.doubleTimeShift2}</td>
                            <td>{rowrates.straightTimeShift3}</td>
                            <td>{rowrates.overTimeShift3}</td>
                            <td>{rowrates.doubleTimeShift3}</td>
                            <td>{rowrates.holidayPay}</td>
                            <td>{rowrates.travel}</td>
                            <td>{rowrates.peakSeason}</td>
                            <td>{rowrates.emergencyCallout}</td>
                            <td>{rowrates.standby}</td>
                            <td>{rowrates.mobilization}</td>
                            <td>{rowrates.demobilization}</td>
                            <td>{rowrates.perDiem}</td>
                            <td>{rowrates.daily}</td>
                            <td>{rowrates.weekly}</td>
                            <td>{rowrates.monthly}</td>
                            <td>{rowrates.rhx}</td>
                            <td>{rowrates.dtx}</td>
                            <td>{rowrates.unioneRate}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
                {tab.key === 'equipment' && (
                  <div className="tableContainer">
                    <Table hover responsive striped bordered={false}>
                      <thead>
                        <tr>
                          {equipmentheaders.map((header, idx) => (
                            <th key={idx}>
                              {sortOrder === 'asc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'desc')
                                  }
                                >
                                  {header.value}
                                  <CaretUp style={{ marginLeft: '12px' }} />
                                </div>
                              )}

                              {sortOrder === 'desc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'asc')
                                  }
                                >
                                  {header.value}
                                  <CaretDown style={{ marginLeft: '12px' }} />
                                </div>
                              )}
                            </th>
                          ))}
                          ;
                        </tr>
                      </thead>
                      <tbody>
                        {ratesEquipmentList.map(
                          (row: IRatesEquipment, indx) => (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row.equipmentCode}</td>
                              <td>{row.equipmentDescription}</td>
                              <td>{row.rateScheduleCode}</td>
                              <td>{row.effectivefromdt}</td>
                              <td>{row.effectivetodt}</td>
                              <td>{row.operatingRate}</td>
                              <td>{row.standbyRate}</td>
                              <td>{row.overtimeRate}</td>
                              <td>{row.mobilization}</td>
                              <td>{row.demobilization}</td>
                              <td>{row.baseFuelRate}</td>
                              <td>{row.equipmentFuelPerHour}</td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
                {tab.key === 'reimbursableMarkup' && (
                  <div className="tableContainer">
                    <Table hover responsive striped bordered={false}>
                      <thead>
                        <tr>
                          {reimbursableMarkupHeaders.map((header, idx) => (
                            <th key={idx}>
                              {sortOrder === 'asc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'desc')
                                  }
                                >
                                  {header.value}
                                  <CaretUp style={{ marginLeft: '12px' }} />
                                </div>
                              )}

                              {sortOrder === 'desc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'asc')
                                  }
                                >
                                  {header.value}
                                  <CaretDown style={{ marginLeft: '12px' }} />
                                </div>
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {reimbursableMarkupList.map(
                          (row: IReimbursableMarkup, indx) => (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row.reimbursableMarkupCode}</td>
                              <td>{row.reimbursableMarkupDescription}</td>
                              <td>{row.rateScheduleCode}</td>
                              <td>{row.unitOfMeasure}</td>
                              <td>{row.effectivefromdt}</td>
                              <td>{row.effectivetodt}</td>
                              <td>{row.price}</td>
                              <td>{row.basePriceMarkup}</td>
                              <td>{row.deliveryMarkup}</td>
                              <td>{row.miscellaneousMarkup}</td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
                {tab.key === 'unitPriceTask' && (
                  <div className="tableContainer">
                    {/*
                <div className="search">
                  <div style={{ display: 'flex' }}>
                    <Form.Group className="mb-3" controlId="searchPO">
                      <Form.Control type="text" placeholder="Search" />
                    </Form.Group>
                    <div className="searchIcon">
                      <SearchIcon />
                    </div>
                  </div>
            </div>*/}
                    <Table hover responsive striped bordered={false}>
                      <thead>
                        <tr>
                          {unitPriceHeaders.map((header, idx) => (
                            <th key={idx}>
                              {sortOrder === 'asc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'desc')
                                  }
                                >
                                  {header.value}
                                  <CaretUp style={{ marginLeft: '12px' }} />
                                </div>
                              )}

                              {sortOrder === 'desc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'asc')
                                  }
                                >
                                  {header.value}
                                  <CaretDown style={{ marginLeft: '12px' }} />
                                </div>
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {unitPriceTasksList.map(
                          (row: IUnitPriceTasks, indx) => (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row.code}</td>
                              <td>{row.description}</td>
                              <td>{row.unitOfMeasure}</td>
                              <td>{row.effectivefromdt}</td>
                              <td>{row.effectivetodt}</td>
                              <td>{row.equipLaborUnitPrice}</td>
                              <td>{row.laborUnitPrice}</td>
                              <td>{row.equipUnitPrice}</td>
                              <td>{row.materialUnitPrice}</td>
                              <td>{row.totalUnitPrice}</td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
                {tab.key === 'compatibleUnit' && (
                  <div className="tableContainer">
                    {/*
                <div className="search">
                  <div style={{ display: 'flex' }}>
                    <Form.Group className="mb-3" controlId="searchPO">
                      <Form.Control type="text" placeholder="Search" />
                    </Form.Group>
                    <div className="searchIcon">
                      <SearchIcon />
                    </div>
                  </div>
                </div>
            */}
                    <Table hover responsive striped bordered={false}>
                      <thead>
                        <tr>
                          {compatibleUnitHeaders.map((header, idx) => (
                            <th key={idx}>
                              {sortOrder === 'asc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'desc')
                                  }
                                >
                                  {header.value}
                                  <CaretUp style={{ marginLeft: '12px' }} />
                                </div>
                              )}

                              {sortOrder === 'desc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'asc')
                                  }
                                >
                                  {header.value}
                                  <CaretDown style={{ marginLeft: '12px' }} />
                                </div>
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {compatibleUnitList.map(
                          (row: ICompatibleUnit, indx) => (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row.cuCode}</td>
                              <td>{row.cuDescription}</td>
                              <td>{row.compFactor}</td>
                              <td>{row.installRate}</td>
                              <td>{row.removeRate}</td>
                              <td>{row.transferRate}</td>
                              <td>{row.effectivefromdt}</td>
                              <td>{row.effectivetodt}</td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
                {tab.key === 'undergroundMultiplier' && (
                  <div className="tableContainer">
                    {/*
                <div className="search">
                  <div style={{ display: 'flex' }}>
                    <Form.Group className="mb-3" controlId="searchPO">
                      <Form.Control type="text" placeholder="Search" />
                    </Form.Group>
                    <div className="searchIcon">
                      <SearchIcon />
                    </div>
                  </div>
            </div> */}
                    <Table hover responsive striped bordered={false}>
                      <thead>
                        <tr>
                          {undergroundMultiplierheaders.map((header, idx) => (
                            <th key={idx}>
                              {sortOrder === 'asc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'desc')
                                  }
                                >
                                  {header.value}
                                  <CaretUp style={{ marginLeft: '12px' }} />
                                </div>
                              )}

                              {sortOrder === 'desc' && (
                                <div
                                  id={header.key}
                                  onClick={e =>
                                    handleSorting(tab.key, header.key, 'asc')
                                  }
                                >
                                  {header.value}
                                  <CaretDown style={{ marginLeft: '12px' }} />
                                </div>
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {undergroundMultiplierList.map(
                          (row: IUndergroundMultiplier, indx) => (
                            <tr key={indx} className="tRow table-hover-row">
                              <td>{row.code}</td>
                              <td>{row.description}</td>
                              <td>{row.complexityFactor}</td>
                              <td>{row.multiplier}</td>
                              <td>{row.effectivefromdt}</td>
                              <td>{row.effectivetodt}</td>
                            </tr>
                          ),
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Tab>
            ),
        )}
      </Tabs>
    </>
  );
};

export { PurchaseOrderRates };
