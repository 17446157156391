import * as Yup from 'yup';

const reviewerModalSchema = () => {
  return Yup.object().shape({
    reviewerComment: Yup.string()
      .trim()
      .required('Rejection comments are required.'),
  });
};
export default reviewerModalSchema;
