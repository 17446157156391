import { LaborRatesFields } from 'enums/fields.ts';

const serviceType = 'bidrateLaborRates';
const downloadExcelServiceTypeValue = 'labourRate';
const serviceTypeName = 'Labor Rates';
const downloadExcelServiceTypeName = 'Labor Rates';

//Preference Fields
const options = [
  { value: 'Effective End Date', key: 'effEndDate' },
  { value: 'Base Wage($)', key: 'baseWage' },
  { value: 'Labor Category', key: 'laborCategory' },

  { value: 'Straight Time($)', key: 'straightTime' },
  { value: 'Overtime($)', key: 'overtime' },
  { value: 'Double-Time($)', key: 'doubleTime' },
  { value: 'Straight Time 1st Shift($)', key: 'strtTimeShft1' },
  { value: 'Straight Time 2nd Shift($)', key: 'strtTimeShft2' },
  { value: 'Straight Time 3rd Shift($)', key: 'strtTimeShft3' },
  { value: 'Overtime 1st Shift($)', key: 'otTimeShft1' },
  { value: 'Overtime 2nd Shift($)', key: 'otTimeShft2' },
  { value: 'Overtime 3rd Shift($)', key: 'otTimeShft3' },
  { value: 'Double-Time 1st Shift($)', key: 'dtTimeShft1' },
  { value: 'Double-Time 2nd Shift($)', key: 'dtTimeShft2' },
  { value: 'Double-Time 3rd Shift($)', key: 'dtTimeShft3' },
  { value: 'Holiday Pay($)', key: 'holidayPay' },
  { value: 'Travel Rate($)', key: 'travelRate' },
  { value: 'StandBy($)', key: 'standby' },
  { value: 'Peak Season($)', key: 'peakSeason' },
  { value: 'Emergency Call-Out($)', key: 'emergencyCallOut' },
  { value: 'Mobilization($)', key: 'mobilization' },
  { value: 'Demobilization($)', key: 'demobilization' },
  { value: 'Perdiem($)', key: 'perdiem' },
  { value: 'Daily($)', key: 'daily' },
  { value: 'Weekly($)', key: 'weekly' },
  { value: 'Monthly($)', key: 'monthly' },
  { value: 'RHX($)', key: 'rhx' },
  { value: 'DTX($)', key: 'dtx' },
];

//Mandatory columns
const columnsArray = [
  {
    key: LaborRatesFields.laborCode,
    value: 'Labor Code',
    showHeader: true,
    required: true,
  },
  {
    key: LaborRatesFields.laborDescription,
    value: 'Labor Description',
    showHeader: true,
    required: true,
  },
  {
    value: 'Labor Category',
    key: 'laborCategory',
    showHeader: true,
    required: true,
  },
  {
    key: LaborRatesFields.workCategory,
    value: 'Work Category',
    showHeader: true,
    required: true,
  },
  {
    key: LaborRatesFields.location,
    value: 'Location',
    showHeader: true,
    required: true,
  },
  {
    key: LaborRatesFields.effStartDate,
    value: 'Effective Start Date',
    showHeader: true,
    required: true,
  },

  {
    value: 'Effective End Date',
    key: 'effEndDate',
    showHeader: false,
    required: false,
  },
  {
    value: 'Base Wage($)',
    key: 'baseWage',
    showHeader: false,
    required: false,
  },

  {
    value: 'Straight Time($)',
    key: 'straightTime',
    showHeader: false,
    required: false,
  },
  { value: 'Overtime($)', key: 'overtime', showHeader: false, required: false },
  {
    value: 'Double-Time($)',
    key: 'doubleTime',
    showHeader: false,
    required: false,
  },
  {
    value: 'Straight Time 1st Shift($)',
    key: 'strtTimeShft1',
    showHeader: false,
    required: false,
  },
  {
    value: 'Straight Time 2nd Shift($)',
    key: 'strtTimeShft2',
    showHeader: false,
    required: false,
  },
  {
    value: 'Straight Time 3rd Shift($)',
    key: 'strtTimeShft3',
    showHeader: false,
    required: false,
  },
  {
    value: 'Overtime 1st Shift($)',
    key: 'otTimeShft1',
    showHeader: false,
    required: false,
  },
  {
    value: 'Overtime 2nd Shift($)',
    key: 'otTimeShft2',
    showHeader: false,
    required: false,
  },
  {
    value: 'Overtime 3rd Shift($)',
    key: 'otTimeShft3',
    showHeader: false,
    required: false,
  },
  {
    value: 'Double-Time 1st Shift($)',
    key: 'dtTimeShft1',
    showHeader: false,
    required: false,
  },
  {
    value: 'Double-Time 2nd Shift($)',
    key: 'dtTimeShft2',
    showHeader: false,
    required: false,
  },
  {
    value: 'Double-Time 3rd Shift($)',
    key: 'dtTimeShft3',
    showHeader: false,
    required: false,
  },
  {
    value: 'Holiday Pay($)',
    key: 'holidayPay',
    showHeader: false,
    required: false,
  },
  {
    value: 'Travel Rate($)',
    key: 'travelRate',
    showHeader: false,
    required: false,
  },
  { value: 'StandBy($)', key: 'standby', showHeader: false, required: false },
  {
    value: 'Peak Season($)',
    key: 'peakSeason',
    showHeader: false,
    required: false,
  },
  {
    value: 'Emergency Call-Out($)',
    key: 'emergencyCallOut',
    showHeader: false,
    required: false,
  },
  {
    value: 'Mobilization($)',
    key: 'mobilization',
    showHeader: false,
    required: false,
  },
  {
    value: 'Demobilization($)',
    key: 'demobilization',
    showHeader: false,
    required: false,
  },
  { value: 'Perdiem($)', key: 'perdiem', showHeader: false, required: false },
  { value: 'Daily($)', key: 'daily', showHeader: false, required: false },
  { value: 'Weekly($)', key: 'weekly', showHeader: false, required: false },
  { value: 'Monthly($)', key: 'monthly', showHeader: false, required: false },
  { value: 'RHX($)', key: 'rhx', showHeader: false, required: false },
  { value: 'DTX($)', key: 'dtx', showHeader: false, required: false },
];

let laborDetailsArray = [
  {
    laborCode: 'PM1',
    laborDescription: 'Project Manager1',
    workCategory: 'TM',
    effStartDate: '2003-01-01',
    location: 'BUH',
  },
];
//AutoFill columns
let autoFillColumnskeys = {
  laborCode: 'laborCode',
  laborDescription: 'laborDescription',
  laborCategory: 'laborCategory',
};

let referenceObject = {
  laborCode: null,
  laborDescription: null,
  laborCategory: null,
  effStartDate: null,
  location: null,
  straightTime: null,
  overtime: null,
  doubleTime: null,
  strtTimeShft1: null,
  strtTimeShft2: null,
  strtTimeShft3: null,
  otTimeShft1: null,
  otTimeShft2: null,
  otTimeShft3: null,
  dtTimeShft1: null,
  dtTimeShft2: null,
  dtTimeShft3: null,
  holidayPay: null,
  travelRate: null,
  emergencyCallOut: null,
  mobilization: null,
  demobilization: null,
  perdiem: null,
  weekly: null,
  monthly: null,
  rhx: null,
  dtx: null,
  bidrateLaborRateNmbr: null,
  bidrateNmbr: null,
  updatedDate: null,
  enteredDate: null,
  workCategory: null,
  baseWage: null,
  standby: null,
  peakSeason: null,
  effEndDate: null,
  daily: null,
};

// Import excel constrants
const header = {};
columnsArray.map(item => {
  header[item.key] = item.value;
});
options.map(item => {
  header[item.key] = item.value;
});

const exelRefObj = [header];
// const exelRefObj = [
//   {
//     laborCode: 'Labor Code',
//     laborDescription: 'Labor Description',
//     effStartDate: 'Eff Start Date',
//     location: 'Location',
//     straightTime: 'Straight Time',
//     overtime: 'Overtime',
//     doubleTime: 'Double Time',
//     strtTimeShft1: 'Strt Time Shft 1',
//     strtTimeShft2: 'Strt Time Shft 2',
//     strtTimeShft3: 'Strt Time Shft 3',
//     otTimeShft1: 'Ot Time Shft 1',
//     otTimeShft2: 'Ot Time Shft 2',
//     otTimeShft3: 'Ot Time Shft 3',
//     dtTimeShft1: 'Dt Time Shft 1',
//     dtTimeShft2: 'Dt Time Shft 2',
//     dtTimeShft3: 'Dt Time Shft 3',
//     holidayPay: 'Holiday Pay',
//     travelRate: 'Travel Rate',
//     emergencyCallOut: 'Emergency Call Out',
//     mobilization: 'Mobilization',
//     demobilization: 'Demobilization',
//     perdiem: 'Perdiem',
//     weekly: 'Weekly',
//     monthly: 'Monthly',
//     rhx: 'Rhx',
//     dtx: 'Dtx',
//     bidrateLaborRateNmbr: 'Bidrate Labor Rate Nmbr',
//     bidrateNmbr: 'Bidrate Nmbr',
//     updatedDate: 'Updated Date',
//     updatedBy: 'Updated By',
//     enteredBy: 'Entered By',
//     enteredDate: 'Entered Date',
//     effEndDate: 'Eff End Date',
//     peakSeason: 'Peak Season',
//     laborCategory: 'Labor Category',
//     workCategory: 'Work Category',
//     fringes: 'Fringes',
//     smToolAdderAmnt: 'Sm Tool Adder Amnt',
//     craftHourlyAdderAmnt: 'Craft Hourly Adder Amnt',
//     ppeAdderAmnt: 'Ppe Adder Amnt',
//     standby: 'Standby',
//     comments: 'Comments',
//     daily: '',
//     baseWage: 'Base Wage',
//   },
// ];

export {
  options,
  columnsArray,
  referenceObject,
  serviceType,
  serviceTypeName,
  downloadExcelServiceTypeValue,
  exelRefObj,
  downloadExcelServiceTypeName,
  autoFillColumnskeys,
};
