import { EquipmentFields } from 'enums/fields.ts';

const serviceType = 'bidrateEquipmentRates';
const serviceTypeName = 'Equipment Rates';
const downloadExcelServiceTypeValue = 'equipRate';
const downloadExcelServiceTypeName = serviceTypeName;
// INFO: endpoint key for downloading BidRate sheet
const url = 'equipRate';
const options = [
  { value: 'Equipment Category', key: 'eqpmntCategory' },
  { value: 'Effective End Date', key: 'effEndDate' },
  { value: 'Owned or Leased (Owned, Rental)', key: 'ownedOrLeased' },
  { value: 'Equipment Type (Standard, Specialty)', key: 'eqpmntType' },
  { value: 'Operating Rate($)', key: 'opRate' },
  {
    value: 'Standby Rate($)',
    key: 'stbyRate',
  },
  { value: 'Over-time Rate($)', key: 'ovrTimeRate' },
  { value: 'Volume CFM', key: 'volumecfm' },
  { value: 'Size', key: 'size' },
  { value: 'Fuel Rental Rate($)', key: 'fuelrentalRate' },
  { value: 'Equipment Rental Rate($)', key: 'eqpmntRentalRate' },
  { value: 'Usage Modifier(per # of hours)', key: 'usgModifier' },
  { value: 'Usage Discount(%)', key: 'usgDsctPct' },
  { value: 'Usage Discounted Rate($)', key: 'usgDsctRate' },
  { value: 'Base Fuel Rate($)', key: 'baseFuelRate' },
  { value: 'Fuel Gallons Per Hour(#)', key: 'fuelGallonsPerHour' },
  { value: 'Equipment Drive Type', key: 'eqpmntDriveType' },
  { value: 'Horsepower(per vehicle)', key: 'horsepower' },
  { value: 'Volume of Water GPM(Gallons/Minute)', key: 'volumeOfWaterGpm' },
  {
    value: 'Number of Water Guns or Lances(total #)',
    key: 'nmWaterGunsLances',
  },
  { value: 'Round Trip Miles(total miles)', key: 'roundTripMiles' },
  { value: 'Cost per Mile($)', key: 'costPerMile' },
  {
    value: 'Lump Sum Round Trip Trucking Charges($)',
    key: 'lumpsumRoundTripTruckingChrgs',
  },
  { value: 'Mobilization($)', key: 'mobilization' },
  { value: 'Demobilization($)', key: 'demobilization' },
  { value: 'Fuel Tolerance($)', key: 'fuelTolerance' },
];

const headers = [
  'Equipment Code.',
  'Equipment Description',
  'Unit of Measure',
  'Work Category',
  'Location',
  'Effective Start Date',
];

const columnsArray = [
  {
    key: EquipmentFields.eqpmntCode,
    value: 'Equipment code',
    required: true,
    showHeader: true,
  },
  {
    key: EquipmentFields.eqpmntDescription,
    value: 'Equipment Description',
    required: true,
    showHeader: true,
  },
  {
    value: 'Equipment Category',
    key: 'eqpmntCategory',
    required: true,
    showHeader: true,
  },
  {
    key: EquipmentFields.unitOfMeasure,
    value: 'Unit of Measure',
    required: true,
    showHeader: true,
  },
  {
    key: EquipmentFields.workCategory,
    value: 'Work Category',
    required: true,
    showHeader: true,
  },
  {
    key: EquipmentFields.location,
    value: 'Location',
    required: true,
    showHeader: true,
  },
  {
    key: EquipmentFields.effStartDate,
    value: 'Effective Start Date',
    required: true,
    showHeader: true,
  },
  {
    value: 'Effective End Date',
    key: 'effEndDate',
    required: false,
    showHeader: false,
  },
  {
    value: 'Owned or Leased (Owned, Rental)',
    key: 'ownedOrLeased',
    required: false,
    showHeader: false,
  },
  {
    value: 'Equipment Type (Standard, Specialty)',
    key: 'eqpmntType',
    required: false,
    showHeader: false,
  },
  {
    value: 'Operating Rate($)',
    key: 'opRate',
    required: false,
    showHeader: false,
  },
  {
    value: 'Standby Rate($)',
    key: 'stbyRate',
    required: false,
    showHeader: false,
  },
  {
    value: 'Over-time Rate($)',
    key: 'ovrTimeRate',
    required: false,
    showHeader: false,
  },
  { value: 'Volume CFM', key: 'volumecfm', required: false, showHeader: false },
  { value: 'Size', key: 'size', required: false, showHeader: false },
  {
    value: 'Fuel Rental Rate($)',
    key: 'fuelrentalRate',
    required: false,
    showHeader: false,
  },
  {
    value: 'Equipment Rental Rate($)',
    key: 'eqpmntRentalRate',
    required: false,
    showHeader: false,
  },
  {
    value: 'Usage Modifier(per # of hours)',
    key: 'usgModifier',
    required: false,
    showHeader: false,
  },
  {
    value: 'Usage Discount(%)',
    key: 'usgDsctPct',
    required: false,
    showHeader: false,
  },
  {
    value: 'Usage Discounted Rate($)',
    key: 'usgDsctRate',
    required: false,
    showHeader: false,
  },
  {
    value: 'Base Fuel Rate($)',
    key: 'baseFuelRate',
    required: false,
    showHeader: false,
  },
  {
    value: 'Fuel Tolerance($)',
    key: 'fuelTolerance',
    required: false,
    showHeader: false,
  },
  {
    value: 'Fuel Gallons Per Hour(#)',
    key: 'fuelGallonsPerHour',
    required: false,
    showHeader: false,
  },
  {
    value: 'Equipment Drive Type',
    key: 'eqpmntDriveType',
    required: false,
    showHeader: false,
  },
  {
    value: 'Horsepower(per vehicle)',
    key: 'horsepower',
    required: false,
    showHeader: false,
  },
  {
    value: 'Volume of Water GPM(Gallons/Minute)',
    key: 'volumeOfWaterGpm',
    required: false,
    showHeader: false,
  },
  {
    value: 'Number of Water Guns or Lances(total #)',
    key: 'nmWaterGunsLances',
    required: false,
    showHeader: false,
  },
  {
    value: 'Round Trip Miles(total miles)',
    key: 'roundTripMiles',
    required: false,
    showHeader: false,
  },
  {
    value: 'Cost per Mile($)',
    key: 'costPerMile',
    required: false,
    showHeader: false,
  },
  {
    value: 'Lump Sum Round Trip Trucking Charges($)',
    key: 'lumpsumRoundTripTruckingChrgs',
    required: false,
    showHeader: false,
  },
  {
    value: 'Mobilization($)',
    key: 'mobilization',
    required: false,
    showHeader: false,
  },
  {
    value: 'Demobilization($)',
    key: 'demobilization',
    required: false,
    showHeader: false,
  },
];

let autoFillColumnskeys = {
  eqpmntCode: 'eqpmntCode',
  eqpmntDescription: 'eqpmntDescription',
  eqpmntCategory: 'eqpmntCategory',
  unitOfMeasure: 'unitOfMeasure',
  horsepower: 'horsepower',
  volumecfm: 'volumecfm',
  fuelGallonsPerHour: 'fuelGallonsPerHour',
  size: 'size',
  eqpmntDriveType: 'eqpmntDriveType',
  volumeOfWaterGpm: 'volumeOfWaterGpm',
};

const referenceObject = {
  eqpmntCode: null,
  eqpmntDescription: null,
  location: null,
  effStartDate: null,
  ownedOrLeased: null,
  eqpmntCategory: null,
  eqpmntType: null,
  opRate: null,
  stbyRate: null,
  ovrTimeRate: null,
  volumecfm: null,
  size: null,
  fuelrentalRate: null,
  eqpmntRentalRate: null,
  usgModifier: null,
  usgDsctPct: null,
  usgDsctRate: null,
  baseFuelRate: null,
  fuelTolerance: null,
  fuelGallonsPerHour: null,
  eqpmntDriveType: null,
  horsepower: null,
  volumeOfWaterGpm: null,
  nmWaterGunsLances: null,
  roundTripMiles: null,
  costPerMile: null,
  lumpsumRoundTripTruckingChrgs: null,
  mobilization: null,
  demobilization: null,
  effEndDate: null,
  bidrateEqptNmbr: null,
  bidrateNmbr: null,
  updatedDate: null,
  updatedBy: null,
  enteredBy: null,
  enteredDate: null,
  unitOfMeasure: null,
  workCategory: null,
};

const tabs = [{ key: 'equipment', value: 'Equipment' }];

const header = {};
columnsArray.map(item => {
  header[item.key] = item.value;
});
options.map(item => {
  header[item.key] = item.value;
});

const equipmentExcelHeaderRefObj = [header];

// const equipmentExcelHeaderRefObj = [
//   {
//     eqpmntCode: 'Eqpmnt Code',
//     eqpmntDescription: 'Eqpmnt Description',
//     location: 'Location',
//     effStartDate: 'Eff Start Date',
//     ownedOrLeased: 'Owned Or Leased',
//     eqpmntCategory: 'Eqpmnt Category',
//     eqpmntType: 'Eqpmnt Type',
//     opRate: 'Op Rate',
//     stbyRate: 'Stby Rate',
//     ovrTimeRate: 'Ovr Time Rate',
//     volumecfm: 'Volumecfm',
//     size: 'Size',
//     fuelrentalRate: 'Fuelrental Rate',
//     eqpmntRentalRate: 'Eqpmnt Rental Rate',
//     usgModifier: 'Usg Modifier',
//     usgDsctPct: 'Usg Dsct Pct',
//     usgDsctRate: 'Usg Dsct Rate',
//     baseFuelRate: 'Base Fuel Rate',
//     fuelTolerance: 'Fuel Tolerance',
//     fuelGallonsPerHour: 'Fuel Gallons Per Hour',
//     eqpmntDriveType: 'Eqpmnt Drive Type',
//     horsepower: 'Horsepower',
//     volumeOfWaterGpm: 'Volume Of Water Gpm',
//     nmWaterGunsLances: 'Nm Water Guns Lances',
//     roundTripMiles: 'Round Trip Miles',
//     costPerMile: 'Cost Per Mile',
//     lumpsumRoundTripTruckingChrgs: 'Lumpsum Round Trip Trucking Chrgs',
//     mobilization: 'Mobilization',
//     demobilization: 'Demobilization',
//     effEndDate: 'Eff End Date',
//     bidrateEqptNmbr: 'Bidrate Eqpt Nmbr',
//     bidrateNmbr: 'Bidrate Nmbr',
//     updatedDate: 'Updated Date',
//     updatedBy: 'Updated By',
//     enteredBy: 'Entered By',
//     enteredDate: 'Entered Date',
//     unitOfMeasure: 'Unit Of Measure',
//     unitType: 'Unit Type',
//     workCategory: 'Work Category',
//     comments: 'Comments',
//   },
// ];
export {
  options,
  headers,
  columnsArray,
  tabs,
  serviceType,
  serviceTypeName,
  referenceObject,
  downloadExcelServiceTypeValue,
  equipmentExcelHeaderRefObj,
  downloadExcelServiceTypeName,
  autoFillColumnskeys,
};
