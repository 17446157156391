import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import {
  displaySuccess,
  displayError,
} from 'components/common/Alert/ToastAlert';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { confirm } from 'components/Dialogs/Confirm';
import { adminConstants } from 'constants/adminConstants';
import { Page } from 'enums/page.ts';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAdminCrewType,
  fetchCrewTypeDetails,
  fetchSelectedAdminCrewList,
  setAdminCrewtypePagination,
  addCrewTypeData,
  updateCrewTypeData,
  deleteCrewTypeData,
} from 'store/slices/AdminModules/crewTypeSlice';
import { setCrewtypeFilters } from 'store/slices/filterSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { getSortOrder, isEmptyVal } from 'utils/utils';
import './AdminCrewType.scss';
import AdminCrewTypeModel from './AdminCrewTypeModel';
import { AddIcon } from 'assets/images/AddIcon/AddIcon';
import { DeleteIcon } from 'assets/images/DeleteIcon/DeleteIcon';
import { EditIcon } from 'assets/images/EditIcon/EditIcon';

const initialCrewTypeData = {
  crewNmbr: null,
  crewType: '',
  crewDesc: '',
  dutyCode: adminConstants.dutyCode[0].value,
  orgDeptGroup: adminConstants.orgGroup[0].value,
};

const AdminCrewType = props => {
  const dispatch = useDispatch();
  const [crewNumb, setCrewNumb] = useState(null);
  const [crewModal, setCrewModal] = useState(false);
  const pagination = useSelector(state => state.adminCrewType.pagination);
  const currentPage = useSelector(
    state => state.adminCrewType.pagination.currentPage,
  );

  const inputListData = useSelector(state => state.filter.crewtype);
  const adminCrewFiltersValues = [
    { label: 'Crew Type', value: 'crewType' },
    { label: 'Description', value: 'crewDesc' },
    { label: 'Duty Code', value: 'dutyCode' },
  ];
  const selectedFilterRecordList = useSelector(
    state => state.adminCrewType.recordItems,
  );
  const [chipFilterValues, setChipFilterValues] = useState({});
  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);

  const sortBy = useSelector(state => state.adminCrewType.pagination.sortBy);
  const sortOrder = useSelector(
    state => state.adminCrewType.pagination.sortOrder,
  );
  const crewTypeData = useSelector(state => state.adminCrewType.crewTypeData);
  const crewTypeDetailsData = useSelector(state => state.adminCrewType.items);
  const headersCrewTypes = [
    {
      value: 'Crew Type',
      sortkey: 'crewType',
    },
    {
      value: 'Description',
      sortkey: 'crewDesc',
    },
    {
      value: 'Duty Code',
      sortkey: 'dutyCode',
    },
    {
      value: 'Action',
      sortkey: '',
    },
  ];
  const [inputs, setInputs] = useState(crewTypeDetailsData);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [selectedCrewType, setSelectedCrewType] = useState(initialCrewTypeData);
  const [crewTypeTitle, setCrewTypeTitle] = useState('');
  const onChangeHandler = useCallback(({ target: { name, value } }) =>
    setInputs(state => ({ ...state, [name]: value }), []),
  );

  const handleCrewTypeClick = crewNmbr => {
    if (crewNmbr) {
      setCrewNumb(crewNmbr);
      setCrewTypeTitle('Edit Crew Type');
      const crewTypeDetailsData = crewTypeData.filter(
        a => a.crewNmbr === crewNmbr,
      )[0];
      setSelectedCrewType({
        crewNmbr: crewTypeDetailsData.crewNmbr,
        crewType: crewTypeDetailsData.crewType,
        crewDesc: crewTypeDetailsData.crewDesc,
        dutyCode: crewTypeDetailsData.dutyCode,
        orgDeptGroup: crewTypeDetailsData.orgDeptGroup,
      });
    } else {
      setCrewTypeTitle('Add Crew Type');
      setSelectedCrewType(initialCrewTypeData);
    }
    setCrewModal(true);
  };

  useEffect(() => {
    dispatch(setActivePage(Page.ADMIN_MODULES));
    if (props.resetFilter) {
      props.setResetFilter(false);
      handleContinueClick([
        {
          FilterValue: '',
          input_rank: null,
          selectFilterValue: '',
          selectFilterLabel: '',
          presentIcon: '',
          filerForm: false,
          FilterLabel: '',
          selectedFilterRecords: [],
        },
      ]);
    } else {
      handleContinueClick(inputListData);
    }
  }, [dispatch]);

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setAdminCrewtypePagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };

  const handleAdminCrewTypeCancel = () => setCrewModal(false);

  const handleAdminCrewTypeSave = cd => {
    const crewNmbr = cd.crewNmbr;
    delete cd.crewNmbr;
    if (crewNmbr) {
      // Updating
      dispatch(
        updateCrewTypeData({
          data: cd,
          crewTypeId: crewNmbr,
          callback: () => {
            setCrewModal(false);
            displaySuccess('Successfully Updated');
          },
        }),
      );
    } else {
      // Adding
      dispatch(
        addCrewTypeData({
          data: cd,
          callback: () => {
            setCrewModal(false);
            displaySuccess('Successfully Saved');
          },
        }),
      );
    }
  };

  const objDecorate = obj => {
    let newObj = _.mapKeys(obj, (value, key) => {
      return key;
    });
    return newObj;
  };

  const handleContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });

    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });
    const selectedFilterDataDecorated = objDecorate(selectedFilterData);

    if (props.resetFilter) {
      dispatch(
        fetchAdminCrewType({
          currentPage: 1,
          crewType: '',
          crewDesc: '',
          dutyCode: '',
        }),
      );
    } else {
      dispatch(fetchAdminCrewType(selectedFilterDataDecorated));
    }

    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setFilterchip(true);
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendor' || val.FilterValue === 'vendorid') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setCrewtypeFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    // Check is using "vendorid"
    const filterchipDataDecorated = objDecorate(filterchipData);
    dispatch(fetchAdminCrewType(filterchipDataDecorated));
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(crewTypeData.map(li => li.crewNmbr));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleCheckClick = e => {
    const { id, checked } = e.target;
    let chkid = Number(id);
    let count = chkid ? 1 : 0;
    if (checked) {
      setIsCheck([...isCheck, chkid]);
      if (crewTypeData.length === isCheck.length + count) {
        setIsCheckAll(true);
      }
    } else {
      setIsCheck(isCheck.filter(item => item !== chkid));
      setIsCheckAll(false);
    }
  };

  const handleDeleteCrewType = async () => {
    if (!isCheck.length) {
      displayError('Please select records to delete!');
    } else {
      const isDeleteConfirm = await confirm({
        message: 'Are you sure you would like to delete the record(s)?',
        title: 'Delete Crew Type',
      });
      if (isDeleteConfirm) {
        dispatch(
          deleteCrewTypeData({
            data: { crewNumbrs: isCheck },
            callback: () => {
              setIsCheck([]);
              displaySuccess('Successfully Deleted');
            },
          }),
        );
      }
    }
  };

  const getDutyCode = dc => {
    const dutyCode = adminConstants.dutyCode.filter(a => a.value === dc);
    return dutyCode.length >= 1 ? dutyCode[0].label : dc;
  };

  return (
    <>
      <div className="aepContainer adminModuleCrewTypeContainer">
        <div className="tableContainer">
          <Row>
            <Col sm={10}>
              <>
                <div className="filterComponent">
                  <AEPFilterComponent
                    inputListData={inputListData}
                    filterValues={adminCrewFiltersValues}
                    setInputFilterData={setCrewtypeFilters}
                    selectedFilterRecordList={selectedFilterRecordList}
                    fetchSelectedList={fetchSelectedAdminCrewList}
                    handleContinueClick={handleContinueClick}
                    continueBtnDisable={continueBtnDisable}
                    handleFilterclose={handleFilterclose}
                    handleFilterChip={handleFilterChip}
                    filterChip={filterChip}
                    chipFilterValues={chipFilterValues}
                  />
                </div>
              </>
            </Col>
            <Col sm={2}>
              <div style={{ float: 'right' }}>
                <span onClick={e => handleCrewTypeClick(null)} title="Add Row">
                  <AddIcon />
                </span>
                <span
                  onClick={handleDeleteCrewType}
                  className="actionbtn"
                  title="Delete Row"
                >
                  <DeleteIcon />
                </span>
              </div>
            </Col>
          </Row>
          <Table hover responsive striped bordered={false}>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                    style={{ width: '24px' }}
                  />
                </th>
                {headersCrewTypes.map((header, idx) => (
                  <th key={idx}>
                    {header.sortkey && (
                      <div
                        id={header.sortkey}
                        onClick={e =>
                          handleSorting(header.sortkey, getSortOrder(sortOrder))
                        }
                      >
                        {header.value}
                        {sortOrder === 'asc' ? (
                          <CaretUp style={{ marginLeft: '12px' }} />
                        ) : (
                          <CaretDown style={{ marginLeft: '12px' }} />
                        )}
                      </div>
                    )}
                    {header.sortkey === '' && <div>{header.value}</div>}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {crewTypeData !== 'undefined' &&
                !_.isEmpty(crewTypeData) &&
                crewTypeData.map((row, indx) => (
                  <tr key={indx} className="tRow table-hover-row">
                    <td>
                      <input
                        type="checkbox"
                        key={row.crewNmbr}
                        id={row.crewNmbr}
                        onClick={handleCheckClick}
                        // @ts-ignore
                        checked={isCheck.includes(row.crewNmbr)}
                        value={row.crewNmbr}
                        style={{ width: '24px', height: '24px' }}
                      />
                    </td>
                    <td>{row.crewType}</td>
                    <td>{row.crewDesc}</td>
                    <td>{getDutyCode(row.dutyCode)}</td>
                    <td>
                      <span
                        className="editRow"
                        onClick={e => handleCrewTypeClick(row.crewNmbr)}
                        title="Edit Row"
                      >
                        <EditIcon />
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="float-right">
            <AEPPagination
              fetchItems={fetchAdminCrewType}
              setPagination={setAdminCrewtypePagination}
              pagination={pagination}
              currentPage={currentPage}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
          </div>
        </div>
        <AdminCrewTypeModel
          show={crewModal}
          crewTypeTitle={crewTypeTitle}
          selectedCrewType={selectedCrewType}
          handleAdminCrewTypeSave={handleAdminCrewTypeSave}
          handleAdminCrewTypeCancel={handleAdminCrewTypeCancel}
          dutyCode={adminConstants.dutyCode}
          orgGroup={adminConstants.orgGroup}
        />
      </div>
    </>
  );
};
export { AdminCrewType };
