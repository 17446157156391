import { BuPath } from 'constants/buPath';
import { ModuleACL, UserType } from 'enums/entitlements.ts';
import { TimesheetStatus } from 'enums/statuses.ts';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { IsAllowedACL } from 'utils/aclHelper';

const TransmissionAccountingFields = props => {
  const user = useSelector(state => state.auth);
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);
  const timesheet = useSelector(state => state.timesheetDetail);
  const isMobile = useMediaQuery({ query: '(max-width: 834px)' });

  const {
    control,
    trigger,
    errors,
    setValue,
    getValues,
    transmissionWO,
    setTransmissionWO,
    fetchWorkOrder,
  } = props;

  const isDisabled = () => {
    if (user?.configObject?.userrole === 'admin' && purchaseOrder?.bucketpo) {
      return false;
    } else if (
      timesheet?.caastatus ===
        TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED &&
      user?.userType === UserType.AEP &&
      purchaseOrder?.bucketpo &&
      !timesheet?.submittedByAep &&
      IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_accept_timesheet')
    ) {
      return true;
    } else if (
      timesheet?.caastatus ===
        TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED &&
      user?.userType === UserType.VENDOR &&
      purchaseOrder?.bucketpo &&
      IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_accept_timesheet') &&
      timesheet?.submittedByAep
    ) {
      return true;
    } else if (
      timesheet?.caastatus ===
        TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED &&
      user?.userType === UserType.VENDOR &&
      purchaseOrder?.bucketpo
    ) {
      return true;
    } else if (
      (user.userType === UserType.VENDOR &&
        timesheet?.caastatus ===
          TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED &&
        timesheet?.submittedByAep) ||
      (user.userType === UserType.AEP &&
        timesheet?.caastatus ===
          TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED &&
        !timesheet?.submittedByAep)
    ) {
      return true;
    } else {
      return (
        !purchaseOrder?.bucketpo ||
        [
          TimesheetStatus.TIMESHEET_APPROVED,
          TimesheetStatus.TIMESHEET_INVOICED,
        ].includes(timesheet?.caastatus)
      );
    }
  };

  return (
    <>
      {purchaseOrder.buPath === BuPath.TRANS && (
        <div className="aepContainer transAccounting">
          <div className="headerContainer">
            <span>Accounting</span>
          </div>

          <div className="transAccountingFields">
            <div className="checkWOSection">
              <Row
                style={
                  isMobile ? { display: 'flex', flexDirection: 'column' } : {}
                }
              >
                <Col sm={isMobile ? 0 : 2}>
                  <Form.Group>
                    <Form.Label>Work Order</Form.Label>
                    <Controller
                      control={control}
                      name="timesheetAccounts.0.workorder"
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          value={value}
                          onChange={e => {
                            setValue(
                              'timesheetAccounts.0.workorder',
                              e.target.value.replace(/\s/g, ''),
                            );
                            setTransmissionWO(
                              e.target.value.replace(/\s/g, ''),
                            );
                          }}
                          ref={ref}
                          type="text"
                          disabled={isDisabled()}
                        />
                      )}
                    />
                    <div className="errorMessage">
                      {
                        errors?.['timesheetAccounts']?.[0]?.['workorder']?.[
                          'message'
                        ]
                      }
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    className="checkWOButton"
                    onClick={() => fetchWorkOrder()}
                    disabled={!transmissionWO.length}
                  >
                    Check WO
                  </Button>
                </Col>
                <Col>
                  <Form.Group className="mr-1">
                    <Form.Label>Project Id</Form.Label>
                    <Controller
                      control={control}
                      name="timesheetAccounts.0.abmsProject"
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          value={value}
                          onChange={e =>
                            setValue(
                              'timesheetAccounts.0.abmsProject',
                              e.target.value,
                            )
                          }
                          ref={ref}
                          type="text"
                          disabled={isDisabled()}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mr-1">
                    <Form.Label>Account Number</Form.Label>
                    <Controller
                      control={control}
                      name="timesheetAccounts.0.accountNmbr"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          value={getValues('timesheetAccounts.0.accountNmbr')}
                          onChange={e => {
                            isNaN(parseInt(e.target.value))
                              ? setValue(
                                  'timesheetAccounts.0.accountNmbr',
                                  null,
                                )
                              : setValue(
                                  'timesheetAccounts.0.accountNmbr',
                                  parseInt(e.target.value),
                                );
                            trigger('timesheetAccounts.0.accountNmbr');
                          }}
                          ref={ref}
                          type="text"
                          disabled={isDisabled()}
                        />
                      )}
                    />
                    <div className="errorMessage">
                      {
                        errors?.['timesheetAccounts']?.[0]?.['accountNmbr']?.[
                          'message'
                        ]
                      }
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mr-1">
                    <Form.Label>PCBU</Form.Label>
                    <Controller
                      control={control}
                      name="timesheetAccounts.0.projectBusinessUnit"
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          value={getValues(
                            'timesheetAccounts.0.projectBusinessUnit',
                          )}
                          onChange={e =>
                            setValue(
                              'timesheetAccounts.0.projectBusinessUnit',
                              e.target.value,
                            )
                          }
                          ref={ref}
                          type="text"
                          disabled={isDisabled()}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mr-1">
                    <Form.Label>GLBU</Form.Label>
                    <Controller
                      control={control}
                      name="timesheetAccounts.0.glbu"
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          value={value}
                          onChange={e => {
                            setValue(
                              'timesheetAccounts.0.glbu',
                              e.target.value,
                            );
                            trigger('timesheetAccounts.0.glbu');
                          }}
                          ref={ref}
                          type="text"
                          disabled={isDisabled()}
                        />
                      )}
                    />
                    <div className="errorMessage">
                      {
                        errors?.['timesheetAccounts']?.[0]?.['glbu']?.[
                          'message'
                        ]
                      }
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mr-1">
                    <Form.Label>Department</Form.Label>
                    <Controller
                      control={control}
                      name="timesheetAccounts.0.dept"
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          value={value}
                          onChange={e => {
                            setValue(
                              'timesheetAccounts.0.dept',
                              e.target.value,
                            );
                            trigger('timesheetAccounts.0.dept');
                          }}
                          ref={ref}
                          type="text"
                          disabled={isDisabled()}
                        />
                      )}
                    />
                    <div className="errorMessage">
                      {
                        errors?.['timesheetAccounts']?.[0]?.['dept']?.[
                          'message'
                        ]
                      }
                    </div>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mr-1">
                    <Form.Label>Cost Component</Form.Label>
                    <Controller
                      control={control}
                      name="timesheetAccounts.0.costComponent"
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          value={value}
                          onChange={e =>
                            setValue(
                              'timesheetAccounts.0.costComponent',
                              e.target.value,
                            )
                          }
                          ref={ref}
                          type="text"
                          disabled={isDisabled()}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mr-1">
                    <Form.Label>ABMS Activity</Form.Label>
                    <Controller
                      control={control}
                      name="timesheetAccounts.0.abmsActivity"
                      defaultValue=""
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Form.Control
                          value={value}
                          onChange={e =>
                            setValue(
                              'timesheetAccounts.0.abmsActivity',
                              e.target.value,
                            )
                          }
                          ref={ref}
                          type="text"
                          disabled={isDisabled()}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { TransmissionAccountingFields };
