import { yupResolver } from '@hookform/resolvers/yup';
import { AddIcon } from 'assets/images/AddIcon/AddIcon';
import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { DeleteIcon } from 'assets/images/DeleteIcon/DeleteIcon';
import { confirm } from 'components/Dialogs/Confirm';
import {
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { districtworkarea } from 'enums/fields.ts';
import { Page } from 'enums/page.ts';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
  adddistrictWorkArea,
  deletdistrictWorkArea,
  fetchSelecteddistrictWorkAreaList,
  fetchdistrictWorkAreaList,
  getdistrictWorkArea,
  getworkArea,
  setDefaultValues,
  setdistrictWorkAreaPagination,
  updatedistrictWorkArea,
} from 'store/slices/AdminModules/districtWorkAreaSlice';
import { setDistrictWorkAreaFilters } from 'store/slices/filterSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { AddNewDistrictWorkAreaModel } from './AddNewDistrictWorkAreaModel';
import { generateSchema } from './formHelpers';
import { isEmptyVal } from 'utils/utils';
// import './AdmindistrictWorkArea.scss';

const AdminDistrictWorkArea = props => {
  const dispatch = useDispatch();
  const [addNewSiteModal, setAddNewSiteModal] = useState(false);
  // @ts-ignore
  const [values, setValues] = useState({});
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);
  const districtWorkAreaList = useSelector(
    // @ts-ignore
    state => state.districtWorkArea.districtWorkAreaList,
  );
  const districtWorkAreaData = useSelector(
    // @ts-ignore
    state => state.districtWorkArea.districtWorkAreaData,
  );
  const currentPage = useSelector(
    // @ts-ignore
    state => state.districtWorkArea.pagination.currentPage,
  );
  // @ts-ignore
  const pagination = useSelector(state => state.districtWorkArea.pagination);
  // @ts-ignore
  const sortBy = useSelector(state => state.districtWorkArea.pagination.sortBy);
  const sortOrder = useSelector(
    // @ts-ignore
    state => state.districtWorkArea.pagination.sortOrder,
  );

  const inputListData = useSelector(state => state.filter.districtworkarea);
  const adminCrewFiltersValues = [
    { label: 'Code', value: 'cdDist' },
    { label: 'Description', value: 'dsDist' },
  ];
  const selectedFilterRecordList = useSelector(
    state => state.districtWorkArea.recordItems,
  );
  const [chipFilterValues, setChipFilterValues] = useState({});
  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);

  const { promiseInProgress: updatedistrictWorkAreaLoading } =
    usePromiseTracker({
      area: PROMISE_TRACKER.updatedistrictWorkArea,
      delay: 0,
    });

  const { promiseInProgress: adddistrictWorkAreaLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.adddistrictWorkArea,
    delay: 0,
  });

  const defaultValues = {
    cdDist: '',
    dsDist: '',
    fgActive: '',
    workAreaId: '',
  };

  const schema = generateSchema();
  const methods = useForm({
    mode: 'onTouched',
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    trigger,
    reset,
    // @ts-ignore
    formState: { errors },
  } = methods;

  const handleCancel = () => {
    dispatch(setDefaultValues({}));
    setValue('cdDist', '');
    setValue('dsDist', '');
    setValue('fgActive', '');
    setValue('workAreaId', '');
    setAddNewSiteModal(false);
  };

  const handleAddDistrictWorkArea = workAreaValue => {
    dispatch(
      adddistrictWorkArea({
        data: workAreaValue,
        callback: async data => {
          if (data) {
            displaySuccess('District Work Area added');
          }
        },
      }),
    );
    setAddNewSiteModal(false);
  };

  const handleUpdateDistrictWorkArea = workAreaValue => {
    dispatch(
      updatedistrictWorkArea({
        data: workAreaValue,
        recordNmbr: districtWorkAreaData.distWorkId,
        callback: async data => {
          if (data) {
            displaySuccess('District Work Area updated');
          }
        },
      }),
    );
    setAddNewSiteModal(false);
  };

  const handleClick = () => {
    setAddNewSiteModal(true);
  };

  const headers = [
    {
      key: districtworkarea.workareacode,
      value: 'District Work Area Code',
      sortkey: 'cdDist',
    },
    {
      key: districtworkarea.workareadescription,
      value: 'District Work Area Description',
      sortkey: 'dsDist',
    },
    {
      key: districtworkarea.workarea,
      value: 'Work Area',
      sortkey: 'workAreaId',
    },
    {
      key: districtworkarea.fieldstatus,
      value: 'Field Status',
      sortkey: 'fgActive',
    },
  ];

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setdistrictWorkAreaPagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };

  const handleDoubleClick = (id, e) => {
    if (e.detail == 2) {
      dispatch(
        getdistrictWorkArea({
          id,
          callback: data => {
            setValue('cdDist', data.cdDist ? data.cdDist : '');
            trigger('cdDist');
            setValue('dsDist', data.dsDist ? data.dsDist : '');
            trigger('dsDist');
            setValue('workAreaId', data.workAreaId ? data.workAreaId : '');
            trigger('workAreaId');
            setValue('fgActive', data.fgActive ? data.fgActive : '');
            // @ts-ignore
            trigger('fgActive ');
            setAddNewSiteModal(true);
          },
        }),
      );
    }
  };

  const ref = useRef();

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map(li => li.distWorkId));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleDelete = async () => {
    if (!isCheck.length) {
      displayError('Please select records to delete!');
    } else {
      if (
        await confirm({
          message: ' Are you sure you would like to delete the record(s)?',
          title: 'Delete District Work Area',
        })
      ) {
        dispatch(deletdistrictWorkArea(isCheck));
      }
    }
  };

  const handleCheckClick = e => {
    const { id, checked } = e.target;
    let chkid = Number(id);
    let count = chkid ? 1 : 0;
    if (checked) {
      setIsCheck([...isCheck, chkid]);
      if (list.length === isCheck.length + count) {
        setIsCheckAll(true);
      }
    } else {
      setIsCheck(isCheck.filter(item => item !== chkid));
      setIsCheckAll(false);
    }
  };

  const objDecorate = obj => {
    let newObj = _.mapKeys(obj, (value, key) => {
      return key;
    });
    return newObj;
  };

  const handleContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });

    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });
    const selectedFilterDataDecorated = objDecorate(selectedFilterData);

    if (props.resetFilter) {
      dispatch(
        fetchdistrictWorkAreaList({ currentPage: 1, cdDist: '', dsDist: '' }),
      );
    } else {
      dispatch(fetchdistrictWorkAreaList(selectedFilterDataDecorated));
    }
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setFilterchip(true);
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendor' || val.FilterValue === 'vendorid') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setDistrictWorkAreaFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    // Check is using "vendorid"
    const filterchipDataDecorated = objDecorate(filterchipData);
    dispatch(fetchdistrictWorkAreaList(filterchipDataDecorated));
  };

  useEffect(() => {
    dispatch(setActivePage(Page.ADMIN_MODULES));
    // @ts-ignore
    dispatch(getworkArea());
    setList(districtWorkAreaList);
    if (props.resetFilter) {
      props.setResetFilter(false);
      handleContinueClick([
        {
          FilterValue: '',
          input_rank: null,
          selectFilterValue: '',
          selectFilterLabel: '',
          presentIcon: '',
          filerForm: false,
          FilterLabel: '',
          selectedFilterRecords: [],
        },
      ]);
    } else {
      handleContinueClick(inputListData);
    }
  }, [dispatch]);

  useEffect(() => {
    setList(districtWorkAreaList);
  }, [districtWorkAreaList]);

  return (
    <>
      <div className="aepContainer adminModuledistrictWorkAreaContainer">
        <div
          className="tableContainer"
          // @ts-ignore
          ref={ref}
        >
          <div className="aepBreadcrumb actionPane">
            <Row>
              <Col sm={10}>
                <>
                  <div className="filterComponent">
                    <AEPFilterComponent
                      inputListData={inputListData}
                      filterValues={adminCrewFiltersValues}
                      setInputFilterData={setDistrictWorkAreaFilters}
                      selectedFilterRecordList={selectedFilterRecordList}
                      fetchSelectedList={fetchSelecteddistrictWorkAreaList}
                      handleContinueClick={handleContinueClick}
                      continueBtnDisable={continueBtnDisable}
                      handleFilterclose={handleFilterclose}
                      handleFilterChip={handleFilterChip}
                      filterChip={filterChip}
                      chipFilterValues={chipFilterValues}
                    />
                  </div>
                </>
              </Col>
              <Col sm={2}>
                <div style={{ float: 'right' }}>
                  <span
                    onClick={
                      // @ts-ignore
                      e => handleClick()
                    }
                    title="Add Row"
                  >
                    <AddIcon />
                  </span>
                  <span
                    onClick={handleDelete}
                    className="actionbtn"
                    title="Delete Row"
                  >
                    <DeleteIcon />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <Table
            responsive
            striped
            bordered={false}
            className="districtWorkAreaTbl"
            hover
          >
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                    style={{ width: '24px' }}
                  />
                </th>
                {headers.map((header, idx) => (
                  <th key={idx}>
                    {sortOrder === 'asc' && (
                      <div
                        id={header.sortkey}
                        // @ts-ignore
                        onClick={e => handleSorting(header.sortkey, 'desc')}
                      >
                        {header.value}
                        <CaretUp style={{ marginLeft: '12px' }} />
                      </div>
                    )}
                    {sortOrder === 'desc' && (
                      <div
                        id={header.sortkey}
                        // @ts-ignore
                        onClick={e => handleSorting(header.sortkey, 'asc')}
                      >
                        {header.value}
                        <CaretDown style={{ marginLeft: '12px' }} />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {districtWorkAreaList &&
                districtWorkAreaList.map((row, indx) => (
                  <tr
                    key={indx}
                    className="tRow table-hover-row"
                    onClick={e => handleDoubleClick(row.distWorkId, e)}
                  >
                    <td>
                      <input
                        type="checkbox"
                        key={row.distWorkId}
                        id={row.distWorkId}
                        onClick={handleCheckClick}
                        // @ts-ignore
                        checked={isCheck.includes(row.distWorkId)}
                        value={row.distWorkId}
                        style={{ width: '24px', height: '24px' }}
                      />
                    </td>
                    <td>{row.cdDist}</td>
                    <td>{row.dsDist}</td>
                    <td>{row?.workArea?.work_area_desc}</td>
                    <td>{row.fgActive == 'Y' ? 'Yes' : 'No'}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <AEPPagination
            fetchItems={fetchdistrictWorkAreaList}
            setPagination={setdistrictWorkAreaPagination}
            pagination={pagination}
            currentPage={currentPage}
            sortBy={sortBy}
            sortOrder={sortOrder}
          />
        </div>
        <AddNewDistrictWorkAreaModel
          show={addNewSiteModal}
          setShowModal={setAddNewSiteModal}
          handleAddWorkArea={handleAddDistrictWorkArea}
          handleUpdateWorkArea={handleUpdateDistrictWorkArea}
          handleCancel={handleCancel}
          workAreaData={districtWorkAreaData}
          handleSubmit={handleSubmit}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          trigger={trigger}
          reset={reset}
          methods={methods}
        />
      </div>
      <ClipLoader
        color="#009cde"
        loading={updatedistrictWorkAreaLoading || adddistrictWorkAreaLoading}
        cssOverride={{
          top: '50vh',
          left: '50vw',
          position: 'absolute',
          zIndex: 1,
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};
export { AdminDistrictWorkArea };
