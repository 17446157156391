import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { Row, Col } from 'react-bootstrap/esm';
import SignatureCanvas from 'react-signature-canvas';
import { useSelector } from 'react-redux';
import { UserType } from 'enums/entitlements.ts';
import { PurchaseOrderStatus } from 'enums/statuses.ts';

const AddSignatureModal = props => {
  // @ts-ignore
  const [canvas, setCanvas] = useState(String);
  const [vTitle, setVTitle] = useState('');
  const [canvasVisibility, setCanvasVisibility] = useState(false);
  const [accSignEnable, setAccSignEnable] = useState(true);
  const [continueBtnEnable, setContinueBtnEnable] = useState(true);

  const padRef = useRef(null);
  const user = useSelector(state => state.auth);
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);

  const isVendorOrAdmin =
    user.userType === UserType.VENDOR ||
    (user.userType === UserType.ADMIN &&
      (purchaseOrder.status === PurchaseOrderStatus.PENDING_INITIAL_REVIEW ||
        purchaseOrder.status ===
          PurchaseOrderStatus.PENDING_INITIAL_REVIEW_DECLINED));

  // Clear the signature canvas
  const clearSignatureCanvas = useCallback(() => {
    padRef?.current?.clear();
    setAccSignEnable(true);
    setCanvas(undefined);
    setCanvasVisibility(false);
    setContinueBtnEnable(true);
  }, []);

  // Capture the signature as a data URL
  const handleGetCanvas = useCallback(() => {
    const canvasData = padRef?.current?.toDataURL();
    if (
      (!isVendorOrAdmin && canvasData) ||
      (isVendorOrAdmin && canvasData && vTitle)
    ) {
      setContinueBtnEnable(false);
    } else {
      setContinueBtnEnable(true);
    }
    setAccSignEnable(true);
    setCanvas(canvasData);
    setCanvasVisibility(true);
    props.setSignatureBase64(canvasData);
  }, [props, padRef]);

  // Handle vendor title change
  const hnndleTitleChange = e => {
    const title = e.target.value;
    if (e.target.value && canvas) {
      setContinueBtnEnable(false);
    } else {
      setContinueBtnEnable(true);
    }
    setVTitle(title);
    props.setVendorTitle(title);
  };

  // Handle form submission
  const handleSubmit = () => {
    props.setSignatureBase64(canvas);
    props.setShowModal(false);
    props.handleContinueClick && props.handleContinueClick();
  };

  const handleModalClose = () => {
    clearSignatureCanvas();
    setVTitle('');
    setCanvasVisibility(false);
    setAccSignEnable(true);
    props.setShowModal(false);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleModalClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton="true">
          <Modal.Title>
            {props.title ? props.title : 'Accept Purchase Order'}
          </Modal.Title>
        </Modal.Header>
        <Divider />
        <Modal.Body>
          {props.subTitle ? (
            <div className="titleText">{props.subTitle}</div>
          ) : (
            <div className="titleText">Can you sign in the Box below</div>
          )}
          <div className="signatureBox">
            {canvasVisibility && canvas ? (
              <img src={canvas} alt="signature" />
            ) : (
              <SignatureCanvas
                ref={padRef}
                penColor="black"
                canvasProps={{
                  className: 'sigCanvas',
                }}
                onBegin={() => setAccSignEnable(false)}
              />
            )}
            <div className="sigOptions">
              <Button
                variant="link"
                className="sigBtn"
                onClick={clearSignatureCanvas}
              >
                Clear
              </Button>
              <Button
                onClick={handleGetCanvas}
                variant="link"
                className="sigBtn"
                disabled={accSignEnable}
              >
                Accept Signature
              </Button>
            </div>
          </div>
          {props.context === 'WorkAuth' && isVendorOrAdmin && (
            <div>
              <Form.Group controlId="form.reviewerComment">
                <Form.Control
                  onChange={e => hnndleTitleChange(e)}
                  name="vendorTitle"
                  value={vTitle}
                  autoComplete="off"
                />
                <br />
                <Form.Label className="vendorTitleLable">
                  Enter Job Title to Continue
                </Form.Label>
              </Form.Group>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonArea
            primaryLabel="Continue"
            primaryDisabled={continueBtnEnable}
            secondaryLabel="Cancel"
            type="submit"
            primaryOnClick={() => handleSubmit()}
            secondaryOnClick={handleModalClose}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { AddSignatureModal };
