import { AddIcon } from 'assets/images/AddIcon/AddIcon';
import { DownloadAllIcon } from 'assets/images/DownloadAll/DownloadAll';
import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { CheckIcon } from 'assets/images/CheckIcon/CheckIcon';
import { DeleteIcon } from 'assets/images/DeleteIcon/DeleteIcon';
import { ErrorIcon } from 'assets/images/ErrorIcon/ErrorIcon';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { ROUTES_STRING } from 'constants/routes';
import {
  SERVICE_TYPES_SELECTEDKEYS,
  SERVICE_TYPES_TABVALUES,
  SERVICE_TYPES_TABkEY,
  TAB_KEYS,
  bidRaterSheetFinalSaveData,
} from 'enums/serviceTypes.ts';
import { usePromiseTracker } from 'react-promise-tracker';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import _ from 'lodash';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  fetchBidRateLocations,
  fetchBidRateEquipmentTypes,
  fetchBidRateOwnershipTypes,
  fetchBidRateSheetDetails,
  fetchBidRateWorkCategory,
  saveBidRateSheetDetailsData,
  saveLaborRatesData,
  setBidRateRowPage,
  setBidRateSheetObj,
  setBidRateSheetPagination,
  setBidRateSheetSelectedTab,
  setBidRateSheetUpdatedData,
  setContinuBtnDisable,
  setPreferenceColumns,
  setMasterDataError,
  setShowValidate,
} from 'store/slices/BidRateSheets/bidRateSheetListSlice';
import '../Common/BidRateTable/BidRateTable.scss';
import { AddNewTabModal } from './AddNewTabModal';
import './BidRateSheetEntry.scss';
import { CompatibleUnitGroup } from './CompatibleUnitGroup';
import { DeleteTabConfirmationModal } from './DeleteTabConfirmationModal';
import { Equipment } from './Equipment';
import { LaborRates } from './LaborRates';
import { Markups } from './Markups';
import { Reimbursable } from './Reimbursable';
import { TaskCodeRates } from './TaskCodeRates';
import { UndergroundMultiRate } from './UndergroundMultiRate';
import { UnitPriceRates } from './UnitPriceRates';
import { newtabList, sortOrderMapping } from './Constant/BidrateSheetEntry';
import { yupResolver } from '@hookform/resolvers/yup';
import { ConfirmationModal } from 'components/common/modals/ConfirmatinModal';
import { format } from 'date-fns';
import { ModuleACL } from 'enums/entitlements.ts';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { IsAllowedACL } from 'utils/aclHelper';
import { getMidnightDate, isEmptyVal } from 'utils/utils';
import { handleDownloadAllPDF } from './../Common/BidRateTable/BidRateUtils';
import { generateSchema } from './../Common/BidRateTable/formHelpers';
import ClipLoader from 'react-spinners/ClipLoader';

const BidRateSheetEntry = () => {
  const user = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [key, setKey] = useState('');
  const [tabs, setTabs] = useState([]);
  const [disableSave, setDisableSave] = useState(false);
  const [tabList, setTabList] = useState([]);
  const [lastUpdatedDate, setLastUpdatedDate] = useState(new Date());
  const [addModalShow, setAddModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [serviceValue, setServicevalue] = useState();
  const [ddlTablList, setDdlTablList] = useState();
  const [bidRateName, setBidRateName] = useState('');
  const [contractorName, setContractorName] = useState('');
  const [navigateBack, setNavigateBack] = useState(false);
  const [bidRateExistingData, setbidRateExistingData] = useState({});
  const { promiseInProgress: bidRateLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.getBidRateDetails,
    delay: 0,
  });

  const defaultValues = {
    name: '',
    contractorname: '',
    mode: 'all',
    lastUpdatedDate: new Date(),
    bidrateLaborRates: [],
    bidrateEquipmentRates: [],
    bidrateUnionRates: [],
    bidrateReimbursibleMarkups: [],
    bidrateCus: [],
    bidrateUnitPrices: [],
    bidrateUndergrndMltplrRates: [],
    bidrateTaskCodes: [],
  };

  const ownershipType = useSelector(
    state => state.bidRateSheetList.ownershipType,
  );

  const equipmentType = useSelector(
    state => state.bidRateSheetList.equipmentType,
  );
  const schema = generateSchema(equipmentType, ownershipType);

  const methods = useForm({
    mode: 'onTouched',
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    trigger,
    setError,
    formState: { errors },
  } = methods;

  const allBidReteFields = watch();

  const bidRateSheetList = useSelector(state => state.bidRateSheetList);
  const selectedTab = useSelector(
    state => state.bidRateSheetList.selectedBidRateTab,
  );
  const bidRateSheetDetailsList = useSelector(
    state => state.bidRateSheetList.bidRateSheetDetailsList,
  );
  const bidRateCurrentSheet = useSelector(
    state => state.bidRateSheetList.bidRateSheetDetailsData,
  );
  const name = useSelector(
    state => state.bidRateSheetList.bidRateSheetDetailsList.name,
  );
  const newBidRateSheet = useSelector(
    state => state.bidRateSheetList.newBidRateSheet,
  );

  const masterDataError = useSelector(
    state => state.bidRateSheetList.masterDataErrors,
  );

  const showValidate = useSelector(
    state => state.bidRateSheetList.showValidate,
  );

  useEffect(() => {
    dispatch(fetchBidRateLocations());
    dispatch(fetchBidRateEquipmentTypes());
    dispatch(fetchBidRateOwnershipTypes());
    dispatch(fetchBidRateWorkCategory());
  }, []);

  useEffect(() => {
    if (!isEmptyVal(bidRateCurrentSheet.name)) {
      dispatch(setBidRateRowPage(true));
      setBidRateName(bidRateCurrentSheet.name);
      setValue('name', bidRateCurrentSheet.name);
    }

    if (!isEmptyVal(bidRateCurrentSheet.contractorname)) {
      setContractorName(bidRateCurrentSheet.contractorname);
      setValue('contractorname', bidRateCurrentSheet.contractorname);
    }

    if (bidRateSheetList.isStatus) {
      //dispatch(fetchBidRateSheetDetails(bidRateSheetList.bidRateNumber));
      navigate(
        `/${ROUTES_STRING.bidRateSheets}/${bidRateSheetList.bidRateNumber}`,
        {
          state: 'bidRateSheetRowPage',
        },
      );
      dispatch(setBidRateSheetObj(false));
    }
  }, [bidRateSheetDetailsList, bidRateSheetList]);

  useEffect(() => {
    if (selectedTab !== '') {
      setKey(selectedTab);
      let obj = {};
      obj['heirarchypath'] = SERVICE_TYPES_SELECTEDKEYS[selectedTab];
      dispatch(setBidRateSheetPagination(obj));
    }
  }, [selectedTab]);

  useEffect(() => {
    let value = 'Full';
    let updatedtabL = [];
    setTabList(_.sortBy(newtabList, ['sortOrder']));

    if (location.state === 'bidRateSheetRowPage') {
      Object.keys(bidRaterSheetFinalSaveData[0]).map(function (finalData) {
        Object.keys(bidRateSheetDetailsList).map(function (item) {
          if (finalData === item && bidRateSheetDetailsList[item]?.length) {
            updatedtabL.push({
              key: SERVICE_TYPES_TABkEY[item],
              value: SERVICE_TYPES_TABVALUES[item],
              sortOrder: sortOrderMapping[SERVICE_TYPES_TABkEY[item]],
            });
          }
        });
      });
      setBidRateName(bidRateSheetDetailsList.name);
      setContractorName(bidRateSheetDetailsList.contractorname);
      dispatch(
        setBidRateSheetPagination({
          totalItes: 0,
          currentPage: 1,
          itemsPerPage: 10,
          sortBy: '',
          sortOrder: 'asc',
        }),
      );

      let tabarr = [...newtabList];
      updatedtabL.map((val, index) => {
        let indextoremove;
        tabarr.find(function (value, index) {
          if (value.value == val['key']) {
            indextoremove = index;
            return index;
          }
        });
        tabarr.splice(indextoremove, 1);
      });
      let sortedTabList = _.sortBy(updatedtabL, ['sortOrder']);
      setDdlTablList(_.sortBy(tabarr, ['sortOrder']));
      setTabs(sortedTabList);
      if (tabList.length != 0) {
        if (sortedTabList[0]?.key) {
          let tabKey = _.isEmpty(selectedTab)
            ? sortedTabList[0]?.key
            : selectedTab;
          setKey(tabKey);
          dispatch(setBidRateSheetSelectedTab(tabKey));
        }
      }
      sortedTabList.map(tab => {
        let availableTab = TAB_KEYS[tab.key];
        let tabData = bidRateSheetDetailsList[availableTab];
        setValue(availableTab, tabData);
      });
    } else {
      setDdlTablList(newtabList);
    }
  }, [bidRateSheetDetailsList]);

  const handleTabTitle = title => {
    switch (title.key) {
      case 'laborRates':
        return Object.keys(masterDataError.bidrateLaborRates).length > 0 ||
          errors?.bidrateLaborRates?.length ? (
          <p>
            <span style={{ paddingRight: '5px' }}>
              <ErrorIcon />
            </span>
            {title.value}
          </p>
        ) : (
          title.value
        );
        break;

      case 'equipment':
        return Object.keys(masterDataError.bidrateEquipmentRates).length > 0 ||
          errors?.bidrateEquipmentRates?.length ? (
          <p>
            <span style={{ paddingRight: '5px' }}>
              <ErrorIcon />
            </span>
            {title.value}
          </p>
        ) : (
          title.value
        );
        break;

      case 'reimbursableMarkup':
        return Object.keys(masterDataError.bidrateReimbursibleMarkups).length >
          0 || errors?.bidrateReimbursibleMarkups?.length ? (
          <p>
            <span style={{ paddingRight: '5px' }}>
              <ErrorIcon />
            </span>
            {title.value}
          </p>
        ) : (
          title.value
        );
        break;
      case 'unitPriceRates':
        return Object.keys(masterDataError.bidrateUnitPrices).length > 0 ||
          errors?.bidrateUnitPrices?.length ? (
          <p>
            <span style={{ paddingRight: '5px' }}>
              <ErrorIcon />
            </span>
            {title.value}
          </p>
        ) : (
          title.value
        );
        break;
      case 'compatibleUnitGroups':
        return Object.keys(masterDataError.bidrateCus).length > 0 ||
          errors?.bidrateCus?.length ? (
          <p>
            <span style={{ paddingRight: '5px' }}>
              <ErrorIcon />
            </span>
            {title.value}
          </p>
        ) : (
          title.value
        );
        break;
      case 'undergroundMultiplier':
        return Object.keys(masterDataError.bidrateUndergrndMltplrRates).length >
          0 || errors?.bidrateUndergrndMltplrRates?.length ? (
          <p>
            <span style={{ paddingRight: '5px' }}>
              <ErrorIcon />
            </span>
            {title.value}
          </p>
        ) : (
          title.value
        );
        break;
      case 'markups':
        return Object.keys(masterDataError.bidrateUnionRates).length > 0 ||
          errors?.bidrateUnionRates?.length ? (
          <p>
            <span style={{ paddingRight: '5px' }}>
              <ErrorIcon />
            </span>
            {title.value}
          </p>
        ) : (
          title.value
        );
        break;
      default:
        return title.value;
        break;
    }
  };

  const handleCancelClick = () => {
    navigate('/bidRateSheets', { state: { from: 'bidrateDetail' } });
    setNavigateBack(false);
  };

  //function to add service type tab on bid rate sheet
  const handleTemplateClick = serviceValue => {
    let tabValues = {};
    let tabLt = [];

    setServicevalue(serviceValue);
    setAddModalShow(false);
    newtabList.map((el, id) => {
      if (el.value == serviceValue) {
        tabValues.key = el.value;
        tabValues.value = el.label;
        tabValues.sortOrder = el.sortOrder;
      }
    });

    let updatedtabList = tabList.filter(function (tabl) {
      return tabl.value !== serviceValue;
    });
    let updatedddlTablList = ddlTablList.filter(function (tabl) {
      return tabl.value !== serviceValue;
    });

    setDdlTablList(updatedddlTablList);
    setTabList(updatedtabList);
    tabLt.push(tabValues);
    setTabs(_.sortBy([...tabs, ...tabLt], ['sortOrder']));
    if (tabLt.length != 0) {
      setKey(tabLt[0].key);
      dispatch(setBidRateSheetSelectedTab(tabLt[0].key));
      let obj = {};
      obj['heirarchypath'] = SERVICE_TYPES_SELECTEDKEYS[tabLt[0].key];
      dispatch(setBidRateSheetPagination(obj));
    }
  };

  //function to delete service type from bid rate sheet
  const handleTabDeleteContinueClick = () => {
    let obj = {};
    const tabList = [...tabs];
    let updatedDdlTablList = [...ddlTablList];
    const deleteTabs = tabs.findIndex(obj => obj.key == key);
    if (tabList.length != 0) {
      tabList.map((val, index) => {
        if (index === deleteTabs) {
          obj.label = val.value;
          obj.value = val.key;
          obj.sortOrder = val.sortOrder;
        }
      });
    }
    updatedDdlTablList.push(obj);
    tabList.splice(deleteTabs, 1);
    if (tabList.length != 0) {
      let setTab = tabList.length <= deleteTabs ? deleteTabs - 1 : deleteTabs;
      setKey(tabList[setTab].key);
      dispatch(setBidRateSheetSelectedTab(tabList[setTab].key));
    }
    setTabs(tabList);
    setDdlTablList(_.sortBy(updatedDdlTablList, ['sortOrder']));

    let currentSheet = { ...bidRateCurrentSheet };

    let masterError = { ...masterDataError };
    let updateShowValidate = { ...showValidate };
    masterError[TAB_KEYS[obj.value]] = {};
    updateShowValidate[TAB_KEYS[obj.value]] = false;
    setValue(`${TAB_KEYS[obj.value]}`, []);
    delete errors[TAB_KEYS[obj.value]];
    currentSheet[TAB_KEYS[obj.value]] = [];
    dispatch(
      setPreferenceColumns({
        serviceType: TAB_KEYS[obj.value],
        valuesList: [],
      }),
    );
    dispatch(setMasterDataError(masterError));
    dispatch(setShowValidate(updateShowValidate));
    dispatch(setBidRateSheetUpdatedData(currentSheet));
    setDeleteModalShow(false);
  };

  const onerror = data => {
    console.log({ onError: data });
  };

  const updateStartAndEndDates = payload => {
    // Convert start and end dates to string type
    for (const [key, value] of Object.entries(payload)) {
      if (Array.isArray(value)) {
        value.map(obj => {
          if (
            obj.hasOwnProperty('effStartDate') &&
            !isEmptyVal(obj['effStartDate'])
          ) {
            obj['effStartDate'] = format(
              getMidnightDate(obj['effStartDate']),
              'yyyy-MM-dd',
            );
          }
          if (
            obj.hasOwnProperty('effEndDate') &&
            !isEmptyVal(obj['effEndDate'])
          ) {
            obj['effEndDate'] = format(
              getMidnightDate(obj['effEndDate']),
              'yyyy-MM-dd',
            );
          }
        });
      }
    }
    return payload;
  };

  //function to save bid rate sheets
  const handleLaborRateSave = data => {
    const validate_only = data['validate_only'];
    if (!isEmptyVal(validate_only) && validate_only) {
      return;
    }

    const bidRaterSheetFinalSaveData = [
      {
        bidrateEquipmentRates: _.cloneDeep(data.bidrateEquipmentRates),
        bidrateLaborRates: _.cloneDeep(data.bidrateLaborRates),
        bidrateCus: _.cloneDeep(data.bidrateCus),
        bidrateReimbursibleMarkups: _.cloneDeep(
          data.bidrateReimbursibleMarkups,
        ),
        bidrateTaskCodes: _.cloneDeep(data.bidrateTaskCodes),
        bidrateUndergrndMltplrRates: _.cloneDeep(
          data.bidrateUndergrndMltplrRates,
        ),
        bidrateUnionRates: _.cloneDeep(data.bidrateUnionRates),
        bidrateUnitPrices: _.cloneDeep(data.bidrateUnitPrices),
      },
    ];
    const existingSheetData = {
      ...bidRateCurrentSheet,
      ...bidRaterSheetFinalSaveData[0],
    };
    if (_.isEmpty(errors)) {
      if (!newBidRateSheet) {
        // Update BidRateSheet Data
        let obj = updateStartAndEndDates(existingSheetData);
        obj.name = bidRateName;
        obj.preference = _.cloneDeep(bidRateSheetList.preferenceColumns);
        obj.contractorname = contractorName;
        setbidRateExistingData(obj);
        dispatch(
          // @ts-ignore
          saveBidRateSheetDetailsData({
            bitRateNumber: bidRateSheetList.bidRateSheetDetailsList.bidrateNmbr,
            bidRateSheetFinalData: obj,
          }),
        );
      } else {
        // Save BidRateSheet Data
        let newBidRateSheet = updateStartAndEndDates(
          bidRaterSheetFinalSaveData[0],
        );
        newBidRateSheet.caastatus = 'SAVED';
        newBidRateSheet.name = bidRateName;
        newBidRateSheet.contractorname = contractorName;
        newBidRateSheet.preference = _.cloneDeep(
          bidRateSheetList.preferenceColumns,
        );
        if (bidRateSheetList.bidRateNumber !== '') {
          setbidRateExistingData(newBidRateSheet);
          dispatch(
            // @ts-ignore
            saveBidRateSheetDetailsData({
              bitRateNumber: bidRateSheetList.bidRateNumber,
              bidRateSheetFinalData: newBidRateSheet,
            }),
          );
        } else {
          // @ts-ignore
          setDisableSave(true);
          setbidRateExistingData(newBidRateSheet);
          dispatch(
            saveLaborRatesData({
              newBidRateSheet: [newBidRateSheet],
              callback: async data => {
                setDisableSave(false);
              },
            }),
          );
        }
      }
    }
  };

  const handlebackbtnClick = () => {
    navigate(`/${ROUTES_STRING.bidRateSheets}`, {
      state: { from: 'bidrateDetail' },
    });
  };

  const handleSelect = k => {
    let obj = {};

    obj['heirarchypath'] = SERVICE_TYPES_SELECTEDKEYS[k];

    setKey(k);
    dispatch(setBidRateSheetPagination(obj));
    dispatch(setBidRateSheetSelectedTab(k));
  };

  const handleAddNewTabModal = k => {
    setAddModalShow(true);
    dispatch(setContinuBtnDisable(true));
  };

  const handleName = event => {
    setValue('name', event?.target.value);
    trigger('name', event.target.value);
    setBidRateName(event.target.value);
    let currentSheet = { ...bidRateCurrentSheet };
    currentSheet.name = event.target.value;
    dispatch(setBidRateSheetUpdatedData(currentSheet));
  };

  const handleContractorName = event => {
    setValue('contractorname', event?.target.value);
    trigger('contractorname', event.target.value);
    setContractorName(event.target.value);
    let currentSheet = { ...bidRateCurrentSheet };
    currentSheet.contractorname = event.target.value;
    dispatch(setBidRateSheetUpdatedData(currentSheet));
  };

  const sheetStatus = newBidRateSheet
    ? bidRateSheetList.caaStatus
    : bidRateSheetList.bidRateSheetDetailsList.caastatus;

  const isDisableDownloadAll = () => bidRateSheetList.bidRateNumber === '';
  const handleDownloadAll = () => {
    const latestBidRateSheet =
      Object.keys(bidRateExistingData).length !== 0
        ? bidRateExistingData
        : bidRateCurrentSheet;
    handleDownloadAllPDF(
      tabs,
      bidRateSheetList.bidRateNumber,
      latestBidRateSheet,
      bidRateSheetList.preferenceColumns,
    );
  };

  return (
    <>
      <div>
        <div className="aepContainer aepHeader">Bid Rate Sheet</div>
        <div className="aepContainer aepBreadcrumb">
          {bidRateSheetList.bidRateRowPage != true ? (
            <Row>
              <Col sm={12} style={{ display: 'flex' }}>
                <div className="backArrow" onClick={() => handlebackbtnClick()}>
                  <BackArrowIcon />
                </div>
                Create New Bid Rate Sheet
                <span>:{bidRateSheetList.bidRateNumber}</span>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={6} style={{ display: 'flex' }}>
                <div className="backArrow" onClick={() => handlebackbtnClick()}>
                  <BackArrowIcon />
                </div>
                Bid Rate Sheet Number: {bidRateSheetList.bidRateNumber}
              </Col>
              <Col sm={6}>
                <Row className="float-right">
                  <div className="statusIcon"></div>Status:&nbsp;
                  {sheetStatus}
                </Row>
              </Col>
            </Row>
          )}
        </div>

        <div>
          <div className="aepContainer">
            <div className="innerContainer">
              <FormProvider {...methods}>
                <Form>
                  <Row>
                    <Col sm={3}>
                      <Form.Group controlId="form.startDate">
                        <Form.Label>Filename:</Form.Label>

                        <Controller
                          control={control}
                          name="Name"
                          defaultValue=""
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Form.Control
                              isInvalid={
                                errors?.name?.['message'] ||
                                errors?.name?.['message']
                              }
                              onChange={event => {
                                handleName(event);
                              }}
                              name="bidRateName"
                              value={bidRateName}
                              ref={ref}
                              type="text"
                            />
                          )}
                        />
                        {errors.name ? (
                          <div className="errorMessage">
                            <ErrorIcon />
                            {errors.name['message']}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="form.startDate">
                        <Form.Label>Contractor Name:</Form.Label>

                        <Controller
                          control={control}
                          name="Contractor Name"
                          defaultValue=""
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Form.Control
                              value={contractorName}
                              isInvalid={errors?.contractorname?.['message']}
                              onChange={event => {
                                handleContractorName(event);
                              }}
                              name="contractorname"
                              ref={ref}
                              type="text"
                            />
                          )}
                        />
                        {errors.contractorname && (
                          <div className="errorMessage">
                            <ErrorIcon />
                            {errors.contractorname['message']}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group>
                        <Form.Label>Last Updated Date:</Form.Label>
                        <DatePicker
                          // selected={lastUpdatedDate}
                          onChange={() => {}}
                          onKeyDown={e => {
                            e.preventDefault();
                          }}
                          disabled
                          selected={
                            bidRateSheetList.bidRateRowPage
                              ? moment(lastUpdatedDate, 'YYYY-MM-DD').toDate()
                              : lastUpdatedDate
                          }
                          className="date-picker-icon-top"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </FormProvider>
            </div>
          </div>
          <div className="aepContainer bidRateSheetContainer">
            <div className="aepBreadcrumb bidRateSheetInnerContainer">
              <Row>
                <Col sm={12}>
                  <div className="upperRightIcons">
                    <div className="shortIcons">
                      <span
                        title="Add New Tab"
                        onClick={e => handleAddNewTabModal()}
                        className="svgStyleOne"
                      >
                        <AddIcon />
                      </span>
                      <span
                        title="Download All"
                        onClick={() =>
                          !isDisableDownloadAll() && handleDownloadAll()
                        }
                        className={
                          isDisableDownloadAll()
                            ? 'svgStyleTwo disable-icon'
                            : 'svgStyleTwo'
                        }
                      >
                        <DownloadAllIcon />
                      </span>
                      <span
                        title="Delete Tab"
                        onClick={e => setDeleteModalShow(true)}
                        className="svgStyleOne"
                      >
                        <DeleteIcon />
                      </span>
                    </div>
                    {tabs.length > 0 && (
                      <div className="submitButtonDivWithoutMrgn">
                        {IsAllowedACL(
                          user,
                          ModuleACL.BIDRATESHEET,
                          'can_submit_bidratesheet',
                        ) && (
                          <Fragment>
                            <ButtonArea
                              primaryLabel="Save"
                              secondaryLabel="Cancel"
                              primaryDisabled={
                                Object.values(showValidate).some(
                                  val => val === true,
                                ) || disableSave
                              }
                              primaryOnClick={handleSubmit(
                                handleLaborRateSave,
                                onerror,
                              )}
                              secondaryOnClick={() => {
                                setNavigateBack(true);
                              }}
                            />
                          </Fragment>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="bidRateSheetInnerLaborContainer">
              {tabs.length === 0 ? (
                <div className="add">
                  <div className=" aepHeader">You havent added any tab yet</div>
                  <div className=" aepBreadcrumb">
                    Add new tab to see the details
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <Tabs
                      id="serviceTabs"
                      activeKey={selectedTab}
                      onSelect={k => handleSelect(k)}
                    >
                      {tabs.map((tab, index) => (
                        <Tab
                          eventKey={tab.key}
                          title={handleTabTitle(tab)}
                          key={index}
                          tabClassName="tab-item"
                        >
                          {tab.key == 'laborRates' && key === tab.key ? (
                            <LaborRates
                              bidRateRowPage={bidRateSheetList.bidRateRowPage}
                              defaultValues={defaultValues}
                              setValue={setValue}
                              trigger={trigger}
                              setError={setError}
                              methods={methods}
                              watch={watch}
                              errors={errors}
                              handleBidRateSave={handleLaborRateSave}
                            />
                          ) : tab.key == 'equipment' && key === tab.key ? (
                            <Equipment
                              bidRateRowPage={bidRateSheetList.bidRateRowPage}
                              defaultValues={defaultValues}
                              setValue={setValue}
                              trigger={trigger}
                              setError={setError}
                              methods={methods}
                              watch={watch}
                              errors={errors}
                              handleBidRateSave={handleLaborRateSave}
                            />
                          ) : tab.key == 'reimbursableMarkup' &&
                            key === tab.key ? (
                            <Reimbursable
                              bidRateRowPage={bidRateSheetList.bidRateRowPage}
                              defaultValues={defaultValues}
                              setValue={setValue}
                              trigger={trigger}
                              setError={setError}
                              methods={methods}
                              watch={watch}
                              errors={errors}
                              handleBidRateSave={handleLaborRateSave}
                            />
                          ) : tab.key == 'unitPriceRates' && key === tab.key ? (
                            <UnitPriceRates
                              bidRateRowPage={bidRateSheetList.bidRateRowPage}
                              defaultValues={defaultValues}
                              setValue={setValue}
                              trigger={trigger}
                              setError={setError}
                              methods={methods}
                              watch={watch}
                              errors={errors}
                              handleBidRateSave={handleLaborRateSave}
                            />
                          ) : tab.key == 'taskCodeRates' && key === tab.key ? (
                            <TaskCodeRates
                              bidRateRowPage={bidRateSheetList.bidRateRowPage}
                              defaultValues={defaultValues}
                              setValue={setValue}
                              trigger={trigger}
                              setError={setError}
                              methods={methods}
                              watch={watch}
                              errors={errors}
                              handleBidRateSave={handleLaborRateSave}
                            />
                          ) : tab.key == 'compatibleUnitGroups' &&
                            key === tab.key ? (
                            <CompatibleUnitGroup
                              bidRateRowPage={bidRateSheetList.bidRateRowPage}
                              defaultValues={defaultValues}
                              setValue={setValue}
                              trigger={trigger}
                              setError={setError}
                              methods={methods}
                              watch={watch}
                              errors={errors}
                              handleBidRateSave={handleLaborRateSave}
                            />
                          ) : tab.key == 'undergroundMultiplier' &&
                            key === tab.key ? (
                            <UndergroundMultiRate
                              bidRateRowPage={bidRateSheetList.bidRateRowPage}
                              defaultValues={defaultValues}
                              setValue={setValue}
                              trigger={trigger}
                              setError={setError}
                              methods={methods}
                              watch={watch}
                              errors={errors}
                              handleBidRateSave={handleLaborRateSave}
                            />
                          ) : tab.key == 'markups' && key === tab.key ? (
                            <Markups
                              bidRateRowPage={bidRateSheetList.bidRateRowPage}
                              defaultValues={defaultValues}
                              setValue={setValue}
                              trigger={trigger}
                              setError={setError}
                              methods={methods}
                              watch={watch}
                              errors={errors}
                              handleBidRateSave={handleLaborRateSave}
                            />
                          ) : null}
                        </Tab>
                      ))}
                    </Tabs>
                  </div>
                </>
              )}
              {Object.keys(errors).length > 0 && (
                <div className="errorMessage bidrate-save-error-message">
                  <p>Please resolve errors before saving</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <ConfirmationModal
          showModal={navigateBack}
          setShowModal={setNavigateBack}
          handleContinueClick={() => handleCancelClick()}
          title="Cancel Sheet?"
          body="Are you sure you want to leave this page?"
        />

        <AddNewTabModal
          show={addModalShow}
          setShowModal={setAddModalShow}
          tabList={ddlTablList}
          handleTemplateClick={handleTemplateClick}
        />
        <DeleteTabConfirmationModal
          show={deleteModalShow}
          setShowModal={setDeleteModalShow}
          selectedTabs={tabs}
          selectedKey={key}
          handleTabDeleteContinueClick={handleTabDeleteContinueClick}
        />
        <ClipLoader
          color="#009cde"
          loading={bidRateLoading}
          cssOverride={{
            top: '50vh',
            left: '50vw',
            position: 'absolute',
            zIndex: 1,
          }}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </>
  );
};
export { BidRateSheetEntry };
